import React, { useEffect, useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import {
  AutocompleteField,
  DateInputField,
  InputField,
  NumberInputField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
  OIB_LENGTH,
  PHONE_NUMBER_REGEX,
} from "../../../common/constants";
import { isOibValid } from "../../../common/isOibValid";
import {
  DATE_OF_MEMBERSHIP_START_IS_BEFORE_DATE_OF_BIRTH_MESSAGE,
  DECIMAL_OR_NUMBER_MESSAGE,
  DIGITS_ONLY_MESSAGE,
  INVALID_EMAIL_FORMAT_MESSAGE,
  INVALID_OIB_MESSAGE,
  MAX_TWO_PARENTS,
  maxCharacterCountMessage,
  maxDigitCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
  OIB_DIGIT_COUNT_MESSAGE,
  OIB_DIGITS_ONLY_MESSAGE,
  PERCENTAGE_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import { postFilterUsers } from "../../common/api/postFilterUsers";
import { UserResponse, UserType } from "../../common/api/UserResponse";
import handleUpdateResponse from "../../common/handleUpdateResponse";
import { isBlank } from "../../common/util/stringUtil";
import { GroupResponse } from "../../group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../group/api/postSearchGroupRequestNonPaged";
import { ParentBriefResponse } from "../../parent/api/ParentBriefResponse";
import { AthleteResponse } from "../api/AthleteResponse";
import { putUpdateAthleteRequest } from "../api/putUpdateAthleteRequest";
import { UpdateAthleteRequest } from "../api/UpdateAthleteRequest";

type RemoveAthleteFromGroupProps = {
  modal: UseModal;
  athlete: AthleteResponse;
  setRender: (render: boolean) => void;
};

type Form = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | string;
  dateOfMembershipStart: Date | string;
  discountPercentage?: number;
  phoneNumber: string;
  oib: string;
  email: string | null;
  parents: UserResponse[];
  groups: GroupResponse[];
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  email: yup
    .string()
    .nullable()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE),
  phoneNumber: yup
    .string()
    .nullable()
    .max(MAX_PHONE_NUMBER_LENGTH, maxDigitCountMessage(MAX_PHONE_NUMBER_LENGTH))
    .matches(new RegExp(PHONE_NUMBER_REGEX), DIGITS_ONLY_MESSAGE),
  oib: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .matches(/^\d{0,11}$/, OIB_DIGITS_ONLY_MESSAGE)
    .length(OIB_LENGTH, OIB_DIGIT_COUNT_MESSAGE)
    .test(
      "oibValidationTest",
      INVALID_OIB_MESSAGE,
      (input: String | undefined | null) => {
        if (input) {
          return isOibValid(input);
        }
        return false;
      }
    ),
  dateOfBirth: yup.date().required(REQUIRED_FIELD_MESSAGE),
  parents: yup.array().max(2, MAX_TWO_PARENTS),
  dateOfMembershipStart: yup
    .date()
    .required("Datum upisa u klub je obvezan")
    .when("dateOfBirth", (dateOfBirth) => {
      if (dateOfBirth)
        return yup
          .date()
          .required(REQUIRED_FIELD_MESSAGE)
          .min(
            dateOfBirth,
            DATE_OF_MEMBERSHIP_START_IS_BEFORE_DATE_OF_BIRTH_MESSAGE
          );
      else return yup.date().required(REQUIRED_FIELD_MESSAGE);
    }),
  discountPercentage: yup
    .number()
    .min(0, PERCENTAGE_MESSAGE)
    .max(100, PERCENTAGE_MESSAGE)
    .typeError(DECIMAL_OR_NUMBER_MESSAGE),
});

const UpdateAthleteModal = ({
  modal,
  athlete,
  setRender,
}: RemoveAthleteFromGroupProps) => {
  const { add: addNotification } = useNotifications();

  const [groupsAutocomplete, setGroupsAutocomplete] = useState(
    [] as GroupResponse[]
  );

  function parentSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as UserResponse[]);
    else
      return postFilterUsers({
        name: query,
        userType: [UserType.PARENT],
      });
  }

  useEffect(() => {
    postSearchGroupRequestNonPaged({
      groupName: "",
      briefResponse: true,
    }).then((response) => {
      setGroupsAutocomplete(response);
    });
  }, []);

  const initialFormValues = {
    firstName: athlete.firstName,
    lastName: athlete.lastName,
    dateOfBirth: athlete.dateOfBirth,
    dateOfMembershipStart: athlete.dateOfMembershipStart,
    discountPercentage:
      athlete.discountPercentage === 0 ? undefined : athlete.discountPercentage,
    phoneNumber: athlete.phoneNumber,
    email: athlete.email,
    parents: athlete.parents.map((parent: ParentBriefResponse) => {
      return {
        id: parent.id,
        firstName: parent.firstName,
        lastName: parent.lastName,
        email: parent.email,
        phoneNumber: parent.phoneNumber,
        userType: UserType.COACH,
      } as UserResponse;
    }),
    oib: athlete.oib,
    groups: athlete.groups,
  } as Form;

  const onSubmitForm = (form: Form) => {
    putUpdateAthleteRequest(
      {
        firstName: form.firstName,
        lastName: form.lastName,
        oib: form.oib,
        gender: athlete.gender,
        dateOfBirth: form.dateOfBirth,
        dateOfMembershipStart: form.dateOfMembershipStart,
        discountPercentage: form.discountPercentage ?? 0,
        phoneNumber: form.phoneNumber ? form.phoneNumber : null,
        email: !isBlank(form.email) ? form.email : null,
        groupToRemoveIds: athlete.groups.map((group) => {
          return group.id;
        }),
        groupToAddIds: form.groups.map((group) => {
          return group.id;
        }),
        parentIds: form.parents.map((parent: any) => {
          return parent.id;
        }),
      } as UpdateAthleteRequest,
      athlete.id
    ).then((response) => {
      if (handleUpdateResponse(response, addNotification)) {
        setRender(true);
        modal.onClose();
      }
    });
  };

  return (
    <>
      <Modal {...modal}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              className="pr-3"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <Typography className="mb-4" variant="h4" element="h4">
                Uredi podatke sportaša
              </Typography>
              <div className="flex flex-row">
                <InputField
                  name="firstName"
                  label={"Ime"}
                  className="mr-3 w-1/2"
                  allowClear={true}
                  required={true}
                  placeholder={athlete.firstName}
                />
                <InputField
                  name="lastName"
                  label={"Prezime"}
                  className="ml-3 w-1/2"
                  allowClear={true}
                  required={true}
                  placeholder={athlete.lastName}
                />
              </div>
              <InputField
                name="oib"
                label={"OIB"}
                className="mt-3"
                allowClear={true}
                required={true}
                maxLength={OIB_LENGTH}
                placeholder={athlete.oib}
              />
              <InputField
                name="email"
                label={"Email"}
                className="mt-3"
                allowClear={true}
                placeholder={athlete.email}
              />
              <InputField
                name="phoneNumber"
                label={"Broj mobitela"}
                className="mt-3"
                allowClear={true}
              />
              <DateInputField
                name="dateOfBirth"
                className="mt-3"
                label="Datum rođenja:"
                maxDate={new Date()}
                allowClear={false}
                required={true}
              />
              <DateInputField
                name="dateOfMembershipStart"
                className="mt-3"
                label="Datum upisa u klub"
                maxDate={new Date()}
                allowClear={false}
                required={true}
              />
              <NumberInputField
                name="discountPercentage"
                label="Popust članarine"
                className="mt-3"
                allowClear={false}
                required={false}
                inlineTrailingIcon={<Icon type="percent" variant="light" />}
                decimalScale={2}
                placeholder="0,00"
                fixedDecimalScale
              />
              <div className="mt-3 flex flex-col mb-3">
                <AutocompleteField
                  className="w-full"
                  name="parents"
                  label={"Roditelji"}
                  options={parentSearch}
                  placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
                  allowMultiple
                  getOptionLabel={(user: UserResponse) =>
                    `${user.firstName} ${user.lastName}`
                  }
                  getOptionValue={(item) => item.id}
                  getMultipleSelectedLabel={(items) => {
                    return items
                      .map(
                        (user: UserResponse) =>
                          `${user.firstName} ${user.lastName}`
                      )
                      .join(", ");
                  }}
                  sendOptionValue={false}
                />
                <AutocompleteField
                  className="w-full mt-3"
                  name="groups"
                  label={"Grupa/e u kojima je sportaš"}
                  options={groupsAutocomplete}
                  allowMultiple
                  getOptionLabel={(group) => `${group.groupName}`}
                  getOptionValue={(item) => {
                    return item;
                  }}
                  filter={(query: string, group) =>
                    `${group.groupName.toLowerCase()}`.includes(
                      query.toLowerCase()
                    )
                  }
                  getMultipleSelectedLabel={(items) => {
                    return items
                      .map((group) => `${group.groupName}`)
                      .join(", ");
                  }}
                  sendOptionValue={false}
                />
              </div>
              <div className="mt-6 flex flex-row justify-end">
                <Button
                  type="button"
                  className="mr-2 w-100"
                  variant="outlined"
                  onClick={() => {
                    modal.onClose();
                  }}
                >
                  Odbaci promjene
                </Button>
                <Button className="w-1/5" variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateAthleteModal;
