import React, { useState } from "react";

import { Pagination, Typography } from "@tiller-ds/core";

import { Link } from "react-router-dom";

import { GroupResponse } from "../../archive/group/api/GroupResponse";
import GroupCard from "../../common/components/cards/GroupCard";

type GroupsListProps = {
  groupList: GroupResponse[];
};

export default function GroupsList({ groupList }: GroupsListProps) {
  const [page, setPage] = useState(0);
  const MAX_ELEMENT_NUMBER_PER_PAGE = 3;

  const groups = groupList
    .sort((group1, group2) => group1.groupName.localeCompare(group2.groupName))
    .map((group) => {
      return group;
    });

  return (
    <>
      <Typography
        element="h3"
        variant="h4"
        className="font-biotif text-primary"
      >
        Grupe
      </Typography>
      {groups.length > 0 ? (
        <div className="w-full">
          <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-x-4">
            {groups
              .slice(
                page * MAX_ELEMENT_NUMBER_PER_PAGE,
                page * MAX_ELEMENT_NUMBER_PER_PAGE + MAX_ELEMENT_NUMBER_PER_PAGE
              )
              .map((group) => {
                return (
                  <div className="w-full lg:w-1/3 ">
                    <Link to={`/archive/groups/${group.id}`}>
                      <GroupCard group={group} />
                    </Link>
                  </div>
                );
              })}
          </div>
          {groups.length > MAX_ELEMENT_NUMBER_PER_PAGE && (
            <div className="w-full mt-1 flex justify-center">
              <div className="justify-center">
                <Pagination
                  onPageChange={(i: number) => {
                    setPage(i);
                  }}
                  totalElements={groups.length}
                  pageNumber={page}
                  pageSize={MAX_ELEMENT_NUMBER_PER_PAGE}
                >
                  {() => null}
                </Pagination>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Typography element="p" variant="subtext" className="italic">
          Nema grupa za prikaz
        </Typography>
      )}
    </>
  );
}
