import React, { useCallback, useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Card, Pagination, Tabs, Typography } from "@tiller-ds/core";
import { DateRangeInputField, SelectField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { Formik } from "formik";
import moment from "moment";

import QRModal from "./QRModalPage";
import { UserContext } from "../../../auth/SecurityProvider";
import {
  INVOICE_STATUS_OPTIONS,
  InvoiceStatus,
  SelectType,
} from "../../../common/constants";
import { postSearchInvoiceRequest } from "../../common/api/postSearchInvoiceRequest";
import { SearchInvoiceRequest } from "../../common/api/searchInvoiceRequest";
import { InvoiceResponse } from "../../common/InvoiceResponse";
import { getGenerateQRCode } from "../api/getGenerateQRCode";
import { getNumOfUnpaidInvoices } from "../api/getNumOfUnpaidInvoices";
import { putSetIsPaidRequest } from "../api/putSetIsPaidRequest";
import { ParentInvoiceTable } from "../components/ParentInvoiceTable";

type FilterForm = {
  startDate: Date | null;
  endDate: Date | null;
  status: InvoiceStatus | null;
};

const initialFilterFormValues = {
  startDate: null,
  endDate: null,
  status: InvoiceStatus.NEPODMIRENO,
} as FilterForm;

export function ParentInvoicesViewPage() {
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
  const userContext = useContext(UserContext);
  const [imageDataUrl, setImageDataUrl] = useState<any>();
  const qrModal = useModal();
  const [filters, setFilters] = useState(initialFilterFormValues);
  const [pagination, setPagination] = useState<{
    page: number;
    totalInvoices: number;
  }>({
    page: 0,
    totalInvoices: 0,
  });

  const getData = useCallback(() => {
    const searchRequest: SearchInvoiceRequest = {
      dueDateFromIncluding: filters.startDate
        ? new Date(moment(new Date(filters.startDate)).toISOString())
        : null,
      dueDateToIncluding: filters.endDate
        ? new Date(
            moment(new Date(filters.endDate)).add(1, "day").toISOString()
          )
        : null,
      status: filters.status,
      athleteParentsFirstName: userContext.user.firstName,
      athleteParentsLastName: userContext.user.lastName,
      pageNumber: pagination.page,
    };

    postSearchInvoiceRequest(searchRequest).then((response: any) => {
      setInvoices(response.content);
      setPagination({
        page: pagination.page,
        totalInvoices: response.totalElements,
      });
    });
  }, [
    pagination.page,
    filters,
    userContext.user.firstName,
    userContext.user.lastName,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getNumOfUnpaidInvoices(userContext.user.id).then((response) => {
      userContext.onChangeNumOfUnpaidInvoices(response.numOfUnpaidInvoices);
    });
    // eslint-disable-next-line
  }, [invoices]);

  function onFilterFormSubmit(form: FilterForm) {
    setFilters({
      ...form,
    });
  }

  function toggleInvoiceIsPaidStatus(invoiceId: number) {
    const existingInvoice = invoices.find(
      (invoice) => invoice.id === invoiceId
    );

    if (!existingInvoice) {
      return;
    }

    putSetIsPaidRequest({
      invoiceId: invoiceId,
      isPaid: !existingInvoice.isPaid,
    }).then((response) => {
      setInvoices((prevState) => {
        const invoiceIndex = prevState.indexOf(existingInvoice);
        prevState[invoiceIndex] = response;

        return prevState;
      });
    });
  }

  function onQRButtonClick(invoiceId: number): void {
    getGenerateQRCode(invoiceId)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          setImageDataUrl(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .then(() => {
        qrModal.onOpen(null);
      });
  }

  return (
    <>
      <div className="mr-3 ml-3 sm:mr-0 sm:ml-0">
        <Typography element="h3" variant="h3" className="font-biotiff">
          Računi
        </Typography>
        <div className="mt-5">
          <Card>
            <Card.Body>
              <div className="flex flex-col space-y-10 sm:m-0">
                <Tabs defaultIndex={0}>
                  <Tabs.Tab label="Pregled računa">
                    <div className="mt-5">
                      <Formik
                        initialValues={initialFilterFormValues}
                        onSubmit={onFilterFormSubmit}
                      >
                        {(formik) => (
                          <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-col gap-y-5 sm:gap-y-0 sm:flex-row sm:gap-x-3 sm:justify-end mb-3">
                              <div className="w-full flex flex-row justify-end">
                                <DateRangeInputField
                                  className="w-full sm:w-[270px]"
                                  allowClear={true}
                                  onReset={() => {
                                    formik.setFieldValue("startDate", null);
                                    formik.setFieldValue("endDate", null);
                                    setFilters({
                                      ...filters,
                                    });
                                  }}
                                  label="Datum dospijeća od - do"
                                  start="startDate"
                                  end="endDate"
                                />
                              </div>
                              <SelectField
                                name="status"
                                options={INVOICE_STATUS_OPTIONS}
                                getOptionLabel={(item: SelectType) => item.name}
                                getOptionValue={(item: SelectType) =>
                                  item.value
                                }
                                hideClearButton={true}
                                className="mt-auto"
                              />
                              <div className="flex flex-row justify-end">
                                <Button
                                  type="submit"
                                  className="items-center mt-auto w-1/5 sm:w-fit"
                                >
                                  <Icon type="funnel" />
                                </Button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <ParentInvoiceTable
                      invoices={invoices}
                      paidCheckboxHandler={toggleInvoiceIsPaidStatus}
                      onQRButtonClick={onQRButtonClick}
                    />
                    {pagination.totalInvoices > 10 && (
                      <div className="mt-3">
                        <Pagination
                          pageNumber={pagination.page}
                          pageSize={10}
                          totalElements={pagination.totalInvoices}
                          onPageChange={(page) => {
                            setPagination((prevState) => ({
                              ...prevState,
                              page: page,
                            }));
                          }}
                        >
                          {() => null}
                        </Pagination>
                      </div>
                    )}
                    <QRModal
                      modal={qrModal}
                      imageDataUrl={imageDataUrl}
                    ></QRModal>
                  </Tabs.Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
