import React from "react";

import { Button } from "@tiller-ds/core";
import { InputField } from "@tiller-ds/formik-elements";

import { Formik } from "formik";
import * as yup from "yup";

import {
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
  PHONE_NUMBER_REGEX,
} from "../../../common/constants";
import {
  DIGITS_ONLY_MESSAGE,
  INVALID_EMAIL_FORMAT_MESSAGE,
  maxCharacterCountMessage,
  maxDigitCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
} from "../../../common/YupConstants";
import { ParentSearchRequest } from "../api/ParentSearchRequest";

export type ParentRegistryFilterCardProps = {
  setSearchRequestJson: (searchRequest: ParentSearchRequest) => void;
  searchRequestJson: ParentSearchRequest;
};

type Form = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  childrenFirstName: string;
};

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  childrenFirstName: "",
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  lastName: yup
    .string()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  email: yup
    .string()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE)
    .nullable(),
  phoneNumber: yup
    .string()
    .max(MAX_PHONE_NUMBER_LENGTH, maxDigitCountMessage(MAX_PHONE_NUMBER_LENGTH))
    .matches(new RegExp(PHONE_NUMBER_REGEX), DIGITS_ONLY_MESSAGE)
    .nullable(),
  childrenFirstName: yup
    .string()
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .nullable(),
});

export default function ParentRegistryFilterCard({
  setSearchRequestJson,
  searchRequestJson,
}: ParentRegistryFilterCardProps) {
  const onSubmitForm = (form: Form) => {
    setSearchRequestJson({
      ...searchRequestJson,
      firstName: form.firstName === "" ? null : form.firstName,
      lastName: form.lastName === "" ? null : form.lastName,
      phoneNumber: form.phoneNumber === "" ? null : form.phoneNumber,
      email: form.email === "" ? null : form.email,
      childrenFirstName:
        form.childrenFirstName === "" ? null : form.childrenFirstName,
    });
  };

  function onClickResetFilter() {
    setSearchRequestJson({
      ...searchRequestJson,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      childrenFirstName: null,
    });
  }

  return (
    <div className="w-full h-fit mb-5 bg-navy-50 border rounded-lg p-2">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
          >
            <div className="flex flex-col justify-between gap-2 lg:flex-row">
              <InputField
                className="w-full"
                name="firstName"
                label="Ime"
              ></InputField>
              <InputField
                className="w-full"
                name="lastName"
                label="Prezime"
              ></InputField>
              <InputField
                className="w-full"
                name="phoneNumber"
                label="Kontakt broj"
              ></InputField>
              <InputField
                className="w-full"
                name="email"
                label="Kontakt email"
              ></InputField>
              <InputField
                className="w-full"
                name="childrenFirstName"
                label="Dijete"
              ></InputField>
            </div>
            <div className="flex flex-row md:flex-none justify-end gap-2">
              <Button
                variant="outlined"
                type="reset"
                onClick={() => {
                  formik.resetForm();
                  onClickResetFilter();
                }}
              >
                Poništi
              </Button>
              <Button variant="filled" type="submit">
                Filtriraj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
