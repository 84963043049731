import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

import { deleteEventNote } from "../../api/eventNoteApi/deleteEventNote";
import { EventNoteResponse } from "../../api/eventNoteApi/EventNoteResponse";

type DeleteEventNoteModalProps = {
  eventId?: string;
  setNotes: (notes: EventNoteResponse[]) => void;
  notes: EventNoteResponse[];
  modal: UseModal;
};

const DeleteEventNoteModal = ({
  setNotes,
  notes,
  eventId,
  modal,
}: DeleteEventNoteModalProps) => {
  const noteId = modal.state as number;

  const onDeleteEventNote = () => {
    if (eventId) {
      deleteEventNote(noteId, eventId).then(() => {});
      setNotes(notes.filter((note: EventNoteResponse) => note.id !== noteId));
      modal.onClose();
    }
  };

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h4" variant="h4">
            Izbriši bilješku
          </Typography>
        }
      >
        <Typography element="p" variant="subtext" className="pt-2">
          Jeste li sigurni da želite izbrisati bilješku?
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={onDeleteEventNote}>
          Izbriši
        </Button>
        <Button variant="outlined" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteEventNoteModal;
