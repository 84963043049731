import React from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { Button, IconButton, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { deleteProgressType } from "../../api/deleteProgressType";

type DeleteProgressTypeModalProps = {
  setRender: (render: any) => void;
  progressTypeId: number;
};

const DeleteProgressTypeModalModal = ({
  setRender,
  progressTypeId,
}: DeleteProgressTypeModalProps) => {
  const { add: addNotification } = useNotifications();

  const modal = useModal();

  const deleteProgressTypeModalFunction = (id: number) => {
    deleteProgressType(id).then((res) => {
      if (res.ok) {
        addNotification({
          title: "Parametar izbrisan!",
          content: "Brisanje tipa parametra uspješno",
          messageList: [],
          severity: "INFO",
          timestamp: new Date(),
        });
        modal.onClose();
        setRender(true);
      }
    });
  };

  return (
    <>
      <IconButton
        icon={
          <Icon
            type="trash"
            variant="bold"
            className="cursor-pointer text-gray-500"
            size={4}
          />
        }
        onClick={modal.onOpen}
        label="Izbiši tip parametra"
      />
      <Modal {...modal}>
        <Modal.Content
          title={
            <Typography element="h3" variant="h4">
              Izbriši mjerni parametar
            </Typography>
          }
        >
          <Typography element="p" variant="subtext" className="pt-2">
            Jeste li sigurni da želite izbrisati mjerni parametar? Svi podaci
            upisani preko ovog parametra će biti izgubljeni.
          </Typography>
        </Modal.Content>
        <Modal.Footer>
          <Button
            variant="filled"
            onClick={() => {
              deleteProgressTypeModalFunction(progressTypeId);
            }}
          >
            Izbriši
          </Button>
          <Button variant="outlined" color="white" onClick={modal.onClose}>
            Odustani
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteProgressTypeModalModal;
