import { ProgressRequest } from "./ProgressRequest";
import { PROGRESS_URL } from "../../../common/Routes";

export async function postProgressRequest(request: ProgressRequest) {
  const response = await fetch(PROGRESS_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
