import React, { useEffect, useState } from "react";

import { Card, Pagination, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { DateInput } from "@tiller-ds/date";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { MAX_REPORT_ROWS } from "../../../common/constants";
import { AthleteGroupReportResponse } from "../../api/AthleteGroupReportResponse";
import { AthleteReportResponse } from "../../api/AthleteReportResponse";
import { exportAthleteReportsExcel } from "../../api/exportAthleteReportsExcel";
import { exportAthleteReportsPDF } from "../../api/exportAthleteReportsPDF";
import { getAthleteGroupReports } from "../../api/getAthleteGroupReports";
import { getAthleteReports } from "../../api/getAthleteReports";
import { formatDateToHr } from "../../util/dateUtil";

export function GroupAthletesStateData() {
  const [groups, setGroups] = useState<AthleteGroupReportResponse[]>([]);
  const [selectedMemberReportGroup, setSelectedMemberReportGroup] = useState<
    AthleteGroupReportResponse
  >();
  const [groupAthleteReports, setGroupAthleteReports] = useState<
    AthleteReportResponse[]
  >([]);
  const [totalGroupAthleteReports, setTotalGroupAthleteReports] = useState<
    number
  >(0);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    getAthleteGroupReports({
      briefResponse: true,
    }).then((response) => {
      setGroups(response);
      if (response.length > 0) {
        setSelectedMemberReportGroup(response[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedMemberReportGroup === undefined) {
      return;
    }
    getAthleteReports({
      groupId: selectedMemberReportGroup.id,
      pageNumber: page,
      pageSize: MAX_REPORT_ROWS,
    }).then((response) => {
      setGroupAthleteReports(response.content);
      setTotalGroupAthleteReports(response.totalElements);
    });
  }, [selectedMemberReportGroup, page]);

  function formatMembershipDuration(
    years: number,
    months: number,
    days: number
  ): string {
    let output = "";
    if (years > 0) {
      output += `${years} ${generateLabelForNumberOfYears(years)}`;
    }
    if (years > 0 && months > 0) {
      output += ", ";
    }
    if (months > 0) {
      output += `${months} ${generateLabelForNumberOfMonths(months)}`;
    }
    if ((years > 0 || months > 0) && days > 0) {
      output += ", ";
    }
    if (days > 0) {
      output += `${days} ${generateLabelForNumberOfDays(days)}`;
    }

    return output;
  }

  function generateLabelForNumberOfYears(years: number): string {
    return years === 1 || years > 4 ? "godina" : "godine";
  }

  function generateLabelForNumberOfMonths(months: number): string {
    if (months === 1) {
      return "mjesec";
    } else if (months >= 2 && months <= 4) {
      return " mjeseca";
    }
    return "mjeseci";
  }

  function generateLabelForNumberOfDays(days: number): string {
    return days === 1 ? "dan" : "dana";
  }

  const exportReport = (format: "excel" | "pdf") => {
    if (selectedMemberReportGroup === undefined) {
      return;
    }

    const request = {
      groupId: selectedMemberReportGroup.id,
    };
    format === "excel"
      ? exportAthleteReportsExcel(request)
      : exportAthleteReportsPDF(request);
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-1">
        <Typography variant="h4" element="h4">
          Trenutni podatci o sportašima odabrane grupe
        </Typography>
        <Typography variant="text" element="p" className="text-gray-600">
          Podatci prikazani u tablici predstavljaju stanje na odabrani datum
        </Typography>
      </div>
      <Card className="flex flex-col gap-y-3">
        <Card.Body>
          <div className="flex flex-col gap-y-3 sm:flex-row sm:gap-y-0 justify-between pb-3 border-b mb-3">
            <div className="mt-auto">
              <DateInput
                label="Na dan"
                name="day"
                disabled
                placeholder={formatDateToHr(new Date())}
                onChange={() => {}}
                value={new Date()}
                allowClear={false}
              />
            </div>
            <div className="flex flex-row justify-between gap-x-3 mt-auto sm:justify-end">
              <DropdownMenu
                title={selectedMemberReportGroup?.groupName}
                color="primary"
                visibleItemCount={5}
              >
                {groups !== undefined &&
                  groups.length > 0 &&
                  groups.map((group) => (
                    <DropdownMenu.Item
                      onSelect={() => setSelectedMemberReportGroup(group)}
                    >
                      {group.groupName}
                    </DropdownMenu.Item>
                  ))}
              </DropdownMenu>
              <DropdownMenu
                title={<span className="hidden sm:inline-block">Preuzmi</span>}
                color="primary"
                openExpanderIcon={<Icon type="download-simple" />}
                closeExpanderIcon={<Icon type="download-simple" />}
                iconPlacement="leading"
                className="pr-2 sm:pr-4"
              >
                <DropdownMenu.Item onSelect={() => exportReport("excel")}>
                  .xlsx
                </DropdownMenu.Item>
                <DropdownMenu.Item onSelect={() => exportReport("pdf")}>
                  .pdf
                </DropdownMenu.Item>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex flex-col gap-y-10">
            <DataTable data={groupAthleteReports} className="border">
              <DataTable.Column
                header="IME SPORTAŠA"
                id="athleteName"
                canSort={false}
              >
                {(item: AthleteReportResponse) =>
                  `${item.athleteFirstName} ${item.athleteLastName}`
                }
              </DataTable.Column>
              <DataTable.Column
                header="DATUM ROĐENJA"
                id="athleteBirthDate"
                canSort={false}
              >
                {(item: AthleteReportResponse) =>
                  formatDateToHr(new Date(item.birthDate))
                }
              </DataTable.Column>
              <DataTable.Column header="GRUPA" id="groupName" canSort={false}>
                {(item: AthleteReportResponse) => item.groupName}
              </DataTable.Column>
              <DataTable.Column
                header="TRAJANJE ČLANSTVA"
                id="membershipDuration"
                canSort={false}
              >
                {(item: AthleteReportResponse) =>
                  formatMembershipDuration(
                    item.membershipYears,
                    item.membershipMonths,
                    item.membershipDays
                  )
                }
              </DataTable.Column>
            </DataTable>
          </div>
        </Card.Body>
        {totalGroupAthleteReports > MAX_REPORT_ROWS && (
          <div className="flex items-center justify-end mb-3 mr-1">
            <Pagination
              onPageChange={(i: number) => {
                setPage(i);
              }}
              pageNumber={page}
              pageSize={MAX_REPORT_ROWS}
              totalElements={totalGroupAthleteReports}
            >
              {() => null}
            </Pagination>
          </div>
        )}
      </Card>
    </div>
  );
}
