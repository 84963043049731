import React from "react";

import { LoadingIcon } from "@tiller-ds/icons";

type LoadingCircleProps = {
  isLoading: boolean;
  children?: React.ReactNode;
};

export default function LoadingCircle({
  isLoading,
  children,
}: LoadingCircleProps) {
  return (
    <>
      {isLoading || !children ? (
        <div className="flex justify-center items-center min-h-[70vh]">
          <div className="h-fit">
            <LoadingIcon size={20} />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
}
