import React from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";
import ProgressResultMapper from "../util/ProgressResultMapper";

type DashLineChartProps = {
  athleteOrGroupName: string;
  athleteOrGroupProgressData: Record<string, number>;
  athleteOrGroupToCompareName: string;
  athleteOrGroupToCompareProgressData: Record<string, number>;
  progressType: ProgressTypeResponse;
};

export default function ComparisonChart({
  athleteOrGroupName,
  athleteOrGroupProgressData,
  athleteOrGroupToCompareName,
  athleteOrGroupToCompareProgressData,
  progressType,
}: DashLineChartProps) {
  let graphData = [];

  if (
    athleteOrGroupProgressData !== undefined &&
    athleteOrGroupToCompareProgressData !== undefined
  ) {
    for (let date in athleteOrGroupProgressData) {
      graphData.push({
        date: date,
        [athleteOrGroupToCompareName]:
          athleteOrGroupToCompareProgressData[date],
        [athleteOrGroupName]: athleteOrGroupProgressData[date]
          ? athleteOrGroupProgressData[date]
          : 0,
      });
    }
  }

  return (
    <ResponsiveContainer height={300} width="100%">
      <LineChart
        width={500}
        height={400}
        data={graphData}
        margin={{
          top: 10,
          right: 5,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickLine={false}
          tickFormatter={(dateString) => {
            if (dateString !== 0 && Date.parse(dateString)) {
              return new Date(dateString).toLocaleDateString("hr-HR", {
                month: "2-digit",
                year: "2-digit",
              });
            } else {
              return "";
            }
          }}
          dy={3}
          height={50}
        />
        <YAxis
          tickLine={false}
          tickFormatter={(result) => {
            return result.toString();
          }}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && label) {
              const athleteResult = +parseFloat(
                payload[0].value as string
              ).toFixed(2);
              const groupResult = +parseFloat(
                payload[1].value as string
              ).toFixed(2);
              return (
                <div className="bg-white rounded border border-gray-250 shadow-md tooltip p-3">
                  <p>{`${new Date(label).toLocaleDateString("hr-HR")}`}</p>
                  <p className="my-1 text-purple-500">{`${athleteOrGroupName}: ${ProgressResultMapper(
                    Number(athleteResult),
                    progressType.measuringUnit
                  )}`}</p>
                  <p className="mt-1 text-aqua-800">{`${athleteOrGroupToCompareName}: ${ProgressResultMapper(
                    Number(groupResult),
                    progressType.measuringUnit
                  )}`}</p>
                </div>
              );
            } else {
              return null;
            }
          }}
        />
        <Legend wrapperStyle={{ marginLeft: 15 }} />
        <Line
          type="monotone"
          dataKey={`${athleteOrGroupName}`}
          stroke="#8B5CF6"
        />
        <Line
          type="monotone"
          dataKey={`${athleteOrGroupToCompareName}`}
          stroke="#14B8A6"
          strokeDasharray="3 4 5 2"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
