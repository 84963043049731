import { AthleteReportsRequest } from "./AthleteReportsRequest";
import { REPORTS_STATE_ATHLETES_URL } from "../../common/Routes";

export async function getAthleteReports(request: AthleteReportsRequest) {
  const response = await fetch(`${REPORTS_STATE_ATHLETES_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
