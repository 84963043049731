import React from "react";

import { Typography } from "@tiller-ds/core";

type LinearAnswerCreateProps = {
  fieldName: string;
};

export default function LinearAnswerOption({
  fieldName,
}: LinearAnswerCreateProps) {
  return (
    <div className="flex flex-col items-center justify-between w-full h-14 space-y-2 px-2 xl:h-20 xl:space-y-4 xl:px-6">
      <Typography element="h6" variant="text" className="font-inter">
        {fieldName}
      </Typography>
      <input
        className="scale-100 sm:scale-150"
        type="radio"
        disabled
        readOnly={true}
      />
    </div>
  );
}
