import React, { useState } from "react";

import { Input } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";

import { MAX_NAME_LENGTH } from "../../../common/constants";
import { AdminSearchRequest } from "../../admin/api/AdminSearchRequest";
import { AthleteSearchRequest } from "../../athlete/api/AthleteSearchRequest";
import { CoachSearchRequest } from "../../coach/api/CoachSearchRequest";
import { GroupSearchRequest } from "../../group/api/GroupSearchRequest";
import { ParentSearchRequest } from "../../parent/api/ParentSearchRequest";

export type RegistrySearchProps = {
  searchRequestJson:
    | AthleteSearchRequest
    | CoachSearchRequest
    | ParentSearchRequest
    | GroupSearchRequest
    | AdminSearchRequest;
  setSearchRequestJson:
    | ((searchRequest: AthleteSearchRequest) => void)
    | ((searchRequest: CoachSearchRequest) => void)
    | ((searchRequest: ParentSearchRequest) => void)
    | ((searchRequest: GroupSearchRequest) => void)
    | ((searchRequest: AdminSearchRequest) => void);
};

export default function RegistrySearch({
  searchRequestJson,
  setSearchRequestJson,
}: RegistrySearchProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = (searchTerm: string) => {
    // if searching by multiple words
    searchTerm.trim();
    if (searchTerm.split(" ").length > 1) {
      const [firstName, lastName] = searchTerm.split(" ");
      setSearchRequestJson({
        ...searchRequestJson,
        pageNumber: 0,
        briefResponse: false,
        groupsGroupName: null,
        coachesFirstName: null,
        coachesLastName: null,
        parentsFirstName: null,
        maxAgeToIncluding: null,
        minAgeFromIncluding: null,
        dateOfBirthToIncluding: null,
        dateOfBirthFromIncluding: null,
        email: null,
        phoneNumber: null,
        groupName: searchTerm,
        firstName: firstName,
        lastName: lastName,
      });
    } else {
      setSearchRequestJson({
        ...searchRequestJson,
        pageNumber: 0,
        briefResponse: false,
        coachesFirstName: null,
        coachesLastName: null,
        parentsFirstName: null,
        maxAgeToIncluding: null,
        groupsGroupName: null,
        minAgeFromIncluding: null,
        dateOfBirthToIncluding: null,
        dateOfBirthFromIncluding: null,
        email: null,
        phoneNumber: null,
        groupName: searchTerm,
        firstName: searchTerm,
        lastName: searchTerm,
      });
    }
  };

  function handleEnterKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  }

  return (
    <Input
      autoComplete="off"
      name="searchBar"
      value={searchTerm}
      placeholder="Pretraži"
      maxLength={MAX_NAME_LENGTH}
      className="w-full"
      inlineLeadingIcon={
        <Icon
          type="magnifying-glass"
          variant="bold"
          onClick={() => {
            onSearch(searchTerm);
          }}
        />
      }
      allowClear
      onReset={() => {
        setSearchTerm("");
        onSearch("");
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
      }}
      onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
        handleEnterKeyPress(e);
      }}
    />
  );
}
