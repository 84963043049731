import React from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { InputField } from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import CancelModal from "../../common/components/modals/CancelModal";
import {
  MAX_CITY_LENGTH,
  MAX_HOUSE_NUMBER_LENGTH,
  MAX_STREET_NAME_LENGTH,
  OIB_LENGTH,
} from "../../common/constants";
import { isOibValid } from "../../common/isOibValid";
import {
  CITY_REQUIRED_MESSAGE,
  IBAN_CHARACTER_COUNT_MESSAGE,
  IBAN_FORMAT_MESSAGE,
  INVALID_OIB_MESSAGE,
  maxCharacterCountMessage,
  MUST_BE_INTEGER_MESSAGE,
  MUST_BE_NUMBER_MESSAGE,
  OIB_DIGIT_COUNT_MESSAGE,
  STREET_REQUIRED_MESSAGE,
  ZIP_CODE_LENGTH_MESSAGE,
} from "../../common/YupConstants";
import { postClubInfo } from "../api/postClubInfo";

type BasicClubDataFormProps = {
  streetName: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  oib: string;
  iban: string;
};

const validationSchemaManagement = yup.object().shape({
  houseNumber: yup
    .string()
    .max(
      MAX_HOUSE_NUMBER_LENGTH,
      maxCharacterCountMessage(MAX_HOUSE_NUMBER_LENGTH)
    )
    .nullable(),
  streetName: yup.string().when("houseNumber", (houseNumber) => {
    if (houseNumber) {
      return yup
        .string()
        .required(STREET_REQUIRED_MESSAGE)
        .max(
          MAX_STREET_NAME_LENGTH,
          maxCharacterCountMessage(MAX_STREET_NAME_LENGTH)
        )
        .nullable();
    } else
      return yup
        .string()
        .max(
          MAX_STREET_NAME_LENGTH,
          maxCharacterCountMessage(MAX_STREET_NAME_LENGTH)
        )
        .nullable();
  }),
  zipCode: yup
    .number()
    .typeError(MUST_BE_NUMBER_MESSAGE)
    .moreThan(9999, ZIP_CODE_LENGTH_MESSAGE)
    .lessThan(100000, ZIP_CODE_LENGTH_MESSAGE)
    .integer(MUST_BE_INTEGER_MESSAGE)
    .nullable(),
  city: yup.string().when("zipCode", (zipCode) => {
    if (zipCode) {
      return yup
        .string()
        .required(CITY_REQUIRED_MESSAGE)
        .max(MAX_CITY_LENGTH, maxCharacterCountMessage(MAX_CITY_LENGTH))
        .nullable();
    } else
      return yup
        .string()
        .max(MAX_CITY_LENGTH, maxCharacterCountMessage(MAX_CITY_LENGTH))
        .nullable();
  }),
  oib: yup
    .string()
    .length(OIB_LENGTH, OIB_DIGIT_COUNT_MESSAGE)
    .nullable()
    .test(
      "oibValidationTest",
      INVALID_OIB_MESSAGE,
      (input: String | undefined | null) => {
        if (input) {
          return isOibValid(input);
        }
        return true;
      }
    ),
  iban: yup
    .string()
    .matches(/[A-Z]{2}\d{19}/g, IBAN_FORMAT_MESSAGE)
    .max(21, IBAN_CHARACTER_COUNT_MESSAGE)
    .nullable(),
});

export default function BasicClubDataForm(
  initialFormValues: BasicClubDataFormProps
) {
  const { add: addNotification } = useNotifications();
  const cancelModal = useModal();

  const onSubmit = async (values: BasicClubDataFormProps) => {
    postClubInfo({
      ...values,
    }).then((response) => {
      if (response) {
        addNotification({
          title: "Spremljeni podaci",
          content: "Vaši podaci su uspješno spremljeni.",
          messageList: [],
          severity: "INFO",
          timestamp: new Date(),
        });
      }
    });
  };

  return (
    <div className="mt-8">
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchemaManagement}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <CancelModal
              modal={cancelModal}
              title="Uređivanje podataka o klubu"
              returnPath="/home"
            />
            <div className="gap-y-12">
              <div className="gap-y-4">
                <div>
                  <Typography
                    element="h6"
                    variant="h6"
                    className="hidden md:inline font-biotiff"
                  >
                    Adresa kluba
                  </Typography>
                  <Typography element="p" className="md:hidden font-biotiff">
                    <b>Adresa kluba</b>
                  </Typography>
                  <hr />
                </div>
                <div className="flex flex-col gap-y-4 mb-6 mt-4">
                  <div className="flex flex-col gap-y-4 md:gap-y-0 sm:flex-row sm:gap-x-4">
                    <InputField
                      name="streetName"
                      label="Ulica"
                      className="w-full md:w-1/2"
                    />
                    <InputField
                      name="houseNumber"
                      label="Kućni broj"
                      className="w-full md:w-1/2"
                    />
                  </div>
                  <div className="flex flex-col gap-y-4 md:gap-y-0 sm:flex-row sm:gap-x-4">
                    <InputField
                      name="city"
                      label="Mjesto"
                      className="w-full md:w-1/2"
                    />
                    <InputField
                      name="zipCode"
                      label="Poštanski broj"
                      className="w-full md:w-1/2"
                    />
                  </div>
                </div>
              </div>
              <div className="gap-y-4">
                <div>
                  <Typography
                    element="h6"
                    variant="h6"
                    className="hidden md:inline font-biotiff"
                  >
                    Ostale informacije
                  </Typography>
                  <Typography element="p" className="md:hidden font-biotiff">
                    <b>Ostale informacije</b>
                  </Typography>
                  <hr />
                </div>
                <div className="flex flex-col gap-y-4 mb-6 mt-4">
                  <InputField
                    name="oib"
                    label="OIB"
                    className="w-full md:w-1/2"
                    maxLength={OIB_LENGTH}
                  />
                  <InputField
                    name="iban"
                    label="IBAN"
                    className="w-full md:w-1/2"
                  />
                </div>
                <hr className="py-2" />
                <div className="flex justify-end gap-x-2">
                  <Button
                    type="reset"
                    variant="outlined"
                    size="sm"
                    onClick={cancelModal.onOpen}
                  >
                    Odustani
                  </Button>
                  <Button variant="filled" type="submit">
                    Spremi
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
