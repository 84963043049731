import React, { useEffect, useState } from "react";

import { Tabs, Typography } from "@tiller-ds/core";

import noEvent from "../../assets/img/noEvent.svg";
import EventCard from "../../common/components/cards/EventCard";
import { eventTypesForIncomingEventsComponent } from "../../common/constants";
import { EventResponse } from "../../event/api/EventResponse";
import { FilterEventsRequest } from "../../event/api/FilterEventsRequest";
import { postFilterEvents } from "../../event/api/PostFilterEvents";
import { typeMapper } from "../../event/util/typeMapper";

type HomePersonalEventProps = {
  userId: number;
};

let monthMap = new Map([
  [0, "SIJ"],
  [1, "VELJ"],
  [2, "OŽU"],
  [3, "TRA"],
  [4, "SVI"],
  [5, "LIP"],
  [6, "SRP"],
  [7, "KOL"],
  [8, "RUJ"],
  [9, "LIS"],
  [10, "STU"],
  [11, "PRO"],
]);

const RENDER_RESULTS = 3;

export default function HomePersonalEvents({ userId }: HomePersonalEventProps) {
  const [events, setEvents] = useState([] as EventResponse[]);
  const [selectedFilter, setSelectedFilter] = useState("Sastanak");

  useEffect(() => {
    let currentDate = new Date();
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    currentDate.setHours(0);
    postFilterEvents({
      endTimeFromIncluding: currentDate.toISOString(),
      startTimeTo: endDate.toISOString(),
      eventType: [typeMapper(selectedFilter)],
      participantsId: userId,
    } as FilterEventsRequest).then((response) => {
      setEvents(response);
    });
  }, [userId, selectedFilter]);

  return (
    <div className="w-full mb-4">
      <Typography
        element="h4"
        variant="h4"
        className="text-primary font-biotif"
      >
        Nadolazeći događaji
      </Typography>
      <Tabs>
        {eventTypesForIncomingEventsComponent.map((type) => {
          return (
            <Tabs.Tab
              label={type}
              onClick={() => {
                setSelectedFilter(type);
              }}
            >
              <div className="flex flex-col justify-stretch lg:flex-row gap-y-2 lg:gap-x-4 mt-4">
                {events.length > 0 ? (
                  events.slice(0, RENDER_RESULTS).map((event) => {
                    let eventMonth = new Date(event.startTime).getMonth();
                    let eventDate = new Date(event.startTime).getDate();
                    return (
                      <div className="w-full lg:w-1/3">
                        <EventCard
                          title={`${eventDate} ${monthMap.get(eventMonth)}`}
                          content={`${event.title}`}
                          eventId={event.id}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="flex flex-col justify-center items-center w-full h-48">
                    <img
                      src={noEvent}
                      alt="Nema nadolazećih događaja"
                      className="w-44 mt-2"
                    />
                    <Typography className="mt-4" element="p" variant="subtitle">
                      Trenutno nema nadolazećih događaja...
                    </Typography>
                  </div>
                )}
              </div>
            </Tabs.Tab>
          );
        })}
      </Tabs>
    </div>
  );
}
