import React, { useEffect, useState } from "react";

import { Card, Pagination, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { DateInput } from "@tiller-ds/date";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import moment from "moment";

import { MAX_REPORT_ROWS } from "../../../common/constants";
import { EventReportResponse } from "../../api/EventReportResponse";
import { exportEventReportsExcel } from "../../api/exportEventReportsExcel";
import { exportEventReportsPDF } from "../../api/exportEventReportsPDF";
import { getEventReports } from "../../api/getEventReports";
import { firstDayOfMonthDate, lastDayOfMonthDate } from "../../util/dateUtil";

export function EventStateData() {
  const [eventReports, setEventReports] = useState<EventReportResponse[]>([]);
  const [totalEventReports, setTotalEventReports] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(
    firstDayOfMonthDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(lastDayOfMonthDate());
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (startDate === null || endDate === null) {
      return;
    }
    getEventReports({
      startDate: startDate,
      endDate: endDate,
      pageNumber: page,
      pageSize: MAX_REPORT_ROWS,
    }).then((response) => {
      setEventReports(response.content);
      setTotalEventReports(response.totalElements);
    });
  }, [startDate, endDate, page]);

  const exportReport = (format: "excel" | "pdf") => {
    if (startDate === null || endDate === null) {
      return;
    }

    const request = {
      startDate: moment(startDate).add(1, "day").toDate(),
      endDate: moment(endDate).add(1, "day").toDate(),
    };
    format === "excel"
      ? exportEventReportsExcel(request)
      : exportEventReportsPDF(request);
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-1">
        <Typography variant="h4" element="h4">
          Podatci o događajima
        </Typography>
        <Typography variant="text" element="p" className="text-gray-600">
          Podatci prikazani u tablici predstavljaju stanje na odabrani datum
        </Typography>
      </div>
      <Card className="flex flex-col gap-y-3">
        <Card.Body>
          <div className="flex flex-col gap-y-3 sm:gap-y-0 sm:flex-row justify-between pb-3 mb-3 border-b">
            <div className="flex flex-col gap-y-3 sm:gap-y-0 sm:flex-row gap-x-3 mt-auto">
              <DateInput
                label="Početak perioda"
                name="startDate"
                value={startDate}
                onReset={() => setStartDate(null)}
                onChange={(value) => setStartDate(value)}
                maxDate={endDate as Date | undefined}
                allowClear={false}
              />
              <DateInput
                label="Kraj perioda"
                name="endDate"
                value={endDate}
                onReset={() => setEndDate(null)}
                onChange={(value) => setEndDate(value)}
                minDate={startDate as Date | undefined}
                allowClear={false}
              />
            </div>
            <div className="mt-auto flex flex-row justify-end ml-3">
              <DropdownMenu
                title={<span className="hidden sm:inline-block">Preuzmi</span>}
                color="primary"
                openExpanderIcon={<Icon type="download-simple" />}
                closeExpanderIcon={<Icon type="download-simple" />}
                iconPlacement="leading"
                className="pr-2 sm:pr-4"
              >
                <DropdownMenu.Item onSelect={() => exportReport("excel")}>
                  .xlsx
                </DropdownMenu.Item>
                <DropdownMenu.Item onSelect={() => exportReport("pdf")}>
                  .pdf
                </DropdownMenu.Item>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex flex-col gap-y-10">
            {eventReports.length > 0 && (
              <DataTable data={eventReports} className="border">
                <DataTable.Column
                  header="NAZIV GRUPE"
                  id="groupName"
                  canSort={false}
                >
                  {(item: EventReportResponse) => item.groupName}
                </DataTable.Column>
                <DataTable.Column
                  header="TRENINZI"
                  id="trainingEvents"
                  canSort={false}
                >
                  {(item: EventReportResponse) => item.numberOfTrainingEvents}
                </DataTable.Column>
                <DataTable.Column
                  header="NATJECANJA"
                  id="competitionEvents"
                  canSort={false}
                >
                  {(item: EventReportResponse) =>
                    item.numberOfCompetitionEvents
                  }
                </DataTable.Column>
                <DataTable.Column
                  header="SASTANCI"
                  id="meetingEvents"
                  canSort={false}
                >
                  {(item: EventReportResponse) => item.numberOfMeetingEvents}
                </DataTable.Column>
                <DataTable.Column
                  header="OSTALI"
                  id="generalEvents"
                  canSort={false}
                >
                  {(item: EventReportResponse) => item.numberOfGeneralEvents}
                </DataTable.Column>
              </DataTable>
            )}
          </div>
        </Card.Body>
        {totalEventReports > MAX_REPORT_ROWS && (
          <div className="flex items-center justify-end mb-3 mr-1">
            <Pagination
              onPageChange={(i: number) => {
                setPage(i);
              }}
              pageNumber={page}
              pageSize={MAX_REPORT_ROWS}
              totalElements={totalEventReports}
            >
              {() => null}
            </Pagination>
          </div>
        )}
      </Card>
    </div>
  );
}
