import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  IconButton,
  Pagination,
  Typography,
} from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { Link } from "react-router-dom";

import CoachRegistryFilterCard from "./CoachRegistryFilterCard";
import {
  MAX_ARCHIVE_ROWS,
  REGISTRY_SEARCH_ERROR_MESSAGE,
} from "../../../common/constants";
import RegistrySearch from "../../common/components/RegistrySearch";
import { RegistryContext } from "../../common/RegistryProvider";
import { GroupBriefResponse } from "../../group/api/GroupBriefResponse";
import { CoachResponse } from "../api/CoachResponse";
import { CoachSearchRequest } from "../api/CoachSearchRequest";
import { exportCoachesExcel } from "../api/exportCoachesExcel";
import { exportCoachesPDF } from "../api/exportCoachesPDF";
import { postSearchCoachRequest } from "../api/postSearchCoachRequest";

type CoachRegistryProps = {
  totalCoaches: number;
  setTotalCoaches: (totalCoaches: number) => void;
  entityCreated: number;
};

export default function CoachRegistry({
  totalCoaches,
  setTotalCoaches,
  entityCreated,
}: CoachRegistryProps) {
  const [mobileView, setMobileView] = useState(false);
  const [coachTableData, setCoachTableData] = useState([] as CoachResponse[]);
  const [error, setError] = useState("");
  const { coachRegistryPage, setCoachRegistryPage } = useContext(
    RegistryContext
  );
  const [filterClicked, setFilterClicked] = useState(false);
  const [searchRequestJson, setSearchRequestJson] = useState({
    pageNumber: coachRegistryPage,
    pageSize: 10,
    sortPropertyList: [
      {
        property: "lastName",
        direction: "ASC",
      },
      {
        property: "firstName",
        direction: "ASC",
      },
    ],
  } as CoachSearchRequest);

  useEffect(() => {
    postSearchCoachRequest(searchRequestJson).then((response: any) => {
      setTotalCoaches(response.totalElements);
      setCoachTableData(response.content);

      response.content.length > 0
        ? setError("")
        : setError(REGISTRY_SEARCH_ERROR_MESSAGE);
    });
  }, [searchRequestJson, setTotalCoaches, entityCreated]);

  function handleResize() {
    if (window.innerWidth < 640) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 640) {
      setMobileView(true);
    }
  }, []);

  return (
    <>
      <Card.Header className="py-2" removeSpacing={true}>
        <div className="flex justify-between items-center sm:items-end flex-col md:flex-row">
          <Typography element="h4" variant="h4" className="hidden md:block">
            Treneri
          </Typography>
          <div className="flex w-full sm:w-auto justify-start md:justify-end">
            <div className="flex w-full gap-2 items-center">
              <RegistrySearch
                searchRequestJson={searchRequestJson}
                setSearchRequestJson={setSearchRequestJson}
              ></RegistrySearch>
              {mobileView ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    filterClicked
                      ? setFilterClicked(false)
                      : setFilterClicked(true);
                  }}
                >
                  <Icon type="funnel" />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  trailingIcon={
                    filterClicked ? (
                      <Icon type="caret-up" variant="bold" size={3} />
                    ) : (
                      <Icon type="caret-down" variant="bold" size={3} />
                    )
                  }
                  onClick={() => {
                    filterClicked
                      ? setFilterClicked(false)
                      : setFilterClicked(true);
                  }}
                >
                  Filtriraj
                </Button>
              )}
            </div>
          </div>
          <DropdownMenu
            title="Preuzmi"
            color="primary"
            iconPlacement="leading"
            openExpanderIcon={<Icon type="download-simple" variant="bold" />}
            closeExpanderIcon={<Icon type="download-simple" variant="bold" />}
          >
            <DropdownMenu.Item
              onSelect={() => exportCoachesExcel(searchRequestJson)}
            >
              .xlsx
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => exportCoachesPDF(searchRequestJson)}
            >
              .pdf
            </DropdownMenu.Item>
          </DropdownMenu>
        </div>
      </Card.Header>
      <Card.Body removeSpacing={true}>
        {filterClicked ? (
          <CoachRegistryFilterCard
            setSearchRequestJson={setSearchRequestJson}
            searchRequestJson={searchRequestJson}
          />
        ) : (
          <></>
        )}
        <DataTable data={coachTableData}>
          <DataTable.Column
            className="w-2/12"
            header="Ime"
            id="firstName"
            canSort={false}
          >
            {(item: CoachResponse) => item.firstName}
          </DataTable.Column>
          <DataTable.Column
            className="w-2/12"
            header="Prezime"
            id="lastName"
            canSort={false}
          >
            {(item: CoachResponse) => item.lastName}
          </DataTable.Column>
          <DataTable.Column
            className="w-3/12"
            header="Kontakt"
            id="contact"
            canSort={false}
          >
            {(item: CoachResponse) => {
              return (
                <>
                  <span>{item.email}</span> <br></br>
                  <span>{item.phoneNumber}</span>
                </>
              );
            }}
          </DataTable.Column>
          <DataTable.Column
            className="w-3/12"
            header="Grupa"
            id="group"
            canSort={false}
          >
            {(item: CoachResponse) => {
              return (
                <>
                  {item.groups[0]
                    ? item.groups
                        .sort((a, b) => a.groupName.localeCompare(b.groupName))
                        .map((group: GroupBriefResponse) => {
                          return (
                            <>
                              <span className="mt-1">{group.groupName}</span>
                              <br></br>
                            </>
                          );
                        })
                    : "-"}
                </>
              );
            }}
          </DataTable.Column>
          <DataTable.Column
            className="w-1/12"
            header="Akcije"
            id="actions"
            canSort={false}
          >
            {(item: CoachResponse) => (
              <Link to={`/archive/coaches/${item.id}`}>
                <IconButton
                  icon={<Icon type="file-search" className="text-gray-500" />}
                  onClick={() => {}}
                  label="Detalji"
                />
              </Link>
            )}
          </DataTable.Column>
        </DataTable>
        <div className="flex justify-center my-3">
          <Typography className="text-red-600" variant="subtext">
            {error}
          </Typography>
        </div>
      </Card.Body>
      {totalCoaches > MAX_ARCHIVE_ROWS && (
        <Card.Footer className="flex items-center justify-end">
          <Pagination
            onPageChange={(i: number) => {
              setCoachRegistryPage(i);
              setSearchRequestJson((searchRequestJson) => ({
                ...searchRequestJson,
                pageNumber: i,
              }));
            }}
            pageNumber={coachRegistryPage}
            pageSize={MAX_ARCHIVE_ROWS}
            totalElements={totalCoaches}
          >
            {() => null}
          </Pagination>
        </Card.Footer>
      )}
    </>
  );
}
