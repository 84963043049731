import React, { useContext, useEffect, useState } from "react";

import { Typography } from "@tiller-ds/core";

import { UserContext } from "../../auth/SecurityProvider";
import LoadingCircle from "../../common/components/LoadingCircle";
import { Message } from "../api/Message";
import { MessageResponse } from "../api/MessageResponse";
import { postReceivedMessagesFilterRequest } from "../api/postReceivedMessagesFilterRequest";
import { postSentMessagesFilterRequest } from "../api/postSentMessagesFilterRequest";
import Messages from "../components/Messages";
import MessagesHeader from "../components/MessagesHeader";
import { MessageContext } from "../MessageProvider";

export default function MessagesViewPage() {
  const [messages, setMessages] = useState([] as Message[]);
  const { user } = React.useContext(UserContext);
  const { showSent, messageFilterRequest, setTotalElements } = useContext(
    MessageContext
  );
  const [isLoading, setIsLoading] = useState(true);

  function onChangeMessages(messages: Message[]) {
    setMessages(messages);
  }

  useEffect(() => {
    setIsLoading(true);
    (() => {
      if (showSent) {
        return postSentMessagesFilterRequest(messageFilterRequest);
      } else {
        return postReceivedMessagesFilterRequest(messageFilterRequest);
      }
    })()
      .then((response: any) => {
        setTotalElements(response.totalElements);
        setMessages(
          response.content.map((messageResponse: MessageResponse) => {
            return {
              ...messageResponse,
              timeSent: new Date(messageResponse.timeSent).toLocaleDateString(
                "hr-HR"
              ),
              isRead: messageResponse.read,
              isSent: showSent || messageResponse.senderId === user.id,
              isChecked: false,
              isPinned: messageResponse.pinned,
            };
          })
        );
      })
      .then(() => setIsLoading(false));
  }, [messageFilterRequest, user.id, showSent, setTotalElements]);

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6">
      <div className="flex flex-col w-full gap-y-2">
        <Typography
          element="h3"
          variant="h3"
          className="hidden md:inline font-biotiff"
        >
          Poruke
        </Typography>
        <Typography
          element="h6"
          variant="h6"
          className="md:hidden font-biotiff"
        >
          Poruke
        </Typography>
        <MessagesHeader
          messages={messages}
          onChangeMessages={onChangeMessages}
        />
        <LoadingCircle isLoading={isLoading}>
          <Messages messages={messages} onChangeMessages={onChangeMessages} />
        </LoadingCircle>
      </div>
    </div>
  );
}
