import { InvoiceTemplateResponse } from "./InvoiceTemplateResponse";
import { INVOICE_TEMPLATE_URL } from "../../../common/Routes";

export async function getAllInvoiceTemplate(): Promise<
  InvoiceTemplateResponse[]
> {
  const response = await fetch(`${INVOICE_TEMPLATE_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
