import { ProgressTypeResponse } from "./ProgressTypeResponse";
import { PROGRESS_URL } from "../../../common/Routes";

export async function updateProgressTypeRequest(
  request: ProgressTypeResponse,
  progressTypeId: number
) {
  const response = await fetch(`${PROGRESS_URL}/type/${progressTypeId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
