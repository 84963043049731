import React, { useContext, useEffect, useState } from "react";

/* eslint-disable import/order */
import { Tooltip, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import FullCalendar, { EventInput } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
/* eslint-enable import/order */
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import {
  CalendarView,
  EventType,
  FIRST_DAY_OF_THE_WEEK,
  SLOT_DURATION,
  SLOT_LABEL_DURATION,
} from "../../../common/constants";
import formatDateInTimeZone from "../../../common/formatDateInTimeZone";
import { EventResponse } from "../../api/EventResponse";
import { FilterEventsRequest } from "../../api/FilterEventsRequest";
import { postFilterEvents } from "../../api/PostFilterEvents";
import { CalendarStateContext } from "../../CalendarProvider";

const NUMBER_OF_SECONDS_IN_ONE_HOUR = 3600000;

type CalendarProps = {
  calendarComponentRef: React.RefObject<FullCalendar>;
  updateCalendarTitle: (newTitle: string) => void;
};

export default function Calendar({
  calendarComponentRef,
  updateCalendarTitle,
}: CalendarProps) {
  const [events, setEvents] = useState([] as EventInput[]);
  const navigate = useNavigate();
  const {
    calendarView,
    setCalendarView,
    eventFilter,
    groupFilter,
    initialDate,
    startDate,
    endDate,
  } = useContext(CalendarStateContext);

  const eventTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";

  useEffect(() => {
    const filterEventsRequest: FilterEventsRequest = {
      endTimeFromIncluding: startDate.toISOString(),
      startTimeTo: endDate.toISOString(),
      eventType: eventFilter === EventType.ALL ? null : [eventFilter],
      // @ts-ignore
      groupsId: groupFilter ? groupFilter.id : undefined,
    };
    postFilterEvents(filterEventsRequest)
      .then((response: EventResponse[]) => {
        setEvents(
          response.map(
            (eventResponse: EventResponse) =>
              ({
                id: eventResponse.id.toString(),
                title: eventResponse.title,
                start: formatDateInTimeZone(
                  eventResponse.startTime,
                  eventTimeFormat
                ),
                end: formatDateInTimeZone(
                  eventResponse.endTime,
                  eventTimeFormat
                ),
                type: eventResponse.eventType,
              } as EventInput)
          )
        );
      })
      .then(() => calendarComponentRef.current?.getApi().updateSize());
  }, [calendarComponentRef, eventFilter, startDate, endDate, groupFilter]);

  function setEventContentClassnames() {
    return classNames({
      "pl-1": true,
      "hidden md:flex": calendarView === CalendarView.MONTH_VIEW,
      flex: calendarView !== CalendarView.MONTH_VIEW,
    });
  }

  function setEventClassnames(eventType: any) {
    return classNames({
      "month-event": calendarView === CalendarView.MONTH_VIEW,
      "week-event": calendarView === CalendarView.WEEK_VIEW,
      "day-event": calendarView === CalendarView.DAY_VIEW,
      meeting:
        eventType === EventType.MEETING &&
        calendarView === CalendarView.MONTH_VIEW,
      "time-grid-meeting":
        eventType === EventType.MEETING &&
        calendarView !== CalendarView.MONTH_VIEW,
      training:
        eventType === EventType.TRAINING &&
        calendarView === CalendarView.MONTH_VIEW,
      "time-grid-training":
        eventType === EventType.TRAINING &&
        calendarView !== CalendarView.MONTH_VIEW,
      competition:
        eventType === EventType.COMPETITION &&
        calendarView === CalendarView.MONTH_VIEW,
      "time-grid-competition":
        eventType === EventType.COMPETITION &&
        calendarView !== CalendarView.MONTH_VIEW,
      general:
        eventType === EventType.GENERAL &&
        calendarView === CalendarView.MONTH_VIEW,
      "time-grid-general":
        eventType === EventType.GENERAL &&
        calendarView !== CalendarView.MONTH_VIEW,
    });
  }

  function calendarHeaderClass(isToday: boolean) {
    return classNames({
      "bg-navy-500 text-white black-dot": isToday,
    });
  }

  function eventDurationCheck(start: Date | null, end: Date | null) {
    let hours = 0;
    if (start && end) {
      start.setSeconds(0);
      end.setSeconds(0);
      hours =
        Math.abs(end.getTime() - start.getTime()) /
        NUMBER_OF_SECONDS_IN_ONE_HOUR;
    }
    //returns true if event duration is shorter than one hour
    return hours !== 0 && hours < 1;
  }

  useEffect(() => {
    let newTitle = calendarComponentRef.current?.getApi()?.getCurrentData()
      .viewTitle;
    if (newTitle) {
      updateCalendarTitle(newTitle);
    }
  });

  function navigateAndRefresh(date: Date) {
    setCalendarView(CalendarView.DAY_VIEW);
    calendarComponentRef.current
      ?.getApi()
      ?.changeView(CalendarView.DAY_VIEW, date);
  }

  return (
    <FullCalendar
      //plugins and calendar related props
      plugins={[timeGridPlugin, dayGridPlugin]}
      initialView={calendarView}
      initialDate={initialDate}
      contentHeight="auto"
      progressiveEventRendering={false}
      allDaySlot={false}
      aspectRatio={1.5}
      headerToolbar={false}
      locale="hr"
      buttonText={{
        today: "Danas",
        month: "Mjesec",
        week: "Tjedan",
        day: "Dan",
        list: "Raspored",
      }}
      firstDay={FIRST_DAY_OF_THE_WEEK}
      nowIndicator
      navLinks={true}
      ref={calendarComponentRef}
      slotDuration={SLOT_DURATION}
      slotLabelInterval={SLOT_LABEL_DURATION}
      dayHeaderClassNames="font-normal"
      eventMinHeight={30}
      dayMaxEvents={3}
      moreLinkContent={(arg) => {
        return <div>{`+${arg.num}`}</div>;
      }}
      navLinkDayClick={(date) => {
        navigateAndRefresh(date);
      }}
      navLinkWeekClick={(date) => {
        navigateAndRefresh(date);
      }}
      dayHeaderContent={(date) => {
        const headerClassname = calendarHeaderClass(date.isToday);
        return (
          <div className="pt-2">
            <div></div>
            <Typography element="h3" variant="h6">
              {date.text.toUpperCase().substring(0, 3)}
            </Typography>
            {calendarComponentRef.current?.getApi().view.type !==
            CalendarView.MONTH_VIEW ? (
              <Typography element="h3" variant="h6">
                <div className={headerClassname}>{date.date.getDate()}</div>
              </Typography>
            ) : (
              ""
            )}
          </div>
        );
      }}
      moreLinkClick={(arg) => {
        navigateAndRefresh(arg.date);
      }}
      //event display related props
      events={events}
      eventClassNames={(arg) => {
        return setEventClassnames(arg.event.extendedProps.type);
      }}
      eventTextColor="black"
      eventDisplay="block"
      eventColor="gray"
      eventClick={(event) => {
        navigate(`/event-details/${event.event.id}`);
      }}
      eventContent={(event) => {
        return (
          <div>
            <div className={setEventContentClassnames()}>
              {eventDurationCheck(event.event.start, event.event.end) &&
                calendarComponentRef.current?.getApi().view.type !==
                  CalendarView.MONTH_VIEW && (
                  <div className="flex my-auto">
                    <Tooltip label={"Događaj traje kraće od prikazanog."}>
                      <Icon
                        type="star"
                        variant="fill"
                        size={1}
                        className="flex"
                      />
                    </Tooltip>
                  </div>
                )}
              {event.event.title}
            </div>
          </div>
        );
      }}
    />
  );
}
