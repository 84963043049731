export type ReactEnvironmentVariable = {
  REACT_APP_CLIENT_ID: string;
  REACT_APP_PROVIDER_URL: string;
  REACT_APP_REDIRECT_URI: string;
  REACT_APP_SIGNOUT_URI: string;
  REACT_APP_ENABLE_SURVEYS: string;
};

interface ReactWindow extends Window {
  REACT_ENV: ReactEnvironmentVariable;
}

declare let window: ReactWindow;
let env = window.REACT_ENV || {};

export default env;
