import React, { useEffect, useState } from "react";

import { Tabs, Typography } from "@tiller-ds/core";

import LoadingCircle from "../../common/components/LoadingCircle";
import { getClubInfo } from "../api/getClubInfo";
import { getClubManagement } from "../api/getClubManagement";
import BasicClubDataForm from "../components/BasicClubDataForm";
import ClubManagementForm from "../components/ClubManagementForm";

type ManagementForm = {
  president: string;
  vicePresident: string;
  director: string;
};

type BasicInfoForm = {
  streetName: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  oib: string;
  iban: string;
};

export default function EditClubInfo() {
  const [
    initialManagementFormValues,
    setInitialManagementFormValues,
  ] = useState({} as ManagementForm);
  const [initialBasicInfoFormValues, setInitialBasicInfoFormValues] = useState(
    {} as BasicInfoForm
  );

  const [isLoadingBasicData, setIsLoadingBasicData] = useState(true);
  const [isLoadingManagementData, setIsLoadingManagementData] = useState(true);

  useEffect(() => {
    getClubManagement().then((response) => {
      setInitialManagementFormValues(response);
      setIsLoadingBasicData(false);
    });
  }, []);

  useEffect(() => {
    getClubInfo().then((response) => {
      setInitialBasicInfoFormValues(response);
      setIsLoadingManagementData(false);
    });
  }, []);

  return (
    <LoadingCircle isLoading={isLoadingBasicData || isLoadingManagementData}>
      <div className="flex flex-col px-4 md:px-0 md:my-6">
        <div className="flex flex-col xl:w-1/2 lg:w-3/4 md:w-full">
          <div className="gap-y-6">
            <Typography
              element="h3"
              variant="h3"
              className="hidden md:inline font-biotiff"
            >
              Uređivanje podataka o klubu
            </Typography>
            <Typography
              element="h6"
              variant="h6"
              className="md:hidden font-biotiff"
            >
              Uređivanje podataka o klubu
            </Typography>
            <div>
              <Tabs>
                <Tabs.Tab label="Uprava kluba">
                  <div className="gap-y-8">
                    <ClubManagementForm {...initialManagementFormValues} />
                  </div>
                </Tabs.Tab>
                <Tabs.Tab label="Podaci o klubu">
                  <BasicClubDataForm {...initialBasicInfoFormValues} />
                </Tabs.Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </LoadingCircle>
  );
}
