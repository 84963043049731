import { CreateEventNoteRequest } from "./CreateEventNoteRequest";
import { EVENT_URL } from "../../../common/Routes";

export async function createEventNote(
  request: CreateEventNoteRequest,
  eventId: string
) {
  const response = await fetch(`${EVENT_URL}/${eventId}/notes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
