import { SetIsPaidRequest } from "./SetIsPaidRequest";
import { INVOICE_URL } from "../../../common/Routes";
import { InvoiceResponse } from "../../common/InvoiceResponse";

export async function putSetIsPaidRequest(
  request: SetIsPaidRequest
): Promise<InvoiceResponse> {
  const response = await fetch(`${INVOICE_URL}/is-paid`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
