import React from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { InputField } from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import CancelModal from "../../common/components/modals/CancelModal";
import {
  MAX_DIRECTOR_LENGTH,
  MAX_PRESIDENT_LENGTH,
  MAX_VICE_PRESIDENT_LENGTH,
} from "../../common/constants";
import { maxCharacterCountMessage } from "../../common/YupConstants";
import { postClubManagement } from "../api/postClubManagement";

type ClubManagementFormProps = {
  president: string;
  vicePresident: string;
  director: string;
};

const validationSchemaManagement = yup.object().shape({
  president: yup
    .string()
    .max(MAX_PRESIDENT_LENGTH, maxCharacterCountMessage(MAX_PRESIDENT_LENGTH))
    .nullable(),
  vicePresident: yup
    .string()
    .max(
      MAX_VICE_PRESIDENT_LENGTH,
      maxCharacterCountMessage(MAX_VICE_PRESIDENT_LENGTH)
    )
    .nullable(),
  director: yup
    .string()
    .max(MAX_DIRECTOR_LENGTH, maxCharacterCountMessage(MAX_DIRECTOR_LENGTH))
    .nullable(),
});

export default function ClubManagementForm(
  initialFormValues: ClubManagementFormProps
) {
  const { add: addNotification } = useNotifications();
  const cancelModal = useModal();

  const onSubmit = async (values: ClubManagementFormProps) => {
    postClubManagement({
      ...values,
    }).then((response) => {
      if (response) {
        addNotification({
          title: "Spremljeni podaci",
          content: "Vaši podaci su uspješno spremljeni.",
          messageList: [],
          severity: "INFO",
          timestamp: new Date(),
        });
      }
    });
  };

  return (
    <div className="mt-8 gap-y-4">
      <div>
        <Typography
          element="h6"
          variant="h6"
          className="hidden md:inline font-biotiff"
        >
          Zastupnici kluba
        </Typography>
        <Typography element="p" className="md:hidden font-bold font-biotiff">
          Zastupnici kluba
        </Typography>
        <hr />
      </div>
      <div>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaManagement}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <CancelModal
                modal={cancelModal}
                title="Uređivanje podataka o klubu"
                returnPath="/home"
              />
              <div className="flex flex-col gap-y-4 mb-6 mt-4">
                <InputField
                  name="president"
                  label="Predsjednik"
                  className="w-full md:w-1/2"
                />
                <InputField
                  name="vicePresident"
                  label="Dopredsjednik"
                  className="w-full md:w-1/2"
                />
                <InputField
                  name="director"
                  label="Direktor"
                  className="w-full md:w-1/2"
                />
              </div>
              <hr className="py-2" />
              <div className="flex justify-end gap-x-2">
                <Button
                  type="reset"
                  variant="outlined"
                  size="sm"
                  onClick={cancelModal.onOpen}
                >
                  Odustani
                </Button>
                <Button variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
