import React, { useContext } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import { UserType } from "../../../archive/common/api/UserResponse";
import { UserContext } from "../../../auth/SecurityProvider";
import ExportEventsModal from "../modals/ExportEventsModal";
import ReportAbsenceModal from "../ReportAbsenceModal";

export default function CalendarFooter() {
  const { userHasRole, user } = useContext(UserContext);

  const navigate = useNavigate();
  const reportAbsence = useModal();
  const exportModal = useModal();

  const createEvent = () => {
    navigate("/calendar/event/new");
  };

  return (
    <div className="flex justify-end px-4 md:px-0">
      <div className="flex flex-col md:flex-row justify-end mt-3 mb-5 space-x-0 md:space-x-5 space-y-2 md:space-y-0 w-fit">
        {userHasRole(UserType.ADMIN) && (
          <Button
            onClick={createEvent}
            leadingIcon={<Icon size={4} type="note-pencil" variant="bold" />}
            variant="outlined"
            color="primary"
            size="sm"
          >
            Kreiraj novi događaj
          </Button>
        )}
        <Button
          onClick={exportModal.onOpen}
          leadingIcon={<Icon type="download" />}
          variant="outlined"
          color="primary"
          size="sm"
        >
          Preuzmi
        </Button>
        {userHasRole(UserType.PARENT) && (
          <>
            <ReportAbsenceModal modal={reportAbsence} parentId={user.id} />
            <Button
              leadingIcon={<Icon type="user-minus" variant="fill" />}
              variant="outlined"
              color="primary"
              size="sm"
              onClick={reportAbsence.onOpen}
            >
              Odsutnost sportaša
            </Button>
          </>
        )}
      </div>
      <ExportEventsModal modal={exportModal} />
    </div>
  );
}
