import { MeasuringUnit } from "../../../common/constants";

export default function ProgressResultMapper(
  result: number,
  measuringUnit: MeasuringUnit
) {
  switch (measuringUnit) {
    case MeasuringUnit.Centimeter:
      return `${result.toString()}cm`;
    case MeasuringUnit.Kilogram:
      return `${result.toString()}kg`;
    case MeasuringUnit.Repetition:
      return `${result.toString()}`;
    case MeasuringUnit.Second:
      const minutes = Math.floor(result / 60);
      const seconds = Math.floor(result % 60);
      const centiseconds = Math.round((result - Math.floor(result)) * 100);
      return `${minutes < 10 ? "0" + minutes.toString() : minutes.toString()}:${
        seconds < 10 ? "0" + seconds.toString() : seconds.toString()
      }.${
        centiseconds < 10
          ? "0" + centiseconds.toString()
          : centiseconds.toString()
      }`;
  }
}
