import React, { useEffect, useState } from "react";

import { Card, Typography } from "@tiller-ds/core";
import { DescriptionList } from "@tiller-ds/data-display";

import { getParentById } from "../../../archive/parent/api/getParentById";
import { ParentResponse } from "../../../archive/parent/api/ParentResponse";
import LoadingCircle from "../../../common/components/LoadingCircle";

export type ParentProfileProps = {
  id: number;
};

export default function ParentProfile({ id }: ParentProfileProps) {
  const [parent, setParent] = useState({} as ParentResponse);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getParentById(id).then((parent) => {
        setParent(parent);
        setIsLoading(false);
      });
    }
  }, [id]);

  return (
    <LoadingCircle isLoading={isLoading}>
      <Card>
        <Card.Body removeSpacing={true}>
          <DescriptionList>
            <DescriptionList.Item label="Ime" type="same-column">
              <Typography className="font-semibold">
                {parent.firstName}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Prezime" type="same-column">
              <Typography className="font-semibold">
                {parent.lastName}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="E-mail" type="same-column">
              <Typography className="font-semibold">{parent.email}</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Broj telefona" type="same-column">
              <Typography className="font-semibold">
                {parent.phoneNumber}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item
              label="Uloga člana u sustavu"
              type="same-column"
            >
              <Typography className="font-semibold">Roditelj</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Djeca" type="same-column">
              <Typography className="font-semibold">
                {parent.children
                  ? parent.children.map((child, index) => (
                      <div>
                        {`${child.firstName}
                        ${child.lastName}`}
                      </div>
                    ))
                  : null}
              </Typography>
            </DescriptionList.Item>
          </DescriptionList>
        </Card.Body>
      </Card>
    </LoadingCircle>
  );
}
