import { AthleteProgressDataRequest } from "./AthleteProgressDataRequest";
import { PROGRESS_URL } from "../../../common/Routes";

export async function postSearchAthleteProgressData(
  request: AthleteProgressDataRequest
) {
  const response = await fetch(`${PROGRESS_URL}/search`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
