import { Notification } from "@croz/nrich-notification-core";

import handleResponse from "../../common/handleResponse";

export default function handleDeleteResponse(
  response: Response,
  addNotification: (notification: Notification) => void,
  notificationBody = {
    title: "Brisanje korisnika uspješno.",
    content: "Korisnik je uspješno izbrisan",
    messageList: [],
    severity: "INFO",
    timestamp: new Date(),
  } as Notification
): boolean {
  return handleResponse(response, addNotification, notificationBody);
}
