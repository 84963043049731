import fetchIntercept from "fetch-intercept";

export const getAccessTokenFromStorage = () => {
  const tokenData = localStorage.getItem("auth");

  if (!tokenData) {
    return null;
  }

  const parsedTokenData = JSON.parse(tokenData);

  return parsedTokenData.access_token;
};

export default function bearerTokenInterceptor() {
  fetchIntercept.register({
    request: function (url, config) {
      const accessToken = getAccessTokenFromStorage();

      if (accessToken && !url.endsWith("/oauth2/token")) {
        const bearerHeader = {
          Authorization: "Bearer " + accessToken,
        };
        if (config.headers) {
          config.headers = { ...config.headers, ...bearerHeader };
        } else {
          config.headers = { ...bearerHeader };
        }
      }

      return [url, config];
    },
  });
}
