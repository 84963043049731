import { UpdateGroupRequest } from "./UpdateGroupRequest";
import { GROUP_URL } from "../../../common/Routes";

export async function putUpdateGroupRequest(
  request: UpdateGroupRequest,
  groupId: number
) {
  const response = await fetch(`${GROUP_URL}/${groupId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
