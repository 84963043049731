import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Breadcrumbs, Button, Link, Typography } from "@tiller-ds/core";
import { RadioGroup } from "@tiller-ds/form-elements";
import {
  CheckboxField,
  InputField,
  NumberInputField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { CreateInvoicePagePreviewTable } from "./CreateInvoicePagePreviewTable";
import InvoiceWarningModal from "./InvoiceWarningModal";
import { AthleteResponse } from "../../../archive/athlete/api/AthleteResponse";
import SearchAthleteAutocompleteComponent from "../../../archive/athlete/components/SearchAthleteAutocompleteComponent";
import LoadingCircle from "../../../common/components/LoadingCircle";
import {
  INVOICE_RECIPIENT_GROUP,
  INVOICE_RECIPIENTS,
} from "../../../common/constants";
import {
  createInvoiceForAthletesValidationSchema,
  createInvoiceForGroupsValidationSchema,
} from "../../common/createInvoiceValidationSchema";
import { InvoiceForm } from "../../common/InvoiceForm";
import { InvoiceResponse } from "../../common/InvoiceResponse";
import { getAllInvoiceTemplate } from "../../template/api/getAllInvoiceTemplate";
import { InvoiceRecipientResponse } from "../../template/api/InvoiceRecipientResponse";
import { InvoiceTemplateResponse } from "../../template/api/InvoiceTemplateResponse";
import SearchInvoiceRecipientGroupAutocompleteComponent from "../../template/components/SearchInvoiceRecipientGroupAutocompleteComponent";
import { CreateInvoiceRequest } from "../api/CreateInvoiceRequest";
import { postCreateInvoiceRequest } from "../api/postCreateInvoiceRequest";
import { postPrepareInvoices } from "../api/postPrepareInvoices";
import handleCreateInvoiceResponse from "../handleCreateInvoiceResponse";

const CreateInvoicePage = () => {
  const [reInitFormik, setReInitFormik] = useState<boolean>(false);
  const [selectedRecipientType, setSelectedRecipientType] = useState(
    INVOICE_RECIPIENT_GROUP
  );
  const [formValues, setFormValues] = useState<InvoiceForm>({
    applyDiscount: true,
    addAthleteNameToDescription: true,
  } as InvoiceForm);
  const [selectedTemplate, setSelectedTemplate] = useState<
    InvoiceTemplateResponse
  >();
  const [templates, setTemplates] = useState<InvoiceTemplateResponse[]>([]);
  const [prepareButtonClicked, setPrepareButtonClicked] = useState<boolean>(
    false
  );
  const [preparedInvoices, setPreparedInvoices] = useState<InvoiceResponse[]>(
    []
  );
  const [createInvoiceRequest, setCreateInvoiceRequest] = useState<
    CreateInvoiceRequest
  >();
  const [userDeletedFromPreview, setUserDeletedFromPreview] = useState<boolean>(
    false
  );
  const [formInitiallySubmitted, setFormInitiallySubmitted] = useState<boolean>(
    false
  );
  const [athletesWithoutParents, setAthletesWithoutParents] = useState<
    AthleteResponse[]
  >([]);
  const invoiceWarningModal = useModal();

  const { add: addNotification } = useNotifications();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false as boolean);

  useEffect(() => {
    getAllInvoiceTemplate().then((response) => setTemplates(response));
  }, []);

  const selectTemplateHandler = (template: InvoiceTemplateResponse) => {
    if (!template) {
      return;
    }

    setSelectedTemplate(template);

    setFormValues({
      amount: template.amount,
      dueDays: template.dueDays,
      description: template.description,
      applyDiscount: template.applyDiscount,
      addAthleteNameToDescription: template.addAthleteNameToDescription,
      invoiceRecipientGroups: template.invoiceRecipientGroups,
      invoiceRecipients: template.invoiceRecipients,
    } as InvoiceForm);

    if (
      template.invoiceRecipientGroups &&
      template.invoiceRecipientGroups.length > 0
    ) {
      setSelectedRecipientType(INVOICE_RECIPIENT_GROUP);
    } else {
      setSelectedRecipientType(INVOICE_RECIPIENTS);
    }

    setReInitFormik(true);
  };

  function generateCreateInvoiceRequest(formData: InvoiceForm) {
    const {
      description,
      amount,
      dueDays,
      invoiceRecipients,
      invoiceRecipientGroups,
    } = formData;

    const request: CreateInvoiceRequest = {
      description,
      amount,
      daysUntilDue: dueDays,
      applyDiscount: formData.applyDiscount,
      addAthleteNameToDescription: formData.addAthleteNameToDescription,
    };

    if (invoiceRecipients && invoiceRecipients.length > 0) {
      const recipientIds = (invoiceRecipients || []).map(
        (recipient) => recipient.id
      );

      if (recipientIds.length > 0) {
        request.athleteIds = recipientIds;
      }
    }

    if (invoiceRecipientGroups && invoiceRecipientGroups.length > 0) {
      const groupIds = (invoiceRecipientGroups || [])
        .map((group) => group.id)
        .filter((id) => (id || id === 0) && Number.isInteger(id));

      if (groupIds.length > 0) {
        request.groupIds = groupIds;
      }
    }

    return request;
  }

  const formSubmitHandler = (form: InvoiceForm) => {
    setPrepareButtonClicked(true);

    let request = generateCreateInvoiceRequest(form);
    setCreateInvoiceRequest(request);
    postPrepareInvoices(request).then((response) => {
      setPreparedInvoices(response);
      const athletes = response.map((invoice) => invoice.athlete);
      const athletesWithoutParents = athletes.filter(
        (athlete) => athlete.parents.length < 1
      );

      if (athletesWithoutParents.length > 0) {
        setAthletesWithoutParents(athletesWithoutParents);
        invoiceWarningModal.onOpen(null);
      }
    });

    setFormValues(form);
    setFormInitiallySubmitted(true);
  };

  useEffect(() => {
    setReInitFormik(false);
  }, [reInitFormik]);

  function saveInvoicesToDb() {
    if (!createInvoiceRequest) {
      return;
    }

    setLoading(true);
    postCreateInvoiceRequest(createInvoiceRequest).then((response) => {
      setLoading(false);
      if (handleCreateInvoiceResponse(response, addNotification)) {
        navigate("/payments");
      }
    });
  }

  function wasRecipientRemovedFromPreviewTable(data: InvoiceForm) {
    return (
      data.invoiceRecipients &&
      formValues.invoiceRecipients &&
      data.invoiceRecipients.length !== formValues.invoiceRecipients.length
    );
  }

  function wasRecipientGroupRemovedFromPreviewTable(data: InvoiceForm) {
    return (
      data.invoiceRecipientGroups &&
      formValues.invoiceRecipientGroups &&
      data.invoiceRecipientGroups.length !==
        formValues.invoiceRecipientGroups.length
    );
  }

  return (
    <LoadingCircle isLoading={loading}>
      <div className="flex flex-col space-y-10 px-4 md:px-0 md:w-3/4 md:mx-auto">
        <Formik
          enableReinitialize={reInitFormik}
          validationSchema={
            selectedRecipientType === INVOICE_RECIPIENT_GROUP
              ? createInvoiceForGroupsValidationSchema
              : createInvoiceForAthletesValidationSchema
          }
          initialValues={formValues}
          onSubmit={formSubmitHandler}
          validateOnChange={true}
          validate={(data) => {
            if (
              formInitiallySubmitted &&
              JSON.stringify(formValues) !== JSON.stringify(data) &&
              !userDeletedFromPreview
            ) {
              // if a user/group was removed from the preview table, update the request
              if (wasRecipientRemovedFromPreviewTable(data)) {
                setPreparedInvoices(
                  preparedInvoices.filter((invoice) =>
                    data.invoiceRecipients.find(
                      (recipient) => recipient.id === invoice.athlete.id
                    )
                  )
                );

                setCreateInvoiceRequest(
                  generateCreateInvoiceRequest({
                    ...formValues,
                    invoiceRecipients: data.invoiceRecipients,
                  })
                );
              } else if (wasRecipientGroupRemovedFromPreviewTable(data)) {
                // remove athletes that do not belong to any remaining group
                setPreparedInvoices(
                  preparedInvoices.filter((invoice) =>
                    data.invoiceRecipientGroups.find((group) =>
                      invoice.athlete.groups.find(
                        (athleteGroup) => athleteGroup.id === group.id
                      )
                    )
                  )
                );

                setCreateInvoiceRequest(
                  generateCreateInvoiceRequest({
                    ...formValues,
                    invoiceRecipientGroups: data.invoiceRecipientGroups,
                  })
                );
              } else {
                // reset preview table
                setPreparedInvoices([]);
              }
            }
            setUserDeletedFromPreview(false);
          }}
        >
          {(formik) => (
            <form
              className="flex flex-col gap-y-5 pr-3"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <Typography variant="h3" element="h3">
                Kreiranje računa
              </Typography>
              <div className="md:pt-2 invisible md:visible">
                <Breadcrumbs>
                  <Breadcrumbs.Breadcrumb>
                    <Link to="/payments">Plaćanja</Link>
                  </Breadcrumbs.Breadcrumb>
                  <Breadcrumbs.Breadcrumb>
                    Kreiranje računa
                  </Breadcrumbs.Breadcrumb>
                </Breadcrumbs>
              </div>
              <div className="flex flex-col items-start sm:flex-row sm:space-x-5 sm:items-center mt-5">
                <DropdownMenu
                  title={
                    selectedTemplate
                      ? selectedTemplate.title
                      : "Odaberite predložak"
                  }
                  color="primary"
                  className="mt-5 mb-5 w-full sm:w-fit"
                  visibleItemCount={
                    templates.length >= 5 ? 5 : templates.length
                  }
                >
                  {templates.map((template) => (
                    <DropdownMenu.Item
                      onSelect={() => selectTemplateHandler(template)}
                    >
                      {template.title}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-x-10 mt-3">
                <div className="flex flex-col items-start md:flex-row md:gap-x-3 md:items-center ">
                  <NumberInputField
                    name="amount"
                    label={"Iznos"}
                    className="w-full md:w-1/2 flex-grow"
                    allowClear={false}
                    required={true}
                    inlineTrailingIcon={
                      <Icon type="currency-eur" variant="light" />
                    }
                    decimalScale={2}
                    placeholder="20,25"
                    fixedDecimalScale
                  />
                  <div className="translate-y-1/2">
                    <CheckboxField
                      name="applyDiscount"
                      label="Primijeni popust"
                    />
                  </div>
                </div>

                <NumberInputField
                  required={true}
                  name="dueDays"
                  label={"Broj dana do dospijeća"}
                  className="mt-3 md:mt.0"
                  allowClear={false}
                  decimalScale={0}
                  fixedDecimalScale
                />
              </div>
              <div className="flex flex-col gap-y-3">
                <InputField
                  required={true}
                  name="description"
                  label={"Opis plaćanja"}
                  className="w-full mt-3"
                  allowClear={true}
                />
                <CheckboxField
                  name="addAthleteNameToDescription"
                  label="Dodaj ime sportaša u opis"
                />
              </div>
              <div className="w-full mt-3">
                <Typography className="justify-center align-top align-text-top mt-2">
                  Sudionici<span className="text-red-600"> *</span>
                </Typography>
                <RadioGroup
                  name="autocompleteType"
                  label=""
                  onChange={(value) => {
                    setSelectedRecipientType(value as string);
                    formik.setFieldValue(INVOICE_RECIPIENT_GROUP, []);
                    formik.setFieldValue(INVOICE_RECIPIENTS, []);
                  }}
                  value={selectedRecipientType}
                >
                  <RadioGroup.Item
                    label="Grupe"
                    value={INVOICE_RECIPIENT_GROUP}
                  ></RadioGroup.Item>
                  <RadioGroup.Item
                    label="Pojedinačni članovi"
                    value={INVOICE_RECIPIENTS}
                  ></RadioGroup.Item>
                </RadioGroup>
              </div>
              <div className="flex flex-col w-full mt-3">
                {selectedRecipientType === INVOICE_RECIPIENTS && (
                  <SearchAthleteAutocompleteComponent />
                )}
                {selectedRecipientType === INVOICE_RECIPIENT_GROUP && (
                  <SearchInvoiceRecipientGroupAutocompleteComponent />
                )}
              </div>
              <div className="flex flex-col-reverse space-y-reverse space-y-5 sm:space-y-0 sm:flex-row sm:space-x-3 justify-end mt-5">
                <Button
                  type="reset"
                  className="w-full sm:w-fit"
                  variant="outlined"
                  onClick={() => navigate("/payments")}
                  hidden={prepareButtonClicked}
                >
                  Odustani
                </Button>
                <Button
                  className="w-full sm:w-fit"
                  variant="filled"
                  type="submit"
                >
                  Pripremi račune
                </Button>
              </div>
              <div className="mt-3 flex flex-col space-y-3 items-center">
                {!prepareButtonClicked && (
                  <Typography variant="subtext" className="mt-3">
                    Računi će se generirati u nastavku.
                  </Typography>
                )}
              </div>
            </form>
          )}
        </Formik>
        {prepareButtonClicked && (
          <div className="mt-3">
            <CreateInvoicePagePreviewTable
              preparedInvoices={preparedInvoices}
              onDeleteInvoiceFromPreview={(remainingInvoices) => {
                setUserDeletedFromPreview(true);
                setSelectedRecipientType(INVOICE_RECIPIENTS);

                setFormValues((prevState) => {
                  const newInitialValues = { ...prevState };

                  newInitialValues.invoiceRecipients = [];
                  newInitialValues.invoiceRecipientGroups = [];

                  let remainingRecipients: InvoiceRecipientResponse[] = [];
                  remainingInvoices.forEach((remainingInvoice) => {
                    const invoiceRecipient = {
                      id: remainingInvoice.athlete.id,
                      firstName: remainingInvoice.athlete.firstName,
                      lastName: remainingInvoice.athlete.lastName,
                    } as InvoiceRecipientResponse;

                    remainingRecipients.push(invoiceRecipient);

                    if (invoiceRecipient.id !== undefined) {
                      newInitialValues.invoiceRecipients = [
                        ...newInitialValues.invoiceRecipients,
                        invoiceRecipient,
                      ];
                    }
                  });

                  let request = generateCreateInvoiceRequest({
                    ...formValues,
                    invoiceRecipients: remainingRecipients,
                  });

                  setCreateInvoiceRequest(request);

                  return newInitialValues;
                });

                setPreparedInvoices(remainingInvoices);

                setReInitFormik(true);
              }}
            />
          </div>
        )}
        {formInitiallySubmitted && (
          <div className="flex flex-col gap-y-3 sm:gap-y-0 sm:flex-row sm:gap-x-3 justify-end">
            <Button
              className="w-full sm:w-fit"
              variant="outlined"
              onClick={() => navigate("/payments")}
            >
              Odustani
            </Button>
            <Button
              className="w-full sm:w-fit"
              variant="filled"
              color="primary"
              onClick={saveInvoicesToDb}
              disabled={preparedInvoices.length === 0}
            >
              Pošalji račune
            </Button>
          </div>
        )}
      </div>
      <InvoiceWarningModal
        modal={invoiceWarningModal}
        athletesWithError={athletesWithoutParents}
      />
    </LoadingCircle>
  );
};

export default CreateInvoicePage;
