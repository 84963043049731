import { GetUsersByRecipientGroupsRequest } from "./GetUsersByRecipientGroupsRequest";
import { UserResponse } from "./UserResponse";
import { USER_URL } from "../../../common/Routes";

export async function getUsersByRecipientGroups(
  request: GetUsersByRecipientGroupsRequest
): Promise<UserResponse[]> {
  const response = await fetch(`${USER_URL}/get-by-recipient-groups`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
