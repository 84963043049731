import React from "react";

import { Card, Link, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

type GroupCardProps = {
  title: string;
  content: string;
  eventId: number;
};

export default function EventCard({ title, content, eventId }: GroupCardProps) {
  return (
    <Card className="h-full">
      <Card.Body>
        <div className="flex flex-col justify-between h-full">
          <div className="pb-2">
            <Typography
              element="p"
              variant="title"
              className="mb-2 text-navy-600 flex items-end"
            >
              <Icon
                type="calendar-blank"
                className="text-md mr-1 w-6 h-6 text-navy-500"
              ></Icon>
              <b>{title}</b>
            </Typography>
            <Typography
              element="p"
              variant="subtitle"
              className="text-gray-500"
            >
              {content}
            </Typography>
          </div>
          <div className="flex items-end justify-end w-full">
            <div className="mr-2 flex">
              <Link to={`/event-details/${eventId}`}>
                <div className="flex flex-row justify-center bg-navy-500 rounded">
                  <Icon
                    size={3}
                    type="caret-right"
                    variant="bold"
                    className="text-md m-2 text-white"
                  ></Icon>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
