import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { AthleteResponse } from "../../../archive/athlete/api/AthleteResponse";

type InvoiceWarningModalProps = {
  modal: UseModal;
  athletesWithError: AthleteResponse[];
};

export default function InvoiceWarningModal({
  modal,
  athletesWithError,
}: InvoiceWarningModalProps) {
  return (
    <Modal
      {...modal}
      icon={
        <Modal.Icon
          icon={<Icon type="warning" className="text-orange-500" />}
        />
      }
    >
      <Modal.Content title="Upozorenje">
        <Typography
          element="p"
          variant="subtext"
          className="pb-4 text-gray-500"
        >
          Sljedeći sportaši nemaju postavljene roditelje u aplikaciji:
        </Typography>
        {athletesWithError
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map((athlete) => (
            <>
              <span className="mt-1">
                {`${athlete.firstName} ${athlete.lastName}`}
              </span>
              <br />
            </>
          ))}
        <Typography
          element="p"
          variant="subtext"
          className="pt-4 text-gray-500"
        >
          Za njih računi neće biti stvoreni.
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          U redu
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
