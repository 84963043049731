import { PARENT_URL } from "../../../common/Routes";

export async function getOwnChildren() {
  const response = await fetch(`${PARENT_URL}/self/children`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
