import React from "react";

import { Typography } from "@tiller-ds/core";

import GroupBadge from "../../../archive/group/components/GroupBadge";
import { RankReportResponse } from "../../api/RankReportResponse";

type RankListProps = {
  results: RankReportResponse[];
};

export function RankList({ results }: RankListProps) {
  return (
    <div>
      {results.map((resultObj) => {
        return (
          <div className="flex flex-col pb-3 border-b mt-3">
            <div className="flex flex-row justify-between">
              <Typography variant="subtitle" element="p">
                {`${resultObj.athlete.firstName} ${resultObj.athlete.lastName}`}
              </Typography>
              <Typography variant="title" element="p">
                <p className={"text-orange-600"}>{resultObj.result}</p>
              </Typography>
            </div>
            <div className="flex flex-row gap-x-1 gap-y-1 flex-wrap">
              {resultObj.athlete.groups.map((group) => (
                <GroupBadge groupName={group.groupName} groupId={group.id} />
              ))}
            </div>
          </div>
        );
      })}
      {results.length === 0 && (
        <div className="mt-16 text-center">
          <Typography variant="subtext" element="p">
            <i>Za ovu statistiku trenutno nema zapisa</i>
          </Typography>
        </div>
      )}
    </div>
  );
}
