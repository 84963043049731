import { MessageRecipientRequest } from "./MessageRecipientRequest";
import { MESSAGE_MARK_AS_PINNED_URL } from "../../common/Routes";

export async function postMarkAsPinned(request: MessageRecipientRequest) {
  await fetch(MESSAGE_MARK_AS_PINNED_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
