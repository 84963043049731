import { MarkInvoicesAsSettledRequest } from "./MarkInvoicesAsSettledRequest";
import { INVOICE_URL } from "../../../common/Routes";

export async function putMarkInvoicesAsSettledRequest(
  request: MarkInvoicesAsSettledRequest
): Promise<Response> {
  return await fetch(`${INVOICE_URL}/mark-as-settled`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
