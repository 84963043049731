import { MessageFilterRequest } from "../messages/api/MessageFilterRequest";

export type SelectType = {
  name: string;
  value: string;
};

export const EVENT_OPTIONS = [
  { name: "Trening", value: "TRAINING" },
  { name: "Natjecanje", value: "COMPETITION" },
  { name: "Sastanak", value: "MEETING" },
  { name: "Ostalo", value: "GENERAL" },
];

export enum CalendarView {
  DAY_VIEW = "timeGridDay",
  WEEK_VIEW = "timeGridWeek",
  MONTH_VIEW = "dayGridMonth",
}

export enum HeaderCheckboxState {
  Unchecked,
  HalfChecked,
  Checked,
}

export enum EventType {
  TRAINING = "TRAINING",
  COMPETITION = "COMPETITION",
  MEETING = "MEETING",
  GENERAL = "GENERAL",
  ALL = "ALL",
}

export enum MeasuringUnit {
  Kilogram = "KILOGRAM",
  Centimeter = "CENTIMETER",
  Second = "SECOND",
  Repetition = "REPETITION",
}

export enum BestResultSorting {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SurveyStatus {
  Active = "Aktivna",
  Inactive = "Neaktivna",
  Finished = "Završena",
}

export enum SurveyAnswerType {
  MultipleChoice = "Više odgovora",
  SingleChoice = "Jedan odgovor",
  LinearScale = "Linearna skala",
  PersonalAnswer = "Vlastiti odgovor",
}

export enum NoteVisibilityType {
  ADMIN_AND_COACH = "ADMIN_AND_COACH",
  ALL = "ALL",
}

export enum RegistryTabIndex {
  GROUPS,
  ATHLETES,
  PARENTS,
  COACHES,
  ADMINS,
}

export enum InvoiceStatus {
  NEPODMIRENO = "NEPODMIRENO",
  PODMIRENO = "PODMIRENO",
  DOSPJELO = "DOSPJELO",
}

export const INVOICE_STATUS_OPTIONS = [
  { name: "Zadano", value: InvoiceStatus.NEPODMIRENO },
  { name: "Podmireno", value: InvoiceStatus.PODMIRENO },
];

export const REGISTRY_SEARCH_ERROR_MESSAGE = "Nema rezultata!";
export const ATTENDANCE_RECORD_MODAL_EMPTY_MESSAGE =
  "Nema sportaša dodanih na ovaj događaj.";

export const ATTENDANCE_RECORD_MODAL_NO_EVENTS_MESSAGE =
  "Danas nema događaja za evidentirati dolaske.";

export const FIRST_DAY_OF_THE_WEEK = 1; // Monday
export const SLOT_DURATION = "01:00:00";
export const SLOT_LABEL_DURATION = "02:00:00";

export const EVENT_REPEATING_OPTIONS = [
  { name: "Ne ponavljaj", value: "NO_REPEATING" },
  {
    name: "Svaki dan",
    value: "DAILY",
  },
  { name: "Tjedno", value: "WEEKLY" },
];

export const EVENT_REPEATING_OPTIONS_WITHOUT_NO_REPEATING = [
  {
    name: "Svaki dan",
    value: "DAILY",
  },
  { name: "Tjedno", value: "WEEKLY" },
];

export const DELETE_UPDATE_REPEATING_EVENT_OPTIONS = [
  "one_instance",
  "all_instances",
];

export const DAYS_OF_WEEK = [
  { name: "PON", value: "MONDAY" },
  { name: "UTO", value: "TUESDAY" },
  { name: "SRI", value: "WEDNESDAY" },
  { name: "ČET", value: "THURSDAY" },
  { name: "PET", value: "FRIDAY" },
  { name: "SUB", value: "SATURDAY" },
  { name: "NED", value: "SUNDAY" },
];

export const IconBGColor = [
  "bg-red-100",
  "bg-orange-100",
  "bg-amber-100",
  "bg-yellow-100",
  "bg-lime-100",
  "bg-green-100",
  "bg-emerald-100",
  "bg-teal-100",
  "bg-cyan-100",
  "bg-sky-100",
  "bg-blue-100",
  "bg-indigo-100",
  "bg-violet-100",
  "bg-purple-100",
  "bg-fuchsia-100",
  "bg-pink-100",
  "bg-rose-100",
];

export const IconFGColor = [
  "text-red-800",
  "text-orange-800",
  "text-amber-800",
  "text-yellow-800",
  "text-lime-800",
  "text-green-800",
  "text-emerald-800",
  "text-teal-800",
  "text-cyan-800",
  "text-sky-800",
  "text-blue-800",
  "text-indigo-800",
  "text-violet-800",
  "text-purple-800",
  "text-fuchsia-800",
  "text-pink-800",
  "text-rose-800",
];

export const MESSAGES_PER_PAGE = 10;
export const BASE_MESSAGES_FILTER_REQUEST = {
  pageNumber: 0,
  pageSize: MESSAGES_PER_PAGE,
  sortPropertyList: [
    {
      property: "message.timeSent",
      direction: "DESC",
    },
  ],
} as MessageFilterRequest;
export enum MessageFilter {
  RECEIVED = "Primljeno",
  READ = "Pročitano",
  UNREAD = "Nepročitano",
  PINNED = "Prikvačeno",
  SENT = "Poslano",
}

export const ROLE_OPTIONS = [
  { name: "Sportaš", value: "ATHLETE" },
  { name: "Trener", value: "COACH" },
  { name: "Roditelj", value: "PARENT" },
  { name: "Administrator", value: "ADMIN" },
];

export const GENDER_OPTIONS = [
  { name: "Muško", value: "MALE" },
  { name: "Žensko", value: "FEMALE" },
];

export let userType = new Map([
  ["ADMIN", "Administrator"],
  ["ATHLETE", "Sportaš"],
  ["COACH", "Trener"],
  ["PARENT", "Roditelj"],
]);

export enum UserType {
  PARENT = "PARENT",
  ATHLETE = "ATHLETE",
  COACH = "COACH",
  ADMIN = "ADMIN",
}

export const MAX_ARCHIVE_ROWS = 10;

export const eventTypesForIncomingEventsComponent = [
  "Sastanak",
  "Natjecanje",
  "Ostalo",
];

export const MAX_COMMENT_LENGTH = 255;
export const MAX_NOTE_LENGTH = 255;

export const NUM_OF_HOURS_IN_DAY = 24;

export const YEARS_LIMIT = 5;

export const MIN_DATE = "2000-01-01";

export const MAX_SURVEY_DESCRIPTION_LENGTH = 255;

export const MIN_SURVEY_TITLE_LENGTH = 2;
export const MAX_SURVEY_TITLE_LENGTH = 50;

export const MAX_SURVEY_OPTION_VALUE_LENGTH = 100;

export const MAX_TITLE_LENGTH = 50;

export const MAX_LEADER_LENGTH = 40;

export const MAX_LOCATION_NAME_LENGTH = 30;

export const MIN_NAME_LENGTH = 2;
export const MAX_NAME_LENGTH = 50;

export const MIN_SURNAME_LENGTH = 2;
export const MAX_SURNAME_LENGTH = 50;

export const MIN_TYPE_NAME_LENGTH = 2;
export const MAX_TYPE_NAME_LENGTH = 30;

export const OIB_LENGTH = 11;

export const MIN_EMAIL_LENGTH = 6;
export const MAX_EMAIL_LENGTH = 100;

export const MAX_STREET_NAME_LENGTH = 40;
export const MAX_HOUSE_NUMBER_LENGTH = 20;
export const MAX_CITY_LENGTH = 40;

export const NAME_AND_SURNAME_REGEX = "^[a-zA-Z 'čćžđšČĆŽĐŠ-]*$";

export const PHONE_NUMBER_REGEX = "^[\\d ]*$";

export const DECIMAL_NUMBER_REGEX = "^\\d*\\.{0,1}\\d*$";

export const NUMBER_REGEX = "^[\\d ]*$";

export const MIN_GROUP_NAME_LENGTH = 2;
export const MAX_GROUP_NAME_LENGTH = 50;

export const MAX_PRESIDENT_LENGTH = 40;
export const MAX_VICE_PRESIDENT_LENGTH = 40;
export const MAX_DIRECTOR_LENGTH = 40;

export const MAX_MESSAGE_DESCRIPTION_LENGTH = 2000;

export const MAX_CONTENT_LENGTH = 255;

export const MAX_PHONE_NUMBER_LENGTH = 20;

export const MAX_MESSAGE_CONTENT_LENGTH = 2000;

export const MAX_MESSAGE_SUBJECT_LENGTH = 50;

export const MAX_EVENT_DESCRIPTION_LENGTH = 255;

export const MAX_CARDS_PER_PAGE = 8;

export const MIN_AUTOCOMPLETE_QUERY_LENGTH = 2;

export const GROUP_MIN_AGE = 1;

export const AUTOCOMPLETE_SEARCH_PLACEHOLDER =
  "Unesite barem 2 slova za pretraživanje...";

export const MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE = 5;

export const SESSION_EXTEND_MODAL_OPEN_AT_MINUTES_LEFT = 5;

export const MAX_REPORT_ROWS = 10;

export const MAX_RANK_REPORT_MODAL_ROWS = 5;

export const SCREEN_WIDTH_BREAKPOINTS = {
  tablet: 640,
  laptop: 1024,
  desktop: 1280,
};

export const MEASUREMENT_UNIT_ABBREVIATION: { [key: string]: string } = {
  second: "sek",
  centimeter: "cm",
  kilogram: "kg",
  repetition: "rep",
};

export const MAX_INVOICE_TEMPLATE_TITLE_LENGTH = 30;

export const MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH = 18;

export const MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH = 50;

export const INVOICE_RECIPIENTS = "invoiceRecipients";

export const INVOICE_RECIPIENT_GROUP = "invoiceRecipientGroups";

export const INVOICE_VIEW_DATE_FORMAT = "dd. MM. yyyy";

export const MAX_INVOICE_REMINDER_MESSAGE_LENGTH = 2000;
