import { COACH_URL } from "../../../common/Routes";

export function deleteCoach(id: number) {
  return fetch(`${COACH_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }).then((res) => {
    return res;
  });
}
