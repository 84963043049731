import { UpdateAthleteRequest } from "./UpdateAthleteRequest";
import { ATHLETE_URL } from "../../../common/Routes";

export async function putUpdateAthleteRequest(
  request: UpdateAthleteRequest,
  athleteId: number
): Promise<Response> {
  return await fetch(`${ATHLETE_URL}/${athleteId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
