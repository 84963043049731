import React, { useContext, useEffect, useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { Checkbox } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { UserType } from "../../../archive/common/api/UserResponse";
import { UserContext } from "../../../auth/SecurityProvider";
import { EventResponse } from "../../../event/api/EventResponse";
import {
  AttendanceRecordResponse,
  newAttendanceRecordResponse,
  participantResponse,
} from "../../api/AttendanceResponse";
import { getAttendanceRecord } from "../../api/getAttendanceRecord";
import { getParticipants } from "../../api/getParticipants";
import { PlannedAbsenceBriefResponse } from "../../api/PlannedAbsenceResponse";
import { putUpdateRecordRequest } from "../../api/UpdateRecordRequest";
import {
  ATTENDANCE_RECORD_MODAL_EMPTY_MESSAGE,
  ATTENDANCE_RECORD_MODAL_NO_EVENTS_MESSAGE,
} from "../../constants";
import formatDateInTimeZone from "../../formatDateInTimeZone";

type AttendanceRecordProps = {
  events: EventResponse[];
  modal: UseModal;
};

type participantsList = {
  participantId: number;
  firstName: string;
  lastName: string;
  wasPresent: boolean;
  plannedAbsence: PlannedAbsenceBriefResponse | null;
};

const AttendanceRecordModal = ({ events, modal }: AttendanceRecordProps) => {
  const { add: addNotification } = useNotifications();

  const { userHasRole } = useContext(UserContext);

  const [loaded, setLoaded] = useState(false);
  const [attendanceRecord, setAttendanceRecord] = useState(
    [] as AttendanceRecordResponse[]
  );
  const [newAttendanceRecord, setNewAttendanceRecord] = useState(
    [] as newAttendanceRecordResponse[]
  );
  const [participants, setParticipants] = useState([] as participantsList[]);
  const [checkedEvent, setCheckedEvent] = useState({} as EventResponse);

  useEffect(() => {
    if (events.length > 0) {
      setCheckedEvent(events[0]);
    }
  }, [events]);

  useEffect(() => {
    if (checkedEvent.id && userHasRole(UserType.COACH)) {
      getAttendanceRecord(checkedEvent.id).then((response) => {
        setLoaded(true);
        setAttendanceRecord(response);
      });
    }
  }, [checkedEvent]); // eslint-disable-line

  useEffect(() => {
    if (loaded) {
      const newRecord = [...attendanceRecord].map(
        (participant: newAttendanceRecordResponse) => {
          return {
            participantId: participant.participantId,
            wasPresent: participant.wasPresent,
            eventId: checkedEvent.id,
          };
        }
      );
      setNewAttendanceRecord(newRecord);
    }
  }, [attendanceRecord, loaded, checkedEvent]);

  useEffect(() => {
    if (loaded) {
      getParticipants(checkedEvent.id).then((response) => {
        const athletes = response.participants.filter(
          (participant: participantResponse) =>
            participant.userType === "ATHLETE"
        );

        function findAthlete(id: number) {
          return attendanceRecord.find(
            (record: AttendanceRecordResponse) => record.participantId === id
          );
        }

        const participantsList = [] as participantsList[];
        for (let athlete of athletes) {
          const recordAthlete = findAthlete(athlete.id);
          participantsList.push({
            participantId: athlete.id,
            firstName: athlete.firstName,
            lastName: athlete.lastName,
            wasPresent: recordAthlete!.wasPresent,
            plannedAbsence: recordAthlete!.plannedAbsence,
          });
        }
        setParticipants(participantsList);
      });
    }
  }, [attendanceRecord, checkedEvent, loaded]);

  function toggleCheck(participantId: number) {
    const updatedList = [...participants].map(
      (participant: participantsList) => {
        if (participant.participantId === participantId) {
          participant.wasPresent = !participant.wasPresent;
        }
        return participant;
      }
    );
    const updatedRecord = [...newAttendanceRecord].map(
      (participant: newAttendanceRecordResponse) => {
        if (participant.participantId === participantId) {
          participant.wasPresent = !participant.wasPresent;
        }
        return participant;
      }
    );
    setNewAttendanceRecord(updatedRecord);
    setParticipants(updatedList);
  }

  const onSubmitAttendance = () => {
    putUpdateRecordRequest({
      eventAttendances: newAttendanceRecord,
    }).then((_) => {});
    addNotification({
      title: "Dolasci su evidentirani.",
      content: `Dolasci sportaša na trening su uspješno evidentirani.`,
      messageList: [],
      severity: "INFO",
      timestamp: new Date(),
    });
    modal.onClose();
  };

  function eventTitle(event: EventResponse) {
    if (event) {
      return (
        <Typography
          variant="subtext"
          className="text-gray-600 flex justify-left"
        >
          {`${event.title} (${formatDateInTimeZone(
            event.startTime,
            "HH:mm"
          )} - ${formatDateInTimeZone(event.endTime, "HH:mm")})`}
        </Typography>
      );
    }
  }

  function eventDate(event: EventResponse) {
    if (event) {
      return `(${formatDateInTimeZone(event.startTime, "dd.MM.yyyy.")})`;
    }
    return <></>;
  }

  return (
    <Modal {...modal}>
      {loaded ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col content-center sm:flex-row gap-2">
            <div className="hidden sm:block my-auto">
              <Icon type="calendar-check" />
            </div>
            <Typography
              element="h3"
              variant="h4"
              className="flex flex-col sm:flex-row gap-1"
            >
              <div className="flex justify-start">Evidencija dolazaka</div>
              <div className="flex justify-start">
                {eventDate(checkedEvent)}
              </div>
            </Typography>
          </div>
          {events.length > 1 ? (
            <DropdownMenu
              title={eventTitle(checkedEvent)}
              openExpanderIcon={<Icon type="caret-down" variant="bold" />}
              closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
            >
              {events.map((event) => {
                return (
                  <DropdownMenu.Item
                    onSelect={() => {
                      setCheckedEvent(event);
                    }}
                  >
                    {eventTitle(event)}
                  </DropdownMenu.Item>
                );
              })}
            </DropdownMenu>
          ) : (
            eventTitle(events[0])
          )}
          {participants.length > 0 ? (
            <div className="border border-gray-200 overflow-y-scroll max-h-96">
              <ul className="w-full text-lg font-medium text-gray-900 bg-white">
                {participants.map((participant) => (
                  <li
                    key={participant.participantId}
                    className="flex justify-between w-full p-3 border-b border-gray-200"
                  >
                    <div className="flex items-center h-8 py-1">
                      <Checkbox
                        checked={participant.wasPresent}
                        onChange={() => {
                          toggleCheck(participant.participantId);
                        }}
                      ></Checkbox>
                      <div className="flex ml-6">
                        <Typography element="h5" variant="h6">
                          {participant.firstName} {participant.lastName}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex items-center justify-end mx-2">
                      {participant.plannedAbsence !== null && (
                        <details className="flex justify-end">
                          <summary className=" inline-flex justify-end text-sm">
                            <p className=" cursor-pointer py-0.5 px-3 rounded-full text-red-800 bg-red-100">
                              Odsutan
                            </p>
                          </summary>
                          <div className="flex flex-col justify-start bg-white shadow py-1 px-2 text-sm">
                            <p className="flex justify-start">
                              {`Do: ${new Date(
                                participant.plannedAbsence.endTime
                              ).toLocaleDateString("hr-HR")}`}
                            </p>
                            <p className="flex justify-start">
                              {participant.plannedAbsence?.note}
                            </p>
                          </div>
                        </details>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Typography variant="text">
              {ATTENDANCE_RECORD_MODAL_EMPTY_MESSAGE}
            </Typography>
          )}
          <div className="flex justify-end my-4">
            <Button
              variant="filled"
              onClick={onSubmitAttendance}
              disabled={participants.length <= 0}
            >
              Spremi
            </Button>
          </div>
        </div>
      ) : (
        <Typography variant="text">
          {ATTENDANCE_RECORD_MODAL_NO_EVENTS_MESSAGE}
        </Typography>
      )}
    </Modal>
  );
};
export default AttendanceRecordModal;
