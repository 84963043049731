import React from "react";

import { Typography } from "@tiller-ds/core";

type ClubInfoBadgeProps = {
  number: number;
  title: string;
};

const typographyTokens = {
  variant: {
    h5: {
      color: "text-navy-600",
    },
  },
};

export default function ClubInfoBadge({ number, title }: ClubInfoBadgeProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center max-w-fit bg-navy-50 rounded-full p-2">
        <Typography
          tokens={typographyTokens}
          element="p"
          variant="h5"
          className="mb-[-3px] font-biotif"
        >
          {number}
        </Typography>
      </div>
      <Typography element="p" variant="h6" className="mt-2 text-primary">
        {title}
      </Typography>
    </div>
  );
}
