import React, { useEffect, useState } from "react";

import { AutocompleteField } from "@tiller-ds/formik-elements";

import { INVOICE_RECIPIENT_GROUP } from "../../../common/constants";
import { getAthleteRecipientGroups } from "../api/getAthleteRecipientGroups";
import { InvoiceRecipientGroupResponse } from "../api/InvoiceRecipientGroupResponse";

export default function SearchInvoiceRecipientGroupAutocompleteComponent() {
  const [
    recipientGroupsAutocomplete,
    setRecipientGroupsAutocomplete,
  ] = useState([] as InvoiceRecipientGroupResponse[]);

  useEffect(() => {
    getAthleteRecipientGroups().then((response) => {
      setRecipientGroupsAutocomplete(response);
    });
  }, []);

  return (
    <AutocompleteField
      name={INVOICE_RECIPIENT_GROUP}
      options={recipientGroupsAutocomplete}
      allowMultiple
      itemToString={(item) => `${item.groupName}`}
      getOptionLabel={(group) => (
        <div className="mt-3 flex items-center justify-between flex-wrap">
          {group.groupName}
        </div>
      )}
      filter={(query: string, option) =>
        `${option.groupName?.toLowerCase()}`.includes(query.toLowerCase())
      }
      getOptionValue={(item) => item}
      getMultipleSelectedLabel={(items) => {
        return items.map((group) => `${group.groupName}`).join(", ");
      }}
      tags={true}
      sendOptionValue={true}
    />
  );
}
