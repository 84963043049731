import FileDownload from "js-file-download";

import { AthleteReportsRequest } from "./AthleteReportsRequest";
import { GROUP_URL, REPORTS_STATE_ATHLETES_URL } from "../../common/Routes";
import { formatDateToHrWithDashes } from "../util/dateUtil";

export function exportAthleteReportsPDF(request: AthleteReportsRequest) {
  fetch(`${GROUP_URL}/${request.groupId}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((group) => {
      fetch(`${REPORTS_STATE_ATHLETES_URL}/export/pdf`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const date = formatDateToHrWithDashes(new Date());
          FileDownload(
            blob,
            `izvještaj_sportaša_${group.groupName}_${date}.pdf`
          );
        });
    });
}
