import { MESSAGE_RECIPIENTS_URL } from "../../common/Routes";

export async function getMessageRecipientsById(id: string) {
  const response = await fetch(`${MESSAGE_RECIPIENTS_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
