import React, { useState } from "react";

import { Button } from "@tiller-ds/core";
import {
  CheckboxField,
  DateRangeInputField,
  InputField,
  SelectField,
} from "@tiller-ds/formik-elements";

import { Formik } from "formik";
import moment from "moment";
import * as yup from "yup";

import {
  INVOICE_STATUS_OPTIONS,
  InvoiceStatus,
  MAX_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
  SelectType,
} from "../../../common/constants";
import {
  maxCharacterCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
} from "../../../common/YupConstants";
import { SearchInvoiceRequest } from "../../common/api/searchInvoiceRequest";

type InvoiceFilterCardProps = {
  setSearchRequestJson: (searchRequest: SearchInvoiceRequest) => void;
  searchRequestJson: SearchInvoiceRequest;
};

type Form = {
  athleteFirstName: string;
  athleteLastName: string;
  athleteParentsFirstName: string;
  athleteParentsLastName: string;
  dueDateFromIncluding: Date | null;
  dueDateToIncluding: Date | null;
  creationDateFromIncluding: Date | null;
  creationDateToIncluding: Date | null;
  status: InvoiceStatus | null;
  isOverdue: boolean;
};

const initialValues = {
  athleteFirstName: "",
  athleteLastName: "",
  athleteParentsFirstName: "",
  athleteParentsLastName: "",
  dueDateFromIncluding: null,
  dueDateToIncluding: null,
  creationDateFromIncluding: null,
  creationDateToIncluding: null,
  status: InvoiceStatus.NEPODMIRENO,
  isOverdue: false,
};

const validationSchema = yup.object().shape({
  athleteFirstName: yup
    .string()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  athleteLastName: yup
    .string()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  athleteParentsFirstName: yup
    .string()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  athleteParentsLastName: yup
    .string()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
});

export default function InvoiceFilterCard({
  setSearchRequestJson,
  searchRequestJson,
}: InvoiceFilterCardProps) {
  const [isOverdueCheckboxChecked, setIsOverdueCheckboxChecked] = useState(
    false
  );

  const onSubmitForm = (form: Form) => {
    setSearchRequestJson({
      ...searchRequestJson,
      athleteFirstName:
        form.athleteFirstName === "" ? null : form.athleteFirstName,
      athleteLastName:
        form.athleteLastName === "" ? null : form.athleteLastName,
      athleteParentsFirstName:
        form.athleteParentsFirstName === ""
          ? null
          : form.athleteParentsFirstName,
      athleteParentsLastName:
        form.athleteParentsLastName === "" ? null : form.athleteParentsLastName,
      dueDateFromIncluding: form.dueDateFromIncluding,
      dueDateToIncluding: form.dueDateToIncluding,
      creationDateFromIncluding: form.creationDateFromIncluding
        ? new Date(form.creationDateFromIncluding)
        : null,
      creationDateToIncluding: form.creationDateToIncluding
        ? new Date(
            moment(new Date(form.creationDateToIncluding))
              .add(1, "day")
              .toISOString()
          )
        : null,

      status: form.status,
      pageNumber: 0,
    });
  };

  const onClickResetFilter = () => {
    setSearchRequestJson({
      ...searchRequestJson,
      athleteFirstName: null,
      athleteLastName: null,
      athleteParentsFirstName: null,
      athleteParentsLastName: null,
      dueDateFromIncluding: null,
      dueDateToIncluding: null,
      creationDateFromIncluding: null,
      creationDateToIncluding: null,
      status: InvoiceStatus.NEPODMIRENO,
    });
  };

  return (
    <div className="w-full h-fit mb-5 bg-navy-50 border rounded-lg p-2">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
          >
            <div className="flex flex-col">
              <div className="flex flex-col gap-y-3 sm:flex-row sm:justify-between sm:gap-y-0 sm:gap-x-2 w-full">
                <InputField
                  className="w-full"
                  name="athleteFirstName"
                  label="Ime sportaša"
                ></InputField>

                <InputField
                  className="w-full"
                  name="athleteParentsFirstName"
                  label="Ime roditelja"
                ></InputField>

                <DateRangeInputField
                  className="w-full"
                  start="dueDateFromIncluding"
                  end="dueDateToIncluding"
                  label="Datum dospijeća od - do"
                  disabled={isOverdueCheckboxChecked}
                  onClick={() => {}}
                />

                <DateRangeInputField
                  className="w-full"
                  start={"creationDateFromIncluding"}
                  end={"creationDateToIncluding"}
                  maxDate={new Date()}
                  label="Datum kreiranja od - do"
                  onClick={() => {}}
                />
              </div>

              <div className="pt-2 flex flex-col gap-y-3 mt-3 sm:mt-0 sm:flex-row sm:justify-between sm:gap-y-0 sm:gap-x-2 w-full">
                <InputField
                  className="w-full"
                  name="athleteLastName"
                  label="Prezime sportaša"
                ></InputField>

                <InputField
                  className="w-full"
                  name="athleteParentsLastName"
                  label="Prezime roditelja"
                ></InputField>

                <SelectField
                  className="w-full"
                  name="status"
                  label="Status"
                  options={INVOICE_STATUS_OPTIONS}
                  getOptionLabel={(item: SelectType) => item.name}
                  getOptionValue={(item: SelectType) => item.value}
                  disabled={isOverdueCheckboxChecked}
                />

                <div className="w-full mt-3 sm:pt-2 sm:mt-3 flex">
                  <CheckboxField
                    name="isOverdue"
                    label="Dospjelo"
                    checked={isOverdueCheckboxChecked}
                    onChange={() => {
                      if (!isOverdueCheckboxChecked) {
                        formik.setFieldValue("status", "NEPODMIRENO");
                        formik.setFieldValue("dueDateFromIncluding", null);
                        formik.setFieldValue("dueDateToIncluding", new Date());
                      }
                      setIsOverdueCheckboxChecked(!isOverdueCheckboxChecked);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col-reverse gap-y-3 mt-3 sm:mt-0 sm:gap-y-0 sm:flex-row md:flex-none sm:justify-end sm:gap-2">
              <Button
                variant="outlined"
                type="reset"
                onClick={() => {
                  formik.resetForm();
                  setIsOverdueCheckboxChecked(false);
                  onClickResetFilter();
                }}
                className="w-full sm:w-fit"
              >
                Poništi
              </Button>
              <Button
                variant="filled"
                type="submit"
                className="w-full sm:w-fit"
              >
                Filtriraj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
