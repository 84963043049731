import { EVENT_PARTICIPANTS_URL } from "../Routes";

export async function getParticipants(eventId: number) {
  const response = await fetch(`${EVENT_PARTICIPANTS_URL}/${eventId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
