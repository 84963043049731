import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import {
  AutocompleteField,
  InputField,
  NumberInputField,
} from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  GROUP_MIN_AGE,
  MAX_GROUP_NAME_LENGTH,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
  MIN_GROUP_NAME_LENGTH,
} from "../../../common/constants";
import {
  GROUP_MIN_AGE_MESSAGE,
  MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE,
  maxCharacterCountMessage,
  MIN_AGE_LESS_THAN_MAX_AGE_MESSAGE,
  minCharacterCountMessage,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import { CoachResponse } from "../../coach/api/CoachResponse";
import { postFilterUsers } from "../../common/api/postFilterUsers";
import { UserResponse, UserType } from "../../common/api/UserResponse";
import { GroupResponse } from "../api/GroupResponse";
import { putUpdateGroupRequest } from "../api/putUpdateGroupRequest";
import { UpdateGroupRequest } from "../api/UpdateGroupRequest";

type UpdateGroupModalProps = {
  modal: UseModal;
  group: GroupResponse;
  setRender: (render: boolean) => void;
};

type Form = {
  name: string;
  minAge: number | "";
  maxAge: number | "";
  athletes: UserResponse[];
  coaches: UserResponse[];
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_GROUP_NAME_LENGTH, minCharacterCountMessage(MIN_GROUP_NAME_LENGTH))
    .max(
      MAX_GROUP_NAME_LENGTH,
      maxCharacterCountMessage(MAX_GROUP_NAME_LENGTH)
    ),
  minAge: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(GROUP_MIN_AGE, GROUP_MIN_AGE_MESSAGE)
    .test("minAgeTest", MIN_AGE_LESS_THAN_MAX_AGE_MESSAGE, function (
      input: any
    ) {
      if (isNaN(this.parent.maxAge)) {
        return true;
      } else {
        return input < this.parent.maxAge;
      }
    }),
  maxAge: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .test("maxAgeTest", MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE, function (
      input: any
    ) {
      if (isNaN(this.parent.minAge)) {
        return true;
      } else {
        return input > this.parent.minAge;
      }
    }),
});
const UpdateGroupModal = ({
  modal,
  group,
  setRender,
}: UpdateGroupModalProps) => {
  const { add: addNotification } = useNotifications();

  function coachSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as UserResponse[]);
    else
      return postFilterUsers({
        name: query,
        userType: [UserType.COACH],
      });
  }

  function athleteSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as UserResponse[]);
    else
      return postFilterUsers({
        name: query,
        userType: [UserType.ATHLETE],
      });
  }

  const onSubmitForm = (form: Form) => {
    putUpdateGroupRequest(
      {
        groupName: form.name,
        minAge: form.minAge,
        maxAge: form.maxAge,
        athleteIds: form.athletes.map((athlete: UserResponse) => {
          return athlete.id;
        }),
        coachIds: form.coaches.map((coach: UserResponse) => {
          return coach.id;
        }),
      } as UpdateGroupRequest,
      group.id
    ).then(() => {});
    setRender(true);
    addNotification({
      title: "Podaci su izmijenjeni.",
      content: "Vaša promjena je uspješno unesena i podaci su izmijenjeni",
      messageList: [],
      severity: "INFO",
      timestamp: new Date(),
    });
    modal.onClose();
  };

  const initialValues = {
    name: group.groupName,
    minAge: group.minAge,
    maxAge: group.maxAge,
    athletes: group.athletes.map((athlete: AthleteResponse) => {
      return {
        id: athlete.id,
        firstName: athlete.firstName,
        lastName: athlete.lastName,
        email: athlete.email,
        phoneNumber: athlete.phoneNumber,
        userType: "ATHLETE",
      } as UserResponse;
    }),
    coaches: group.coaches.map((coach: CoachResponse) => {
      return {
        id: coach.id,
        firstName: coach.firstName,
        lastName: coach.lastName,
        email: coach.email,
        phoneNumber: coach.phoneNumber,
        userType: "COACH",
      } as UserResponse;
    }),
  } as Form;

  return (
    <>
      <Modal {...modal}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="pr-3"
              autoComplete="off"
            >
              <Typography className="mb-6" variant="h4" element="h4">
                Uredi grupu
              </Typography>
              <div className="flex flex-col space-y-4 mb-6">
                <InputField
                  name="name"
                  label={"Ime grupe"}
                  required={true}
                  placeholder={group.groupName}
                />
                <div className="flex flex-row mt-3 justify-between">
                  <NumberInputField
                    name="minAge"
                    label={"Minimalna dob"}
                    className="mr-3 w-1/2"
                    required={true}
                    maxLength={2}
                    allowNegative={false}
                  />
                  <NumberInputField
                    name="maxAge"
                    label={"Maksimalna dob"}
                    className="ml-3 w-1/2"
                    required={true}
                    maxLength={2}
                    allowNegative={false}
                  />
                </div>
                <AutocompleteField
                  className="w-full mt-3"
                  name="coaches"
                  options={coachSearch}
                  placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
                  label="Treneri"
                  allowMultiple
                  getOptionLabel={(user) =>
                    `${user.firstName} ${user.lastName}`
                  }
                  getOptionValue={(item) => {
                    return item.id;
                  }}
                  getMultipleSelectedLabel={(items) => {
                    return items
                      .map(
                        (user: UserResponse) =>
                          `${user.firstName} ${user.lastName}`
                      )
                      .join(", ");
                  }}
                  required={true}
                  sendOptionValue={false}
                />
                <AutocompleteField
                  className="w-full mt-3"
                  name="athletes"
                  options={athleteSearch}
                  placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
                  label="Sportaši"
                  allowMultiple
                  getOptionLabel={(user) =>
                    `${user.firstName} ${user.lastName}`
                  }
                  getOptionValue={(item) => {
                    return item.id;
                  }}
                  getMultipleSelectedLabel={(items) => {
                    return items
                      .map(
                        (user: UserResponse) =>
                          `${user.firstName} ${user.lastName}`
                      )
                      .join(", ");
                  }}
                  required={true}
                  sendOptionValue={false}
                />
              </div>

              <div className="flex flex-row justify-end">
                <Button
                  type="button"
                  className="mr-2"
                  variant="outlined"
                  onClick={() => {
                    modal.onClose();
                  }}
                >
                  Odbaci promjene
                </Button>
                <Button variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateGroupModal;
