import React, { useEffect, useState } from "react";

import { UseModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";
import { TextareaField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { ErrorMessage, Formik } from "formik";

import { AthleteDetailedResponse } from "../../archive/athlete/api/AthleteDetailedResponse";
import { ParentDetailedResponse } from "../../archive/parent/api/ParentDetailedResponse";
import { MAX_COMMENT_LENGTH } from "../../common/constants";
import { PlannedAbsenceRequest } from "../api/PlannedAbsenceRequest";
import { postPlannedAbsence } from "../api/postPlannedAbsence";

type AnnounceAbsenceFormProps = {
  parent: ParentDetailedResponse;
  modal: UseModal<any>;
};

const initialValues = {
  startDate: new Date(),
  endDate: new Date(),
  message: "",
};

type Form = {
  startDate: Date;
  endDate: Date;
  message: string;
};

export default function AnnounceAbsenceForm({
  parent,
  modal,
}: AnnounceAbsenceFormProps) {
  const [endDate, setEndDate] = useState(new Date() as Date | null);
  const [startDate, setStartDate] = useState(new Date() as Date | null);
  const [athlete, setAthlete] = useState({} as AthleteDetailedResponse);
  const [characterCount, setCharacterCount] = useState(0);

  const { add: addNotification } = useNotifications();

  useEffect(() => {
    setAthlete(
      parent.children[0]
        ? parent.children[0]
        : ({ firstName: "-" } as AthleteDetailedResponse)
    );
  }, [setAthlete, parent]);

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, []);

  const onSubmitForm = (form: Form) => {
    postPlannedAbsence(
      {
        startTime: form.startDate,
        endTime: form.endDate,
        note: form.message,
      } as PlannedAbsenceRequest,
      athlete.id
    ).then(() => {
      addNotification({
        title: "Odsutnost najavljena",
        content: "Uspješno ste najavili odsutnost.",
        messageList: [],
        severity: "INFO",
        timestamp: new Date(),
      });
    });
    modal.onClose();
  };

  return (
    <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
      {(formik) => (
        <form onSubmit={formik.handleSubmit} className="pt-6">
          {parent.children.length > 1 ? (
            <>
              <div>
                <Typography className="title">
                  Tko ne dolazi na trening?
                </Typography>
                <div className="mt-1">
                  <DropdownMenu
                    openExpanderIcon={<Icon type="caret-down" variant="bold" />}
                    closeExpanderIcon={
                      <Icon type="caret-down" variant="bold" />
                    }
                    title={
                      athlete
                        ? `${athlete.firstName} ${athlete.lastName}`
                        : "Izaberi dijete"
                    }
                    color={"primary"}
                  >
                    {parent.children.map((child: AthleteDetailedResponse) => {
                      return (
                        <DropdownMenu.Item
                          onSelect={() => {
                            setAthlete(child);
                          }}
                        >
                          {`${child.firstName} ${child.lastName}`}
                        </DropdownMenu.Item>
                      );
                    })}
                  </DropdownMenu>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <ErrorMessage name="checked">
            {(msg) => <div className="text-red-500 text-sm">{msg}</div>}
          </ErrorMessage>
          <div className="flex flex-col">
            <div className="mt-3 flex flex-row">
              <DateInput
                className="w-1/2 mr-3"
                name="startTime"
                label="Datum početka"
                onChange={(date: Date | null) => {
                  date?.setHours(12);
                  setStartDate(date);
                  setEndDate(date);
                  formik.setFieldValue("endDate", date);
                  formik.setFieldValue("startDate", date);
                }}
                value={startDate}
                allowClear={false}
                minDate={new Date()}
                onBlur={() => {}}
              />
              <DateInput
                className="w-1/2 ml-3"
                name="endTime"
                label="Datum završetka"
                onBlur={() => {}}
                onChange={(date: Date | null) => {
                  date?.setHours(12);
                  setEndDate(date);
                  formik.setFieldValue("endDate", date);
                }}
                value={endDate}
                allowClear={false}
                minDate={startDate as Date | undefined}
              />
            </div>
            <TextareaField
              className="mt-3"
              textareaClassName="h-32"
              name="message"
              label="Upišite razlog odsutnosti djeteta"
              maxLength={MAX_COMMENT_LENGTH}
              onKeyUp={() =>
                setCharacterCount(formik.getFieldProps("message").value.length)
              }
            />
            <Typography
              variant="subtext"
              className="flex justify-end mt-1 text-gray-400"
            >{`${characterCount}/${MAX_COMMENT_LENGTH}`}</Typography>
          </div>

          <div className="flex justify-end pt-6 mt-6 border-t">
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                modal.onClose();
              }}
            >
              Odustani
            </Button>
            <Button
              className="ml-3"
              type="submit"
              variant="filled"
              color="primary"
            >
              Najavi
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
