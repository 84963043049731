import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import {
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { useNavigate, useParams } from "react-router-dom";

import ParentDetailsHeader from "./ParentDetailsHeader";
import { UserContext } from "../../../auth/SecurityProvider";
import DeleteModal from "../../../common/components/modals/DeleteModal";
import { RegistryTabIndex } from "../../../common/constants";
import { AthleteDetailedResponse } from "../../athlete/api/AthleteDetailedResponse";
import { CoachResponse } from "../../coach/api/CoachResponse";
import { UserType } from "../../common/api/UserResponse";
import UserIcon from "../../common/components/UserIcon";
import handleDeleteResponse from "../../common/handleDeleteResponse";
import { RegistryContext } from "../../common/RegistryProvider";
import GroupBadge from "../../group/components/GroupBadge";
import { deleteParent } from "../api/deleteParent";
import { getParentById } from "../api/getParentById";
import { ParentDetailedResponse } from "../api/ParentDetailedResponse";
import UpdateParentModal from "../components/UpdateParentModal";

export default function ParentDetails() {
  const { userHasRole } = useContext(UserContext);
  const { setRegistryTabIndex } = useContext(RegistryContext);
  const [parent, setParent] = useState({} as ParentDetailedResponse);
  const [loaded, setLoaded] = useState(false);
  const [athletes, setAthletes] = useState([] as AthleteDetailedResponse[]);
  const [render, setRender] = useState(false);

  const { add: addNotification } = useNotifications();

  const navigate = useNavigate();

  const { id } = useParams();

  const deleteParentModal = useModal();

  const deleteParentModalFunction = () => {
    deleteParent(parent.id).then((res) => {
      if (res.ok) {
        handleDeleteResponse(res, addNotification);
        deleteParentModal.onClose();
        navigate("/archive");
      }
    });
  };

  useEffect(() => {
    if (id) {
      getParentById((id as unknown) as number).then(
        (response: ParentDetailedResponse) => {
          setParent(response);
          setLoaded(true);
          setRender(false);
          setAthletes(response.children);
        }
      );
    }
  }, [id, render]);

  const updateModal = useModal();

  setRegistryTabIndex(RegistryTabIndex.PARENTS);

  if (!loaded) {
    return <></>;
  } else {
    return (
      <div className="flex flex-col px-4 md:px-0 md:my-6 gap-y-6">
        <div className="flex flex-col">
          <div className="justify-between items-start hidden md:flex">
            <div className="flex md:flex-col">
              <Typography
                element="h3"
                variant="h3"
                className="hidden md:inline font-biotiff"
              >
                Detalji roditelja
              </Typography>
              <div className="hidden md:block">
                {(userHasRole(UserType.ADMIN) ||
                  userHasRole(UserType.COACH)) && (
                  <Breadcrumbs>
                    <Breadcrumbs.Breadcrumb>
                      <Link to="/archive">Registar roditelja</Link>
                    </Breadcrumbs.Breadcrumb>
                    <Breadcrumbs.Breadcrumb>
                      Detalji roditelja
                    </Breadcrumbs.Breadcrumb>
                  </Breadcrumbs>
                )}
              </div>
            </div>
            <div className="flex justify-end md:justify-between">
              {userHasRole(UserType.ADMIN) && (
                <>
                  <div className="flex justify-between items-center">
                    <Button
                      className="w-fit mr-1"
                      variant="outlined"
                      size="sm"
                      color="primary"
                      leadingIcon={<Icon type="trash" variant="bold" />}
                      onClick={deleteParentModal.onOpen}
                    >
                      <div className="pt-0 hidden md:flex">
                        Izbriši roditelja
                      </div>
                      <div className="flex md:hidden">Izbriši</div>
                    </Button>
                    <Button
                      className="w-fit"
                      leadingIcon={<Icon type="note-pencil" variant="bold" />}
                      variant="outlined"
                      color="primary"
                      size="sm"
                      onClick={updateModal.onOpen}
                    >
                      Uredi podatke
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col md:hidden">
            <div className="flex flex-col gap-y-2">
              <div className="flex md:flex-col justify-between items-center">
                <IconButton
                  icon={<Icon type="caret-left" variant="bold" />}
                  className="md:hidden"
                  onClick={() => navigate("/archive")}
                />
                <div className="justify-end md:justify-between md:flex">
                  <DropdownMenu
                    title="Opcije"
                    variant="outlined"
                    color="primary"
                    openExpanderIcon={<Icon type="caret-down" variant="bold" />}
                    closeExpanderIcon={
                      <Icon type="caret-down" variant="bold" />
                    }
                  >
                    {userHasRole(UserType.ADMIN) && (
                      <>
                        <DropdownMenu.Item
                          onSelect={() => {
                            updateModal.onOpen(null);
                          }}
                        >
                          Uredi podatke
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onSelect={() => {
                            deleteParentModal.onOpen(null);
                          }}
                        >
                          Izbriši roditelja
                        </DropdownMenu.Item>
                      </>
                    )}
                  </DropdownMenu>
                </div>
              </div>
              <Typography
                element="h6"
                variant="h6"
                className="md:hidden font-biotiff"
              >
                Detalji roditelja
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <ParentDetailsHeader parent={parent} />
          <div className="flex w-full mb-8 space-x-0 flex-col items-center md:items-start md:flex-row md:space-x-8 justify-start">
            {athletes.map((athlete) => {
              const coachArray = [] as CoachResponse[];
              for (let group of athlete.groups) {
                for (let coach of group.coaches) {
                  coachArray.push(coach);
                }
              }
              const uniqueIds = [] as number[];

              const uniqueCoaches = coachArray.filter((element) => {
                const isDuplicate = uniqueIds.includes(element.id);
                if (!isDuplicate) {
                  uniqueIds.push(element.id);
                  return true;
                }
                return false;
              });
              return (
                <div className="w-full mb-8 last:mr-0 mr-0 mb-4 md:w-1/3 md:mr-0">
                  <Link
                    className="hover:no-underline"
                    to={`/archive/athletes/${athlete.id}`}
                  >
                    <Card>
                      <Card.Header>
                        <div className="flex flex-col items-center justify-center">
                          <div className="mb-2 sm:mb-4">
                            <UserIcon
                              initials={
                                athlete.firstName.charAt(0).toUpperCase() +
                                athlete.lastName.charAt(0).toUpperCase()
                              }
                              entityId={athlete.id}
                              width="80px"
                              height="80px"
                              textClassName="text-2xl"
                            />
                          </div>
                          <div className="mb-3 flex flex-col justify-center">
                            <div className="flex justify-center mb-2 text-navy-500 ">
                              <Typography
                                className="text-base font-medium mb:text-lg"
                                element="h3"
                                variant="h4"
                              >
                                {`${athlete.firstName} ${athlete.lastName}`}
                              </Typography>
                            </div>
                            <div className="flex flex-wrap gap-2 justify-center">
                              {athlete.groups.map((group) => {
                                return (
                                  <GroupBadge
                                    groupName={group.groupName}
                                    groupId={group.id}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div className="flex justify-center text-navy-500 mt-2">
                          <Typography
                            className="text-base font-bold mb:text-lg"
                            element="h3"
                            variant="h5"
                          >
                            Trener/i
                          </Typography>
                        </div>
                        <div className="grid grid-cols-1 justify-center mb-8">
                          {uniqueCoaches.map((coach) => {
                            return (
                              <div className="mt-4">
                                <div className="flex justify-center text-navy-400">
                                  <Typography className="text-base">
                                    {`${coach.firstName} ${coach.lastName}`}
                                  </Typography>
                                </div>
                                <div className="flex justify-center text-navy-400">
                                  <Icon
                                    type="phone"
                                    className="mr-1"
                                    variant="fill"
                                  />
                                  <Typography className="text-base">
                                    {`${coach.phoneNumber}`}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <DeleteModal
          modal={deleteParentModal}
          title="Izbriši roditelja"
          content="Jeste li sigurni da želite izbrisati roditelja?"
          buttonConfirmText="Izbriši roditelja"
          buttonConfirmFunction={deleteParentModalFunction}
        />
        {userHasRole(UserType.ADMIN) && (
          <UpdateParentModal
            modal={updateModal}
            setRender={setRender}
            parent={parent}
          />
        )}
      </div>
    );
  }
}
