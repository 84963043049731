import { ClubUserCountInfoResponse } from "./ClubUserCountInfoResponse";
import { USER_URL } from "../../common/Routes";

export async function getClubUserCountInfo(): Promise<
  ClubUserCountInfoResponse
> {
  const response = await fetch(`${USER_URL}/info`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
