import React from "react";

import { Typography } from "@tiller-ds/core";

type ResultProgressBarProps = {
  totalRespondents: number;
  totalAnswers: number;
};

export default function ResultProgressBar({
  totalRespondents,
  totalAnswers,
}: ResultProgressBarProps) {
  const progressPercentage: number = (100 / totalRespondents) * totalAnswers;
  return (
    <div className="flex items-center justify-between">
      <div className="w-24 h-4 rounded-md bg-aqua-100 border border-aqua-400 sm:w-48 sm:h-8 sm:rounded-lg">
        <div
          className="h-full rounded-tl-md rounded-bl-lg bg-aqua-400 border-r-1 border-aqua-700 sm:rounded-tl-lg first:animate-progress"
          style={{
            width: `${progressPercentage}%`,
          }}
        ></div>
      </div>
      <Typography variant="h6" className="font-biotif pl-6 text-xs sm:text-sm">
        {totalAnswers}
      </Typography>
    </div>
  );
}
