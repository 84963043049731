import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  IconButton,
  Pagination,
  Typography,
} from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { Link } from "react-router-dom";

import ParentRegistryFilterCard from "./ParentRegistryFilterCard";
import {
  MAX_ARCHIVE_ROWS,
  REGISTRY_SEARCH_ERROR_MESSAGE,
} from "../../../common/constants";
import { AthleteBriefResponse } from "../../athlete/api/AthleteBriefResponse";
import RegistrySearch from "../../common/components/RegistrySearch";
import { RegistryContext } from "../../common/RegistryProvider";
import { exportParentsExcel } from "../api/exportParentsExcel";
import { exportParentsPDF } from "../api/exportParentsPDF";
import { ParentResponse } from "../api/ParentResponse";
import { ParentSearchRequest } from "../api/ParentSearchRequest";
import { postSearchParentRequest } from "../api/postSearchParentRequest";

type ParentRegistryProps = {
  totalParents: number;
  setTotalParents: (totalParents: number) => void;
  entityCreated: number;
};

export default function ParentRegistry({
  totalParents,
  setTotalParents,
  entityCreated,
}: ParentRegistryProps) {
  const [parentTableData, setParentTableData] = useState(
    [] as ParentResponse[]
  );
  const [mobileView, setMobileView] = useState(false);
  const [error, setError] = useState("");
  const { parentRegistryPage, setParentRegistryPage } = useContext(
    RegistryContext
  );
  const [filterClicked, setFilterClicked] = useState(false);
  const [searchRequestJson, setSearchRequestJson] = useState({
    pageNumber: parentRegistryPage,
    pageSize: 10,
    sortPropertyList: [
      {
        property: "lastName",
        direction: "ASC",
      },
      {
        property: "firstName",
        direction: "ASC",
      },
    ],
  } as ParentSearchRequest);

  useEffect(() => {
    postSearchParentRequest(searchRequestJson).then((response: any) => {
      setTotalParents(response.totalElements);
      setParentTableData(response.content);

      response.content.length > 0
        ? setError("")
        : setError(REGISTRY_SEARCH_ERROR_MESSAGE);
    });
  }, [searchRequestJson, setTotalParents, entityCreated]);

  function handleResize() {
    if (window.innerWidth < 640) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 640) {
      setMobileView(true);
    }
  }, []);

  return (
    <>
      <Card.Header className="py-2" removeSpacing={true}>
        <div className="flex justify-between items-center sm:items-end flex-col md:flex-row">
          <Typography element="h4" variant="h4" className="hidden md:block">
            Roditelji
          </Typography>
          <div className="flex w-full sm:w-auto justify-start md:justify-end">
            <div className="flex w-full gap-2 items-center">
              <RegistrySearch
                searchRequestJson={searchRequestJson}
                setSearchRequestJson={setSearchRequestJson}
              ></RegistrySearch>
              {mobileView ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    filterClicked
                      ? setFilterClicked(false)
                      : setFilterClicked(true);
                  }}
                >
                  <Icon type="funnel" />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  trailingIcon={
                    filterClicked ? (
                      <Icon type="caret-up" variant="bold" size={3} />
                    ) : (
                      <Icon type="caret-down" variant="bold" size={3} />
                    )
                  }
                  onClick={() => {
                    filterClicked
                      ? setFilterClicked(false)
                      : setFilterClicked(true);
                  }}
                >
                  Filtriraj
                </Button>
              )}
            </div>
          </div>
          <DropdownMenu
            title="Preuzmi"
            color="primary"
            iconPlacement="leading"
            openExpanderIcon={<Icon type="download-simple" variant="bold" />}
            closeExpanderIcon={<Icon type="download-simple" variant="bold" />}
          >
            <DropdownMenu.Item
              onSelect={() => exportParentsExcel(searchRequestJson)}
            >
              .xlsx
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => exportParentsPDF(searchRequestJson)}
            >
              .pdf
            </DropdownMenu.Item>
          </DropdownMenu>
        </div>
      </Card.Header>
      <Card.Body removeSpacing={true}>
        {filterClicked ? (
          <ParentRegistryFilterCard
            setSearchRequestJson={setSearchRequestJson}
            searchRequestJson={searchRequestJson}
          />
        ) : (
          <></>
        )}
        <DataTable data={parentTableData}>
          <DataTable.Column
            className="w-2/12"
            header="Ime"
            id="firstName"
            canSort={false}
          >
            {(item: ParentResponse) => item.firstName}
          </DataTable.Column>
          <DataTable.Column
            className="w-2/12"
            header="Prezime"
            id="lastName"
            canSort={false}
          >
            {(item: ParentResponse) => item.lastName}
          </DataTable.Column>
          <DataTable.Column
            className="w-3/12"
            header="Kontakt"
            id="contact"
            canSort={false}
          >
            {(item: ParentResponse) => {
              return (
                <>
                  <span>{item.email}</span> <br></br>
                  <span>{item.phoneNumber}</span>
                </>
              );
            }}
          </DataTable.Column>
          <DataTable.Column
            className="w-3/12"
            header="Dijete"
            id="child"
            canSort={false}
          >
            {(item: ParentResponse) => {
              return (
                <>
                  {item.children[0]
                    ? item.children
                        .sort((a, b) => a.firstName.localeCompare(b.firstName))
                        .map((athlete: AthleteBriefResponse) => {
                          return (
                            <>
                              <span className="mt-1">
                                {`${athlete.firstName} ${athlete.lastName}`}
                              </span>
                              <br></br>
                            </>
                          );
                        })
                    : "-"}
                </>
              );
            }}
          </DataTable.Column>
          <DataTable.Column
            className="w-1/12"
            header="Akcije"
            id="actions"
            canSort={false}
          >
            {(item: ParentResponse) => (
              <Link to={`/archive/parents/${item.id}`}>
                <IconButton
                  icon={<Icon type="file-search" className="text-gray-500" />}
                  onClick={() => {}}
                  label="Detalji"
                />
              </Link>
            )}
          </DataTable.Column>
        </DataTable>
        <div className="flex justify-center my-3">
          <Typography className="text-red-600" variant="subtext">
            {error}
          </Typography>
        </div>
      </Card.Body>
      {totalParents > MAX_ARCHIVE_ROWS && (
        <Card.Footer className="flex items-center justify-end">
          <Pagination
            onPageChange={(i: number) => {
              setParentRegistryPage(i);
              setSearchRequestJson((searchRequestJson) => ({
                ...searchRequestJson,
                pageNumber: i,
              }));
            }}
            pageNumber={parentRegistryPage}
            pageSize={MAX_ARCHIVE_ROWS}
            totalElements={totalParents}
          >
            {() => null}
          </Pagination>
        </Card.Footer>
      )}
    </>
  );
}
