import FileDownload from "js-file-download";
import moment from "moment";

import { FilterEventsRequest } from "./FilterEventsRequest";
import { EVENT_URL } from "../../common/Routes";

export async function exportEventToIcs(request: FilterEventsRequest) {
  fetch(`${EVENT_URL}/export/ics`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let suffix =
        moment(request.startTimeTo).format("yyyy-MM-DD") +
        "_" +
        moment(request.endTimeFromIncluding).format("yyyy-MM-DD");
      FileDownload(blob, `kalendar_${suffix}.ics`);
    });
}
