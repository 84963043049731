import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Card, IconButton, Typography } from "@tiller-ds/core";
import { TextareaField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import classNames from "classnames";
import { Formik } from "formik";
import * as yup from "yup";

import DeleteCommentModal from "./modals/DeleteCommentModal";
import { UserContext } from "../../auth/SecurityProvider";
import { MAX_COMMENT_LENGTH } from "../../common/constants";
import { REQUIRED_FIELD_MESSAGE } from "../../common/YupConstants";
import { createEventComment } from "../api/eventCommentApi/createEventComment";
import { EventCommentResponse } from "../api/eventCommentApi/EventCommentResponse";
import { getCommentById } from "../api/eventCommentApi/getCommentById";
import { getCommentsForEvent } from "../api/eventCommentApi/getCommentsForEvent";
import { updateEventComment } from "../api/eventCommentApi/updateEventComment";

const MAX_COMMENTS_WITHOUT_OVERFLOW = 2;

type CommentsProps = {
  eventId?: string;
};

type CommentForm = {
  content: string;
};

const saveNoteInitialValues = {
  content: "",
};

export default function Comments({ eventId }: CommentsProps) {
  const [comments, setComments] = useState([] as EventCommentResponse[]);
  const [currentlyEditingNoteId, setCurrentlyEditingNoteId] = useState(0);
  const [updateNoteFormInitValues, setUpdateNoteFormInitValues] = useState(
    {} as CommentForm
  );
  const [commentCharacterCount, setCommentCharacterCount] = useState(0);
  const [editCommentCharacterCount, setEditCommentCharacterCount] = useState(0);
  const modal = useModal();
  const { user } = useContext(UserContext);

  const validationSchema = yup.object().shape({
    content: yup
      .string()
      .required(REQUIRED_FIELD_MESSAGE)
      .nullable()
      .max(255, "Maksimalni broj znakova je 255"),
  });

  useEffect(() => {
    if (eventId) {
      getCommentsForEvent(eventId).then((response) => {
        if (response) {
          setComments(response);
        }
      });
    }
  }, [eventId]);

  function switchToUpdate(commentId: number) {
    if (eventId) {
      getCommentById(commentId, eventId).then((response) => {
        setUpdateNoteFormInitValues({
          content: response.content,
        });
        setCurrentlyEditingNoteId(commentId);
      });
    }
  }

  const onSaveComment = (form: CommentForm) => {
    if (eventId) {
      createEventComment(
        {
          content: form.content,
        },
        eventId
      ).then((response: EventCommentResponse) => {
        setComments([response, ...comments]);
      });
      form.content = "";
      setCommentCharacterCount(0);
    }
  };

  const onUpdateNote = (form: CommentForm) => {
    if (eventId) {
      updateEventComment(
        {
          content: form.content,
        },
        currentlyEditingNoteId,
        eventId
      ).then((response: EventCommentResponse) => {
        setComments([
          response,
          ...comments.filter(
            (note: EventCommentResponse) => note.id !== currentlyEditingNoteId
          ),
        ]);
        setCurrentlyEditingNoteId(0);
        setEditCommentCharacterCount(0);
      });
    }
  };

  const commentsClassnames = classNames({
    "overflow-auto overscroll-auto rounded-md": true,
    "h-auto": comments.length <= MAX_COMMENTS_WITHOUT_OVERFLOW,
    "h-52": comments.length > MAX_COMMENTS_WITHOUT_OVERFLOW,
  });

  const openDeleteCommentModal = (commentId: number) => {
    modal.onOpen(commentId);
  };
  return (
    <Card className="w-full h-full p-0 sm:p-4">
      <Card.Header className="flex justify-between items-center text-lg font-bold p-1">
        Komentari
      </Card.Header>
      <Card.Body>
        <ul className={commentsClassnames}>
          {comments.map((comment: EventCommentResponse) => {
            return (
              <li className="bg-indigo-50 rounded-md mb-2 p-4 border border-navy-300 shadow-2 text-black">
                {currentlyEditingNoteId === comment.id ? (
                  <Formik
                    initialValues={updateNoteFormInitValues}
                    onSubmit={onUpdateNote}
                  >
                    {(formik) => (
                      <form className="flex flex-col">
                        <div className="mb-2 flex items-start justify-between gap-x-1">
                          <div className="flex flex-col w-full">
                            <TextareaField
                              className="w-full resize-none"
                              name="content"
                              maxLength={MAX_COMMENT_LENGTH}
                              onKeyUp={() =>
                                setEditCommentCharacterCount(
                                  formik.getFieldProps("content").value.length
                                )
                              }
                            ></TextareaField>
                            <Typography
                              variant="subtext"
                              className="flex justify-end mt-1 text-gray-400"
                            >{`${editCommentCharacterCount}/${MAX_COMMENT_LENGTH}`}</Typography>
                          </div>
                          <div className="flex">
                            <IconButton
                              label="Spremi"
                              icon={<Icon type="check" />}
                              onClick={formik.handleSubmit}
                            />
                            <IconButton
                              label="Odbaci"
                              icon={<Icon type="x" />}
                              onClick={() => {
                                setCurrentlyEditingNoteId(0);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <div>
                    <div className="flex justify-between items-start">
                      <Typography className="break-word text-base my-auto">
                        {comment.content}
                      </Typography>
                      <div className="flex p-1">
                        {comment.createdBy.id === user.id && (
                          <div className="flex p-1 my-auto">
                            <IconButton
                              label="Uredi"
                              icon={
                                <Icon
                                  type="pencil-simple"
                                  variant="fill"
                                  className="text-gray-500"
                                />
                              }
                              onClick={() => {
                                switchToUpdate(comment.id);
                                setEditCommentCharacterCount(
                                  comment.content.length
                                );
                              }}
                            />
                            <IconButton
                              label="Izbriši"
                              icon={
                                <Icon
                                  type="trash"
                                  variant="fill"
                                  className="text-gray-500"
                                />
                              }
                              onClick={() => {
                                openDeleteCommentModal(comment.id);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-start space-x-1 mt-1">
                      <Typography
                        variant="subtitle"
                        className="mt-1 text-gray-500"
                      >
                        {`${comment.createdBy.firstName} ${comment.createdBy.lastName},`}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        className="mt-1 text-gray-500"
                      >
                        {new Date(comment.dateCreated).toLocaleDateString(
                          "hr-HR"
                        )}
                      </Typography>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        <Formik
          initialValues={saveNoteInitialValues}
          onSubmit={onSaveComment}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex flex-col">
              <TextareaField
                placeholder="Upišite svoj komentar..."
                name="content"
                className="mt-3 resize-none"
                maxLength={MAX_COMMENT_LENGTH}
                onKeyUp={() =>
                  setCommentCharacterCount(
                    formik.getFieldProps("content").value.length
                  )
                }
              />
              <Typography
                variant="subtext"
                className="flex justify-end mt-1 text-gray-400"
              >{`${commentCharacterCount}/${MAX_COMMENT_LENGTH}`}</Typography>
              <div className="flex justify-end space-x-4">
                <Button variant="filled" className="mt-2 " type="submit">
                  Dodaj
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card.Body>
      <DeleteCommentModal
        comments={comments}
        setComments={setComments}
        eventId={eventId}
        modal={modal}
      />
    </Card>
  );
}
