import { Modal, UseModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";

type ExtendSessionModalProps = {
  modal: UseModal<unknown>;

  onExtendSession: () => void;
};

export default function ExtendSessionModal({
  modal,
  onExtendSession,
}: ExtendSessionModalProps) {
  return (
    <Modal {...modal}>
      <div className="flex flex-col gap-y-10">
        <div>
          <Typography variant="title" element="h3">
            Vaša sesija uskoro ističe
          </Typography>
          <Typography variant="subtitle" element="h4">
            Sustav će vas odjaviti za 5 minuta
          </Typography>
        </div>
        <div className="flex flex-row gap-x-3 justify-end">
          <Button onClick={modal.onClose} variant="outlined">
            Odustani
          </Button>
          <Button onClick={onExtendSession}>Obnovi sesiju</Button>
        </div>
      </div>
    </Modal>
  );
}
