import React from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { IconButton, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { AthleteResponse } from "../../../archive/athlete/api/AthleteResponse";
import { CoachResponse } from "../../../archive/coach/api/CoachResponse";

type TrainerListModalProps = {
  coachList: CoachResponse[];
  athlete?: AthleteResponse;
};

const CoachListModal = ({ coachList, athlete }: TrainerListModalProps) => {
  const modal = useModal();

  return (
    <>
      <IconButton
        className="cursor-pointer text-bold"
        icon={<Icon type="dots-three-vertical" />}
        label="Pogledaj sve trenere"
        onClick={modal.onOpen}
      />
      <Modal {...modal}>
        <Modal.Content
          title={
            <div className="flex flex-col mb-4">
              <Typography variant="h5" className="font-biotif">
                Svi treneri
              </Typography>
              {athlete && (
                <Typography
                  variant="subtext"
                  className="font-inter text-gray-400"
                >
                  Sportaš: {athlete.firstName + " " + athlete.lastName}
                </Typography>
              )}
            </div>
          }
        >
          {coachList.map((coach) => {
            return (
              <div className="flex justify-between items-end mt-2 pb-2 border-b mx-4">
                <div className="flex flex-col items-start text-navy-500 mt-1">
                  <Typography className="text-md mb-1">Trener</Typography>
                  <Typography className="text-md font-bold">
                    {coach.firstName + " " + coach.lastName}
                  </Typography>
                </div>
                <div className="mt-2">
                  <Typography className="text-md font-bold" variant={"subtext"}>
                    {coach.phoneNumber}
                  </Typography>
                </div>
              </div>
            );
          })}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CoachListModal;
