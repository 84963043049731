import { StaticDataReportResponse } from "./StaticDataReportResponse";
import { REPORTS_STATE_STATIC_DATA_URL } from "../../common/Routes";

export async function getStaticDataReport(): Promise<StaticDataReportResponse> {
  const response = await fetch(`${REPORTS_STATE_STATIC_DATA_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response.json();
}
