import React from "react";

import { Badge, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { SurveyStatus } from "../../../../common/constants";
import switchColor from "../../../util/SwitchColor";

type SurveyCardProps = {
  surveyName: string;
  status: SurveyStatus;
};

export default function SurveyDetails({ surveyName, status }: SurveyCardProps) {
  return (
    <div className="flex items-center w-full h-20 border-b cursor-pointer px-4">
      <div className="w-1/2">
        <Typography variant="h6" className="text-xs font-biotif">
          {surveyName}
        </Typography>
      </div>
      <div className="flex w-1/2">
        <div className="flex justify-center w-full">
          <Badge color={switchColor(status)} onClick={function noRefCheck() {}}>
            {status}
          </Badge>
        </div>
        <div className="flex justify-center w-full">24. 07. 2022.</div>
        {status === SurveyStatus.Finished ||
        status === SurveyStatus.Inactive ? (
          <div className="flex justify-center w-1/3">
            <Icon type="trash" variant="bold" />
          </div>
        ) : (
          <div className="flex justify-center w-1/3"></div>
        )}
      </div>
    </div>
  );
}
