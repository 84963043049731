import { MessageFilterRequest } from "./MessageFilterRequest";
import { MessageResponse } from "./MessageResponse";
import { MESSAGE_URL } from "../../common/Routes";

export async function postReceivedMessagesFilterRequest(
  request: MessageFilterRequest
): Promise<MessageResponse[]> {
  const response = await fetch(MESSAGE_URL + "/received", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
