import React from "react";

import { Card } from "@tiller-ds/core";

import Ball from "../../assets/img/ball.png";
import Logo from "../../assets/img/logo.png";
import AdminHeaderInfo from "../../common/components/cards/AdminHeaderInfo";

type AdminHeaderProps = {
  entityCreated: number;
};

export default function AdminHeader({ entityCreated }: AdminHeaderProps) {
  return (
    <div>
      <Card className="w-full mb-8 mt-4">
        <Card.Body removeSpacing={true}>
          <div className="flex flex-col justify-around h-56 w-full md:flex-row justify-between md:h-44">
            <div
              className="w-full h-max-fit flex justify-center items-center md:ml-6"
              style={{
                backgroundImage: `url(${Ball})`,
                backgroundColor: "#fff",
                backgroundRepeat: "no-repeat",
                height: "auto",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-4 mb:p-0">
                <img src={Logo} alt="Logo kluba" />
              </div>
            </div>
            <AdminHeaderInfo entityCreated={entityCreated} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
