import React from "react";

import { AttendanceReportData } from "./AttendanceReportData";
import { EventStateData } from "./EventStateData";
import { GroupAthletesStateData } from "./GroupAthletesStateData";
import { GroupStateData } from "./GroupStateData";
import { StaticStateData } from "./StaticStateData";

export default function StateReport() {
  return (
    <div className="flex flex-col gap-y-12 mt-3">
      <StaticStateData />

      <GroupStateData />

      <GroupAthletesStateData />

      <EventStateData />

      <AttendanceReportData />
    </div>
  );
}
