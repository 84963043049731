import { GetNumOfUnpaidInvoicesResponse } from "./GetNumOfUnpaidInvoicesResponse";
import { INVOICE_URL } from "../../../common/Routes";

export async function getNumOfUnpaidInvoices(
  parentId: number
): Promise<GetNumOfUnpaidInvoicesResponse> {
  const response = await fetch(`${INVOICE_URL}/num-of-unpaid/${parentId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
}
