import { PLANNED_ABSENCE_URL } from "../../common/Routes";

export type DeletePlannedAbsenceBulkRequest = {
  plannedAbsenceIds: number[];
};

export function deletePlannedAbsences(
  deletePlannedAbsenceBulkRequest: DeletePlannedAbsenceBulkRequest
) {
  return fetch(`${PLANNED_ABSENCE_URL}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(deletePlannedAbsenceBulkRequest),
  }).then((res) => {
    return res;
  });
}
