import { CreateCoachRequest } from "./CreateCoachRequest";
import { COACH_URL } from "../../../common/Routes";

export async function postCreateCoachRequest(
  request: CreateCoachRequest
): Promise<Response> {
  return await fetch(`${COACH_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
