import { EventCommentRequest } from "./EventCommentRequest";
import { EVENT_URL } from "../../../common/Routes";

export async function createEventComment(
  request: EventCommentRequest,
  eventId: string
) {
  const response = await fetch(`${EVENT_URL}/${eventId}/comments`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
