import React from "react";

import { Button, Typography } from "@tiller-ds/core";

import { ReactComponent as HAVKLogoVertical } from "../../assets/img/HAVK_vertical.svg";
import { logout } from "../api/logout";

type AuthorizationErrorHandlerPageProps = {
  status: number;
};

export default function AuthorizationErrorHandlerPage({
  status,
}: AuthorizationErrorHandlerPageProps) {
  let title;
  let description;
  switch (status) {
    case 401:
    case 404:
      title = "Dobrodošli u aplikaciju sportskog kluba HAVK Mladost!";
      description =
        "Molimo Vas za strpljenje dok Vam administrator ne dodijeli prava za korištenje aplikacije.";
      break;
    default:
      title = `Greška: ${status}`;
      description = "Nešto je pošlo po krivu! Pokušajte ponovno kasnije.";
      break;
  }

  return (
    <div className="h-screen flex flex-col justify-center align-middle">
      <div className="flex flex-col">
        <div className="m-auto pb-6">
          <HAVKLogoVertical />
        </div>
        <div className="text-center flex flex-col justify-center space-y-2">
          <div className="flex flex-col space-y-3 pb-5 w-4/5 md:w-2/5 lg:w-1/3 ml-auto mr-auto">
            <Typography className="font-extrabold">{title}</Typography>
            <Typography className="text-gray-500">{description}</Typography>
          </div>

          <div>
            <Button onClick={logout} className="w-60">
              Odjava
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
