import React from "react";

import { Typography } from "@tiller-ds/core";

import UserIcon from "../../../archive/common/components/UserIcon";

type CardWithPictureProps = {
  title: string;
  name?: string;
  picture?: string;
};

export default function CardWithPicture({
  title,
  name,
  picture,
}: CardWithPictureProps) {
  const firstName = name?.split(" ")[0] ?? "N";
  const lastName = name?.split(" ")[1] ?? "N";

  return (
    <div className="flex gap-x-2 bg-white shadow rounded-lg p-2 ">
      <div className="flex flex-col justify-center">
        {picture ? (
          <img src={picture} alt="profile" className="w-8 rounded-full" />
        ) : (
          <UserIcon
            initials={
              firstName.charAt(0).toUpperCase() +
              lastName.charAt(0).toUpperCase()
            }
            entityId={(lastName + firstName).charCodeAt(0)}
            width="2.1rem"
            height="2.1rem"
            textClassName="text-md pb-1"
          />
        )}
      </div>
      <div className="flex flex-col">
        <Typography element="h3" variant="h6">
          {title}
        </Typography>
        <Typography element="p" variant="subtext" className="break-word">
          {name ? name : <i>Nema informacija</i>}
        </Typography>
      </div>
    </div>
  );
}
