import React, { useEffect } from "react";

import { TextareaField } from "@tiller-ds/formik-elements";

import { SurveyAnswerType } from "../../../../../common/constants";

type PersonalAnswerOptionProps = {
  addNewAnswer: (type: SurveyAnswerType) => any;
};

export default function PersonalAnswerOption({
  addNewAnswer,
}: PersonalAnswerOptionProps) {
  useEffect(() => {
    addNewAnswer(SurveyAnswerType.PersonalAnswer);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full">
      <TextareaField
        name={"Personal answer display"}
        placeholder={"Sudionik ankete piše vlastiti odgovor ili komentar."}
        readOnly={true}
        disabled={true}
        textareaClassName="h-24"
      />
    </div>
  );
}
