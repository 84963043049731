import React from "react";

import { Typography } from "@tiller-ds/core";

import ResultProgressBar from "./ResultProgressBar";

type AnswerProps = {
  answer: string;
  totalRespondents: number;
  totalAnswers: number;
};

export default function MultipleAnswerResult({
  answer,
  totalRespondents,
  totalAnswers,
}: AnswerProps) {
  return (
    <div className="flex items-center justify-between w-full px-6 h-14 border border-navy-200 rounded cursor-pointer">
      <Typography element="h6" variant="text" className="font-inter">
        {answer}
      </Typography>
      <ResultProgressBar
        totalRespondents={totalRespondents}
        totalAnswers={totalAnswers}
      />
    </div>
  );
}
