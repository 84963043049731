import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { DateInputField } from "@tiller-ds/formik-elements";
import { Intl } from "@tiller-ds/intl";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  EXPORT_DATE_INVALID_RANGE_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import { exportEventToIcs } from "../../api/exportEventToIcs";
import formatDate from "../../util/formatDate";

const date = new Date();
const thisDateNextYear = new Date();
thisDateNextYear.setFullYear(thisDateNextYear.getFullYear() + 1);

type ExportEventsModalProps = {
  modal: UseModal;
};

type Form = {
  startDate: Date;
  endDate: Date;
};

const initialValues = {
  startDate: date,
  endDate: thisDateNextYear,
} as Form;

const validationSchema = yup.object().shape({
  startDate: yup
    .date()
    .required(REQUIRED_FIELD_MESSAGE)
    .default(initialValues.startDate),
  endDate: yup
    .date()
    .required(REQUIRED_FIELD_MESSAGE)
    .default(initialValues.endDate)
    .test("endDate", EXPORT_DATE_INVALID_RANGE_MESSAGE, (value, context) => {
      return value.getTime() >= context.parent.startDate.getTime();
    }),
});

const ExportEventsModal = ({ modal }: ExportEventsModalProps) => {
  const { add: addNotification } = useNotifications();

  const onSubmit = (data: Form) => {
    exportEventToIcs({
      startTimeTo: formatDate(new Date(data.endDate)),
      endTimeFromIncluding: formatDate(new Date(data.startDate)),
      eventType: null,
    }).then((ignored) => {
      addNotification({
        title: "Preuzimanje kalendara",
        content: "Preuzimanje je u tijeku...",
        messageList: [],
        severity: "INFO",
        timestamp: new Date(),
      });
      modal.onClose();
    });
  };

  return (
    <Modal {...modal}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Modal.Content
            title={
              <Typography element="h4" variant="h4">
                Preuzmi kalendar
              </Typography>
            }
          >
            <Typography element="p" variant="subtext" className="pt-2">
              Odaberite željeni vremenski raspon
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row gap-3 mt-3">
                <DateInputField
                  name="startDate"
                  label={<Intl name="Od" />}
                  allowClear={false}
                />
                <DateInputField
                  name="endDate"
                  label={<Intl name="Do" />}
                  allowClear={false}
                />
              </div>
              <Modal.Footer>
                <Button variant="filled" type="submit">
                  Preuzmi
                </Button>
                <Button variant="outlined" onClick={modal.onClose}>
                  Odustani
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

export default ExportEventsModal;
