import React from "react";

import { Typography } from "@tiller-ds/core";

export default function Eula() {
  return (
    <Typography variant="text" className="flex flex-col">
      <strong>
        Ugovor o licenci s krajnjim korisnikom za korištenje aplikativnog
        rješenja SPRIT ("Ugovor")
      </strong>
      <div className="py-4">
        Aplikativno rješenje (“Rješenje”) koje je predmet ovog Ugovora Vam se
        licencira, a ne prodaje. Korištenjem Rješenja pristajete na odredbe ovog
        Ugovora sklopljenog s autorom Rješenja, društvom CROZ d.o.o., sa
        sjedištem u Zagrebu, Lastovska ulica 23, Republika Hrvatska, Osobni
        identifikacijski broj (OIB): 86132384544 ("Davatelj licence"). Ukoliko
        ne pristajete na navedeno niste ovlašteni instalirati, kopirati ili
        koristiti Rješenje.
        <br />
        Rješenje i sve datoteke koje Vam se dostavljaju od strane Davatelja
        licence, i to putem elektroničkih komunikacija ili na drugi način, u
        obliku zakrpa ("patch"), ažuriranja ("update"), nadogradnje ("upgrade"),
        kao i svi tiskani materijali i bilo koja elektronička dokumentacija
        ("Uputstva"), i sve preslike i izvedena djela iz takvog softvera i
        Uputstava ("Rješenje") predstavlja autorsko djelo i drugo pravo
        intelektualnog vlasništva Davatelja licence. Rješenje se distribuira
        isključivo za korištenje od strane ovlaštenih krajnjih korisnika u
        skladu s ovim Ugovorom. Bilo koje korištenje, reproduciranje, izmjena
        ili distribucija Rješenja koja nije izričito dopuštena odredbama ovog
        Ugovora je izričito zabranjena.
      </div>
      <strong>Davanje ograničene licence za korištenje</strong>
      <ul className="list-disc pl-4 py-4">
        <li>
          Ukoliko pristajete na odredbe ovog Ugovora možete pristupiti Rješenju
          odnosno koristiti Rješenje.
        </li>
        <li>
          Podložno Vašem pristanku na ovaj Ugovor i poštivanju istog, Davatelj
          licence Vam ovime daje, i Vi ovime primate, sadržajno ograničeno,
          neprenosivo, neisključivo ("non-exclusive") i vremenski ograničeno
          pravo korištenja Rješenja, isključivo na Vašem uređaju i to samo u
          obliku završnog izvedbenog programa odnosno produkcijske verzije
          programa u okviru instalirane funkcionalnosti, te isključivo za
          potrebe korištenja Rješenja za vlastitu poslovnu uporabu.
        </li>
        <li>
          Vaše pravo na korištenje Rješenja ograničeno je na razdoblje licence
          definirano u zasebnom ugovoru ili u slučaju da takav ugovor ne postoji
          do otkazivanja bez navođenja razloga od strane Vas ili Davatelja
          licence bez otkaznog roka. Otkazivanje se može izvršiti sprječavanjem
          daljnjeg korištenja Rješenja od strane Davatelja licence ili brisanjem
          Rješenja iz Vašeg sustava.
        </li>
        <li>
          "Demo" verzija Rješenja se ne može koristiti za bilo kakvu
          komercijalnu ili poslovnu upotrebu, već samo u svrhu testiranja
          Rješenja od strane Davatelja licence i Vas, te za Vaš pregled
          funkcionalnosti takvog softvera kako bi se utvrdila mogućnost daljnje
          komercijalne upotrebe Rješenja. Mogućnost korištenja "demo" verzije
          Rješenja je predviđeno do 31.12.2023. godine, a Davatelj licence može
          otkazati Vaše pravo korištenja "demo" verzije Rješenja u bilo kojem
          trenutku bez prethodne obavijesti.
        </li>
        <li>
          Davatelj licence izričito zadržava sva prava na Rješenju koja nije
          izričito prenio na Vas temeljem ovog Ugovora.
        </li>
        <li>
          Ovaj Ugovor, pravo korištenja dano temeljem ovog Ugovora, primjerci
          Rješenja ili bilo kojih drugih materijala koji su Vam isporučeni
          temeljem ovog Ugovora, ne smijete, u cijelosti ili djelomično,
          prenositi, podlicencirati, dati u zakup, dati u najam, kopirati,
          reproducirati, distribuirati, ili na bilo koji drugi način prenijeti
          na bilo koji drugu osobu bez prethodnog pisanog odobrenja Davatelja
          licence, te kršenje navedenog predstavlja razlog za raskid ovog
          Ugovora od strane Davatelja licence na Vašu štetu.
        </li>
        <li>
          Prihvaćate da Rješenje i njegova struktura, organizacija i izvorni kod
          predstavljaju vrijedne poslovne tajne Davatelja licence i njegovih
          dobavljača. U skladu s navedenim, obvezujete se da nećete (odnosno da
          nećete omogućiti drugima):
          <ul className="list-disc pl-8">
            <li>
              modificirati, prilagoditi, promijeniti, prevoditi ili stvarati
              izvedene radove iz Rješenja;
            </li>
            <li>spajati Rješenje s drugim softverom;</li>
            <li>
              Rješenje prenositi, podlicencirati, dati u zakup, dati u najam,
              kopirati, reproducirati, distribuirati, ili na bilo koji drugi
              način prenijeti na bilo koji drugu osobu;
            </li>
            <li>
              učiniti Rješenje dostupnim bilo kojoj trećoj strani odnosno na
              drugi način koristiti Rješenje za korist treće strane;
            </li>
            <li>
              vršiti obrnuti inženjering Rješenja, dekompilirati Rješenje,
              prevoditi Rješenje, rastavljati Rješenje ili na drugi način
              pokušati pribaviti izvorni kod Rješenja;
            </li>
            <li>
              na drugi način koristiti ili kopirati Rješenje osim kako je to
              izričito dozvoljeno ovim Ugovorom.
            </li>
          </ul>
        </li>
        <li>
          Prihvaćate da Rješenje predstavlja vrijedno vlasništvo i poslovnu
          tajnu Davatelja licence koji obuhvaćaju značajne kreativne napore i
          povjerljive podatke, ideje i izričaj.
        </li>
      </ul>
      <strong>Pristup i korištenje Rješenja </strong>
      <ul className="list-disc pl-4 py-4">
        <li>
          Vi ste isključivo odgovorni za osiguravanje IT sustava koji omogućuje
          pristup Rješenju, sukladno specifikacijama i tehničkim zahtjevima
          Rješenja o kojima Vas Davatelj licence može obavijestiti s vremena na
          vrijeme.{" "}
        </li>
      </ul>
      <strong>Dodatne usluge</strong>
      <ul className="list-disc pl-4 py-4">
        <li>
          Ovim Ugovorom se ne reguliraju bilo kakve dodatne usluge koje Davatelj
          licence može nuditi na tržištu s vremena na vrijeme te sklapanjem ovog
          Ugovora ne ostvarujete nikakva prava na iste. Za pružanje takvih
          usluga potreban je prethodni pisani ugovor s Davateljem licence.
        </li>
        <li>
          Davatelj licence ima pravo s vremena na vrijeme, bez ikakve obveze
          prema Vama glede sadržaja ili budućeg trajanja, omogućavati ažuriranja
          ili nadogradnje Rješenja.
        </li>
      </ul>
      <strong>Prestanak ugovora</strong>
      <ul className="list-disc pl-4 py-4">
        <li>
          Davatelj licence ima pravo raskinuti ovaj Ugovor bez prethodne
          obavijesti u slučaju Vašeg kršenja bilo koje odredbe ovog Ugovora.
        </li>
        <li>
          Raskid ili prestanak ovog Ugovora neće utjecati na međusobna
          potraživanja koja su nastala, odnosno koja su dospjela prije trenutka
          raskida ili prestanka ovog Ugovora, kao niti na eventualna prava na
          naknadu štete zbog raskida Ugovora.
        </li>
        <li>
          U slučaju prestanka ili raskida ovog Ugovora, nemate pravo zahtijevati
          vraćanje iznosa plaćenih Davatelju licence, ako takvi postoje.
        </li>
        <li>
          Nakon prestanka ili raskida ovog Ugovora, sva Vaša eventualna prava u
          odnosu na Rješenje prestaju i vraćaju se Davatelju licence, odnosno
          odgovarajućim nositeljima prava, neovisno o tome da li ste ih i
          koristili.
        </li>
        <li>
          Nakon prestanka ili raskida ovog Ugovora, nemate više nikakva prava
          koristiti Rješenje.
        </li>
        <li>
          Nakon prestanka ili raskida ovog Ugovora, obvezujete se o vlastitom
          trošku uništiti sve kopije Rješenja i sve povezane isprave koje
          odobravaju korištenje istih.
        </li>
        <li>
          U slučaju neispunjavanja bilo kojih Vaših obveza iz ovog Ugovora ili
          drugih ugovora sklopljenih s Davateljem licence, dodatno na druga
          prava sukladno ovom Ugovoru i primjenjivim propisima, Davatelj licence
          ima pravo zastati s ispunjavanjem svojih obveza iz ovog Ugovora,
          odnosno onemogućiti Vas u daljnjem korištenju Rješenja sve do
          ispunjenja Vaših obveza. Zastoj s ispunjavanjem obveza Davatelja
          licence iz ovog Ugovora, odnosno onemogućavanje daljnjeg korištenja
          Rješenja temeljem ovog Ugovora ne utječe na ispunjavanja Vaših obveza
          iz ovog Ugovora i drugih Ugovora s Davateljem licence.
        </li>
      </ul>
      <strong>Isključenje jamstva i ograničenje odgovornosti</strong>
      <ul className="list-disc pl-4 py-4">
        <li>
          Prihvaćate da se Rješenje isporučuje u konačnom obliku izvedbenog
          programa u stanju "viđeno-kupljeno" te je isključeno bilo kakvo
          jamstvo glede materijalnih nedostataka Rješenja, kao i dodatnih
          usluga.{" "}
        </li>
        <li>
          U slučaju pojave bilo kakvih materijalnih nedostataka Rješenja i/ili
          dodatnih usluga jedini Vaši pravni lijekovi su oni izričito predviđeni
          ovim Ugovorom, te su izričito u najvećem opsegu dopuštenom zakonom
          isključeni ostali pravni lijekovi.
        </li>
        <li>
          Davatelj licence ne odgovara za učinak ili poslovne rezultate koje bi
          mogli ostvariti ili koje očekujete od korištenja Rješenja ili
          ispunjenja ovog Ugovora. Davatelj licence ne odgovara za bilo kakve
          posljedice nastale korištenjem Rješenja (gubitak podataka, problemi
          nastali zbog pristupanja drugih programa Rješenju, problemi nastali
          zbog razmjene podataka sa drugim programima, greške u unosu podataka,
          te bilo koje druge greške koje mogu nastati radom Rješenja.
        </li>
        <li>
          Davatelj licence ne daje nikakve izjave ili jamstva, izričita ili
          prešutna, u odnosu na Rješenje ili Dodatne usluge, uključujući, ali se
          ne ograničavajući na bilo kakva jamstva glede kvalitete, učinka,
          iskoristivosti, zadovoljavajuće kvalitete, prikladnosti za određenu
          svrhu ili bilo kojih drugih aspekata Rješenja i/ili dodatnih usluga.
        </li>
        <li>
          Prihvaćate da je najviši iznos naknade štete koju možete potraživati
          od Davatelja licence ograničen na, što god je veće, iznos koji ste
          stvarno platili za licencu, ili 5,00 EUR.
        </li>
        <li>
          Prihvaćate da je Davatelj licence odgovoran za štetu samo u slučaju
          namjere ili krajnje nepažnje.
        </li>
        <li>
          Prihvaćate da je Davatelj licence samo odgovoran za običnu (izravnu)
          štetu, a nikako za izmaklu korist, izgubljenu dobit, nematerijalnu
          štetu ili povredu prava osobnosti bilo koje vrste, odnosno bilo kakvu
          neizravnu ili posljedičnu štetu, gubitak proizvodnje, gubitak podataka
          ili slično.
        </li>
        <li>
          Vi snosite cjelokupnu odgovornost vezano uz podatke koje unosite u
          Rješenje (uključujući i za njihovu sigurnost i zaštitu), za njihovu
          točnost kao i za redovito pravljenje sigurnosnih kopija tih podataka
          (backup).
        </li>
      </ul>
      <strong>Opće odredbe</strong>
      <ul className="list-disc pl-4 py-4">
        <li>
          Za sve sporove iz ovog Ugovora nadležan je stvarno nadležni sud
          temeljem sjedišta Davatelja licence i primjenjivat će se hrvatsko
          pravo.
        </li>
      </ul>
    </Typography>
  );
}
