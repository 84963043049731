import React, { useEffect, useState } from "react";

import { Breadcrumbs, IconButton, Link, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { getUsersByRecipientGroups } from "../../archive/common/api/getUsersByRecipientGroups";
import { UserResponse } from "../../archive/common/api/UserResponse";
import LoadingCircle from "../../common/components/LoadingCircle";
import { EVENT_OPTIONS, EVENT_REPEATING_OPTIONS } from "../../common/constants";
import { EventResponse } from "../api/EventResponse";
import { getEventWithParticipantsById } from "../api/getEventById";
import { getRepeatingEventWithParticipantsById } from "../api/getRepeatingEventWithParticipantsById";
import { RepeatingEventResponse } from "../api/RepeatingEventResponse";
import CreateForm from "../components/CreateOrUpdateEventForm";

type Form = {
  title: string;
  eventType: string;
  locationName: string;
  locationAddress: string;
  description: string;
  repeating: string;
  eventLeader: string;
  startDateTime: Date;
  endDateTime: Date;
  startTimeOfFirstInstance: string;
  endTimeOfFirstInstance: string;
  dateOfFirstInstance: Date;
  dateOfLastInstance: Date;
  excludeWeekends: boolean;
  separationCount: number;
  participants: UserResponse[] | undefined;
  daysOfWeek: string[];
};

type EventCreatePageProps = {
  editMode: boolean;
};

export default function EventCreatePage({ editMode }: EventCreatePageProps) {
  const [initialEventFormValues, setInitialEventFormValues] = useState(
    {} as Form
  );
  const [repeating, setRepeating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [recipientGroups, setRecipientGroups] = useState([] as any);
  const [participants, setParticipants] = useState([] as UserResponse[]);

  const { id, isRepeating } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getUsersByRecipientGroups({
      recipientGroups: recipientGroups,
    }).then((users) => {
      setParticipants(users);
    });
  }, [recipientGroups]);

  useEffect(() => {
    if (editMode && id && isRepeating === "non-repeating") {
      getEventWithParticipantsById(id).then((response) => {
        setRepeating(false);
        setRecipientGroups(response.recipientGroups);
        setEventFormValues(response);
        setIsActive(
          moment(response.endTime).isAfter(moment(new Date(Date.now())))
        );
      });
    } else if (editMode && id && isRepeating === "repeating") {
      setRepeating(true);
      getRepeatingEventWithParticipantsById(id).then((response) => {
        setRecipientGroups(response.recipientGroups);
        setRepeatingEventFormValues(response);
        setIsActive(
          moment(response.endTime).isAfter(moment(new Date(Date.now())))
        );
      });
    } else {
      setInitialEventFormValues({
        title: "",
        eventType: EVENT_OPTIONS[0].value,
        locationName: "",
        locationAddress: "",
        description: "",
        repeating: EVENT_REPEATING_OPTIONS[0].value,
        eventLeader: "",
        startDateTime: new Date(),
        endDateTime: new Date(),
        startTimeOfFirstInstance: "",
        endTimeOfFirstInstance: "",
        dateOfFirstInstance: new Date(),
        dateOfLastInstance: new Date(),
        excludeWeekends: true,
        separationCount: 1,
        participants: undefined,
        daysOfWeek: [],
      });
    }
  }, [id, isRepeating, editMode]);
  /* eslint-enable */

  const setEventFormValues = (event: EventResponse) => {
    setInitialEventFormValues({
      title: event.title,
      eventType: event.eventType,
      locationName: event.locationName,
      locationAddress: event.locationAddress,
      description: event.description,
      repeating: EVENT_REPEATING_OPTIONS[0].value,
      eventLeader: event.eventLeader,
      startDateTime: new Date(
        formatInTimeZone(
          event.startTime,
          "Europe/Zagreb",
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      ),
      endDateTime: new Date(
        formatInTimeZone(
          event.endTime,
          "Europe/Zagreb",
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      ),
      startTimeOfFirstInstance: "10:00",
      endTimeOfFirstInstance: "11:00",
      dateOfFirstInstance: new Date(),
      dateOfLastInstance: new Date(),
      excludeWeekends: true,
      separationCount: 1,
      participants: event.participants,
      daysOfWeek: ["MONDAY"],
    });
  };

  const setRepeatingEventFormValues = (event: RepeatingEventResponse) => {
    setInitialEventFormValues({
      title: event.title,
      eventType: event.eventType,
      locationName: event.locationName,
      locationAddress: event.locationAddress,
      description: event.description,
      repeating: event.repeatingType,
      eventLeader: event.eventLeader,
      //Sent on update, used in case of updating a single instance of a repeating event
      startDateTime: new Date(
        formatInTimeZone(
          event.instanceStartTime,
          "Europe/Zagreb",
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      ),
      //Sent on update, used in case of updating a single instance of a repeating event
      endDateTime: new Date(
        formatInTimeZone(
          event.instanceEndTime,
          "Europe/Zagreb",
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      ),
      startTimeOfFirstInstance: formatInTimeZone(
        event.startTime,
        "Europe/Zagreb",
        "HH:mm"
      ),
      endTimeOfFirstInstance: formatInTimeZone(
        event.endTime,
        "Europe/Zagreb",
        "HH:mm"
      ),
      dateOfFirstInstance: new Date(
        formatInTimeZone(
          event.startTime,
          "Europe/Zagreb",
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      ),
      dateOfLastInstance: new Date(
        formatInTimeZone(
          event.endTime,
          "Europe/Zagreb",
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      ),
      excludeWeekends: event.excludeWeekends,
      separationCount: event.separationCount ? event.separationCount : 1,
      participants: event.participants,
      daysOfWeek: event.daysOfWeek,
    });
  };

  const getStringMode = () => {
    if (editMode) {
      return "Uređivanje događaja";
    } else {
      return "Kreiranje događaja";
    }
  };

  const updateDisabled = () => {
    return (
      <Typography className="italic" element="p" variant="subtext">
        Prošle događaje nije moguće uređivati
      </Typography>
    );
  };

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6">
      <div className="flex flex-col gap-y-8">
        <div className="flex justify-between">
          <div>
            <div className="flex md:flex-col">
              <IconButton
                icon={<Icon type="caret-left" variant="bold" />}
                className="md:hidden pl-4"
                onClick={() => navigate("/calendar")}
              />
              <Typography
                element="h3"
                variant="h3"
                className="hidden md:inline font-biotiff"
              >
                {getStringMode()}
              </Typography>
              <Typography
                element="h6"
                variant="h6"
                className="md:hidden font-biotiff pl-4"
              >
                {getStringMode()}
              </Typography>
            </div>
            <div className="md:pt-2 invisible md:visible">
              <Breadcrumbs>
                <Breadcrumbs.Breadcrumb>
                  <Link to="/calendar">Kalendar</Link>
                </Breadcrumbs.Breadcrumb>
                <Breadcrumbs.Breadcrumb>
                  {getStringMode()}
                </Breadcrumbs.Breadcrumb>
              </Breadcrumbs>
            </div>
          </div>
        </div>
        <LoadingCircle isLoading={isLoading}>
          <div className="xl:px-40">
            {editMode && initialEventFormValues.title !== undefined ? (
              isActive ? (
                <CreateForm
                  initialFormValues={initialEventFormValues}
                  editMode={editMode}
                  id={id}
                  repeatingEvent={repeating}
                  setIsLoading={setIsLoading}
                  participants={participants}
                  recipientGroups={recipientGroups}
                  setRecipientGroups={setRecipientGroups}
                />
              ) : (
                updateDisabled()
              )
            ) : (
              <div></div>
            )}
            {!editMode && initialEventFormValues.title !== undefined ? (
              <CreateForm
                initialFormValues={initialEventFormValues}
                editMode={editMode}
                repeatingEvent={false}
                setIsLoading={setIsLoading}
                participants={participants}
                recipientGroups={recipientGroups}
                setRecipientGroups={setRecipientGroups}
              />
            ) : (
              <div></div>
            )}
          </div>
        </LoadingCircle>
      </div>
    </div>
  );
}
