import { ParentSearchRequest } from "./ParentSearchRequest";
import { PARENT_URL } from "../../../common/Routes";

export async function postSearchParentRequest(request: ParentSearchRequest) {
  const response = await fetch(`${PARENT_URL}/search/pageable`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
