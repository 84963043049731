import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

type QRModalProps = {
  modal: UseModal;
  imageDataUrl: string;
};

export default function QRModal({ modal, imageDataUrl }: QRModalProps) {
  return (
    <Modal {...modal}>
      <Modal.Content title={""}>
        <div className="flex justify-center">
          <img src={imageDataUrl} alt="qr kod" />
        </div>
        <div className="flex justify-center">
          <Typography
            element="p"
            variant="subtext"
            className="pb-4 text-gray-500"
          >
            Skenirajte uplatnicu s vašom aplikacijom za bankarstvo
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          U redu
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
