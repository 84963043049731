import React, { useEffect, useState } from "react";

import { RadioGroup } from "@tiller-ds/form-elements";
import { AutocompleteField } from "@tiller-ds/formik-elements";

import { useFormikContext } from "formik";

import { postFilterUsers } from "../../archive/common/api/postFilterUsers";
import { UserResponse, UserType } from "../../archive/common/api/UserResponse";
import { getRecipientGroups } from "../api/getRecipientGroups";
import { RecipientGroup } from "../api/RecipientGroup";
import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
  userType,
} from "../constants";

type RecipientsAutocompleteFieldSelectorProps = {
  recipientsName: string;
  recipientGroupsName: string;
};
export default function RecipientsAutocompleteFieldSelector({
  recipientsName,
  recipientGroupsName,
}: RecipientsAutocompleteFieldSelectorProps) {
  const [selectedRecipientType, setSelectedRecipientType] = useState(
    "recipients"
  );
  const [
    recipientGroupsAutocomplete,
    setRecipientGroupsAutocomplete,
  ] = useState([] as RecipientGroup[]);
  const formik = useFormikContext();

  useEffect(() => {
    getRecipientGroups().then((response) => {
      setRecipientGroupsAutocomplete(response);
    });
  }, []);

  function userSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as UserResponse[]);
    else
      return postFilterUsers({
        name: query,
        userType: [
          UserType.COACH,
          UserType.ATHLETE,
          UserType.PARENT,
          UserType.ADMIN,
        ],
      });
  }

  return (
    <div className="w-full">
      <div>
        <RadioGroup
          className="mb-2"
          name="autocompleteType"
          label=""
          onChange={(value) => {
            setSelectedRecipientType(value as string);
            formik.setFieldValue(recipientsName, []);
            formik.setFieldValue(recipientGroupsName, []);
          }}
          value={selectedRecipientType}
        >
          <RadioGroup.Item
            label="Grupe"
            value="recipientGroups"
          ></RadioGroup.Item>
          <RadioGroup.Item
            label="Pojedinačni članovi"
            value="recipients"
          ></RadioGroup.Item>
        </RadioGroup>
        <div className="flex flex-col w-full">
          {selectedRecipientType === "recipients" && (
            <AutocompleteField
              name={recipientsName}
              options={userSearch}
              placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
              allowMultiple
              itemToString={(item) => `${item.firstName} ${item.lastName}`}
              getOptionLabel={(user) => (
                <div className="flex items-center justify-between flex-wrap">
                  <div>
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
                    {userType.get(user.userType)}
                  </div>
                </div>
              )}
              getOptionValue={(item) => item.id}
              getMultipleSelectedLabel={(items) => {
                return items
                  .map(
                    (user: UserResponse) => `${user.firstName} ${user.lastName}`
                  )
                  .join(", ");
              }}
              tags={true}
              sendOptionValue={false}
            />
          )}
          {selectedRecipientType === "recipientGroups" && (
            <AutocompleteField
              name={recipientGroupsName}
              options={recipientGroupsAutocomplete}
              allowMultiple
              itemToString={(item) => `${item.name}`}
              getOptionLabel={(mailingList) => `${mailingList.name}`}
              filter={(query: string, option) =>
                `${option.name?.toLowerCase()}`.includes(query.toLowerCase())
              }
              getOptionValue={(item) => item}
              getMultipleSelectedLabel={(items) => {
                return items
                  .map((mailingList) => `${mailingList.name}`)
                  .join(", ");
              }}
              tags={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
