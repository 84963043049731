import React, { useEffect, useState } from "react";

import { Button, IconButton, Pagination, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { InputField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { Formik } from "formik";
import * as yup from "yup";

import { MAX_NAME_LENGTH } from "../../../common/constants";
import { maxCharacterCountMessage } from "../../../common/YupConstants";
import { InvoiceResponse } from "../../common/InvoiceResponse";
import { formatAmount } from "../../common/InvoiceUtil";

type FilterMode = "PARENT" | "ATHLETE";

const filterModeTitle = {
  PARENT: "Roditelj",
  ATHLETE: "Sportaš",
};

type FilterForm = {
  filterValue: string;
};

const initialFilterValues = {
  filterValue: "",
};

const filterValidationSchema = yup.object().shape({
  filterValue: yup
    .string()
    .nullable()
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH)),
});

type CreateInvoicePagePreviewTableProps = {
  preparedInvoices: InvoiceResponse[];
  onDeleteInvoiceFromPreview: (remainingInvoices: InvoiceResponse[]) => void;
};

export function CreateInvoicePagePreviewTable({
  preparedInvoices,
  onDeleteInvoiceFromPreview,
}: CreateInvoicePagePreviewTableProps) {
  const [selectedFilterMode, setSelectedFilterMode] = useState<FilterMode>();
  const [invoices, setInvoices] = useState({
    originalInvoices: preparedInvoices,
    filteredInvoices: preparedInvoices,
  });
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    setInvoices({
      filteredInvoices: preparedInvoices,
      originalInvoices: preparedInvoices,
    });
  }, [preparedInvoices]);

  const filterFormSubmitHandler = (form: FilterForm) => {
    if (form.filterValue === null || form.filterValue.length === 0) {
      setInvoices((prevState) => ({
        ...prevState,
        filteredInvoices: prevState.originalInvoices,
      }));
      return;
    }

    switch (selectedFilterMode) {
      case "ATHLETE":
        setInvoices((prevState) => ({
          ...prevState,
          filteredInvoices: prevState.originalInvoices.filter(
            (invoice) =>
              invoice.athlete.firstName
                .toLowerCase()
                .includes(form.filterValue.toLowerCase()) ||
              invoice.athlete.lastName
                .toLowerCase()
                .includes(form.filterValue.toLowerCase())
          ),
        }));

        break;
      case "PARENT":
        setInvoices((prevState) => ({
          ...prevState,
          filteredInvoices: prevState.originalInvoices.filter(
            (invoice) =>
              invoice.athlete.parents.filter(
                (parent) =>
                  parent.firstName
                    .toLowerCase()
                    .includes(form.filterValue.toLowerCase()) ||
                  parent.lastName
                    .toLowerCase()
                    .includes(form.filterValue.toLowerCase())
              ).length > 0
          ),
        }));
        break;
    }
  };

  const deleteInvoiceHandler = (invoice: InvoiceResponse) => {
    setInvoices({
      originalInvoices: invoices.originalInvoices.filter(
        (inv) => inv !== invoice
      ),
      filteredInvoices: invoices.originalInvoices.filter(
        (inv) => inv !== invoice
      ),
    });

    const notDeletedInvoices = invoices.originalInvoices.filter(
      (originalInvoice) => originalInvoice !== invoice
    );

    onDeleteInvoiceFromPreview(notDeletedInvoices);
  };

  return (
    <div className="flex flex-col space-y-5 mx-auto w-full mt-5">
      <Typography variant="h3" element="h3">
        Pretpregled računa
      </Typography>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={filterFormSubmitHandler}
        validationSchema={filterValidationSchema}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:space-x-3 justify-end">
              <DropdownMenu
                title={
                  selectedFilterMode
                    ? filterModeTitle[selectedFilterMode]
                    : "Odaberite filter"
                }
                color="primary"
              >
                <DropdownMenu.Item
                  onSelect={() => setSelectedFilterMode("PARENT")}
                >
                  Roditelj
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onSelect={() => setSelectedFilterMode("ATHLETE")}
                >
                  Sportaš
                </DropdownMenu.Item>
              </DropdownMenu>
              <div className="flex flex-row space-x-3 w-full sm:w-fit justify-end">
                <InputField
                  name="filterValue"
                  maxLength={MAX_NAME_LENGTH}
                  className="w-full sm:w-fit"
                  placeholder="Pretraži"
                  inlineLeadingIcon={<Icon type="magnifying-glass" />}
                />
                <Button type="submit" className="w-1/5 sm:w-fit">
                  <Icon type="funnel" />
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <DataTable
        data={
          invoices.filteredInvoices && invoices.filteredInvoices.length > 0
            ? invoices.filteredInvoices.slice(page * 10, (page + 1) * 10)
            : []
        }
      >
        <DataTable.Column
          header="Sportaš"
          id="athlete"
          canSort={false}
          className="w-3/12"
        >
          {(item: InvoiceResponse) => (
            <Typography variant="text">{`${item.athlete.firstName} ${item.athlete.lastName}`}</Typography>
          )}
        </DataTable.Column>
        <DataTable.Column
          header="Roditelj"
          id="parent"
          canSort={false}
          className="w-2/12"
        >
          {(item: InvoiceResponse) => (
            <div className="flex flex-col space-y-1">
              {item.athlete.parents.map((parent) => (
                <Typography variant="text">
                  {`${parent.firstName} ${parent.lastName}`}
                </Typography>
              ))}
            </div>
          )}
        </DataTable.Column>
        <DataTable.Column
          header={
            <div className="w-full text-right">
              <p>Popust</p>
            </div>
          }
          id="discount"
          canSort={false}
          align="right"
          className="w-1/12"
        >
          {(item: InvoiceResponse) => {
            if (item.applyDiscount) {
              return (
                <Typography variant="text">
                  {item.athlete.discountPercentage} %
                </Typography>
              );
            } else {
              return <Typography variant="text">0 %</Typography>;
            }
          }}
        </DataTable.Column>
        <DataTable.Column
          header={
            <div className="w-full text-right">
              <p>Iznos</p>
            </div>
          }
          id="amount"
          canSort={false}
          className="w-1/12"
          align={"right"}
        >
          {(item: InvoiceResponse) => (
            <Typography variant="text">
              <span>{formatAmount(item.amountWithDiscount)}</span>
            </Typography>
          )}
        </DataTable.Column>
        <DataTable.Column
          header="Izbriši"
          id="delete-button"
          canSort={false}
          className="w-1/12"
        >
          {(item: InvoiceResponse) => (
            <div className="text-center">
              <IconButton
                icon={<Icon type="trash" className="translate-x-1/2" />}
                onClick={() => deleteInvoiceHandler(item)}
                label="Delete"
              />
            </div>
          )}
        </DataTable.Column>
      </DataTable>
      <div>
        {invoices.filteredInvoices.length > 10 && (
          <div className="w-full mt-1 flex justify-center">
            <Pagination
              onPageChange={(i: number) => setPage(i)}
              totalElements={invoices.filteredInvoices.length}
              pageNumber={page}
              pageSize={10}
            >
              {() => null}
            </Pagination>
          </div>
        )}
      </div>
    </div>
  );
}
