import React, { useEffect, useState } from "react";

import { RadioGroup } from "@tiller-ds/form-elements";
import { AutocompleteField } from "@tiller-ds/formik-elements";
import { Autocomplete } from "@tiller-ds/selectors";

import { useFormikContext } from "formik";

import { postFilterUsers } from "../../archive/common/api/postFilterUsers";
import { UserResponse, UserType } from "../../archive/common/api/UserResponse";
import { getRecipientGroups } from "../../common/api/getRecipientGroups";
import { RecipientGroup } from "../../common/api/RecipientGroup";
import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
  userType,
} from "../../common/constants";

type EventRecipientsAutocompleteFieldSelectorProps = {
  recipientsName: string;
  recipientGroupsName: string;
  recipientGroups: any;
  setRecipientGroups: (recipientGroups: any) => void;
  participants: UserResponse[];
};
export default function EventRecipientsAutocompleteFieldSelector({
  recipientsName,
  recipientGroupsName,
  recipientGroups,
  setRecipientGroups,
  participants,
}: EventRecipientsAutocompleteFieldSelectorProps) {
  const formik = useFormikContext();

  const [selectedRecipientType, setSelectedRecipientType] = useState(
    recipientGroups.length > 0 ? "recipientGroups" : "recipients"
  );
  const [
    recipientGroupsAutocomplete,
    setRecipientGroupsAutocomplete,
  ] = useState([] as RecipientGroup[]);

  useEffect(() => {
    getRecipientGroups().then((response) => {
      setRecipientGroupsAutocomplete(response);
    });
  }, []);

  useEffect(() => {
    if (selectedRecipientType === "recipientGroups") {
      formik.setFieldValue("participants", participants);
    }
    // eslint-disable-next-line
  }, [participants]);

  function userSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as UserResponse[]);
    else
      return postFilterUsers({
        name: query,
        userType: [
          UserType.COACH,
          UserType.ATHLETE,
          UserType.PARENT,
          UserType.ADMIN,
        ],
      });
  }

  return (
    <div className="w-full">
      <div>
        <RadioGroup
          className="mb-2"
          name="autocompleteType"
          label=""
          onChange={(value) => {
            setSelectedRecipientType(value as string);
            formik.setFieldValue(recipientsName, []);
            setRecipientGroups([]);
          }}
          value={selectedRecipientType}
        >
          <RadioGroup.Item
            label="Grupe"
            value="recipientGroups"
          ></RadioGroup.Item>
          <RadioGroup.Item
            label="Pojedinačni članovi"
            value="recipients"
          ></RadioGroup.Item>
        </RadioGroup>
        <div className="flex flex-col w-full">
          {selectedRecipientType === "recipients" && (
            <AutocompleteField
              name={recipientsName}
              options={userSearch}
              placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
              allowMultiple
              itemToString={(item) => `${item.firstName} ${item.lastName}`}
              getOptionLabel={(user) => (
                <div className="flex items-center justify-between flex-wrap">
                  <div>
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
                    {userType.get(user.userType)}
                  </div>
                </div>
              )}
              getOptionValue={(item) => item.id}
              getMultipleSelectedLabel={(items) => {
                return items
                  .map(
                    (user: UserResponse) => `${user.firstName} ${user.lastName}`
                  )
                  .join(", ");
              }}
              tags={true}
              sendOptionValue={false}
            />
          )}
          {selectedRecipientType === "recipientGroups" && (
            <div className="flex flex-col justify-between space-y-2">
              <Autocomplete
                label={
                  "Polje za unos pojedinačnih članova automatski će se ispuniti na temelju odabranih grupa"
                }
                name={recipientGroupsName}
                options={recipientGroupsAutocomplete}
                allowMultiple
                itemToString={(item) => `${item.name}`}
                getOptionLabel={(mailingList) => `${mailingList.name}`}
                filter={(query: string, option) =>
                  `${option.name?.toLowerCase()}`.includes(query.toLowerCase())
                }
                getOptionValue={(item) => item.name}
                getMultipleSelectedLabel={(items) => {
                  return items
                    .map((mailingList) => `${mailingList.name}`)
                    .join(", ");
                }}
                value={recipientGroups}
                onChange={(values) => setRecipientGroups(values)}
              />
              <AutocompleteField
                label={"Pojedinačni članovi"}
                name={recipientsName}
                options={userSearch}
                placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
                allowMultiple
                itemToString={(item) => `${item.firstName} ${item.lastName}`}
                getOptionLabel={(user) => (
                  <div className="flex items-center justify-between flex-wrap">
                    <div>
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
                      {userType.get(user.userType)}
                    </div>
                  </div>
                )}
                getOptionValue={(item) => item.id}
                getMultipleSelectedLabel={(items) => {
                  return items
                    .map(
                      (user: UserResponse) =>
                        `${user.firstName} ${user.lastName}`
                    )
                    .join(", ");
                }}
                tags={true}
                sendOptionValue={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
