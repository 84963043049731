import FileDownload from "js-file-download";

import { REPORTS_STATE_GROUPS_URL } from "../../common/Routes";
import { formatDateToHrWithDashes } from "../util/dateUtil";

export function exportGroupReportsExcel() {
  fetch(`${REPORTS_STATE_GROUPS_URL}/export/excel`, {
    method: "GET",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const date = formatDateToHrWithDashes(new Date());
      FileDownload(blob, `izvještaj_grupa_${date}.xlsx`);
    });
}
