import React, { useEffect, useState } from "react";

import { Card, Typography } from "@tiller-ds/core";
import { DescriptionList } from "@tiller-ds/data-display";

import { AthleteResponse } from "../../../archive/athlete/api/AthleteResponse";
import { getAthleteById } from "../../../archive/athlete/api/getAthleteById";
import LoadingCircle from "../../../common/components/LoadingCircle";

export type AthleteProfileProps = {
  id: number;
};

export default function AthleteProfile({ id }: AthleteProfileProps) {
  const [athlete, setAthlete] = useState({} as AthleteResponse);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAthleteById(id).then((athlete) => {
      setAthlete(athlete);
      setIsLoading(false);
    });
  }, [id]);

  return (
    <LoadingCircle isLoading={isLoading}>
      <Card>
        <Card.Body removeSpacing={true}>
          <DescriptionList>
            <DescriptionList.Item label="Ime" type="same-column">
              <Typography className="font-semibold">
                {athlete.firstName}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Prezime" type="same-column">
              <Typography className="font-semibold">
                {athlete.lastName}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="OIB" type="same-column">
              <Typography className="font-semibold">{athlete.oib}</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Datum rođenja" type="same-column">
              <Typography className="font-semibold">
                {new Date(athlete.dateOfBirth).toLocaleDateString("hr-HR")}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Spol" type="same-column">
              <Typography className="font-semibold">
                {athlete.gender === "MALE" ? "Muško" : "Žensko"}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="E-mail" type="same-column">
              <Typography className="font-semibold">{athlete.email}</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Broj telefona" type="same-column">
              <Typography className="font-semibold">
                {athlete.phoneNumber}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item
              label="Uloga člana u sustavu"
              type="same-column"
            >
              <Typography className="font-semibold">Sportaš</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item
              label="Dodijeljene grupe sportašu"
              type="same-column"
            >
              <Typography className="font-semibold">
                {athlete.groups ? (
                  athlete.groups.map((group, index) => (
                    <div>{group.groupName}</div>
                  ))
                ) : (
                  <i>Nema informacija</i>
                )}
              </Typography>
            </DescriptionList.Item>
          </DescriptionList>
        </Card.Body>
      </Card>
    </LoadingCircle>
  );
}
