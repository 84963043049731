import { SearchProgressTypeRequest } from "./SearchProgressTypeRequest";
import { PROGRESS_URL } from "../../../common/Routes";

export async function postSearchProgressTypeRequest(
  searchProgressTypeRequest: SearchProgressTypeRequest
) {
  const response = await fetch(`${PROGRESS_URL}/type/search`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(searchProgressTypeRequest),
  });
  return response.json();
}
