import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

import { useNotifications } from "@croz/nrich-notification-core";

import { useFormikContext } from "formik";

import handleCreateResponse from "../../common/handleCreateResponse";
import { CreateAdminRequest } from "../api/CreateAdminRequest";
import { postCreateAdminRequest } from "../api/postCreateAdminRequest";

type Form = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  roleSelect: string;
};

type ConfirmCreateAdminModalProps = {
  modal: UseModal<unknown>;
  createUserModal: UseModal<unknown>;
  entityCreated: number | undefined;
  setEntityCreated: ((entityCreated: number) => void) | undefined;
};

export default function ConfirmCreateAdminModal({
  modal,
  createUserModal,
  entityCreated,
  setEntityCreated,
}: ConfirmCreateAdminModalProps) {
  const { add: addNotification } = useNotifications();
  const formik = useFormikContext();

  function createAdmin() {
    const formValues = formik.values as Form;
    postCreateAdminRequest({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phoneNumber:
        formValues.phoneNumber.length > 0 ? formValues.phoneNumber : null,
      email: formValues.email,
    } as CreateAdminRequest).then((response) => {
      if (handleCreateResponse(response, addNotification)) {
        if (entityCreated !== undefined && setEntityCreated !== undefined) {
          setEntityCreated(entityCreated + 1);
        }
        modal.onClose();
        createUserModal.onClose();
      }
    });
  }

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h3" variant="h4">
            Kreiraj administratora
          </Typography>
        }
      >
        <Typography element="p" variant="subtext" className="pt-2">
          Jeste li sigurni da korisniku želite dodijeliti ulogu administratora?
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={() => createAdmin()}>
          Kreiraj
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
