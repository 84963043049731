import fetchIntercept from "fetch-intercept";

export default function responseErrorInterceptor() {
  fetchIntercept.register({
    response: function (response) {
      if (
        !response.ok &&
        !(response.status === 400) &&
        !response.redirected &&
        !response.url.endsWith("/current-user") &&
        !window.location.pathname.endsWith("/event/new") &&
        !window.location.pathname.includes("/event/update/") &&
        !window.location.pathname.startsWith("/error")
      ) {
        window.location.replace(`/error/${response.status}`);
      }
      return response;
    },
  });
}
