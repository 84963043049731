import { EventNoteResponse } from "./EventNoteResponse";
import { EVENT_URL } from "../../../common/Routes";

export async function getNoteById(
  noteId: number,
  eventId: string
): Promise<EventNoteResponse> {
  const response = await fetch(`${EVENT_URL}/${eventId}/notes/${noteId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
