import { CreateInvoiceRequest } from "./CreateInvoiceRequest";
import { INVOICE_URL } from "../../../common/Routes";

export async function postCreateInvoiceRequest(
  request: CreateInvoiceRequest
): Promise<Response> {
  return await fetch(`${INVOICE_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
