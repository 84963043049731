import { EventType } from "../../common/constants";

export function typeMapper(name: string) {
  switch (name) {
    case "Ostalo":
      return EventType.GENERAL;
    case "Natjecanje":
      return EventType.COMPETITION;
    case "Trening":
      return EventType.TRAINING;
    case "Sastanak":
      return EventType.MEETING;
  }
  return "";
}
