import React from "react";

import { Card, Link, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import UserIcon from "../../../archive/common/components/UserIcon";
import { GroupResponse } from "../../../archive/group/api/GroupResponse";

type GroupCardProps = {
  group: GroupResponse;
};

export default function GroupCard({ group }: GroupCardProps) {
  return (
    <Card className="h-full">
      <Card.Body>
        <div className="pb-1">
          <Typography element="p" variant="title">
            {group.groupName}
          </Typography>
          <Typography element="p" variant="subtitle" className="text-gray-500">
            {`Dobna skupina: ${group.minAge}-${group.maxAge}`}
          </Typography>
        </div>
        <div className="flex justify-between items-end w-full">
          <div className="flex items-end">
            <div className="flex flex-row justify-start -space-x-3">
              {group.athletes[0] ? (
                <UserIcon
                  initials={
                    group.athletes[0].firstName.charAt(0).toUpperCase() +
                    group.athletes[0].lastName.charAt(0).toUpperCase()
                  }
                  entityId={group.athletes[0].id}
                  width="40px"
                  height="40px"
                  textClassName="text-lg"
                />
              ) : null}
              {group.athletes[1] !== undefined ? (
                <UserIcon
                  initials={
                    group.athletes[1].firstName.charAt(0).toUpperCase() +
                    group.athletes[1].lastName.charAt(0).toUpperCase()
                  }
                  entityId={group.athletes[1].id}
                  width="40px"
                  height="40px"
                  textClassName="text-lg"
                />
              ) : null}
              {group.athletes[2] !== undefined ? (
                <UserIcon
                  initials={
                    group.athletes[2].firstName.charAt(0).toUpperCase() +
                    group.athletes[2].lastName.charAt(0).toUpperCase()
                  }
                  entityId={group.athletes[2].id}
                  width="40px"
                  height="40px"
                  textClassName="text-lg"
                />
              ) : null}
            </div>
            {group.athletes.length <= 3 ? (
              <p></p>
            ) : (
              <Typography className="text-base font-bold ml-1">
                {`+${group.athletes.length - 3}`}
              </Typography>
            )}
          </div>
          <div>
            <Link
              className="hover:no-underline"
              to={`/archive/groups/${group.id}`}
            >
              <div className="flex flex-row justify-center bg-navy-500 rounded">
                <Icon
                  size={3}
                  type="caret-right"
                  variant="bold"
                  className="text-md m-2 text-white"
                ></Icon>
              </div>
            </Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
