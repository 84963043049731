import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

import { logout } from "../api/logout";

type LicenceModalProps = {
  modal: UseModal<unknown>;
};

export default function LicenceLogoutModal({ modal }: LicenceModalProps) {
  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h3" variant="h3" className="text-black">
            Prihvaćanje Uvjeta i pravila korištenja aplikacije
          </Typography>
        }
      >
        <Typography element="p" variant="subtext" className="pt-2">
          Pristup stranici nije moguć bez prihvaćanja
          <strong> Uvjeta i pravila korištenja aplikacije</strong>. Jeste li
          sigurni da želite izaći i odjaviti se iz aplikacije?
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="filled"
          onClick={() => {
            logout();
          }}
        >
          Odjava
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
