import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Card, IconButton, Typography } from "@tiller-ds/core";
import { TextareaField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import classNames from "classnames";
import { Formik } from "formik";
import * as yup from "yup";

import DeleteEventNoteModal from "./modals/DeleteEventNoteModal";
import { MAX_NOTE_LENGTH } from "../../common/constants";
import { REQUIRED_FIELD_MESSAGE } from "../../common/YupConstants";
import { createEventNote } from "../api/eventNoteApi/createEventNote";
import { EventNoteResponse } from "../api/eventNoteApi/EventNoteResponse";
import { getNotesForEvent } from "../api/eventNoteApi/getAllNotes";
import { getNoteById } from "../api/eventNoteApi/getNoteById";
import { updateEventNote } from "../api/eventNoteApi/updateEventNote";

const MAX_NOTES_WITHOUT_OVERFLOW = 4;

type EventNoteProps = {
  eventId?: string;
};

type NotesForm = {
  content: string;
};

const saveNoteInitialValues = {
  content: "",
};

export default function Notes({ eventId }: EventNoteProps) {
  const [notes, setNotes] = useState([] as EventNoteResponse[]);
  const [currentlyEditingNoteId, setCurrentlyEditingNoteId] = useState(0);
  const [updateNoteFormInitValues, setUpdateNoteFormInitValues] = useState(
    {} as NotesForm
  );
  const [noteCharacterCount, setNoteCharacterCount] = useState(0);
  const [editNoteCharacterCount, setEditNoteCharacterCount] = useState(0);

  const validationSchema = yup.object().shape({
    content: yup
      .string()
      .required(REQUIRED_FIELD_MESSAGE)
      .nullable()
      .max(255, "Maksimalni broj znakova je 255"),
  });

  const modal = useModal();

  useEffect(() => {
    if (eventId) {
      getNotesForEvent(eventId).then((response) => {
        if (response) {
          setNotes(response);
        }
      });
    }
  }, [eventId]);

  function switchToUpdate(noteId: number) {
    if (eventId) {
      getNoteById(noteId, eventId).then((response) => {
        setUpdateNoteFormInitValues({
          content: response.content,
        });
        setCurrentlyEditingNoteId(noteId);
      });
    }
  }

  const onSaveNote = (form: NotesForm) => {
    if (eventId) {
      createEventNote(
        {
          content: form.content,
        },
        eventId
      ).then((response: EventNoteResponse) => {
        setNotes([response, ...notes]);
      });
      form.content = "";
      setNoteCharacterCount(0);
    }
  };

  const onUpdateNote = (form: NotesForm) => {
    if (eventId) {
      updateEventNote(
        {
          content: form.content,
        },
        currentlyEditingNoteId,
        eventId
      ).then((response: EventNoteResponse) => {
        setNotes([
          response,
          ...notes.filter(
            (note: EventNoteResponse) => note.id !== currentlyEditingNoteId
          ),
        ]);
        setCurrentlyEditingNoteId(0);
        setEditNoteCharacterCount(0);
      });
    }
  };

  const notesClassnames = classNames({
    "overflow-auto overscroll-auto rounded-md": true,
    "h-auto": notes.length <= MAX_NOTES_WITHOUT_OVERFLOW,
    "h-52": notes.length > MAX_NOTES_WITHOUT_OVERFLOW,
  });

  const openDeleteNoteModal = (noteId: number) => {
    modal.onOpen(noteId);
  };
  return (
    <Card className="w-full h-auto p-0 sm:p-4">
      <Card.Header className="flex justify-between items-center text-lg font-bold p-1">
        Bilješke
      </Card.Header>
      <Card.Body>
        <ul className={notesClassnames}>
          {notes.map((note: EventNoteResponse) => {
            return (
              <li className="bg-orange-50 rounded-md mb-2 p-2 border border-navy-300 shadow-2 text-black">
                {currentlyEditingNoteId === note.id ? (
                  <Formik
                    initialValues={updateNoteFormInitValues}
                    onSubmit={onUpdateNote}
                  >
                    {(formik) => (
                      <form className="flex flex-col">
                        <div className="mb-2 flex items-start justify-between gap-x-1">
                          <div className="flex flex-col w-full">
                            <TextareaField
                              className="w-full resize-none"
                              name="content"
                              maxLength={MAX_NOTE_LENGTH}
                              onKeyUp={() =>
                                setEditNoteCharacterCount(
                                  formik.getFieldProps("content").value.length
                                )
                              }
                            ></TextareaField>
                            <Typography
                              variant="subtext"
                              className="flex justify-end mt-1 text-gray-400"
                            >{`${editNoteCharacterCount}/${MAX_NOTE_LENGTH}`}</Typography>
                          </div>
                          <div className="flex">
                            <IconButton
                              icon={<Icon type="check" />}
                              label="Spremi"
                              onClick={formik.handleSubmit}
                            />
                            <IconButton
                              icon={<Icon type="x" />}
                              label="Odbaci"
                              onClick={() => {
                                setCurrentlyEditingNoteId(0);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <div>
                    <div className="flex items-start justify-between">
                      <Typography className="break-word text-base my-auto">
                        {note.content}
                      </Typography>
                      <div className="flex p-1">
                        <IconButton
                          icon={
                            <Icon
                              type="pencil-simple"
                              variant="fill"
                              className="text-gray-500"
                            />
                          }
                          label="Uredi"
                          onClick={() => {
                            switchToUpdate(note.id);
                            setEditNoteCharacterCount(note.content.length);
                          }}
                        />
                        <IconButton
                          icon={
                            <Icon
                              type="trash"
                              variant="fill"
                              className="text-gray-500"
                            />
                          }
                          label="Izbriši"
                          onClick={() => {
                            openDeleteNoteModal(note.id);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-start space-x-4 mt-1">
                      <Typography
                        variant="subtitle"
                        className="mt-1 text-gray-500"
                        icon={<Icon type="calendar-blank" />}
                        iconPlacement="leading"
                      >
                        {new Date(note.date).toLocaleDateString("hr-HR")}
                      </Typography>
                    </div>
                    <DeleteEventNoteModal
                      setNotes={setNotes}
                      notes={notes}
                      eventId={eventId}
                      modal={modal}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        <Formik
          initialValues={saveNoteInitialValues}
          onSubmit={onSaveNote}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex flex-col ">
              <TextareaField
                placeholder="Upišite svoju bilješku..."
                name="content"
                className="mt-3 resize-none"
                maxLength={MAX_NOTE_LENGTH}
                onKeyUp={() =>
                  setNoteCharacterCount(
                    formik.getFieldProps("content").value.length
                  )
                }
              />
              <Typography
                variant="subtext"
                className="flex justify-end mt-1 text-gray-400"
              >{`${noteCharacterCount}/${MAX_NOTE_LENGTH}`}</Typography>
              <div className="flex justify-end space-x-4">
                <Button variant="filled" className="mt-2 " type="submit">
                  Dodaj
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}
