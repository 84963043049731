import { Notification } from "@croz/nrich-notification-core";

import { ErrorResponse } from "./api/ErrorResponse";

export default function handleResponse(
  response: Response,
  addNotification: (notification: Notification) => void,
  notificationBody: Notification
): boolean {
  if (response.ok) {
    addNotification(notificationBody);
    return true;
  } else if (response.status === 400) {
    response.json().then((body: ErrorResponse) => {
      addNotification({
        title: body.notification.title,
        content: body.notification.content,
        messageList: body.notification.messageList,
        severity: body.notification.severity,
        timestamp: new Date(body.notification.timestamp),
      });
    });
  }
  return false;
}
