import { PROGRESS_URL } from "../../../common/Routes";

export async function getAverageMonthlyProgressDataOfGroup(groupId: number) {
  const response = await fetch(`${PROGRESS_URL}/average/group/${groupId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
