import React, { useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { RadioGroup } from "@tiller-ds/form-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { useNavigate } from "react-router-dom";

import { DELETE_UPDATE_REPEATING_EVENT_OPTIONS } from "../../../common/constants";
import { deleteAllInstances, deleteEvent } from "../../api/deleteEvent";

type DeleteEventModalProps = {
  modal: UseModal;
  eventId: number;
  eventRepeating: boolean;
};

export default function DeleteEventModal({
  modal,
  eventId,
  eventRepeating,
}: DeleteEventModalProps) {
  const [deletingMode, setDeletingMode] = useState(
    DELETE_UPDATE_REPEATING_EVENT_OPTIONS[0]
  );
  const navigate = useNavigate();
  const { add: addNotification } = useNotifications();

  const onSuccessfulEventDeletion = (title: string, content: string) => {
    addNotification({
      title: title,
      content: content,
      messageList: [],
      severity: "INFO",
      timestamp: new Date(),
    });
    modal.onClose();
    navigate("/calendar");
  };

  const onDeleteEvent = () => {
    if (deletingMode === DELETE_UPDATE_REPEATING_EVENT_OPTIONS[0]) {
      deleteEvent(eventId).then((res) => {
        if (res.ok) {
          onSuccessfulEventDeletion(
            "Događaj je izbrisan",
            "Vaš događaj je uspješno izbrisan!"
          );
        }
      });
    } else if (deletingMode === "all_instances") {
      deleteAllInstances(eventId).then((res) => {
        if (res.ok) {
          onSuccessfulEventDeletion(
            "Događaji su izbrisani",
            "Vaši događaji su uspješno izbrisani!"
          );
        }
      });
    }
  };

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h3" variant="h4">
            Izbriši događaj
          </Typography>
        }
      >
        <RadioGroup
          name="deletingMode"
          onChange={(value) => {
            setDeletingMode(value as string);
          }}
          value={deletingMode}
          vertical={true}
          label={
            <Typography element="p" variant="subtext" className="pt-2">
              Jeste li sigurni da želite izbrisati događaj?
            </Typography>
          }
        >
          <RadioGroup.Item
            label="Izbriši samo ovaj događaj"
            value={DELETE_UPDATE_REPEATING_EVENT_OPTIONS[0]}
          />
          <RadioGroup.Item
            label="Izbriši sve instance ovog događaja"
            value={DELETE_UPDATE_REPEATING_EVENT_OPTIONS[1]}
            disabled={!eventRepeating}
          />
        </RadioGroup>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={onDeleteEvent}>
          Izbriši
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
