import * as yup from "yup";

import {
  MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH,
  MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH,
} from "../../common/constants";
import {
  maxCharacterCountMessage,
  maxDigitCountMessage,
  MIN_ONE_ATHLETE_MESSAGE,
  MIN_ONE_GROUP_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../common/YupConstants";

export const createInvoiceValidationSchema = {
  amount: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .max(
      MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH,
      maxDigitCountMessage(MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH)
    ),
  dueDays: yup.string().required(REQUIRED_FIELD_MESSAGE).nullable(),
  description: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .max(
      MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH,
      maxCharacterCountMessage(MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH)
    ),
};

export const createInvoiceForGroupsValidationSchema = yup.object().shape({
  ...createInvoiceValidationSchema,
  invoiceRecipientGroups: yup
    .array()
    .of(yup.object())
    .min(1, MIN_ONE_GROUP_MESSAGE)
    .required(MIN_ONE_GROUP_MESSAGE),
});

export const createInvoiceForAthletesValidationSchema = yup.object().shape({
  ...createInvoiceValidationSchema,
  invoiceRecipients: yup
    .array()
    .of(yup.object())
    .min(1, MIN_ONE_ATHLETE_MESSAGE)
    .required(MIN_ONE_ATHLETE_MESSAGE),
});
