import React from "react";

import { Pagination, Typography } from "@tiller-ds/core";

import SurveyDetails from "./Cards/SurveyDetails";
import { SurveyStatus } from "../../../common/constants";

export default function DraftSurveyList() {
  return (
    <>
      <div className="w-full bg-white mt-4">
        <div className="flex items-center w-full h-16 border-b px-4">
          <div className="w-1/2">
            <Typography
              element="h6"
              variant="text"
              className="text-xs font-biotif"
            >
              Naziv ankete
            </Typography>
          </div>
          <div className="flex w-1/2">
            <div className="flex justify-center w-full">
              <Typography
                element="h6"
                variant="text"
                className="text-xs font-biotif"
              >
                Status
              </Typography>
            </div>
            <div className="flex justify-center w-full">
              <Typography
                element="h6"
                variant="text"
                className="text-xs font-biotif"
              >
                Datum zatvaranja
              </Typography>
            </div>
            <div className="flex justify-center w-1/3">
              <Typography
                element="h6"
                variant="text"
                className="text-xs font-biotif"
              >
                Izbriši
              </Typography>
            </div>
          </div>
        </div>
        <SurveyDetails
          status={SurveyStatus.Inactive}
          surveyName={"Zadovoljstvo proteklom sezonom - kadeti"}
        />
        <SurveyDetails
          status={SurveyStatus.Inactive}
          surveyName={"Termin roditeljskog sastanka - juniori"}
        />
        <SurveyDetails
          status={SurveyStatus.Inactive}
          surveyName={"Nabavka nove opreme - kadeti"}
        />
        <SurveyDetails
          status={SurveyStatus.Inactive}
          surveyName={"Pojačani treninzi za natjecanje"}
        />
      </div>
      <div className="w-full flex justify-end mt-2">
        <Pagination
          onPageChange={() => {}}
          totalElements={12}
          pageNumber={3}
          pageSize={5}
        >
          {() => {
            return <span></span>;
          }}
        </Pagination>
      </div>
    </>
  );
}
