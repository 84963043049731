import { COACH_URL } from "../../../common/Routes";

export async function getCoachById(coachId?: number) {
  const response = await fetch(`${COACH_URL}/${coachId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
