import { NOTE_URL } from "../../../../common/Routes";
import { AthleteNoteResponse } from "../../api/AthleteNoteResponse";

export async function getNotesByAthleteId(
  athleteId: number
): Promise<AthleteNoteResponse[]> {
  const response = await fetch(`${NOTE_URL}/athletes/${athleteId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
