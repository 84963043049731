import { MessageResponse } from "./MessageResponse";
import { MESSAGE_URL } from "../../common/Routes";

export async function getMessageById(id: string): Promise<MessageResponse> {
  const response = await fetch(`${MESSAGE_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
