import React, { useEffect, useState } from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { Button, IconButton, Typography } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";
import { NumberInputField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import { MeasuringUnit } from "../../../../common/constants";
import {
  MUST_BE_INTEGER_MESSAGE,
  NUMBER_TOO_BIG_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../../common/YupConstants";
import { AthleteResponse } from "../../../athlete/api/AthleteResponse";
import { AthleteProgressDataRequest } from "../../api/AthleteProgressDataRequest";
import { deleteProgressTrackingData } from "../../api/deleteProgressTrackingData";
import { getProgressTypes } from "../../api/getProgressTypes";
import { postProgressRequest } from "../../api/postProgressRequest";
import { postSearchAthleteProgressData } from "../../api/postSearchAthleteProgressData";
import { ProgressTrackingDataResponse } from "../../api/ProgressTrackingDataResponse";
import { ProgressTypeResponse } from "../../api/ProgressTypeResponse";

type MeasurementProps = {
  groupId: number;
  coachId: number;
  athleteId: number;
  athlete: AthleteResponse;
};

type Form = {
  value: number;
  valueMinutes: number;
  valueSeconds: number;
  valueCentiseconds: number;
  date: string;
};

const initialFormValues: Form = {
  value: 0,
  valueMinutes: 0,
  valueSeconds: 0,
  valueCentiseconds: 0,
  date: "",
};

const maxDate = new Date();
const minDate = new Date();
minDate.setFullYear(2000, 1, 1);

const validationSchema = yup.object().shape({
  value: yup.number().required(REQUIRED_FIELD_MESSAGE),
  valueMinutes: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .integer(MUST_BE_INTEGER_MESSAGE)
    .max(165, NUMBER_TOO_BIG_MESSAGE),
  valueSeconds: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .integer(MUST_BE_INTEGER_MESSAGE)
    .max(59, NUMBER_TOO_BIG_MESSAGE),
  valueCentiseconds: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .integer(MUST_BE_INTEGER_MESSAGE),
});

const ProgressDataEntryModal = ({
  groupId,
  coachId,
  athleteId,
  athlete,
}: MeasurementProps) => {
  const { add: addNotification } = useNotifications();

  const [progressTypes, setProgressTypes] = useState(
    [] as ProgressTypeResponse[]
  );
  const [date, setDate] = useState(new Date() as Date | null);
  const [selectedProgressType, setSelectedProgressType] = useState(
    {} as ProgressTypeResponse | null
  );

  const [value, setValue] = useState(0);
  const [measuringUnit, setMeasuringUnit] = useState("");

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [centiseconds, setCentiseconds] = useState(0);

  const [canBeDeleted, setCanBeDeleted] = useState(false);

  const [progressTrackingDataId, setProgressTrackingDataId] = useState(0);

  const MAX_VISIBLE_ITEM_COUNT_DROPDOWN = 4;

  const [visibleItemCountDropdown, setVisibleItemCountDropdown] = useState(
    MAX_VISIBLE_ITEM_COUNT_DROPDOWN
  );

  useEffect(() => {
    if (progressTypes[0]) {
      setSelectedProgressType(progressTypes[0]);
      if (progressTypes.length < MAX_VISIBLE_ITEM_COUNT_DROPDOWN) {
        setVisibleItemCountDropdown(progressTypes.length);
      }
      setMeasuringUnit(
        getMeasuringUnitAbbreviation(progressTypes[0].measuringUnit)
      );
    }
  }, [progressTypes]);

  useEffect(() => {
    if (selectedProgressType) {
      postSearchAthleteProgressData({
        athleteId: athleteId,
        coachId: coachId,
        groupId: groupId,
        progressTypeId: selectedProgressType.id,
        date: date,
      } as AthleteProgressDataRequest).then(
        (response: ProgressTrackingDataResponse[]) => {
          if (response.length > 0) {
            const result = response[0].result;

            if (
              response[0].progressType.measuringUnit === MeasuringUnit.Second
            ) {
              setMinutes(Math.floor(result / 60));
              setSeconds(Math.floor(result % 60));
              setCentiseconds(Math.round((result - Math.floor(result)) * 100));
            } else {
              setValue(result);
            }

            setDate(date);
            setProgressTrackingDataId(response[0].id);
            setCanBeDeleted(true);
          } else {
            setValue(0);
            setMinutes(0);
            setSeconds(0);
            setCentiseconds(0);

            setCanBeDeleted(false);
          }
        }
      );
    }
  }, [athleteId, coachId, groupId, selectedProgressType, date]);

  const getMeasuringUnitAbbreviation = (measuringUnit: MeasuringUnit) => {
    switch (measuringUnit) {
      case MeasuringUnit.Centimeter:
        return "cm";
      case MeasuringUnit.Kilogram:
        return "kg";
      default:
        return "";
    }
  };

  const onSubmitMeasurement = () => {
    if (selectedProgressType) {
      const result =
        selectedProgressType.measuringUnit === MeasuringUnit.Second
          ? minutes * 60 + seconds + Number((centiseconds / 100).toFixed(2))
          : value;

      postProgressRequest({
        athleteId: athleteId,
        coachId: coachId,
        groupId: groupId,
        progressTypeId: selectedProgressType.id,
        date: date,
        result: result,
      }).then((_) => {
        // ignore
      });
      addNotification({
        title: "Unos statistike uspješan",
        content: "Statistički podatak je uspješno unesen.",
        messageList: [],
        severity: "INFO",
        timestamp: new Date(),
      });
      modal.onClose();
    }
  };

  const modal = useModal();

  return (
    <>
      <IconButton
        onClick={() => {
          modal.onOpen(() => {
            getProgressTypes().then((response: ProgressTypeResponse[]) => {
              setProgressTypes(response);
              setDate(new Date());
            });
          });
        }}
        icon={<Icon type="presentation-chart" className="text-gray-500" />}
        label="Statistika"
      />
      <Modal {...modal}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitMeasurement}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="p-2 flex flex-col"
              autoComplete="off"
            >
              <Typography
                className="mb-1 flex justify-start"
                variant="h4"
                element="h4"
              >
                Unesi mjerenje
              </Typography>
              <Typography
                className="flex justify-start mb-4"
                variant="subtitle"
                element="h4"
              >
                Sportaš - {athlete.firstName} {athlete.lastName}
              </Typography>
              <div className="flex flex-col justify-start gap-y-2">
                <DateInput
                  name="test"
                  className="wf-full gap-y-1"
                  label="Datum mjerenja:"
                  onChange={(value) => {
                    value?.setHours(12);
                    setDate(value);
                  }}
                  defaultValue={Date.now()}
                  value={date}
                  onBlur={() => {}}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <div className="flex flex-col justify-start gap-y-1">
                  <Typography className="flex justify-start title">
                    Odaberi statistiku
                  </Typography>
                  <DropdownMenu
                    title={
                      selectedProgressType
                        ? selectedProgressType.name
                        : "Nema statistike"
                    }
                    openExpanderIcon={<Icon type="caret-down" variant="bold" />}
                    closeExpanderIcon={
                      <Icon type="caret-down" variant="bold" />
                    }
                    color="primary"
                    visibleItemCount={visibleItemCountDropdown}
                  >
                    {progressTypes.map((progressType: ProgressTypeResponse) => {
                      return (
                        <DropdownMenu.Item
                          onSelect={() => {
                            setSelectedProgressType(progressType);
                            setMeasuringUnit(
                              getMeasuringUnitAbbreviation(
                                progressType.measuringUnit
                              )
                            );
                          }}
                        >
                          {progressType.name}
                        </DropdownMenu.Item>
                      );
                    })}
                  </DropdownMenu>
                </div>

                <div className="flex flex-col justify-start gap-y-1">
                  <Typography className="flex justify-start">
                    Vrijednost
                  </Typography>
                  <div className="flex justify-start">
                    {selectedProgressType ? (
                      selectedProgressType.measuringUnit ===
                      MeasuringUnit.Second ? (
                        <>
                          <NumberInputField
                            className="w-1/3"
                            name="valueMinutes"
                            inlineTrailingAddOn="min"
                            value={minutes}
                            allowNegative={false}
                            maxLength={3}
                            onChange={(value: any) => {
                              formik.setFieldValue("valueMinutes", value);
                              setMinutes(value);
                            }}
                          />
                          <NumberInputField
                            className="w-1/3 mx-1"
                            name="valueSeconds"
                            inlineTrailingAddOn="s"
                            value={seconds}
                            allowNegative={false}
                            maxLength={3}
                            onChange={(value: any) => {
                              formik.setFieldValue("valueSeconds", value);
                              setSeconds(value);
                            }}
                          />
                          <NumberInputField
                            className="w-1/3"
                            name="valueCentiseconds"
                            inlineTrailingAddOn="cs"
                            value={centiseconds}
                            allowNegative={false}
                            maxLength={3}
                            onChange={(value: any) => {
                              formik.setFieldValue("valueCentiseconds", value);
                              setCentiseconds(value);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <NumberInputField
                            className="w-1/3"
                            name="value"
                            inlineTrailingAddOn={measuringUnit}
                            value={value}
                            onChange={(value: any) => {
                              formik.setFieldValue("value", value);
                              setValue(value);
                            }}
                          />
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-6 pt-6 border-t">
                {canBeDeleted ? (
                  <Button
                    className="mr-2"
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      if (selectedProgressType) {
                        deleteProgressTrackingData(
                          progressTrackingDataId
                        ).then();
                        addNotification({
                          title: "Statistički podatak obrisan",
                          content: "Statistički podatak je uspješno obrisan.",
                          messageList: [],
                          severity: "INFO",
                          timestamp: new Date(),
                        });
                      }
                      setValue(0);
                      setMinutes(0);
                      setSeconds(0);
                      setCentiseconds(0);
                      setCanBeDeleted(false);
                      formik.resetForm();
                    }}
                  >
                    Izbriši
                  </Button>
                ) : (
                  <Button
                    className="mr-2"
                    variant="outlined"
                    type="button"
                    disabled
                  >
                    Izbriši
                  </Button>
                )}
                <Button variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ProgressDataEntryModal;
