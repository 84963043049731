import { MessageRecipientRequest } from "./MessageRecipientRequest";
import { MESSAGE_MARK_AS_DELETED } from "../../common/Routes";

export async function postMarkAsDeleted(request: MessageRecipientRequest) {
  await fetch(MESSAGE_MARK_AS_DELETED, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
