import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import {
  Badge,
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import classNames from "classnames";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { UserType } from "../../archive/common/api/UserResponse";
import GroupBadge from "../../archive/group/components/GroupBadge";
import { UserContext } from "../../auth/SecurityProvider";
import LoadingCircle from "../../common/components/LoadingCircle";
import AttendanceRecordModal from "../../common/components/modals/AttendanceRecordModal";
import { EventType } from "../../common/constants";
import { EventResponse } from "../api/EventResponse";
import { getEventWithParticipantsById } from "../api/getEventById";
import EventLeaderCard from "../components/cards/EventLeaderCard";
import EventLocationCard from "../components/cards/EventLocationCard";
import EventTimeCard from "../components/cards/EventTimeCard";
import Comments from "../components/Comments";
import DeleteEventModal from "../components/modals/DeleteEventModal";
import Notes from "../components/Notes";

type UserRoleButtonsProps = {
  deleteEventModal: UseModal<unknown>;
  event: EventResponse;
  isAdmin: boolean;
  isCoach: boolean;
};

export const typographyCardTokens = {
  variant: {
    h6: {
      color: "text-navy-500",
    },
  },
};

const UserRoleButtons = ({
  deleteEventModal,
  event,
  isAdmin,
  isCoach,
}: UserRoleButtonsProps) => {
  const navigate = useNavigate();
  const attendanceRecord = useModal();
  return (
    <div>
      <>
        {isAdmin ? (
          <div className="flex gap-x-2">
            <Button
              leadingIcon={<Icon type="trash" variant="fill" size={4} />}
              variant="outlined"
              size="sm"
              onClick={deleteEventModal.onOpen}
            >
              <div className="pt-0 hidden xl:flex">Izbriši događaj</div>
              <div className="flex xl:hidden">Izbriši</div>
            </Button>
            {moment(event.endTime).isSameOrAfter(
              moment(new Date(Date.now()))
            ) ? (
              <Button
                leadingIcon={
                  <Icon type="note-pencil" variant="bold" size={4} />
                }
                variant="outlined"
                size="sm"
                onClick={() => {
                  navigate(
                    `/calendar/event/update/${event.id}/${
                      event.isRepeating ? "repeating" : "non-repeating"
                    }`
                  );
                }}
              >
                <div className="pt-0 hidden xl:flex">Uredi događaj</div>
                <div className="flex xl:hidden">Uredi</div>
              </Button>
            ) : null}
          </div>
        ) : null}
      </>
      {isCoach ? (
        <div>
          <Button
            leadingIcon={<Icon type="users-three" />}
            id="open-button"
            size="sm"
            variant="outlined"
            onClick={attendanceRecord.onOpen}
            className="mr-0"
          >
            <div className="hidden md:block">Evidencija dolazaka</div>
          </Button>
          <AttendanceRecordModal events={[event]} modal={attendanceRecord} />
        </div>
      ) : null}
    </div>
  );
};

export default function EventDetailsPage() {
  const { userHasRole } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  const [event, setEvent] = useState({} as EventResponse);

  const navigate = useNavigate();

  const { id } = useParams();

  const deleteEventModal = useModal();

  const eventType = () => {
    switch (event.eventType) {
      case EventType.TRAINING:
        return "trening";
      case EventType.GENERAL:
        return "ostalo";
      case EventType.MEETING:
        return "sastanak";
      case EventType.COMPETITION:
        return "natjecanje";
    }
  };

  const isRepeating = () => {
    if (event.isRepeating) {
      return "Ponavljajući događaj";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (id) {
      getEventWithParticipantsById(id).then((response) => {
        setEvent(response);
        setIsLoading(false);
      });
    }
  }, [id]);

  const isSingleDayEvent =
    new Date(event.startTime).toDateString() ===
    new Date(event.endTime).toDateString();

  function eventDetailsClassnames(description: string) {
    return classNames({
      "pt-2 md:pb-0": true,
      italic: !description,
    });
  }

  const roleButtons = () => {
    return (
      <UserRoleButtons
        event={event}
        deleteEventModal={deleteEventModal}
        isAdmin={userHasRole(UserType.ADMIN)}
        isCoach={userHasRole(UserType.COACH)}
      />
    );
  };

  return (
    <LoadingCircle isLoading={isLoading}>
      <div className="flex flex-col px-4 md:px-0 md:my-6">
        <div className="flex flex-col gap-y-8">
          <div className="justify-between items-start hidden md:flex">
            <div className="flex md:flex-col w-2/3">
              <Typography
                element="h3"
                variant="h3"
                className="hidden md:inline font-biotiff"
              >
                {`${event.title} (${eventType()})`}
              </Typography>
              <div className="hidden md:block">
                <Breadcrumbs>
                  <Breadcrumbs.Breadcrumb>
                    <Link to="/calendar">Kalendar</Link>
                  </Breadcrumbs.Breadcrumb>
                  <Breadcrumbs.Breadcrumb>
                    Detaljni prikaz događaja
                  </Breadcrumbs.Breadcrumb>
                </Breadcrumbs>
              </div>
            </div>
            <div className="flex justify-end md:justify-between">
              {roleButtons()}
            </div>
          </div>
          <div className="flex flex-col md:hidden">
            <div className="flex flex-col gap-y-4">
              <div className="flex md:flex-col justify-between items-center">
                <IconButton
                  icon={<Icon type="caret-left" variant="bold" />}
                  className="md:hidden"
                  onClick={() => navigate("/calendar")}
                />
                <div className="justify-end md:justify-between md:flex">
                  {roleButtons()}
                </div>
              </div>
              <Typography
                element="h6"
                variant="h6"
                className="md:hidden font-biotiff"
              >
                {`${event.title} (${eventType()})`}
              </Typography>
            </div>
          </div>

          <div className="xl:flex">
            <div className="flex flex-col w-full xl:w-2/3">
              <div className="grid lg:grid-cols-3 gap-y-2 lg:gap-x-2 lg:mb-2">
                <EventLocationCard
                  locationName={event.locationName}
                  locationAddress={event.locationAddress}
                />
                <EventTimeCard
                  startDate={new Date(event.startTime)}
                  endDate={
                    isSingleDayEvent ? undefined : new Date(event.endTime)
                  }
                  startTime={event.startTime}
                  endTime={event.endTime}
                  repeatingEvent={isRepeating()}
                />
                <EventLeaderCard content={event.eventLeader} />
              </div>

              <div className="pb-4 lg:pb-2 pt-4 lg:pt-0">
                <Card isExpanded status="idle">
                  <Card.Header>
                    <Card.Header.Title>
                      <Typography element="h3" variant="h6">
                        Grupe
                      </Typography>
                    </Card.Header.Title>
                  </Card.Header>
                  <Card.Body removeSpacing={true}>
                    <div className="flex flex-wrap px-6 py-2">
                      {event.groups && event.groups.length > 0 ? (
                        event?.groups?.map((group) => {
                          return (
                            <div key={group.id} className="pr-2 pb-1">
                              <GroupBadge
                                groupName={group.groupName}
                                groupId={group.id}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <Typography
                          element="p"
                          className="pl-2"
                          variant="subtext"
                        >
                          <i> Događaj nije povezan s nijednom grupom</i>
                        </Typography>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="pb-4 lg:pb-2 pt-4 lg:pt-0">
                <Card isExpanded status="idle">
                  <Card.Header>
                    <Card.Header.Title>
                      <Typography element="h3" variant="h6">
                        Sudionici
                      </Typography>
                    </Card.Header.Title>
                  </Card.Header>
                  <Card.Body removeSpacing={true}>
                    <div className="flex flex-wrap px-6 py-2">
                      {event?.participants
                        ?.sort((participant1, participant2) =>
                          participant1.lastName.localeCompare(
                            participant2.lastName
                          )
                        )
                        .map((participant) => {
                          return (
                            <div key={participant.id} className="pr-2 pb-1">
                              <Badge variant="filled" color="secondary">
                                {`${participant.firstName} ${participant.lastName}`}
                              </Badge>
                            </div>
                          );
                        })}
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="h-min pb-0">
                <Card className="h-fit">
                  <Card.Header>
                    <Typography element="h3" variant="h6">
                      Detalji događaja
                    </Typography>
                  </Card.Header>
                  <Card.Body removeSpacing={true}>
                    <div className="px-6 pt-1 pb-2">
                      <Typography
                        element="p"
                        variant="subtext"
                        className={eventDetailsClassnames(event.description)}
                      >
                        {event.description
                          ? event.description
                          : "Detalji događaja nisu uneseni"}
                      </Typography>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="w-full py-4 lg:py-2">
                <Comments eventId={id}></Comments>
              </div>
            </div>
            <div className="xl:pl-3 w-full xl:w-1/3">
              <Notes eventId={id}></Notes>
            </div>
          </div>
          <DeleteEventModal
            modal={deleteEventModal}
            eventId={event.id}
            eventRepeating={event.parentEventId !== null}
          />
        </div>
      </div>
    </LoadingCircle>
  );
}
