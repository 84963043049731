import FileDownload from "js-file-download";

import { CoachSearchRequest } from "./CoachSearchRequest";
import { COACH_URL } from "../../../common/Routes";

export function exportCoachesExcel(request: CoachSearchRequest) {
  fetch(`${COACH_URL}/export/excel`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let date = new Date().toLocaleDateString();
      FileDownload(blob, `treneri_${date}.xlsx`);
    });
}
