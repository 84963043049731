import { EventCommentRequest } from "./EventCommentRequest";
import { EVENT_URL } from "../../../common/Routes";

export async function updateEventComment(
  request: EventCommentRequest,
  commentId: number,
  eventId: string
) {
  const response = await fetch(
    `${EVENT_URL}/${eventId}/comments/${commentId}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    }
  );
  return response.json();
}
