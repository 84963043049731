import { EventRequest } from "./EventRequest";
import { EVENT_URL, REPEATING_EVENT_URL } from "../../common/Routes";

export function updateEvent(id: string, event: EventRequest) {
  return fetch(`${EVENT_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  }).then((res) => {
    return res;
  });
}

export function updateRepeatingEvent(id: string, event: EventRequest) {
  return fetch(`${REPEATING_EVENT_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  }).then((res) => {
    return res;
  });
}
