import React, { useContext, useEffect, useState } from "react";

import { Card, IconButton, Pagination } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";

import { Link } from "react-router-dom";

import RemoveAthleteFromGroupModal from "./RemoveAthleteFromGroupModal";
import { UserContext } from "../../../auth/SecurityProvider";
import { MAX_ARCHIVE_ROWS } from "../../../common/constants";
import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import { AthleteSearchRequest } from "../../athlete/api/AthleteSearchRequest";
import { postSearchAthleteRequest } from "../../athlete/api/postSearchAthleteRequest";
import { UserType } from "../../common/api/UserResponse";
import NoAvailableData from "../../common/components/NoAvailableData";
import { ParentBriefResponse } from "../../parent/api/ParentBriefResponse";
import ProgressDataEntryModal from "../../statistics/components/modals/ProgressDataEntryModal";

type GroupAthleteProps = {
  groupId: number;
  loaded: boolean;
  groupName: string;
  render: boolean;
  setRender: (render: boolean) => void;
  entityCreated?: number;
  setEntityCreated?: (entityCreated: number) => void;
};

export default function GroupAthletesTable({
  groupId,
  loaded,
  groupName,
  render,
  setRender,
  entityCreated,
  setEntityCreated,
}: GroupAthleteProps) {
  const { userHasRole, user } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [athleteTableData, setAthleteTableData] = useState(
    [] as AthleteResponse[]
  );
  const [searchRequestJson, setSearchRequestJson] = useState({
    groupsId: groupId.toString(),
    pageSize: 10,
    pageNumber: 0,
    sortPropertyList: [
      {
        direction: "ASC",
        property: "lastName",
      },
      {
        direction: "ASC",
        property: "firstName",
      },
    ],
  } as AthleteSearchRequest);

  useEffect(() => {
    postSearchAthleteRequest(searchRequestJson).then((response: any) => {
      setAthleteTableData(response.content);
      setTotalElements(response.totalElements);
      setRender(false);
    });
  }, [searchRequestJson, setRender, render, setEntityCreated, entityCreated]);

  if (!loaded) {
    return <></>;
  } else {
    return (
      <Card className="h-full p-4">
        <Card.Header className="flex justify-between items-center text-lg font-bold p-1">
          Popis Sportaša
        </Card.Header>
        <Card.Body>
          {loaded && athleteTableData.length > 0 ? (
            <DataTable data={athleteTableData}>
              <DataTable.Column
                header="Ime Prezime"
                id="firstNameLastName"
                canSort={false}
              >
                {(item: AthleteResponse) =>
                  `${item.firstName} ${item.lastName}`
                }
              </DataTable.Column>
              <DataTable.Column
                header="Datum Rođenja"
                id="dateOfBirth"
                canSort={false}
              >
                {(item: AthleteResponse) =>
                  new Date(item.dateOfBirth).toLocaleDateString("hr-HR")
                }
              </DataTable.Column>
              <DataTable.Column
                header="Ime Roditelja"
                id="parents"
                canSort={false}
              >
                {(item: AthleteResponse) => {
                  return (
                    <>
                      {item.parents[0]
                        ? item.parents
                            .sort((a, b) =>
                              a.firstName.localeCompare(b.firstName)
                            )
                            .map((parent: ParentBriefResponse) => {
                              return (
                                <>
                                  <span className="mt-1">
                                    {`${parent.firstName} ${parent.lastName}`}
                                  </span>
                                  <br></br>
                                </>
                              );
                            })
                        : "-"}
                    </>
                  );
                }}
              </DataTable.Column>
              <DataTable.Column header="Akcije" id="actions" canSort={false}>
                {(item: AthleteResponse) => (
                  <div className="flex space-x-4">
                    {userHasRole(UserType.ADMIN) && (
                      <RemoveAthleteFromGroupModal
                        setRender={setRender}
                        groupName={groupName}
                        groupId={groupId}
                        athlete={item}
                      />
                    )}
                    {userHasRole(UserType.COACH) && (
                      <ProgressDataEntryModal
                        coachId={user.id}
                        groupId={groupId}
                        athleteId={item.id}
                        athlete={item}
                      />
                    )}
                    <Link to={`/archive/athletes/${item.id}`}>
                      <IconButton
                        icon={
                          <Icon type="file-search" className="text-gray-500" />
                        }
                        onClick={() => {}}
                        label="Detalji"
                      />
                    </Link>
                  </div>
                )}
              </DataTable.Column>
            </DataTable>
          ) : (
            <NoAvailableData message="Nema sportaša u ovoj grupi" />
          )}
        </Card.Body>

        {totalElements > MAX_ARCHIVE_ROWS && (
          <Card.Footer className="flex items-center justify-end">
            <Pagination
              onPageChange={(i: number) => {
                setPage(i);
                setSearchRequestJson((searchRequestJson) => ({
                  ...searchRequestJson,
                  pageNumber: i,
                }));
              }}
              pageNumber={page}
              pageSize={MAX_ARCHIVE_ROWS}
              totalElements={totalElements}
            >
              {() => null}
            </Pagination>
          </Card.Footer>
        )}
      </Card>
    );
  }
}
