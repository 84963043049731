import React, { useState } from "react";

import { Button, Card, Typography } from "@tiller-ds/core";
import { Textarea, Toggle } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import LinearAnswerOptionCreate from "./LinearAnswerOptionCreate";
import MultipleAnswerOptionCreate from "./MultipleAnswerOptionCreate";
import PersonalAnswerOption from "./PersonalAnswerOption";
import {
  MAX_SURVEY_DESCRIPTION_LENGTH,
  SurveyAnswerType,
} from "../../../../../common/constants";
import { Question } from "../../../pages/SurveyCreate";

export type SurveyQuestionCreateProps = {
  questionFieldName: string;
  setQuestions: (questions: Question[]) => void;
  questions: Question[];
};

export type Answer = {
  type: SurveyAnswerType;
  answerNumber: number;
  name: string;
  value: string;
  minRating?: number;
  maxRating?: number;
  minRatingLabel?: string;
  maxRatingLabel?: string;
};

export default function SurveyQuestionCreate({
  questionFieldName,
  setQuestions,
  questions,
}: SurveyQuestionCreateProps) {
  const [questionInput, setQuestionInput] = useState("");
  const [answers, setAnswers] = useState([] as Answer[]);
  const [answerType, setAnswerType] = useState(SurveyAnswerType.MultipleChoice);
  const [answerRequired, setAnswerRequired] = useState(false);

  const questionInputHandler = (e: React.FormEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    setQuestionInput(value);
  };

  const UpdateQuestion = () => {
    const updatedQuestions = [...questions].map((question) => {
      if (question.name === questionFieldName) {
        question.question = questionInput;
        question.answers = answers;
        question.required = answerRequired;
      }
      return question;
    });
    setQuestions(updatedQuestions);
  };

  const addNewAnswer = (type: SurveyAnswerType) => {
    if (type === SurveyAnswerType.MultipleChoice) {
      setAnswers([
        ...answers,
        {
          answerNumber: answers.length,
          type: SurveyAnswerType.MultipleChoice,
          name: answers.length.toString(),
          value: "",
        },
      ]);
    } else if (type === SurveyAnswerType.LinearScale) {
      setAnswers([
        ...answers,
        {
          answerNumber: answers.length,
          type: SurveyAnswerType.LinearScale,
          name: answers.length.toString(),
          value: "",
          minRating: 0,
          maxRating: 0,
        },
      ]);
    } else {
      setAnswers([
        ...answers,
        {
          answerNumber: answers.length,
          type: SurveyAnswerType.PersonalAnswer,
          name: answers.length.toString(),
          value: "",
        },
      ]);
    }
  };

  const toggleCheck = () => {
    setAnswerRequired((current) => !current);
  };

  return (
    <div onBlur={UpdateQuestion} tabIndex={0}>
      <Card className="w-full h-full mb-8">
        <Card.Body>
          <>
            <div>
              <DropdownMenu
                title={answerType}
                openExpanderIcon={<Icon type="caret-down" variant="bold" />}
                closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
              >
                <DropdownMenu.Item
                  onSelect={() => {
                    setAnswerType(SurveyAnswerType.MultipleChoice);
                    setAnswers([] as Answer[]);
                  }}
                >
                  Više odgovora
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onSelect={() => {
                    setAnswerType(SurveyAnswerType.SingleChoice);
                    setAnswers([] as Answer[]);
                  }}
                >
                  Jedan odgovor
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onSelect={() => {
                    setAnswerType(SurveyAnswerType.LinearScale);
                    setAnswers([] as Answer[]);
                    addNewAnswer(SurveyAnswerType.LinearScale);
                  }}
                >
                  Linearna skala
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onSelect={() => {
                    setAnswerType(SurveyAnswerType.PersonalAnswer);
                    setAnswers([] as Answer[]);
                  }}
                >
                  Osobni odgovor
                </DropdownMenu.Item>
              </DropdownMenu>
            </div>
            <div className="py-4">
              <Textarea
                name={questionFieldName}
                value={questionInput}
                label={`${parseInt(questionFieldName) + 1} . Pitanje`}
                textareaClassName="h-24"
                maxLength={MAX_SURVEY_DESCRIPTION_LENGTH}
                onChange={questionInputHandler}
                required
              />
              <Typography
                variant="subtext"
                className="flex justify-end mt-1 text-gray-400"
              >{`${
                questionInput.toString().length
              }/${MAX_SURVEY_DESCRIPTION_LENGTH}`}</Typography>
            </div>
            {(() => {
              if (answerType === SurveyAnswerType.MultipleChoice) {
                return (
                  <div className="space-y-4 py-4 w-full">
                    {answers.map((answer: Answer) => {
                      return (
                        <MultipleAnswerOptionCreate
                          key={answer.answerNumber}
                          fieldName={answer.name}
                          setAnswers={setAnswers}
                          answers={answers}
                          singleChoice={false}
                        />
                      );
                    })}
                    <Button
                      trailingIcon={<Icon type="plus" />}
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        addNewAnswer(SurveyAnswerType.MultipleChoice);
                      }}
                    >
                      Dodaj odgovor
                    </Button>
                  </div>
                );
              } else if (answerType === SurveyAnswerType.LinearScale) {
                return (
                  <LinearAnswerOptionCreate
                    addNewAnswer={addNewAnswer}
                    setAnswers={setAnswers}
                    answers={answers}
                  />
                );
              } else if (answerType === SurveyAnswerType.SingleChoice) {
                return (
                  <div className="space-y-4 py-4 w-full">
                    {answers.map((answer: Answer) => {
                      return (
                        <MultipleAnswerOptionCreate
                          key={answer.answerNumber}
                          fieldName={answer.name}
                          setAnswers={setAnswers}
                          answers={answers}
                          singleChoice={true}
                        />
                      );
                    })}
                    <Button
                      trailingIcon={<Icon type="plus" />}
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        addNewAnswer(SurveyAnswerType.SingleChoice);
                      }}
                    >
                      Dodaj odgovor
                    </Button>
                  </div>
                );
              } else {
                return (
                  <div className="py-4 mt-4 max-h-[23rem] w-full">
                    <PersonalAnswerOption addNewAnswer={addNewAnswer} />
                  </div>
                );
              }
            })()}
            <div className="w-full flex justify-end py-4 border-t">
              <div>
                <Toggle
                  checked={answerRequired}
                  onClick={() => {
                    toggleCheck();
                  }}
                  label={
                    <span>
                      <span className="text-sm leading-5 font-medium text-gray-900">
                        Obvezno
                      </span>
                    </span>
                  }
                />
              </div>
            </div>
          </>
        </Card.Body>
      </Card>
    </div>
  );
}
