import { AthleteDetailedResponse } from "./AthleteDetailedResponse";
import { ATHLETE_URL } from "../../../common/Routes";

export async function getAthleteById(
  athleteId: number
): Promise<AthleteDetailedResponse> {
  const response = await fetch(`${ATHLETE_URL}/${athleteId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
