import React from "react";

import { IconBGColor, IconFGColor } from "../../../common/constants";

type GroupBadgeProps = {
  groupName: string;
  groupId: number;
};

export default function GroupBadge({ groupName, groupId }: GroupBadgeProps) {
  return (
    <div
      className={`py-0.5 inline-flex rounded-full items-center text-base px-3 leading-5 shadow ${
        IconBGColor[Math.floor(groupId % 17)]
      }`}
    >
      <p className={IconFGColor[Math.floor(groupId % 17)]}>{groupName}</p>
    </div>
  );
}
