import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

import { deleteEventComment } from "../../api/eventCommentApi/deleteEventComment";
import { EventCommentResponse } from "../../api/eventCommentApi/EventCommentResponse";

type DeleteCommentModalProps = {
  eventId?: string;
  setComments: (comments: EventCommentResponse[]) => void;
  comments: EventCommentResponse[];
  modal: UseModal;
};

const DeleteCommentModal = ({
  setComments,
  comments,
  eventId,
  modal,
}: DeleteCommentModalProps) => {
  const commentId = modal.state as number;

  const onDeleteEventComment = () => {
    if (eventId) {
      deleteEventComment(commentId, eventId).then(() => {});
      setComments(
        comments.filter(
          (comment: EventCommentResponse) => comment.id !== commentId
        )
      );
      modal.onClose();
    }
  };

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h4" variant="h4">
            Izbriši komentar
          </Typography>
        }
      >
        <Typography element="p" variant="subtext" className="pt-2">
          Jeste li sigurni da želite izbrisati komentar?
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={onDeleteEventComment}>
          Izbriši
        </Button>
        <Button variant="outlined" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCommentModal;
