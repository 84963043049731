import { EventRequest } from "./EventRequest";
import { EVENT_URL } from "../../common/Routes";

export async function postEvent(event: EventRequest): Promise<Response> {
  return await fetch(EVENT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(event),
  });
}
