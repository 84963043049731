import { AttendanceBulkRequest } from "./AttendanceRequest";
import { EVENT_ATTENDANCES_URL } from "../Routes";

export async function putUpdateRecordRequest(request: AttendanceBulkRequest) {
  const response = await fetch(`${EVENT_ATTENDANCES_URL}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
