import { CreateAdminRequest } from "./CreateAdminRequest";
import { ADMIN_URL } from "../../../common/Routes";

export async function postCreateAdminRequest(
  request: CreateAdminRequest
): Promise<Response> {
  return await fetch(`${ADMIN_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
