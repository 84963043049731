import React, { useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Tabs, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { File, UploadButton, useFileUpload } from "@tiller-ds/upload";
import { Destination } from "@tiller-ds/upload/UploadyWrapper";

import {
  NotificationSeverity,
  useNotifications,
} from "@croz/nrich-notification-core";

import { useNavigate } from "react-router-dom";

import { getAccessTokenFromStorage } from "../../auth/bearerTokenInterceptor";
import { INVOICE_UPLOAD_BANK_STATEMENT } from "../../common/Routes";
import InvoiceViewPage from "../admin-invoice-page/pages/InvoiceViewPage";
import InvoiceTemplateList from "../template/components/InvoiceTemplateList";

export default function PaymentsView() {
  const [render, setRender] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const createInvoiceTemplateModal = useModal();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const { add: addNotification } = useNotifications();

  const fileUploadHook = useFileUpload<File>([], true);

  const authHeader = {
    Authorization: "Bearer " + getAccessTokenFromStorage(),
  } as Record<string, unknown>;

  const destinationOption = {
    headers: authHeader,
    filesParamName: "file",
  } as Destination;

  const handleOpeningCreateModal = () => {
    setOpenCreateModal(true);
    createInvoiceTemplateModal.onOpen(null);
  };

  const navigate = useNavigate();

  function handleFileUploadResponse(item: any) {
    let severity: NotificationSeverity = "INFO";
    let title: string = "Obavijest!";

    console.log(item.file);

    if (
      item.file.type !== "text/plain" ||
      item.file.size === 0 ||
      item.uploadResponse.data.startsWith(
        "U datoteci "
          .concat(item.file.name)
          .concat(
            " nisu pronađene transakcije koje odgovaraju nepodmirenim računima"
          )
      )
    ) {
      title = "Upozorenje!";
      severity = "ERROR";
    }

    return addNotification({
      title: title,
      content: item.uploadResponse.data,
      messageList: [],
      severity: severity,
      timestamp: new Date(),
      uxNotificationOptions: {
        timeout: 10000,
      },
    });
  }

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6">
      <div className="flex flex-col w-full gap-y-6">
        <div className="flex flex-col sm:flex-row justify-between">
          <Typography
            element="h3"
            variant="h3"
            className="hidden md:inline font-biotiff"
          >
            Plaćanja
          </Typography>
          <Typography
            element="h6"
            variant="h6"
            className="md:hidden font-biotiff"
          >
            Plaćanja
          </Typography>
          {selectedTab === 1 && (
            <Button
              className="w-full mt-10 sm:w-fit sm:mr-1 sm:mt-0"
              variant="outlined"
              size="sm"
              color={"primary"}
              leadingIcon={<Icon type="plus-circle" />}
              onClick={handleOpeningCreateModal}
            >
              <p className="text-sm ">Kreiraj predložak</p>
            </Button>
          )}
          {selectedTab === 0 && (
            <div className="flex flex-col gap-y-3 mt-10 sm:gap-y-0 sm:flex-row sm:mt-0">
              <Button
                className="w-full sm:w-fit sm:ml-auto"
                variant="outlined"
                size="sm"
                color={"primary"}
                leadingIcon={<Icon type="plus-circle" />}
                onClick={() => navigate("/payments/new")}
              >
                <p className="text-sm ">Kreiraj račun</p>
              </Button>
              <UploadButton
                className="w-full sm:w-fit sm:ml-2"
                variant="outlined"
                size="sm"
                color={"primary"}
                leadingIcon={<Icon type="download" />}
                hook={fileUploadHook}
                url={INVOICE_UPLOAD_BANK_STATEMENT}
                allowMultiple={false}
                destinationOptions={destinationOption}
                mapUploadResponse={(item) => {
                  handleFileUploadResponse(item);
                  setRender(true);
                  return {} as File;
                }}
              >
                <p className="text-sm ">Uvoz bankovnog izvatka</p>
              </UploadButton>
            </div>
          )}
        </div>
        <div className="bg-white shadow rounded-lg overflow-visible p-2">
          <Tabs defaultIndex={0}>
            <Tabs.Tab label={"Računi"} onClick={() => setSelectedTab(0)}>
              <InvoiceViewPage render={render} setRender={setRender} />
            </Tabs.Tab>

            <Tabs.Tab label={"Predlošci"} onClick={() => setSelectedTab(1)}>
              <InvoiceTemplateList
                openCreateModal={openCreateModal}
                handleOpeningCreateModal={handleOpeningCreateModal}
                createInvoiceTemplateModal={createInvoiceTemplateModal}
              />
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
