import React, { useEffect, useState } from "react";

import { Card, Tabs } from "@tiller-ds/core";

import AthleteChartSelector from "./AthleteChartSelector";
import GroupChartSelector from "./GroupChartSelector";
import LoadingCircle from "../../../common/components/LoadingCircle";
import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import { AthleteSearchRequest } from "../../athlete/api/AthleteSearchRequest";
import { postSearchAthleteRequestNonPaged } from "../../athlete/api/postSearchAthleteRequestNonPaged";
import NoAvailableData from "../../common/components/NoAvailableData";
import { GroupResponse } from "../../group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../group/api/postSearchGroupRequestNonPaged";
import { getProgressTypes } from "../../statistics/api/getProgressTypes";
import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";

type TabSelectorProps = {
  groupId: number;
  groupName: string;
  render: boolean;
};

export default function GroupDetailsStatisticSelector({
  groupId,
  groupName,
  render,
}: TabSelectorProps) {
  const [groupList, setGroupList] = useState([] as GroupResponse[]);
  const [athleteList, setAthleteList] = useState([] as AthleteResponse[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAthlete, setIsLoadingAthlete] = useState(true);
  const [selectedAthlete, setSelectedAthlete] = useState({} as AthleteResponse);
  const [progressTypeList, setProgressTypeList] = useState(
    [] as ProgressTypeResponse[]
  );

  useEffect(() => {
    if (groupId !== undefined && groupName !== undefined) {
      postSearchGroupRequestNonPaged({
        groupName: "",
        briefResponse: true,
      }).then((response: any) => {
        setGroupList(response);
        setIsLoading(false);
      });
      let athleteListSearchRequestJson = {
        groupsId: groupId.toString(),
        sortPropertyList: [
          {
            direction: "ASC",
            property: "firstName",
          },
          {
            direction: "ASC",
            property: "lastName",
          },
        ],
      } as AthleteSearchRequest;
      postSearchAthleteRequestNonPaged(athleteListSearchRequestJson).then(
        (response: any) => {
          setAthleteList(response);
          setSelectedAthlete(response[0]);
          setIsLoadingAthlete(false);
        }
      );
      getProgressTypes().then((response: ProgressTypeResponse[]) => {
        setProgressTypeList(response);
      });
    }
  }, [groupId, groupName, render]);

  return (
    <LoadingCircle isLoading={isLoading || isLoadingAthlete}>
      <div className="w-full">
        <Tabs defaultIndex={0} fullWidth>
          <Tabs.Tab label="Statistika Sportaša">
            <div className="w-full mt-4">
              <Card className="p-4">
                {athleteList.length > 0 || progressTypeList.length > 0 ? (
                  <AthleteChartSelector
                    athlete={selectedAthlete}
                    progressTypeList={progressTypeList}
                    groupList={groupList}
                    athleteList={athleteList}
                  />
                ) : (
                  <NoAvailableData message="Grupa ne sadržava sportaše i njihovu statistiku" />
                )}
              </Card>
            </div>
          </Tabs.Tab>
          <Tabs.Tab label="Statistika Grupe">
            <div className="w-full mt-4">
              <Card className="p-4">
                {progressTypeList.length > 0 ? (
                  <GroupChartSelector
                    groupId={groupId}
                    groupName={groupName}
                    progressTypeList={progressTypeList}
                    groupList={groupList}
                  />
                ) : (
                  <NoAvailableData message="Ne postoji statistika grupe" />
                )}
              </Card>
            </div>
          </Tabs.Tab>
        </Tabs>
      </div>
    </LoadingCircle>
  );
}
