import React from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import {
  AutocompleteField,
  InputField,
  NumberInputField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  GROUP_MIN_AGE,
  MAX_GROUP_NAME_LENGTH,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
  MIN_GROUP_NAME_LENGTH,
} from "../../../common/constants";
import {
  GROUP_MIN_AGE_MESSAGE,
  MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE,
  maxCharacterCountMessage,
  MIN_AGE_LESS_THAN_MAX_AGE_MESSAGE,
  minCharacterCountMessage,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import { postSearchAthleteRequestNonPaged } from "../../athlete/api/postSearchAthleteRequestNonPaged";
import { postFilterUsers } from "../../common/api/postFilterUsers";
import { UserResponse, UserType } from "../../common/api/UserResponse";
import { CreateGroupRequest } from "../api/CreateGroupRequest";
import { postCreateGroupRequest } from "../api/postCreateGroupRequest";

type CreateGroupModalProps = {
  entityCreated: number;
  setEntityCreated: (entityCreated: number) => void;
};

type Form = {
  name: string;
  minAge: number | "";
  maxAge: number | "";
  coaches: UserResponse[];
  athletes: AthleteResponse[];
};

const initialValues = {
  name: "",
  minAge: "",
  maxAge: "",
  coaches: [] as UserResponse[],
  athletes: [] as AthleteResponse[],
} as Form;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_GROUP_NAME_LENGTH, minCharacterCountMessage(MIN_GROUP_NAME_LENGTH))
    .max(
      MAX_GROUP_NAME_LENGTH,
      maxCharacterCountMessage(MAX_GROUP_NAME_LENGTH)
    ),
  minAge: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(GROUP_MIN_AGE, GROUP_MIN_AGE_MESSAGE)
    .test("minAgeTest", MIN_AGE_LESS_THAN_MAX_AGE_MESSAGE, function (
      input: any
    ) {
      if (isNaN(this.parent.maxAge)) {
        return true;
      } else {
        return input < this.parent.maxAge;
      }
    }),
  maxAge: yup
    .number()
    .required(REQUIRED_FIELD_MESSAGE)
    .test("maxAgeTest", MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE, function (
      input: any
    ) {
      if (isNaN(this.parent.minAge)) {
        return true;
      } else {
        return input > this.parent.minAge;
      }
    }),
});

export default function CreateGroupModal({
  entityCreated,
  setEntityCreated,
}: CreateGroupModalProps) {
  const modal = useModal();
  const { add: addNotification } = useNotifications();

  function athleteSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as AthleteResponse[]);
    else {
      const [firstName, lastName] = query.split(" ");
      if (query.split(" ").length > 1) {
        return postSearchAthleteRequestNonPaged({
          firstName: firstName,
          lastName: lastName,
        });
      } else
        return postSearchAthleteRequestNonPaged({
          firstName: query,
          lastName: query,
        });
    }
  }

  function coachSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as UserResponse[]);
    else
      return postFilterUsers({
        name: query,
        userType: [UserType.COACH],
      });
  }

  const onSubmitCreateGroup = (form: Form) => {
    postCreateGroupRequest({
      groupName: form.name,
      minAge: form.minAge,
      maxAge: form.maxAge,
      coachIds: form.coaches.map((coach: UserResponse) => {
        return coach.id;
      }),
      athleteIds: form.athletes.map((athlete: AthleteResponse) => {
        return athlete.id;
      }),
    } as CreateGroupRequest).then((response) => {
      if (response) {
        setEntityCreated(entityCreated + 1);
        addNotification({
          title: "Kreiranje grupe uspješno.",
          content: "Grupa je uspješno kreirana.",
          messageList: [],
          severity: "INFO",
          timestamp: new Date(),
        });
        modal.onClose();
      }
    });
  };

  return (
    <>
      <Button
        className="w-fit mr-1"
        variant="outlined"
        size="sm"
        color={"primary"}
        leadingIcon={<Icon type="users-three" variant="fill" size={4} />}
        onClick={() => {
          modal.onOpen(modal);
        }}
      >
        <p className="text-sm ">Kreiraj grupu</p>
      </Button>

      <Modal {...modal}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitCreateGroup}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="pr-3"
              autoComplete="off"
            >
              <Typography element="h3" variant="h5" className="mb-6">
                Kreiranje grupe
              </Typography>
              <div className="flex flex-col space-y-4 mb-6">
                <InputField name="name" label={"Ime grupe"} required={true} />
                <div className="flex flex-row mt-3 justify-between">
                  <NumberInputField
                    name="minAge"
                    label={"Minimalna dob"}
                    className="mr-3 w-1/2"
                    required={true}
                    maxLength={2}
                    allowNegative={false}
                  />
                  <NumberInputField
                    name="maxAge"
                    label={"Maksimalna dob"}
                    className="ml-3 w-1/2"
                    required={true}
                    maxLength={2}
                    allowNegative={false}
                  />
                </div>
                <AutocompleteField
                  className="w-full mt-3"
                  name="coaches"
                  label={"Trener/i grupe"}
                  options={coachSearch}
                  placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
                  allowMultiple
                  getOptionLabel={(user) =>
                    `${user.firstName} ${user.lastName}`
                  }
                  getOptionValue={(item) => {
                    return item.id;
                  }}
                  getMultipleSelectedLabel={(items) => {
                    return items
                      .map(
                        (user: UserResponse) =>
                          `${user.firstName} ${user.lastName}`
                      )
                      .join(", ");
                  }}
                  sendOptionValue={false}
                />
                <AutocompleteField
                  className="w-full mt-3"
                  name="athletes"
                  label={"Dodaj sportaše u grupu"}
                  options={athleteSearch}
                  placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
                  allowMultiple
                  getOptionLabel={(athlete) => (
                    <div className="flex items-center justify-between flex-wrap">
                      <div>
                        {athlete.firstName} {athlete.lastName}
                      </div>
                      <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
                        {new Date(athlete.dateOfBirth).toLocaleDateString(
                          "hr-HR"
                        )}
                      </div>
                    </div>
                  )}
                  getOptionValue={(item) => {
                    return item.id;
                  }}
                  getMultipleSelectedLabel={(items) => {
                    return items
                      .map(
                        (athlete) => `${athlete.firstName} ${athlete.lastName}`
                      )
                      .join(", ");
                  }}
                  sendOptionValue={false}
                />
              </div>
              <Modal.Footer>
                <Button variant="filled" type="submit">
                  Kreiraj grupu
                </Button>
                <Button
                  type="button"
                  className="mr-2"
                  variant="outlined"
                  onClick={() => {
                    modal.onClose();
                  }}
                >
                  Odustani
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
