import React, { useEffect, useState } from "react";

import { Card, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { exportTrendReportsExcel } from "../../api/exportTrendReportsExcel";
import { exportTrendReportsPDF } from "../../api/exportTrendReportsPDF";
import { getTrendReports } from "../../api/getTrendReports";
import { TrendReportResponse } from "../../api/TrendReportResponse";

type TrendReportWrapper = {
  month: string;
  trend: TrendReportResponse;
};

const date = new Date();

const years: number[] = [];
for (let i = 2023; i > 1999; i--) {
  years.push(i);
}

export const ENGLISH_MONTH_TO_CROATIAN_MONTH: { [key: string]: string } = {
  JANUARY: "Siječanj",
  FEBRUARY: "Veljača",
  MARCH: "Ožujak",
  APRIL: "Travanj",
  MAY: "Svibanj",
  JUNE: "Lipajnj",
  JULY: "Srpanj",
  AUGUST: "Kolovoz",
  SEPTEMBER: "Rujan",
  OCTOBER: "Listopad",
  NOVEMBER: "Studeni",
  DECEMBER: "Prosinac",
};

export default function Trend() {
  const [trends, setTrends] = useState<TrendReportWrapper[]>();
  const [selectedYear, setSelectedYear] = useState<number>(date.getFullYear());

  useEffect(() => {
    getTrendReports({ year: selectedYear }).then((response) => {
      Object.keys(response).map((key) => {
        return {
          month: key,
          trend: response[key],
        } as TrendReportWrapper;
      });
      setTrends(
        Object.keys(response).map((key) => {
          return {
            month: key,
            trend: response[key],
          } as TrendReportWrapper;
        })
      );
    });
  }, [selectedYear]);

  const getTrendColorClass = (trendPercent: number): string => {
    if (trendPercent > 0) {
      return "text-green-600";
    } else if (trendPercent === 0) {
      return "text-yellow-600";
    } else {
      return "text-red-600";
    }
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-1">
        <Typography variant="h4" element="h4">
          Broj upisanih sportaša
        </Typography>
        <Typography variant="text" element="p" className="text-gray-600">
          Tablični prikaz podataka o upisu i ispisu sportaša u odabranom periodu
        </Typography>
      </div>
      <Card className="flex flex-col gap-y-3">
        <Card.Body>
          <div className="flex flex-row justify-between pb-3 border-b mb-3">
            <DropdownMenu
              title={selectedYear + "."}
              color="primary"
              visibleItemCount={5}
            >
              {years.map((year) => (
                <DropdownMenu.Item onSelect={() => setSelectedYear(year)}>
                  {year}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu>
            <DropdownMenu
              title={<span className="hidden sm:inline-block">Preuzmi</span>}
              color="primary"
              openExpanderIcon={<Icon type="download-simple" />}
              closeExpanderIcon={<Icon type="download-simple" />}
              iconPlacement="leading"
              className="pr-2 sm:pr-4"
            >
              <DropdownMenu.Item
                onSelect={() => exportTrendReportsExcel({ year: selectedYear })}
              >
                .xlsx
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onSelect={() => exportTrendReportsPDF({ year: selectedYear })}
              >
                .pdf
              </DropdownMenu.Item>
            </DropdownMenu>
          </div>
          <div className="flex flex-col gap-y-10">
            {trends !== undefined && (
              <DataTable data={trends} className="border">
                <DataTable.Column header="MJESEC" id="month" canSort={false}>
                  {(item: TrendReportWrapper) =>
                    ENGLISH_MONTH_TO_CROATIAN_MONTH[item.month]
                  }
                </DataTable.Column>
                <DataTable.Column
                  header="BROJ UPISANIH"
                  id="enrollmentCount"
                  canSort={false}
                >
                  {(item: TrendReportWrapper) => {
                    return item.trend.numOfNewMemberships;
                  }}
                </DataTable.Column>
                <DataTable.Column
                  header="BROJ ISPISANIH"
                  id="canceledEnrollmentCount"
                  canSort={false}
                >
                  {(item: TrendReportWrapper) =>
                    item.trend.numOfCanceledMemberships
                  }
                </DataTable.Column>
                <DataTable.Column header="STANJE" id="state" canSort={false}>
                  {(item: TrendReportWrapper) => item.trend.numOfTotalMembers}
                </DataTable.Column>
                <DataTable.Column header="TREND" id="trend" canSort={false}>
                  {(item: TrendReportWrapper) => (
                    <span
                      className={getTrendColorClass(item.trend.trendPercentage)}
                    >
                      {`${Math.round(item.trend.trendPercentage * 100) / 100}%`}
                    </span>
                  )}
                </DataTable.Column>
              </DataTable>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
