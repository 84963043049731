import React, { useState } from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { Button, IconButton, Tooltip, Typography } from "@tiller-ds/core";
import {
  CheckboxField,
  InputField,
  RadioGroupField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  BestResultSorting,
  MAX_TYPE_NAME_LENGTH,
  MIN_TYPE_NAME_LENGTH,
} from "../../../../common/constants";
import {
  maxCharacterCountMessage,
  minCharacterCountMessage,
  PARAMETER_NAME_ALREADY_USED_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../../common/YupConstants";
import { ProgressTypeResponse } from "../../api/ProgressTypeResponse";
import { updateProgressTypeRequest } from "../../api/updateProgressTypeRequest";
import { UpdateProgressTypeResponse } from "../../api/UpdateProgressTypeResponse";

type Form = {
  typeName: string;
  isAscending: BestResultSorting;
  displayInPersonalRecords: boolean;
};

type EditProgressTypeModalProps = {
  setRender: (render: any) => void;
  progressTypeTableData: ProgressTypeResponse[];
  progressParameter: ProgressTypeResponse;
};

const EditProgressTypeModal = ({
  setRender,
  progressTypeTableData,
  progressParameter,
}: EditProgressTypeModalProps) => {
  const initialFormValues: Form = {
    typeName: progressParameter.name,
    isAscending: progressParameter.bestResultSorting,
    displayInPersonalRecords: progressParameter.displayInPersonalRecords,
  };

  const parameterNames = progressTypeTableData
    .map((parameter: ProgressTypeResponse) => parameter.name)
    .filter((name: string) => name !== progressParameter.name);
  const validationSchema = yup.object().shape({
    typeName: yup
      .string()
      .min(MIN_TYPE_NAME_LENGTH, minCharacterCountMessage(MIN_TYPE_NAME_LENGTH))
      .max(MAX_TYPE_NAME_LENGTH, maxCharacterCountMessage(MAX_TYPE_NAME_LENGTH))
      .required(REQUIRED_FIELD_MESSAGE)
      .nullable()
      .notOneOf(parameterNames, PARAMETER_NAME_ALREADY_USED_MESSAGE),
  });

  const { add: addNotification } = useNotifications();

  const onSubmitMeasurement = (form: Form) => {
    updateProgressTypeRequest(
      {
        name: form.typeName,
        displayInPersonalRecords: showBestResultSorting,
        measuringUnit: progressParameter.measuringUnit,
        bestResultSorting: form.isAscending,
      } as UpdateProgressTypeResponse,
      progressParameter.id
    ).then((_) => {
      setRender(true);
      addNotification({
        title: "Uređivanje spremljeno",
        content: "Uređivanje tipa parametra uspješno",
        messageList: [],
        severity: "INFO",
        timestamp: new Date(),
      });
      modal.onClose();
    });
  };

  const [showBestResultSorting, setShowBestResultSorting] = useState(
    initialFormValues.displayInPersonalRecords
  );

  const modal = useModal();

  return (
    <>
      <IconButton
        icon={
          <Icon
            type="note-pencil"
            variant="bold"
            className="mr-4 cursor-pointer text-gray-500"
            size={4}
          />
        }
        onClick={modal.onOpen}
        label="Uredi tip parametra"
      />
      <Modal {...modal}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitMeasurement}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="p-2 flex flex-col">
              <Typography
                className="my-2 flex justify-start"
                variant="h4"
                element="h4"
              >
                Uređivanje mjernog parametra
              </Typography>
              <div className="flex flex-col space-y-4 my-4">
                <InputField
                  name="typeName"
                  label="Ime mjernog parametra"
                  allowClear
                  required
                />
              </div>
              <div className="flex flex-col my-2 pb-2">
                <Typography variant="text" className="mb-4">
                  Prikaži u sekciji osobnih rekorda?
                </Typography>
                <CheckboxField
                  name="displayInPersonalRecords"
                  label="Prikaži"
                  onChange={() => {
                    setShowBestResultSorting(
                      (showBestResultSorting: boolean) => !showBestResultSorting
                    );
                  }}
                  checked={showBestResultSorting}
                />
              </div>
              <div
                className={`my-2 pb-4 ${!showBestResultSorting && "hidden"}`}
              >
                <RadioGroupField
                  name="isAscending"
                  label={
                    <div className="flex">
                      <p>Najbolji rezultat je onaj koji je vrijednosno</p>
                      <Tooltip
                        label={
                          <div>
                            Ako je kod mjerenja parametra cilj postići što niži
                            <br />
                            rezultat (npr. plivanje na 50m) tada označite
                            Najmanji, <br />
                            a ako je cilj postići što viši rezultat (npr. <br />
                            zadržavanje daha) tada označite Najveći. <br />
                          </div>
                        }
                      >
                        <Icon type="info" />
                      </Tooltip>
                    </div>
                  }
                >
                  <RadioGroupField.Item
                    label="Najveći"
                    value={BestResultSorting.Desc}
                    disabled={!showBestResultSorting}
                  />
                  <RadioGroupField.Item
                    label="Najmanji"
                    value={BestResultSorting.Asc}
                    disabled={!showBestResultSorting}
                  />
                </RadioGroupField>
              </div>
              <div className="w-full flex justify-end space-x-4 pt-4 border-t">
                <Button variant="outlined" onClick={modal.onClose}>
                  Odustani
                </Button>
                <Button variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default EditProgressTypeModal;
