import { PROGRESS_URL } from "../../../common/Routes";

export async function deleteProgressTrackingData(
  progressTrackingDataId: number
) {
  const response = await fetch(`${PROGRESS_URL}/${progressTrackingDataId}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
