import { AthleteGroupCountInfoResponse } from "./AthleteGroupCountInfoResponse";
import { GROUP_URL } from "../../common/Routes";

export async function getAthleteGroupCountInfo(): Promise<
  AthleteGroupCountInfoResponse
> {
  const response = await fetch(`${GROUP_URL}/info`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
