import { GROUP_URL } from "../../../common/Routes";

export async function getGroupDetails(id: string | undefined) {
  const response = await fetch(`${GROUP_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
