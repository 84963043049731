import React from "react";

import { Typography } from "@tiller-ds/core";

import MessageImportantNotice from "./MessageImportantNotice";
import MessageSentNotice from "./MessageSentNotice";
import UserIcon from "../../archive/common/components/UserIcon";
import { Message } from "../../messages/api/Message";

type BasicCardProps = {
  message: Message;
};

export default function RoundedImage({ message }: BasicCardProps) {
  return (
    <div className="flex items-center gap-x-2 w-full mb-1">
      <div className="flex-nowrap md:hidden">
        <UserIcon
          initials={
            message.senderFirstName.charAt(0).toUpperCase() +
            message.senderLastName.charAt(0).toUpperCase()
          }
          entityId={message.senderId}
          width="36px"
          height="36px"
          textClassName="text-lg"
        />
      </div>
      <div className="hidden md:flex flex-nowrap">
        <UserIcon
          initials={
            message.senderFirstName.charAt(0).toUpperCase() +
            message.senderLastName.charAt(0).toUpperCase()
          }
          entityId={message.senderId}
          width="40px"
          height="40px"
          textClassName="text-lg"
        />
      </div>
      <div className="flex-col lg:flex w-full">
        <Typography
          className="truncate lg:overflow-auto lg:whitespace-normal"
          element="p"
        >
          {message.isRead ? (
            `${message.senderFirstName} ${message.senderLastName}`
          ) : (
            <b>
              {message.senderFirstName} {message.senderLastName}
            </b>
          )}
        </Typography>
        <Typography
          className="lg:hidden w-full truncate"
          element="p"
          variant="text"
        >
          {message.isSent && <MessageSentNotice />}
          {message.isImportant && <MessageImportantNotice />}
          {message.isRead ? message.title : <b>{message.title}</b>}
        </Typography>
      </div>
    </div>
  );
}
