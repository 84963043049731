import React from "react";

import { IconBGColor, IconFGColor } from "../../../common/constants";

type UserIconProps = {
  initials: string;
  entityId: number;
  width: string;
  height: string;
  textClassName: string;
};

export default function UserIcon({
  initials,
  entityId,
  width,
  height,
  textClassName,
}: UserIconProps) {
  return (
    <div
      className={`shadow-md rounded-full flex justify-center items-center ${
        IconBGColor[Math.floor(entityId % 17)]
      }`}
      style={{
        width: width,
        height: height,
      }}
    >
      <span className={IconFGColor[Math.floor(entityId % 17)]}>
        <p className={`mt-1 ${textClassName}`}>{initials}</p>
      </span>
    </div>
  );
}
