import { AdminSearchRequest } from "./AdminSearchRequest";
import { ADMIN_URL } from "../../../common/Routes";

export async function postSearchAdminRequest(request: AdminSearchRequest) {
  const response = await fetch(`${ADMIN_URL}/search/pageable`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
