import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { InputField } from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
  PHONE_NUMBER_REGEX,
} from "../../../common/constants";
import {
  DIGITS_ONLY_MESSAGE,
  INVALID_EMAIL_FORMAT_MESSAGE,
  maxCharacterCountMessage,
  maxDigitCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import handleUpdateResponse from "../../common/handleUpdateResponse";
import { ParentResponse } from "../api/ParentResponse";
import { putUpdateParentRequest } from "../api/putUpdateParentRequest";
import { UpdateParentRequest } from "../api/UpdateParentRequest";

type UpdateParentModalProps = {
  modal: UseModal;
  parent: ParentResponse;
  setRender: (render: boolean) => void;
};

type Form = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE),
  phoneNumber: yup
    .string()
    .nullable()
    .max(MAX_PHONE_NUMBER_LENGTH, maxDigitCountMessage(MAX_PHONE_NUMBER_LENGTH))
    .matches(new RegExp(PHONE_NUMBER_REGEX), DIGITS_ONLY_MESSAGE),
});

const UpdateParentModal = ({
  modal,
  parent,
  setRender,
}: UpdateParentModalProps) => {
  const { add: addNotification } = useNotifications();

  const initialFormValues = {
    firstName: parent.firstName,
    lastName: parent.lastName,
    phoneNumber: parent.phoneNumber,
    email: parent.email,
  } as Form;

  const onSubmitForm = (form: Form) => {
    putUpdateParentRequest(
      {
        firstName: form.firstName,
        lastName: form.lastName,
        phoneNumber: form.phoneNumber ? form.phoneNumber : null,
        email: form.email,
      } as UpdateParentRequest,
      parent.id
    ).then((response) => {
      if (handleUpdateResponse(response, addNotification)) {
        setRender(true);
        modal.onClose();
      }
    });
  };

  return (
    <>
      <Modal {...modal}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="pr-3"
              autoComplete="off"
            >
              <Typography className="mb-4" variant="h4" element="h4">
                Uredi podatke roditelja
              </Typography>
              <div className="flex">
                <InputField
                  name="firstName"
                  label="Ime"
                  className="mr-3 w-1/2"
                  allowClear
                  required
                  placeholder={parent.firstName}
                />
                <InputField
                  name="lastName"
                  label="Prezime"
                  className="ml-3 w-1/2"
                  allowClear
                  required
                  placeholder={parent.lastName}
                />
              </div>
              <InputField
                name="email"
                label="Email"
                className="mt-3"
                allowClear
                required
                placeholder={parent.email}
              />
              <InputField
                name="phoneNumber"
                label="Broj mobitela"
                className="mt-3"
                allowClear
              />
              <div className="mt-6 flex flex-row justify-end">
                <Button
                  type="button"
                  className="mr-2 w-100"
                  variant="outlined"
                  onClick={() => {
                    modal.onClose();
                  }}
                >
                  Odbaci promjene
                </Button>
                <Button className="w-1/5" variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateParentModal;
