import * as yup from "yup";

import {
  DECIMAL_NUMBER_REGEX,
  MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH,
  MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH,
  MAX_INVOICE_TEMPLATE_TITLE_LENGTH,
  NUMBER_REGEX,
} from "../../../common/constants";
import {
  DECIMAL_OR_NUMBER_MESSAGE,
  maxCharacterCountMessage,
  maxDigitCountMessage,
  ONLY_NUMBER_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";

export const invoiceTemplateValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .max(
      MAX_INVOICE_TEMPLATE_TITLE_LENGTH,
      maxCharacterCountMessage(MAX_INVOICE_TEMPLATE_TITLE_LENGTH)
    )
    .nullable(),
  amount: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .matches(RegExp(DECIMAL_NUMBER_REGEX), DECIMAL_OR_NUMBER_MESSAGE)
    .max(
      MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH,
      maxDigitCountMessage(MAX_INVOICE_TEMPLATE_AMOUNT_LENGTH)
    )
    .nullable(),
  dueDays: yup
    .string()
    .nullable()
    .matches(RegExp(NUMBER_REGEX), ONLY_NUMBER_MESSAGE),
  description: yup
    .string()
    .nullable()
    .max(
      MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH,
      maxCharacterCountMessage(MAX_INVOICE_TEMPLATE_DESCRIPTION_LENGTH)
    ),
  invoiceRecipients: yup.array().nullable(),
  invoiceRecipientGroups: yup.array().nullable(),
});
