import { Notification } from "@croz/nrich-notification-core";

import handleResponse from "../../common/handleResponse";

export default function handleUpdateResponse(
  response: Response,
  addNotification: (notification: Notification) => void,
  notificationBody = {
    title: "Podaci su izmijenjeni.",
    content: "Vaša promjena je uspješno unesena i podaci su izmijenjeni",
    messageList: [],
    severity: "INFO",
    timestamp: new Date(),
  } as Notification
): boolean {
  return handleResponse(response, addNotification, notificationBody);
}
