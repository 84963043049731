import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Card, IconButton, Pagination } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";

import CreateOrUpdateInvoiceTemplateModal from "./CreateOrUpdateInvoiceTemplateModal";
import DeleteInvoiceTemplateModal from "./DeleteInvoiceTemplateModal";
import NoAvailableData from "../../../archive/common/components/NoAvailableData";
import LoadingCircle from "../../../common/components/LoadingCircle";
import {
  INVOICE_RECIPIENT_GROUP,
  INVOICE_RECIPIENTS,
} from "../../../common/constants";
import { getAllInvoiceTemplate } from "../api/getAllInvoiceTemplate";
import { getInvoiceTemplateById } from "../api/getInvoiceTemplateById";
import { InvoiceTemplateResponse } from "../api/InvoiceTemplateResponse";

type InvoiceTemplateListProps = {
  openCreateModal: boolean;
  handleOpeningCreateModal: () => void;
  createInvoiceTemplateModal: UseModal<unknown>;
};

export default function InvoiceTemplateList(
  invoiceTemplateListProps: InvoiceTemplateListProps
) {
  const MAX_INVOICE_TEMPLATES_PER_PAGE = 10;

  const [render, setRender] = useState(false);

  const deleteInvoiceTemplateModal = useModal();
  const updateInvoiceTemplateModal = useModal();

  const [invoiceTemplateState, setInvoiceTemplateState] = useState({
    page: 0,
    total: 0,
  });

  const [invoiceTemplate, setInvoiceTemplate] = useState(
    {} as InvoiceTemplateResponse
  );
  const [invoiceTemplateDataState, setInvoiceTemplateDataState] = useState({
    isLoading: true,
    data: [] as InvoiceTemplateResponse[],
  });
  const [selectedRecipientType, setSelectedRecipientType] = useState(
    INVOICE_RECIPIENT_GROUP
  );

  function openDeleteModal(id: number) {
    getInvoiceTemplateById(id).then((response) => {
      setInvoiceTemplate(response);
      deleteInvoiceTemplateModal.onOpen(null);
    });
  }

  function openUpdateModal(id: number) {
    getInvoiceTemplateById(id).then((response) => {
      setInvoiceTemplate(response);
      response.invoiceRecipientGroups?.length === 0
        ? setSelectedRecipientType(INVOICE_RECIPIENTS)
        : setSelectedRecipientType(INVOICE_RECIPIENT_GROUP);
      updateInvoiceTemplateModal.onOpen(null);
    });
  }

  useEffect(() => {
    setRender(false);
    getAllInvoiceTemplate().then((response) => {
      setInvoiceTemplateState({
        page: invoiceTemplateState.page,
        total: response.length,
      });
      setInvoiceTemplateDataState({ isLoading: false, data: response });
    });
  }, [render, setRender, invoiceTemplateState.page]);

  return (
    <>
      <LoadingCircle isLoading={invoiceTemplateDataState.isLoading}>
        <Card>
          <Card.Body removeSpacing={true}>
            <div className="mt-2 mb-6">
              {invoiceTemplateDataState.data.length !== 0 ? (
                <DataTable
                  data={invoiceTemplateDataState.data.slice(
                    invoiceTemplateState.page * MAX_INVOICE_TEMPLATES_PER_PAGE,
                    invoiceTemplateState.page * MAX_INVOICE_TEMPLATES_PER_PAGE +
                      MAX_INVOICE_TEMPLATES_PER_PAGE
                  )}
                >
                  <DataTable.Column
                    className="w-5/12"
                    header="Naziv predloška"
                    id="title"
                    canSort={false}
                  >
                    {(item: InvoiceTemplateResponse) => item.title}
                  </DataTable.Column>
                  <DataTable.Column
                    className="w-5/12"
                    header="Opis plaćanja"
                    id="description"
                    canSort={false}
                  >
                    {(item: InvoiceTemplateResponse) => item.description}
                  </DataTable.Column>
                  <DataTable.Column
                    className="w-1/12"
                    header="Akcije"
                    id="akcije"
                    canSort={false}
                  >
                    {(item: InvoiceTemplateResponse) => (
                      <div className="flex space-x-4">
                        <IconButton
                          onClick={() => {
                            openUpdateModal(item.id);
                          }}
                          label="Detalji"
                          icon={<Icon type="file-search" />}
                        />
                        <IconButton
                          onClick={() => {
                            openDeleteModal(item.id);
                          }}
                          label="Izbriši"
                          icon={<Icon type={"trash"} />}
                        />
                      </div>
                    )}
                  </DataTable.Column>
                </DataTable>
              ) : (
                <NoAvailableData
                  className="mt-2 p-2"
                  message="U sustavu trenutno nema predložaka"
                />
              )}
            </div>
          </Card.Body>
          {invoiceTemplateState.total > MAX_INVOICE_TEMPLATES_PER_PAGE && (
            <Card.Footer className="flex items-center justify-end">
              <Pagination
                onPageChange={(i: number) => {
                  setInvoiceTemplateState({
                    page: i,
                    total: invoiceTemplateState.total,
                  });
                }}
                pageNumber={invoiceTemplateState.page}
                pageSize={MAX_INVOICE_TEMPLATES_PER_PAGE}
                totalElements={invoiceTemplateState.total}
              >
                {() => null}
              </Pagination>
            </Card.Footer>
          )}
        </Card>
      </LoadingCircle>
      <DeleteInvoiceTemplateModal
        modal={deleteInvoiceTemplateModal}
        invoiceTemplate={invoiceTemplate}
        setRender={setRender}
      />
      <CreateOrUpdateInvoiceTemplateModal
        modal={updateInvoiceTemplateModal}
        setRender={setRender}
        invoiceTemplate={invoiceTemplate}
        selectedRecipientType={selectedRecipientType}
        setSelectedRecipientType={setSelectedRecipientType}
        isUpdateModal={true}
      />
      {invoiceTemplateListProps.openCreateModal && (
        <CreateOrUpdateInvoiceTemplateModal
          modal={invoiceTemplateListProps.createInvoiceTemplateModal}
          setRender={setRender}
          invoiceTemplate={{} as InvoiceTemplateResponse}
          selectedRecipientType={selectedRecipientType}
          setSelectedRecipientType={setSelectedRecipientType}
          isUpdateModal={false}
        />
      )}
    </>
  );
}
