import { CLUB_MANAGEMENT_URL } from "../../common/Routes";

export async function getClubManagement() {
  const response = await fetch(CLUB_MANAGEMENT_URL, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
