import FileDownload from "js-file-download";

import { ParentSearchRequest } from "./ParentSearchRequest";
import { PARENT_URL } from "../../../common/Routes";

export function exportParentsExcel(request: ParentSearchRequest) {
  fetch(`${PARENT_URL}/export/excel`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let date = new Date().toLocaleDateString();
      FileDownload(blob, `roditelji_${date}.xlsx`);
    });
}
