import React from "react";

import { Card, Typography } from "@tiller-ds/core";

import { ReactComponent as HAVKLogoVertical } from "../../assets/img/HAVK_vertical.svg";

export default function PrivacyPolicy() {
  return (
    <div className="flex flex-col">
      <HAVKLogoVertical className="pt-10 pb-4" />
      <div className="mx-auto w-screen md:w-3/5">
        <Card>
          <Card.Header>
            <Typography element="h1" variant="h1">
              Politika privatnosti
            </Typography>
          </Card.Header>
          <Card.Body>
            <div>
              <Typography variant="text" className="flex flex-col">
                <Typography variant="h4">1. Uvod</Typography>
                <div className="py-4">
                  Ova Politika privatnosti objašnjava kako mobilna aplikacija
                  Sprit (u daljnjem tekstu "Aplikacija") prikuplja, koristi i
                  štiti vaše osobne podatke u skladu s Općom uredbom o zaštiti
                  podataka (GDPR) i drugim relevantnim zakonima. Registracijom i
                  korištenjem Aplikacije sklapate s Voditeljem obrade podataka
                  ugovor o pružanju usluga putem Aplikacije te pristajete na
                  uvjete ove Politike privatnosti.
                </div>
                <Typography variant="h4">
                  2. Voditelj i izvršitelj obrade podataka
                </Typography>
                <ul className="list-disc pl-4 py-4">
                  <li>
                    <strong>Voditelj obrade podataka</strong> odgovoran za
                    obradu vaših osobnih podataka je: <br />
                    <strong>HAVK Mladost</strong>
                    <br />
                    Jarunska 5, Zagreb
                    <br />
                    E-mail: info@havk-mladost.hr
                    <br />
                    Telefon: +385 1 3690 149
                  </li>
                  <br />

                  <li>
                    <strong>Izvršitelj obrade podataka:</strong>
                    <br />
                    <strong>CROZ d.o.o.</strong>
                    <br />
                    Lastovska 23, 10000 Zagreb, Hrvatska
                    <br />
                    E-mail: dpo@croz.net
                    <br />
                    Telefon: +385912260875
                  </li>
                </ul>

                <div className="py-4">
                  <strong>HAVK Mladost</strong> posvećuje veliku važnost zaštiti
                  osobnih podataka svojih korisnika. Politika zaštite
                  privatnosti uređuje način postupanja s informacijama koje
                  vaterpolski klub HAVK Mladost prikuplja i obrađuje putem
                  Aplikacije namijenjene roditeljima djece sportaša.
                  <br />
                  <br />
                  HAVK Mladost prikuplja, obrađuje i čuva Vaše osobne podatke
                  sukladno EU Općoj uredbi o zaštiti podataka (GDPR) i drugim
                  važećim propisima.
                  <br />
                  <br />
                  <strong>CROZ</strong>, kao izvršitelj obrade podataka, pruža
                  uslugu hostinga aplikacije u oblaku. CROZ osigurava tehničku
                  podršku i infrastrukturu za funkcioniranje aplikacije, u
                  skladu s ugovorenim obvezama i GDPR-om.
                </div>

                <Typography variant="h4">
                  3. Osobni podaci koje prikupljamo
                </Typography>
                <div className="py-4">
                  Aplikacija prikuplja i obrađuje samo one osobne podatke koji
                  su nužni za pružanje naših usluga. Osobni podaci koje
                  prikupljamo uključuju:
                </div>

                <ul className="list-disc pl-4 py-4">
                  <li>
                    <strong>Podaci za pristup i registraciju:</strong>
                    <ul className="list-disc pl-4 py-4">
                      <li>
                        <strong>E-mail adresa:</strong> Potrebna za registraciju
                        i komunikaciju.
                      </li>
                      <li>
                        <strong>Lozinka:</strong> Prikuplja se radi zaštite
                        vašeg računa. Preporučujemo korištenje snažne i
                        jedinstvene lozinke.
                      </li>
                      <li>
                        <strong>Kontakt podaci:</strong> Broj telefona ili drugi
                        kontakt podaci koje unesete prilikom registracije.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Osobni podaci prikazani u aplikaciji:</strong>
                    <ul className="list-disc pl-4 py-4">
                      <li>
                        <strong>Ime i prezime roditelja/skrbnika:</strong>
                        Koriste se za identifikaciju registriranih korisnika.
                      </li>
                      <li>
                        <strong>Ime i prezime djeteta/djece:</strong> Potrebno
                        za upravljanje odnosom roditelj-dijete unutar
                        aplikacije.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Podaci o plaćanju:</strong>
                    <ul className="list-disc pl-4 py-4">
                      <li>
                        <strong>Podaci o plaćanju članarine:</strong> Aplikacija
                        bilježi podatke o plaćanju članarine, no ne pohranjuje
                        osobne podatke o plaćanju (poput brojeva kreditnih
                        kartica ili bankovnih podataka).
                      </li>
                    </ul>
                  </li>
                </ul>

                <Typography variant="h4">
                  4. Svrha i pravna osnova obrade
                </Typography>
                <div className="py-4">
                  Prikupljamo i obrađujemo vaše osobne podatke temeljem
                  sljedećih pravnih osnova:
                  <ul className="list-disc pl-4 py-4">
                    <li>
                      <strong>Izvršavanje ugovora:</strong> Obrada osobnih
                      podataka nužna je za pružanje usluga putem aplikacije, kao
                      što su registracija, upravljanje računom i praćenje
                      plaćanja.
                    </li>
                    <li>
                      <strong> Legitimni interes:</strong> Obradujemo određene
                      podatke na temelju legitimnog interesa, primjerice radi
                      osiguranja sigurnosti aplikacije i održavanja komunikacije
                      s korisnicima.
                    </li>
                    <li>
                      <strong> Poštivanje zakonskih obveza: </strong>U nekim
                      slučajevima možemo obrađivati osobne podatke kako bismo
                      ispunili zakonske obveze u skladu s važećim zakonima i
                      propisima.
                    </li>
                  </ul>
                </div>

                <Typography variant="h4">
                  5. Kako koristimo vaše podatke
                </Typography>
                <div className="py-4">
                  Osobni podaci koje prikupljamo koriste se za sljedeće svrhe:
                  <ul className="list-disc pl-4 py-4">
                    <li>Za registraciju korisnika u aplikaciji.</li>
                    <li>
                      Za održavanje vašeg računa i omogućavanje pristupa
                      aplikaciji.
                    </li>
                    <li>
                      Za prikaz vašeg profila i profila vašeg djeteta/djece
                      unutar aplikacije.
                    </li>
                    <li>
                      Za administraciju podataka o plaćanju (napomena: osobni
                      financijski podaci se ne prikupljaju niti pohranjuju u
                      aplikaciji).
                    </li>
                    <li>
                      Za komunikaciju s vama u vezi s ažuriranjima, tehničkom
                      podrškom i važnim informacijama vezanim uz usluge
                      aplikacije.
                    </li>
                  </ul>
                </div>

                <Typography variant="h4">
                  6. Dijeljenje i otkrivanje podataka
                </Typography>
                <div className="py-4">
                  Vaše osobne podatke ne dijelimo, ne prodajemo niti
                  iznajmljujemo trećim stranama, osim u sljedećim slučajevima:
                  <ul className="list-disc pl-4 py-4">
                    <li>
                      Ako je to zakonski obvezno ili od strane nadležnih tijela.
                    </li>
                    <li>
                      Ako je potrebno radi zaštite sigurnosti i integriteta
                      aplikacije, primjerice u slučajevima sumnje na prijevaru
                      ili nezakonite aktivnosti.
                    </li>
                    <li>
                      Ako je potrebno u svrhu tehničke podrške aplikaciji,
                      podaci se dijele s CROZ-om kao izvršiteljem obrade
                      podataka, koji pruža uslugu hostinga aplikacije u oblaku.
                      CROZ nema uvida u osobne podatke koji se unose u
                      aplikaciju.
                    </li>
                  </ul>
                </div>

                <Typography variant="h4">
                  7. Razdoblje čuvanja podataka
                </Typography>
                <div className="py-4">
                  Vaše osobne podatke zadržavamo onoliko dugo koliko je potrebno
                  za pružanje usluga putem aplikacije i kako bismo ispunili
                  zakonske obveze. Nakon deaktivacije vašeg računa, vaši podaci
                  bit će anonimizirani ili izbrisani, osim ako zakon ne
                  zahtijeva dulje razdoblje čuvanja.
                </div>

                <Typography variant="h4">8. Sigurnost podataka</Typography>
                <div className="py-4">
                  Zaštita vaših osobnih podataka shvaćamo vrlo ozbiljno i
                  implementirali smo odgovarajuće tehničke i organizacijske
                  mjere kako bismo zaštitili vaše podatke od neovlaštenog
                  pristupa, izmjene ili otkrivanja. To uključuje sigurno
                  šifriranje lozinki i redovite sigurnosne provjere.
                </div>

                <Typography variant="h4">9. Vaša prava</Typography>
                <div className="py-4">
                  Prema GDPR-u imate sljedeća prava u vezi s vašim osobnim
                  podacima:
                  <ul className="list-disc pl-4 py-4">
                    <li>
                      <strong>Pravo na pristup:</strong> U svakom trenutku
                      možete zatražiti pristup svojim osobnim podacima
                    </li>
                    <li>
                      <strong>Pravo na ispravak:</strong> Imate pravo zatražiti
                      ispravak netočnih ili nepotpunih osobnih podataka.
                    </li>
                    <li>
                      <strong>Pravo na brisanje:</strong> Možete zatražiti
                      brisanje vaših osobnih podataka u određenim slučajevima.
                    </li>
                    <li>
                      <strong>Pravo na ograničenje obrade:</strong> Možete
                      zatražiti ograničenje obrade vaših osobnih podataka u
                      specifičnim slučajevima
                    </li>
                    <li>
                      <strong>Pravo na prijenos podataka:</strong> Možete
                      zatražiti prijenos vaših osobnih podataka u
                      strukturiranom, uobičajenom i strojno čitljivom formatu
                    </li>
                    <li>
                      <strong>Pravo na prigovor:</strong> Imate pravo
                      prigovoriti obradi vaših osobnih podataka u slučajevima
                      kada se oslanjamo na legitimne interese
                    </li>
                  </ul>
                </div>

                <Typography variant="h4">
                  10. Promjene ove politike privatnosti
                </Typography>
                <div className="py-4">
                  Ovu Politiku privatnosti možemo ažurirati s vremena na vrijeme
                  u skladu s pravnim, tehničkim ili poslovnim razvojem.
                  Najnovija verzija uvijek će biti dostupna unutar aplikacije.
                </div>

                <Typography variant="h4">11. Kontakt informacije</Typography>
                <div className="py-4">
                  Ako imate pitanja ili zabrinutosti u vezi s ovom Politikom
                  privatnosti ili našim postupcima obrade podataka, ili nas
                  želite kontaktirati za ostvarivanje svojih prava to možete
                  učiniti na:
                  <br />
                  <br />
                  <strong>HAVK Mladost</strong>
                  <br />
                  Jarunska 5, Zagreb
                  <br />
                  E-mail: info@havk-mladost.hr
                  <br />
                  Telefon: +385 1 3690 149
                </div>

                <div className="py-4 text-right">
                  Ova Politika privatnosti stupa na snagu 16. listopada 2024.
                </div>
              </Typography>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
