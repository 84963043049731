import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  IconButton,
  Link,
  Pagination,
  Typography,
} from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import GroupRegistryFilterCard from "./GroupRegistryFilterCard";
import {
  MAX_ARCHIVE_ROWS,
  REGISTRY_SEARCH_ERROR_MESSAGE,
} from "../../../common/constants";
import { CoachResponse } from "../../coach/api/CoachResponse";
import RegistrySearch from "../../common/components/RegistrySearch";
import { RegistryContext } from "../../common/RegistryProvider";
import { exportGroupsExcel } from "../api/exportGroupsExcel";
import { exportGroupsPDF } from "../api/exportGroupsPDF";
import { GroupResponse } from "../api/GroupResponse";
import { GroupSearchRequest } from "../api/GroupSearchRequest";
import { postSearchGroupRequest } from "../api/postSearchGroupRequest";

type GroupRegistryProps = {
  totalGroups: number;
  setTotalGroups: (totalGroups: number) => void;
  entityCreated: number;
};

export default function GroupRegistry({
  totalGroups,
  setTotalGroups,
  entityCreated,
}: GroupRegistryProps) {
  const [groupTableData, setGroupTableData] = useState([] as GroupResponse[]);
  const { groupRegistryPage, setGroupRegistryPage } = useContext(
    RegistryContext
  );
  const [error, setError] = useState("");
  const [filterClicked, setFilterClicked] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [searchRequestJson, setSearchRequestJson] = useState({
    pageNumber: groupRegistryPage,
    pageSize: 10,
    sortPropertyList: [
      {
        property: "groupName",
        direction: "ASC",
      },
    ],
  } as GroupSearchRequest);

  function handleResize() {
    if (window.innerWidth < 640) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 640) {
      setMobileView(true);
    }
  }, []);

  useEffect(() => {
    postSearchGroupRequest(searchRequestJson).then((response: any) => {
      setTotalGroups(response.totalElements);
      setGroupTableData(response.content);

      response.content.length > 0
        ? setError("")
        : setError(REGISTRY_SEARCH_ERROR_MESSAGE);
    });
  }, [searchRequestJson, setTotalGroups, entityCreated]);

  return (
    <>
      <Card.Header className="py-2" removeSpacing={true}>
        <div className="flex justify-between items-center sm:items-end flex-col md:flex-row">
          <Typography element="h4" variant="h4" className="hidden md:block">
            Grupe
          </Typography>
          <div className="flex w-full sm:w-auto justify-start md:justify-end">
            <div className="flex w-full gap-2 items-center">
              <RegistrySearch
                searchRequestJson={searchRequestJson}
                setSearchRequestJson={setSearchRequestJson}
              ></RegistrySearch>
              {mobileView ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    filterClicked
                      ? setFilterClicked(false)
                      : setFilterClicked(true);
                  }}
                >
                  <Icon type="funnel" />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  trailingIcon={
                    filterClicked ? (
                      <Icon type="caret-up" variant="bold" />
                    ) : (
                      <Icon type="caret-down" variant="bold" size={3} />
                    )
                  }
                  onClick={() => {
                    filterClicked
                      ? setFilterClicked(false)
                      : setFilterClicked(true);
                  }}
                >
                  Filtriraj
                </Button>
              )}
            </div>
          </div>
          <DropdownMenu
            title="Preuzmi"
            color="primary"
            iconPlacement="leading"
            openExpanderIcon={<Icon type="download-simple" variant="bold" />}
            closeExpanderIcon={<Icon type="download-simple" variant="bold" />}
          >
            <DropdownMenu.Item
              onSelect={() => exportGroupsExcel(searchRequestJson)}
            >
              .xlsx
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => exportGroupsPDF(searchRequestJson)}
            >
              .pdf
            </DropdownMenu.Item>
          </DropdownMenu>
        </div>
      </Card.Header>
      <Card.Body removeSpacing={true}>
        {filterClicked ? (
          <GroupRegistryFilterCard
            setSearchRequestJson={setSearchRequestJson}
            searchRequestJson={searchRequestJson}
          />
        ) : (
          <></>
        )}
        <DataTable data={groupTableData}>
          <DataTable.Column
            className="w-3/12"
            header="Ime"
            id="name"
            canSort={false}
          >
            {(item: GroupResponse) => item.groupName}
          </DataTable.Column>
          <DataTable.Column
            className="w-4/12"
            header="Ime trenera"
            id="coachName"
            canSort={false}
          >
            {(item: GroupResponse) => {
              return (
                <>
                  {item.coaches[0]
                    ? item.coaches
                        .sort((a, b) => a.firstName.localeCompare(b.firstName))
                        .map((coach: CoachResponse) => {
                          return (
                            <>
                              <span className="mt-1">
                                {`${coach.firstName} ${coach.lastName}`}
                              </span>
                              <br></br>
                            </>
                          );
                        })
                    : "-"}
                </>
              );
            }}
          </DataTable.Column>
          <DataTable.Column
            className="w-2/12"
            header="Dob"
            id="ageGroup"
            canSort={false}
          >
            {(item: GroupResponse) => `${item.minAge}-${item.maxAge}`}
          </DataTable.Column>
          <DataTable.Column
            className="w-2/12"
            header="Broj sportaša"
            id="numberOfAthletes"
            canSort={false}
          >
            {(item: GroupResponse) => item.athletes.length}
          </DataTable.Column>
          <DataTable.Column
            className="w-1/12"
            header="Akcije"
            id="actions"
            canSort={false}
          >
            {(item: GroupResponse) => (
              <div className="flex flex-row space-x-4">
                <Link to={`/archive/groups/${item.id}`}>
                  <IconButton
                    icon={<Icon type="file-search" className="text-gray-500" />}
                    onClick={() => {}}
                    label="Detalji"
                  />
                </Link>
              </div>
            )}
          </DataTable.Column>
        </DataTable>
        <div className="flex justify-center my-3">
          <Typography className="text-red-600" variant="subtext">
            {error}
          </Typography>
        </div>
      </Card.Body>
      {totalGroups > MAX_ARCHIVE_ROWS && (
        <Card.Footer className="flex items-center justify-end">
          <Pagination
            onPageChange={(i: number) => {
              setGroupRegistryPage(i);
              setSearchRequestJson((searchRequestJson) => ({
                ...searchRequestJson,
                pageNumber: i,
              }));
            }}
            pageNumber={groupRegistryPage}
            pageSize={MAX_ARCHIVE_ROWS}
            totalElements={totalGroups}
          >
            {() => null}
          </Pagination>
        </Card.Footer>
      )}
    </>
  );
}
