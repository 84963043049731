import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Badge, Button, Card, IconButton, Pagination } from "@tiller-ds/core";
import { DataTable, useDataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";
import { PageResizer } from "@tiller-ds/selectors";

import { useNotifications } from "@croz/nrich-notification-core";

import moment from "moment";

import NoAvailableData from "../../../archive/common/components/NoAvailableData";
import { ParentBriefResponse } from "../../../archive/parent/api/ParentBriefResponse";
import { InvoiceStatus } from "../../../common/constants";
import formatDateInTimeZone from "../../../common/formatDateInTimeZone";
import { postSearchInvoiceRequest } from "../../common/api/postSearchInvoiceRequest";
import { SearchInvoiceRequest } from "../../common/api/searchInvoiceRequest";
import { InvoiceResponse } from "../../common/InvoiceResponse";
import { formatAmount } from "../../common/InvoiceUtil";
import { exportInvoiceExcel } from "../../parent-invoice-page/api/getExportPayment";
import { putMarkInvoicesAsSettledRequest } from "../api/putMarkInvoicesAsSettledRequest";
import InvoiceFilterCard from "../components/InvoiceFilterCard";
import SendInvoiceReminderModal from "../components/SendInvoiceReminderModal";
import handleMarkingInvoiceAsPaidRequest from "../handleMarkingInvoiceAsPaidRequest";

const pageSizes = [15, 30, 50, 100];

type InvoiceViewPageProps = {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
};

export default function InvoiceViewPage({
  render,
  setRender,
}: InvoiceViewPageProps) {
  const [invoiceData, setInvoiceData] = useState([] as InvoiceResponse[]);
  const [filterClicked, setFilterClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [totalElements, setTotalElements] = useState(0);
  const [dataTableState, dataTableHook] = useDataTable();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const sendInvoiceReminderModal = useModal();
  const { add: addNotification } = useNotifications();
  const [searchRequestJson, setSearchRequestJson] = useState({
    pageNumber: page,
    pageSize: pageSize,
    sortPropertyList: [
      {
        property: "creationDate",
        direction: "DESC",
      },
    ],
    status: InvoiceStatus.NEPODMIRENO,
  } as SearchInvoiceRequest);

  useEffect(
    () =>
      setSearchRequestJson((prevState) => ({
        ...prevState,
        pageNumber: page,
        pageSize: pageSize,
      })),
    [page, pageSize]
  );

  function getSortedParentsFullName(parents: ParentBriefResponse[]): string {
    if (parents.length === 2) {
      parents.sort((a, b) => a.firstName.localeCompare(b.lastName));

      return `${parents[0].firstName} ${parents[0].lastName}, ${parents[1].firstName} ${parents[1].lastName} `;
    }
    return `${parents[0].firstName} ${parents[0].lastName}`;
  }

  function shouldShowFooter(): boolean {
    return page === Math.floor(totalElements / 10);
  }

  useEffect(() => {
    setRender(false);
    postSearchInvoiceRequest(searchRequestJson).then((response: any) => {
      if (response.content) {
        setInvoiceData(response.content);
        setTotalAmount(response.totalAmount);
        setTotalAmountPaid(response.totalAmountPaid);
        setTotalElements(response.totalElements);
      }
    });
  }, [render, searchRequestJson, setRender]);

  function isRowSelected() {
    return dataTableState.selectedCount > 0;
  }

  function getInvoiceIds() {
    const tableIndexes = Object.keys(dataTableState.selected) as Array<string>;

    return invoiceData
      .filter((invoice) =>
        tableIndexes.includes(invoiceData.indexOf(invoice).toString())
      )
      .map((invoice) => invoice.id);
  }

  function markInvoicesAsPaid() {
    putMarkInvoicesAsSettledRequest({
      invoiceIds: getInvoiceIds(),
    }).then((response) => {
      setRender(true);
      deselectAllRows();
      handleMarkingInvoiceAsPaidRequest(response, addNotification);
    });
  }

  function deselectAllRows() {
    dataTableHook.setSelected({}, false);
  }

  return (
    <>
      <div className="pt-2 pb-2 flex flex-col sm:flex-row gap-y-3 sm:gap-y-0 sm:justify-between mb-3 mt-3">
        <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-y-0">
          <Button
            disabled={!isRowSelected()}
            variant="outlined"
            size="sm"
            color={"primary"}
            leadingIcon={<Icon type="bell-ringing" />}
            onClick={() => {
              sendInvoiceReminderModal.onOpen(null);
            }}
            className="w-full sm:w-fit"
          >
            {" "}
            <p className="text-sm ">Pošalji podsjetnik</p>
          </Button>

          <Button
            className="w-full sm:w-fit sm:ml-2"
            disabled={!isRowSelected()}
            variant="outlined"
            size="sm"
            color={"primary"}
            onClick={() => markInvoicesAsPaid()}
          >
            <p className="text-sm">Zatvori račune</p>
          </Button>
        </div>

        <div className="flex">
          <Button
            variant="outlined"
            color="primary"
            trailingIcon={
              filterClicked ? (
                <Icon type="caret-up" size={3} variant="bold" />
              ) : (
                <Icon type="caret-down" size={3} variant="bold" />
              )
            }
            onClick={() => {
              filterClicked ? setFilterClicked(false) : setFilterClicked(true);
            }}
            className="w-full sm:w-fit"
          >
            Filtriraj
          </Button>
        </div>
      </div>
      <div>
        {filterClicked && (
          <InvoiceFilterCard
            setSearchRequestJson={setSearchRequestJson}
            searchRequestJson={searchRequestJson}
          />
        )}
      </div>
      <Card>
        <Card.Body removeSpacing={true}>
          {invoiceData.length !== 0 ? (
            <div>
              <DataTable
                data={invoiceData}
                showFooter={shouldShowFooter()}
                hook={dataTableHook}
              >
                <DataTable.Selector />

                <DataTable.Column
                  header="Poziv na broj"
                  id="reference-number"
                  canSort={false}
                >
                  {(item: InvoiceResponse) => item.referenceNumber}
                </DataTable.Column>

                <DataTable.Column header="Sportaš" id="athlete" canSort={false}>
                  {(item: InvoiceResponse) =>
                    `${item.athlete.firstName} ${item.athlete.lastName}`
                  }
                </DataTable.Column>

                <DataTable.Column header="Roditelj" id="parent" canSort={false}>
                  {(item: InvoiceResponse) =>
                    item.athlete.parents.length > 0 &&
                    getSortedParentsFullName(item.athlete.parents)
                  }
                </DataTable.Column>

                <DataTable.Column
                  header="Kreiran"
                  id="creation-date"
                  canSort={false}
                >
                  {(item: InvoiceResponse) =>
                    new Date(item.creationDate).toLocaleDateString("hr-HR")
                  }
                </DataTable.Column>

                <DataTable.Column
                  header="Dospijeće"
                  id="due-date"
                  canSort={false}
                >
                  {(item: InvoiceResponse) =>
                    new Date(item.dueDate).toLocaleDateString("hr-HR")
                  }
                </DataTable.Column>

                <DataTable.Column
                  header="Iznos"
                  id="amount"
                  canSort={false}
                  footer={`Ukupno: ${formatAmount(totalAmount)}`}
                  align="right"
                >
                  {(item: InvoiceResponse) =>
                    formatAmount(item.amountWithDiscount)
                  }
                </DataTable.Column>

                <DataTable.Column
                  header="Plaćeno"
                  id="amount-paid"
                  canSort={false}
                  footer={`Ukupno: ${formatAmount(totalAmountPaid)}`}
                  align="right"
                >
                  {(item: InvoiceResponse) => formatAmount(item.amountPaid)}
                </DataTable.Column>

                <DataTable.Column
                  header="Uplatnica"
                  id="paymentSlip"
                  key={"paymentSlip"}
                  canSort={false}
                >
                  {(invoice: InvoiceResponse) => (
                    <IconButton
                      icon={<Icon type="file" />}
                      className="mx-auto translate-x-7 text-gray-500"
                      label="Preuzmi uplatnicu"
                      onClick={() =>
                        exportInvoiceExcel({
                          invoiceId: invoice.id,
                          athleteFullName: `${invoice.athlete.firstName}_${invoice.athlete.lastName}`,
                          creationDate: formatDateInTimeZone(
                            invoice.creationDate,
                            "dd.MM.yyyy."
                          ),
                        })
                      }
                    />
                  )}
                </DataTable.Column>

                <DataTable.Column header="Status" id="status" canSort={false}>
                  {(item: InvoiceResponse) =>
                    item.status === InvoiceStatus.NEPODMIRENO &&
                    moment(item.dueDate).isBefore(new Date()) ? (
                      <Badge color="danger">{InvoiceStatus.DOSPJELO}</Badge>
                    ) : item.status === InvoiceStatus.NEPODMIRENO ? (
                      <Badge color="warning">{InvoiceStatus.NEPODMIRENO}</Badge>
                    ) : (
                      <Badge color="success">{InvoiceStatus.PODMIRENO}</Badge>
                    )
                  }
                </DataTable.Column>
              </DataTable>
            </div>
          ) : (
            <NoAvailableData
              className="mt-2 p-2"
              message="U sustavu trenutno nema računa"
            />
          )}
        </Card.Body>
      </Card>

      <div className="flex flex-col md:flex-row justify-between mt-3">
        <div className="flex flex-row justify-start w-full md:w-1/2">
          <PageResizer
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setPage(0);
            }}
            pageSize={pageSize}
            pageSizes={pageSizes}
            totalElements={totalElements}
          >
            {(selector) => (
              <p className="text-sm flex items-center leading-5 text-gray-700">
                Prikaži {selector} rezultata po stranici
              </p>
            )}
          </PageResizer>
        </div>
        <div className="w-full md:w-1/2 mt-5 md:mt-0 flex flex-row justify-center md:justify-end">
          <div className="flex justify-center">
            <Pagination
              onPageChange={(i: number) => {
                setPage(i);
                setSearchRequestJson((searchRequestJson) => ({
                  ...searchRequestJson,
                  pageNumber: i,
                }));
                deselectAllRows();
              }}
              totalElements={totalElements}
              pageNumber={page}
              pageSize={pageSize}
            >
              {() => null}
            </Pagination>
          </div>
        </div>
      </div>
      <SendInvoiceReminderModal
        modal={sendInvoiceReminderModal}
        selectedInvoices={dataTableState.selected}
        invoiceData={invoiceData}
      />
    </>
  );
}
