import React, { useEffect, useState } from "react";

import ClubInfoBadge from "./ClubInfoBadge";
import { getAthleteGroupCountInfo } from "../../../home/api/getAthleteGroupCountInfo";
import { getClubUserCountInfo } from "../../../home/api/getClubUserCountInfo";

type AdminHeaderInfoProps = {
  entityCreated: number;
};

export default function AdminHeaderInfo({
  entityCreated,
}: AdminHeaderInfoProps) {
  const [numOfAthletes, setNumOfAthletes] = useState(0);
  const [numOfCoaches, setNumOfCoaches] = useState(0);
  const [numOfGroups, setNumOfGroups] = useState(0);

  useEffect(() => {
    getClubUserCountInfo().then((response) => {
      if (response) {
        setNumOfAthletes(response.numOfAthletes);
        setNumOfCoaches(response.numOfCoaches);
      }
    });
    getAthleteGroupCountInfo().then((response) => {
      if (response) {
        setNumOfGroups(response.numOfAthleteGroups);
      }
    });
  }, [entityCreated]);

  return (
    <div className="w-full flex items-center justify-center p-4 mb:p-0">
      <div className="flex justify-evenly w-full">
        <ClubInfoBadge number={numOfAthletes} title={"Sportaši"} />
        <ClubInfoBadge number={numOfGroups} title={"Grupe"} />
        <ClubInfoBadge number={numOfCoaches} title={"Treneri"} />
      </div>
    </div>
  );
}
