import React, { useState } from "react";

import { Breadcrumbs, Button, Card, Link, Typography } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";
import {
  InputField,
  TextareaField,
  ToggleField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import { UserResponse } from "../../../archive/common/api/UserResponse";
import { RecipientGroup } from "../../../common/api/RecipientGroup";
import RecipientsAutocompleteFieldSelector from "../../../common/components/RecipientsAutocompleteFieldSelector";
import {
  MAX_SURVEY_DESCRIPTION_LENGTH,
  MAX_SURVEY_TITLE_LENGTH,
  MIN_SURVEY_TITLE_LENGTH,
} from "../../../common/constants";
import {
  maxCharacterCountMessage,
  minCharacterCountMessage,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import SurveyQuestionCreate, {
  Answer,
} from "../components/Cards/QuestionCard/SurveyQuestionCreate";

const initialValues = {
  anonymousSurvey: false,
  surveyEndDate: new Date(),
  surveyTitle: "",
  surveyDescription: "",
  surveyRecipients: [],
  surveyRecipientGroups: [],
  surveyAnswers: [],
  surveyQuestions: [],
};

type Form = {
  anonymousSurvey: boolean;
  surveyEndDate: Date;
  surveyTitle: string;
  surveyDescription: string;
  surveyAnswers: Answer[];
  surveyQuestions: Question[];
  surveyRecipients: UserResponse[];
  surveyRecipientGroups: RecipientGroup[];
};

const validationSchema = yup.object().shape({
  surveyDescription: yup
    .string()
    .max(
      MAX_SURVEY_DESCRIPTION_LENGTH,
      maxCharacterCountMessage(MAX_SURVEY_DESCRIPTION_LENGTH)
    ),
  surveyTitle: yup
    .string()
    .min(
      MIN_SURVEY_TITLE_LENGTH,
      minCharacterCountMessage(MIN_SURVEY_TITLE_LENGTH)
    )
    .max(
      MAX_SURVEY_TITLE_LENGTH,
      maxCharacterCountMessage(MAX_SURVEY_TITLE_LENGTH)
    )
    .required(REQUIRED_FIELD_MESSAGE),
  surveyEndDate: yup
    .date()
    .required(REQUIRED_FIELD_MESSAGE)
    .required(REQUIRED_FIELD_MESSAGE),
});

export type Question = {
  questionNumber: number;
  required: boolean;
  question: string;
  answers: Answer[];
  name: string;
};

export default function SurveyCreate() {
  const [questions, setQuestions] = useState([] as Question[]);
  const [endDate, setEndDate] = useState(new Date() as Date | null);
  const { add: addNotification } = useNotifications();

  const onSubmitForm = (form: Form) => {
    addNotification({
      title: "Anketa poslana!",
      content: "Vaša anketa poslana je odabranim primateljima.",
      messageList: [],
      severity: "INFO",
      timestamp: new Date(),
    });
  };

  function addNewQuestion() {
    setQuestions([
      ...questions,
      {
        questionNumber: questions.length,
        required: false,
        question: "",
        answers: [] as Answer[],
        name: questions.length.toString(),
      },
    ]);
  }

  return (
    <div className="w-full px-4 sm:px-0">
      <div className="flex flex-col items-center w-full h-full mt-8">
        <div className="justify-start w-full items-center mb-8">
          <div className="flex justify-between">
            <Typography
              element="h3"
              variant="h3"
              className="hidden md:inline font-biotiff"
            >
              Kreiranje ankete
            </Typography>
            <Typography
              element="h6"
              variant="h6"
              className="md:hidden font-biotiff"
            >
              Kreiranje ankete
            </Typography>
            <div className="flex space-x-4">
              <Button variant="outlined">Spremi</Button>
              <Button
                className="sticky top-4"
                form="survey-form"
                type="submit"
                variant="filled"
                color="primary"
              >
                Pošalji
              </Button>
            </div>
          </div>
          <div>
            <Breadcrumbs>
              <Breadcrumbs.Breadcrumb>
                <Link to="/survey">Nedavne ankete</Link>
              </Breadcrumbs.Breadcrumb>
              <Breadcrumbs.Breadcrumb>{"Nova anketa"}</Breadcrumbs.Breadcrumb>
            </Breadcrumbs>
          </div>
        </div>
        <div className="w-full lg:w-4/6">
          <Formik
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <form
                id="survey-form"
                onSubmit={formik.handleSubmit}
                className="pt-6"
              >
                <Card className="w-full h-full mb-8">
                  <Card.Body>
                    <div className="flex flex-col space-y-6 w-full">
                      <div className="flex flex-col justify-between items-end xl:flex-row">
                        <div className="flex flex-col w-full mb-4 xl:w-4/6 xl:mb-0">
                          <RecipientsAutocompleteFieldSelector
                            recipientsName={"surveyRecipients"}
                            recipientGroupsName={"surveyRecipientGroups"}
                          />
                        </div>
                        <DateInput
                          className="w-full xl:w-1/4"
                          name="surveyEndDate"
                          label="Datum završetka"
                          onBlur={() => {}}
                          onChange={(date: Date | null) => {
                            date?.setHours(12);
                            setEndDate(date);
                            formik.setFieldValue("surveyEndDate", date);
                          }}
                          value={endDate}
                          allowClear={false}
                          required
                        />
                      </div>
                      <div>
                        <InputField
                          name="surveyTitle"
                          label="Naslov"
                          placeholder="Naslov ankete"
                          allowClear={true}
                          required
                        />
                        <TextareaField
                          className="mt-6 mb-4"
                          textareaClassName="h-24"
                          name="surveyDescription"
                          label="Opis"
                          placeholder="Detaljan opis ove ankete."
                          maxLength={MAX_SURVEY_DESCRIPTION_LENGTH}
                        />
                      </div>
                      <div className="w-full flex justify-end mt-4">
                        <div>
                          <ToggleField
                            name={"anonymousSurvey"}
                            label={<p className="text-muted">Anonimno</p>}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                {questions.map((question: Question) => {
                  return (
                    <SurveyQuestionCreate
                      key={question.questionNumber}
                      questionFieldName={question.name}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                  );
                })}
              </form>
            )}
          </Formik>
          <div className="w-full flex justify-center mb-20">
            <Button
              leadingIcon={<Icon type="plus" variant="bold" size={3} />}
              variant="outlined"
              color="primary"
              onClick={() => {
                addNewQuestion();
              }}
            >
              Kreiraj Pitanje
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
