import React from "react";

import { Typography } from "@tiller-ds/core";

import ResultCircularProgress from "./ResultCircularProgress";

type AnswerProps = {
  answer: string;
  totalRespondents: number;
  totalAnswers: number;
};

export default function LinearAnswerResult({
  answer,
  totalRespondents,
  totalAnswers,
}: AnswerProps) {
  return (
    <div className="flex flex-col items-center justify-end w-full h-32 space-y-4 px-6">
      <ResultCircularProgress
        totalRespondents={totalRespondents}
        totalAnswers={totalAnswers}
      />
      <Typography element="h6" variant="text" className="font-inter">
        {answer}
      </Typography>
    </div>
  );
}
