import { ATHLETE_URL } from "../../../common/Routes";

export function deleteAthlete(id: number) {
  return fetch(`${ATHLETE_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }).then((res) => {
    return res;
  });
}
