import React, { useState } from "react";

import { Card, Pagination, Tabs, Typography } from "@tiller-ds/core";

import EventCardWithTimeAndLocation from "../../../common/components/cards/EventCardWithTimeAndLocation";
import { EventType, MAX_CARDS_PER_PAGE } from "../../../common/constants";
import { EventResponse } from "../../../event/api/EventResponse";
import { GroupResponse } from "../../group/api/GroupResponse";

type CoachGroupEventsSelectorProps = {
  groupList: GroupResponse[];
};

export default function GroupEventsSelector({
  groupList,
}: CoachGroupEventsSelectorProps) {
  const [page, setPage] = useState(0);
  const currentDate = new Date();

  const filterEvents = (events: EventResponse[]) =>
    events.filter(
      (event) =>
        new Date(event.startTime) >= currentDate &&
        event.eventType === EventType.TRAINING
    );

  return (
    <>
      <Typography
        element="h3"
        variant="h4"
        className="text-primary font-biotif"
      >
        Treninzi grupa
      </Typography>
      {groupList.length > 0 ? (
        <Card>
          <Card.Body>
            <Tabs>
              {groupList
                .sort((group1, group2) =>
                  group1.groupName.localeCompare(group2.groupName)
                )
                .map((group) => {
                  return (
                    <Tabs.Tab
                      label={group.groupName}
                      onClick={() => setPage(0)}
                    >
                      <div className="flex flex-wrap md:grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 my-6">
                        {filterEvents(group.events).length > 0 ? (
                          filterEvents(group.events)
                            .sort((firstEvent, secondEvent) =>
                              firstEvent.startTime.localeCompare(
                                secondEvent.startTime
                              )
                            )
                            .slice(
                              page * MAX_CARDS_PER_PAGE,
                              page * MAX_CARDS_PER_PAGE + MAX_CARDS_PER_PAGE
                            )
                            .map((event) => {
                              return (
                                <EventCardWithTimeAndLocation
                                  event={event}
                                  className="w-full"
                                />
                              );
                            })
                        ) : (
                          <Typography
                            variant="subtext"
                            className="whitespace-nowrap italic"
                          >
                            Nema zakazanog termina u ovoj grupi
                          </Typography>
                        )}
                      </div>
                      {filterEvents(group.events).length >
                        MAX_CARDS_PER_PAGE && (
                        <div className="flex justify-center">
                          <div>
                            <Pagination
                              onPageChange={(i: number) => {
                                setPage(i);
                              }}
                              pageSize={MAX_CARDS_PER_PAGE}
                              totalElements={filterEvents(group.events).length}
                              pageNumber={page}
                            >
                              {() => null}
                            </Pagination>
                          </div>
                        </div>
                      )}
                    </Tabs.Tab>
                  );
                })}
            </Tabs>
          </Card.Body>
        </Card>
      ) : (
        <Typography element="p" variant="subtext" className="italic">
          Nema treninga grupa za prikaz
        </Typography>
      )}
    </>
  );
}
