import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import {
  Badge,
  Breadcrumbs,
  Button,
  Card,
  Link,
  Pagination,
  Typography,
} from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import { MAX_CARDS_PER_PAGE } from "../../../common/constants";
import { postSearchProgressTypeRequest } from "../api/postSearchProgressTypeRequest";
import { ProgressTypeResponse } from "../api/ProgressTypeResponse";
import { SearchProgressTypeRequest } from "../api/SearchProgressTypeRequest";
import { SearchProgressTypeResponse } from "../api/SearchProgressTypeResponse";
import ProgressTypeCard from "../components/cards/ProgressTypeCard";
import CreateProgressTypeModal from "../components/modals/CreateProgressTypeModal";
import DeleteProgressTypeModalModal from "../components/modals/DeleteProgressTypeModal";
import EditProgressTypeModal from "../components/modals/EditProgressTypeModal";

export default function ProgressTypesPage() {
  const [progressTypes, setProgressTypes] = useState(
    [] as ProgressTypeResponse[]
  );

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [render, setRender] = useState(false);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [searchProgressTypeRequest, setSearchProgressTypeRequest] = useState({
    pageNumber: 0,
    pageSize: 8,
  } as SearchProgressTypeRequest);

  const translateMeasuringUnit = {
    KILOGRAM: "Težina (kg)",
    CENTIMETER: "Centimetri (cm)",
    SECOND: "Vrijeme (mm:ss:cs)",
    REPETITION: "Broj ponavljanja (br)",
  };

  useEffect(() => {
    setSearchProgressTypeRequest((searchProgressTypeRequest) => ({
      ...searchProgressTypeRequest,
      pageNumber: page,
      sortPropertyList: [
        {
          property: "measuringUnit",
          direction: "ASC",
        },
        {
          property: "name",
          direction: "ASC",
        },
      ],
    }));
  }, [page]);

  useEffect(() => {
    postSearchProgressTypeRequest(searchProgressTypeRequest).then(
      (response: SearchProgressTypeResponse) => {
        setLoaded(true);
        setRender(false);
        setProgressTypes(response.content);
        setTotalElements(response.totalElements);
      }
    );
  }, [searchProgressTypeRequest, render]);

  const createModal = useModal();

  if (!loaded) {
    return <></>;
  } else {
    return (
      <div className="flex flex-col px-4 md:px-0 gap-y-6 md:my-6">
        <div className="hidden md:block">
          <Typography
            element="h3"
            variant="h3"
            className="hidden md:inline font-biotiff"
          >
            Pregled parametara statistike
          </Typography>
          <div className="pt-2">
            <Breadcrumbs>
              <Breadcrumbs.Breadcrumb>
                <Link to="/home">Početna stranica</Link>
              </Breadcrumbs.Breadcrumb>
              <Breadcrumbs.Breadcrumb>
                Pregled parametara statistike
              </Breadcrumbs.Breadcrumb>
            </Breadcrumbs>
          </div>
        </div>
        <div className="pb-6 md:hidden">
          <div className="flex gap-x-4">
            <div
              className="flex cursor-pointer"
              onClick={() => navigate("/home")}
            >
              <Icon type="caret-left" className="my-auto" variant="bold" />
            </div>
            <Typography element="h6" variant="h6" className="font-biotiff">
              Pregled parametara statistike
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-4">
            <div className="border-b-[1px] pb-1 border-gray-200">
              <Typography element="h2" variant="h6">
                Aktivni osobni rekordi
              </Typography>
              <Typography variant="subtext">
                Aktivni osobni rekordi postavljeni su svim sportašima.
              </Typography>
            </div>
            {progressTypes.length > 0 ? (
              <div className="flex flex-col w-full gap-x-4 gap-y-2 md:flex-row md:overflow-x-auto md:flex-nowrap pb-4">
                {progressTypes
                  .filter(
                    (progressType) => progressType.displayInPersonalRecords
                  )
                  .map((progressType) => {
                    return (
                      <div>
                        <ProgressTypeCard progressType={progressType} />
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col justify-between items-end gap-y-4 w-full lg:flex-row border-b-[1px] pb-1 border-gray-200">
              <div className="flex flex-col">
                <Typography
                  element="h2"
                  variant="h6"
                  className="justify-left text-slate-600"
                >
                  Parametri
                </Typography>
                <Typography
                  variant="subtext"
                  className="text-gray-600 flex justify-left"
                >
                  Dodavanje, uređivanje i brisanje parametara događa se na
                  razini cijele aplikacije. Svi sportaši i grupe imaju jednake
                  mjerljive parametre.
                </Typography>
              </div>
              <div className="flex justify-end h-fit">
                <Button
                  variant="outlined"
                  size="md"
                  color="primary"
                  leadingIcon={<Icon type="plus" />}
                  onClick={createModal.onOpen}
                  className="whitespace-nowrap"
                >
                  Novi parametar
                </Button>
              </div>
            </div>
            <Card className="mb-8">
              <Card.Body>
                <DataTable data={progressTypes}>
                  <DataTable.Column
                    header="NAZIV PARAMETRA"
                    id="Name"
                    canSort={false}
                  >
                    {(item: ProgressTypeResponse) => item.name}
                  </DataTable.Column>
                  <DataTable.Column
                    header="MJERNA JEDINICA"
                    id="MeasuringUnit"
                    canSort={false}
                  >
                    {(item: ProgressTypeResponse) =>
                      translateMeasuringUnit[item.measuringUnit]
                    }
                  </DataTable.Column>
                  <DataTable.Column
                    header="AKCIJE"
                    id="contact"
                    canSort={false}
                  >
                    {(item: ProgressTypeResponse) => (
                      <div className="flex flex-row space-x-4">
                        <EditProgressTypeModal
                          progressTypeTableData={progressTypes}
                          setRender={setRender}
                          progressParameter={item}
                        />
                        <DeleteProgressTypeModalModal
                          progressTypeId={item.id}
                          setRender={setRender}
                        />
                      </div>
                    )}
                  </DataTable.Column>
                  <DataTable.Column
                    header="OSOBNI REKORD"
                    id="ShowPersonalRecords"
                    canSort={false}
                  >
                    {(item: ProgressTypeResponse) => (
                      <Badge
                        color={
                          item.displayInPersonalRecords ? "success" : "danger"
                        }
                      >
                        {item.displayInPersonalRecords
                          ? "Aktivan"
                          : "Neaktivan"}
                      </Badge>
                    )}
                  </DataTable.Column>
                </DataTable>
                {totalElements > MAX_CARDS_PER_PAGE && (
                  <div className="w-full flex justify-end mt-4">
                    <Pagination
                      onPageChange={(i: number) => {
                        setPage(i);
                      }}
                      totalElements={totalElements}
                      pageNumber={page}
                      pageSize={MAX_CARDS_PER_PAGE}
                    >
                      {() => {
                        return <span></span>;
                      }}
                    </Pagination>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
        <CreateProgressTypeModal
          modal={createModal}
          setRender={setRender}
          progressTypeTableData={progressTypes}
        />
      </div>
    );
  }
}
