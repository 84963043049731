import * as React from "react";
import { ReactElement } from "react";

import {
  DEFAULT_REACT_APP_CLIENT_ID,
  DEFAULT_REACT_APP_PROVIDER_URL,
  DEFAULT_REACT_APP_REDIRECT_URI,
  DEFAULT_REACT_APP_SIGNOUT_URI,
  REACT_APP_ENABLE_SURVEYS,
} from "./default-env-vars";
import env, { ReactEnvironmentVariable } from "../env";

interface EnvironmentVariableProviderProps {
  children: ReactElement;
}

type EnvironmentVariablesContext = {
  env: ReactEnvironmentVariable;
};

const envVarsContext = React.createContext<EnvironmentVariablesContext>({
  env: {},
} as EnvironmentVariablesContext);
const { Provider } = envVarsContext;

export default function EnvironmentVariablesProvider({
  children,
}: EnvironmentVariableProviderProps) {
  const [envVars] = React.useState<ReactEnvironmentVariable>({
    REACT_APP_CLIENT_ID:
      env.REACT_APP_CLIENT_ID ||
      process.env.REACT_APP_CLIENT_ID ||
      DEFAULT_REACT_APP_CLIENT_ID,
    REACT_APP_PROVIDER_URL:
      env.REACT_APP_PROVIDER_URL ||
      process.env.REACT_APP_PROVIDER_URL ||
      DEFAULT_REACT_APP_PROVIDER_URL,
    REACT_APP_REDIRECT_URI:
      env.REACT_APP_REDIRECT_URI ||
      process.env.REACT_APP_REDIRECT_URI ||
      DEFAULT_REACT_APP_REDIRECT_URI,
    REACT_APP_SIGNOUT_URI:
      env.REACT_APP_SIGNOUT_URI ||
      process.env.REACT_APP_SIGNOUT_URI ||
      DEFAULT_REACT_APP_SIGNOUT_URI,
    REACT_APP_ENABLE_SURVEYS:
      env.REACT_APP_ENABLE_SURVEYS ||
      process.env.REACT_APP_ENABLE_SURVEYS ||
      REACT_APP_ENABLE_SURVEYS,
  });

  return <Provider value={{ env: envVars }}>{children}</Provider>;
}

export function useEnvironmentVariable() {
  const context = React.useContext(envVarsContext);
  if (context === undefined) {
    throw new Error(
      `useEnvironmentVariable hook must be used within EnvironmentVariableProvider`
    );
  }
  return context.env;
}
