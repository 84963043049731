import { PLANNED_ABSENCE_URL } from "../../common/Routes";

export async function getPlannedAbsencesByUserId(userId: number) {
  const response = await fetch(`${PLANNED_ABSENCE_URL}/users/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
