import React, { useEffect, useState } from "react";

import { Modal, UseModal } from "@tiller-ds/alert";
import { Pagination, Typography } from "@tiller-ds/core";

import { RankList } from "./RankList";
import {
  MAX_RANK_REPORT_MODAL_ROWS,
  MEASUREMENT_UNIT_ABBREVIATION,
} from "../../../common/constants";
import { getRankReports } from "../../api/getRankReports";
import { RankReportResponse } from "../../api/RankReportResponse";
import { firstDayOfMonthDate, lastDayOfMonthDate } from "../../util/dateUtil";

type RankModalProps = {
  modal: UseModal<unknown>;

  progressTypeId: number;

  startDate: Date | null;

  endDate: Date | null;

  groupId: number | undefined;

  progressTitle: string | undefined;

  progressMeasurementUnit: string | undefined;
};

export function RankModal({
  modal,
  progressTypeId,
  startDate,
  endDate,
  groupId,
  progressTitle,
  progressMeasurementUnit,
}: RankModalProps) {
  const [rankReports, setRankReports] = useState<RankReportResponse[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalReports, setTotalReports] = useState<number>(0);

  useEffect(() => {
    getRankReports({
      startDate: startDate === null ? firstDayOfMonthDate() : startDate,
      endDate: endDate === null ? lastDayOfMonthDate() : endDate,
      groupId: groupId,
      progressTypeId: progressTypeId,
      pageNumber: page,
      pageSize: MAX_RANK_REPORT_MODAL_ROWS,
    }).then((response) => {
      setRankReports(response.content);
      setTotalReports(response.totalElements);
    });
  }, [endDate, groupId, page, progressTypeId, startDate]);

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <div>
            <Typography variant="h6" element="h6">
              {progressTitle}
            </Typography>
            <Typography variant="subtext" element="p">
              (
              {progressMeasurementUnit &&
                MEASUREMENT_UNIT_ABBREVIATION[
                  progressMeasurementUnit?.toLowerCase()
                ]}
              )
            </Typography>
          </div>
        }
      >
        <div className="mt-10">
          <RankList results={rankReports} />
        </div>
        <div className="flex items-center justify-center mb-1 mt-3">
          <Pagination
            onPageChange={(i: number) => {
              setPage(i);
            }}
            totalElements={totalReports}
            pageNumber={page}
            pageSize={MAX_RANK_REPORT_MODAL_ROWS}
          >
            {() => null}
          </Pagination>
        </div>
      </Modal.Content>
    </Modal>
  );
}
