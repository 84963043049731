export const REQUIRED_FIELD_MESSAGE = "Polje je obavezno";

export const OIB_DIGIT_COUNT_MESSAGE = "Potrebno je unijeti točno 11 znamenki";
export const OIB_DIGITS_ONLY_MESSAGE = "OIB smije sadržavati samo znamenke";
export const IBAN_CHARACTER_COUNT_MESSAGE = "Potrebno je unijeti točno 21 znak";
export const IBAN_FORMAT_MESSAGE = "IBAN nije u ispravnom formatu";

export const INVALID_OIB_MESSAGE = "OIB nije unesen u ispravnom obliku";
export const INVALID_EMAIL_FORMAT_MESSAGE =
  "Email nije unesen u ispravnom obliku";

export const MIN_ONE_PARTICIPANT_MESSAGE =
  "Potrebno je unijeti barem jednog sudionika";
export const MIN_ONE_PARTICIPANT_GROUP_MESSAGE =
  "Potrebno je unijeti barem jednu grupu sudionika";
export const MIN_ONE_ATHLETE_MESSAGE =
  "Potrebno je označiti barem jednog sportaša";
export const MIN_ONE_RECIPIENT_MESSAGE =
  "Potrebno je označiti barem jednog primatelja";
export const MIN_ONE_RECIPIENT_GROUP_MESSAGE =
  "Potrebno je označiti barem jednu grupu primatelja";
export const MIN_EVENT_DURATION_MESSAGE =
  "Minimalno trajanje događaja je 5 minuta";
export const MIN_ONE_REPEATING_DAY_MESSAGE =
  "Potrebno je odabrati barem jedan dan u tjednu na koji će se događaj ponavljati";

export const END_TIME_AFTER_START_TIME_MESSAGE =
  "Vrijeme završetka mora biti nakon vremena početka";

export const MIN_AGE_LESS_THAN_MAX_AGE_MESSAGE =
  "Minimalna dob mora biti manja od maksimalne dobi";
export const MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE =
  "Maksimalna dob mora biti veća od minimalne dobi";

export const CONNECT_PARENT_WITH_CHILDREN_MESSAGE =
  "Potrebno je povezati roditelja s djetetom/djecom";
export const MAX_TWO_PARENTS = "Dijete može imati maksimalno dva roditelja";

export const NO_SPECIAL_CHARACTERS_MESSAGE =
  "Polje ne smije sadržavati posebne znakove";

export const PARAMETER_NAME_ALREADY_USED_MESSAGE =
  "Ovo ime parametra se već koristi";

export const DIGITS_ONLY_MESSAGE =
  "Broj mobitela smije sadržavati samo znamenke";

export const MUST_BE_INTEGER_MESSAGE = "Vrijednost mora biti cijeli broj";

export const MUST_BE_NUMBER_MESSAGE = "Vrijednost mora biti broj";

export const NUMBER_TOO_BIG_MESSAGE = "Prevelika brojčana vrijednost";

export const SEPARATION_COUNT_MAX_MESSAGE =
  "Unesena vrijednost mora biti manja od 52";

export const SEPARATION_COUNT_MIN_MESSAGE =
  "Unesena vrijednost mora biti veća od 0";

export const GROUP_MIN_AGE_MESSAGE = "Vrijednost mora biti veća od 0";

export const ZIP_CODE_LENGTH_MESSAGE = "Unos mora imati točno 5 brojeva";

export const STREET_REQUIRED_MESSAGE =
  "Ukoliko unosite kućni broj morate unijeti i ulicu";

export const CITY_REQUIRED_MESSAGE =
  "Ukoliko unosite poštanski broj morate unijeti i grad";

export const DATE_OF_MEMBERSHIP_START_IS_BEFORE_DATE_OF_BIRTH_MESSAGE =
  "Datum upisa u klub ne može biti prije datuma rođenja";

export const QUESTION_MIN_TWO_ANSWERS_MESSAGE =
  "Pitanje mora imati barem dva ponuđena odgovora";
export const REQUIRED_QUESTION_MESSAGE = "Potrebno je ispuniti pitanje";

export const EXPORT_DATE_INVALID_RANGE_MESSAGE =
  "Krajnji datum mora biti nakon početnog datuma";

export const DECIMAL_OR_NUMBER_MESSAGE =
  "Polje smije sadržavati samo znamenke i decimalnu točku";

export const ONLY_NUMBER_MESSAGE = "Polje smije sadržavati samo znamenke";

export const MIN_ONE_GROUP_MESSAGE = "Potrebna je označiti barem jednu grupu";

export const PERCENTAGE_MESSAGE = "Postotak mora biti između 0 i 100";

export function maxDigitCountMessage(count: number) {
  return "Moguće je unijeti najviše " + count + "znamenki";
}

export function maxCharacterCountMessage(count: number) {
  return "Moguće je unijeti najviše " + count + " znakova";
}

export function minCharacterCountMessage(count: number) {
  return (
    "Potrebno je unijeti najmanje " +
    count +
    (count < 5 ? " znaka" : " znakova")
  );
}
