import { CreateInvoiceTemplateRequest } from "./createInvoiceTemplateRequest";
import { INVOICE_TEMPLATE_URL } from "../../../common/Routes";

export async function postCreateInvoiceTemplateRequest(
  request: CreateInvoiceTemplateRequest
): Promise<Response> {
  return await fetch(`${INVOICE_TEMPLATE_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
