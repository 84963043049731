import FileDownload from "js-file-download";

import { AdminSearchRequest } from "./AdminSearchRequest";
import { ADMIN_URL } from "../../../common/Routes";

export function exportAdminsPDF(request: AdminSearchRequest) {
  fetch(`${ADMIN_URL}/export/pdf`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let date = new Date().toLocaleDateString();
      FileDownload(blob, `administratori_${date}.pdf`);
    });
}
