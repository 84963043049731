import React, { useContext } from "react";

import { Button, IconButton, Pagination, Typography } from "@tiller-ds/core";
import { Checkbox } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import emptyMailbox from "../../assets/img/emptyMailbox.svg";
import { UserContext } from "../../auth/SecurityProvider";
import MessageImportantNotice from "../../common/components/MessageImportantNotice";
import MessageSentNotice from "../../common/components/MessageSentNotice";
import RoundedImage from "../../common/components/RoundedImage";
import {
  BASE_MESSAGES_FILTER_REQUEST,
  MESSAGES_PER_PAGE,
} from "../../common/constants";
import { Message } from "../api/Message";
import { postMarkAsPinned } from "../api/postMarkAsPinned";
import { postMarkAsRead } from "../api/postMarkAsRead";
import { postMarkAsUnpinned } from "../api/postMarkAsUnpinned";
import { MessageContext } from "../MessageProvider";

type MessagesProps = {
  messages: Message[];
  onChangeMessages: (messages: Message[]) => void;
};

export default function Messages({
  messages,
  onChangeMessages,
}: MessagesProps) {
  const navigate = useNavigate();
  const { user, onChangeNumOfUnreadMessages } = useContext(UserContext);
  const {
    page,
    totalElements,
    pageSize,
    messageFilterRequest,
    setPage,
    setPageSize,
    setMessageFilterRequest,
  } = useContext(MessageContext);

  const createMessage = () => {
    navigate("/messages/new");
  };

  function onClickShowMore() {
    const totalPageSize = pageSize + MESSAGES_PER_PAGE;
    setPageSize(totalPageSize);
    setMessageFilterRequest({
      ...BASE_MESSAGES_FILTER_REQUEST,
      pageSize: totalPageSize,
    });
  }

  function toggleMessageCheckbox(messageId: number) {
    onChangeMessages(
      messages.map((message) => {
        if (message.id === messageId) {
          return { ...message, isChecked: !message.isChecked };
        } else {
          return message;
        }
      })
    );
  }

  function toggleMessagePinned(messageId: number) {
    if (messages.find((message) => message.id === messageId)?.isPinned) {
      postMarkAsUnpinned({
        messageIds: [messageId],
      }).then(() =>
        onChangeMessages(
          messages.map((message) => {
            if (message.id === messageId) {
              return { ...message, isPinned: !message.isPinned };
            } else {
              return message;
            }
          })
        )
      );
    } else {
      postMarkAsPinned({
        messageIds: [messageId],
      }).then(() =>
        onChangeMessages(
          messages.map((message) => {
            if (message.id === messageId) {
              return { ...message, isPinned: !message.isPinned };
            } else {
              return message;
            }
          })
        )
      );
    }
  }

  function onClickMessage(messageId: number) {
    const message = messages.find((message) => message.id === messageId);
    if (message) {
      if (!message.isRead) {
        postMarkAsRead({
          messageIds: [messageId],
        }).then(() => {
          onChangeNumOfUnreadMessages(user.numOfUnreadMessages - 1);
        });
      }

      onChangeMessages(
        messages.map((message) => {
          if (message.id === messageId) {
            return { ...message, isRead: true };
          }
          return message;
        })
      );
      navigate(`/message-details/${messageId}`);
    }
  }

  function getMessageClassName(message: Message, index: number) {
    return classNames({
      "flex mr-5 w-full items-center gap-x-4 lg:gap-x-8 pt-3 pb-3": true,
      "border-l-[#EF4444] border-l-4": message.isImportant,
      "pl-1": !message.isImportant,
      "bg-gray-200": !message.isRead,
      "bg-navy-75": message.isRead,
      "bg-blue-100": message.isChecked,
      "border-b-2 border-b-[#ACB6D8]": index !== messages.length - 1,
    });
  }

  return (
    <div className="pb-0 md:pb-8">
      {messages.length === 0 ? (
        <div>
          <div className="flex justify-center items-center min-h-[70vh]">
            <div className="flex flex-col items-center">
              <img
                className="w-1/2 md:w-1/3"
                src={emptyMailbox}
                alt="No messages"
              />
              <Typography className="mt-4" element="p" variant="subtitle">
                Trenutno nema poruka za čitanje...
              </Typography>
            </div>
          </div>
          <div className="lg:hidden float-right sticky bottom-7 right-4 sm:right-16">
            <Button
              leadingIcon={<Icon type="plus" size={3} variant="bold" />}
              variant="filled"
              size="lg"
              onClick={createMessage}
            >
              <div className="whitespace-nowrap">Nova poruka</div>
            </Button>
          </div>
        </div>
      ) : (
        <div className="mb-5 relative">
          <div className="bg-white flex-col w-full shadow-lg">
            {messages.map((message, index) => {
              return (
                <div className={getMessageClassName(message, index)}>
                  <div className="flex ml-1 md:ml-4 gap-x-2">
                    <Checkbox
                      checked={message.isChecked}
                      onChange={() => {
                        toggleMessageCheckbox(message.id);
                      }}
                    />
                    <IconButton
                      showTooltip={false}
                      icon={
                        <Icon
                          type="star"
                          variant={message.isPinned ? "fill" : "regular"}
                          className={
                            message.isPinned
                              ? "pt-1 text-yellow-500"
                              : "pt-1 text-gray-500"
                          }
                        />
                      }
                      onClick={() => toggleMessagePinned(message.id)}
                      className="border-0 h-full self-center"
                    />
                  </div>
                  <div
                    className="flex w-full overflow-hidden overflow-ellipsis gap-x-8 items-center cursor-pointer"
                    onClick={() => onClickMessage(message.id)}
                  >
                    <div className="w-4/5 lg:w-[150px] xl:w-[200px] shrink-0">
                      <RoundedImage message={message} />
                    </div>
                    <div className="hidden lg:block">
                      <Typography element="p">
                        {message.isSent && <MessageSentNotice />}
                        {message.isImportant && <MessageImportantNotice />}
                        {message.isRead ? (
                          message.title
                        ) : (
                          <b>{message.title}</b>
                        )}
                      </Typography>
                    </div>
                    <div className="hidden lg:flex ml-auto mr-5 whitespace-nowrap lg:w-[150px] xl:w-[200px] justify-end shrink-0">
                      <Typography element="p" variant="text">
                        {message.timeSent}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {totalElements > MESSAGES_PER_PAGE && (
            <div className="hidden lg:block float-right mt-2.5">
              <Pagination
                onPageChange={(i: number) => {
                  setPage(i);
                  setMessageFilterRequest({
                    ...messageFilterRequest,
                    pageNumber: i,
                  });
                }}
                pageNumber={page}
                pageSize={pageSize}
                totalElements={totalElements}
              >
                {() => <></>}
              </Pagination>
            </div>
          )}
          <div
            className={
              (pageSize >= totalElements ? "invisible h-[30px] " : "mb-8 ") +
              "lg:hidden w-fit block ml-auto mr-auto mt-2.5"
            }
          >
            <Button
              className="mb-4"
              variant="text"
              onClick={() => onClickShowMore()}
            >
              <span className="border-b-2 border-b-indigo-600 leading-3">
                Prikaži više...
              </span>
            </Button>
          </div>
          <div
            className={
              "lg:hidden float-right sticky bottom-7 right-4 sm:right-16" +
              (pageSize >= totalElements && " bottom-0")
            }
          >
            <Button
              leadingIcon={<Icon type="plus" size={3} variant="bold" />}
              variant="filled"
              size="lg"
              onClick={createMessage}
            >
              Nova poruka
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
