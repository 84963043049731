import React from "react";

import { Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { EventResponse } from "../../../event/api/EventResponse";

type EventCardWithTimeAndLocationProps = {
  event: EventResponse;
  className?: string;
};

export default function EventCardWithTimeAndLocation({
  event,
  className,
}: EventCardWithTimeAndLocationProps) {
  let days = new Map([
    [1, "Pon"],
    [2, "Uto"],
    [3, "Sri"],
    [4, "Čet"],
    [5, "Pet"],
    [6, "Sub"],
    [0, "Ned"],
  ]);

  const eventTime = new Date(event.startTime).toLocaleTimeString("hr-HR", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const eventDay = new Date(event.startTime).getDay();

  const eventDate = `${new Date(event.startTime).getDate()}.${
    new Date(event.startTime).getMonth() + 1
  }.${new Date(event.startTime).getFullYear()}.`;

  return (
    <div
      className={`${className} flex flex-col bg-navy-50 rounded-md p-2 border-navy-500 border-l-4`}
    >
      <div className="flex flex-col">
        <div className="flex gap-x-1">
          <Icon type="calendar-blank" variant="bold" />
          <Typography
            element="h6"
            variant="h6"
            className="align-center text-gray-900"
          >
            {`${eventDate} (${days.get(eventDay)})`}
          </Typography>
        </div>
        <div className="flex gap-x-1">
          <Icon type="clock" variant="bold" />
          <Typography
            element="h6"
            variant="h6"
            className="align-center text-gray-900"
          >
            {`${eventTime}h`}
          </Typography>
        </div>
      </div>
      <Typography element="p" variant="subtext" className="text-navy-400">
        {`${event.locationName}`}
        {event.locationAddress && `, ${event.locationAddress}`}
      </Typography>
    </div>
  );
}
