import { PARENT_URL } from "../../../common/Routes";

export function deleteParent(id: number) {
  return fetch(`${PARENT_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }).then((res) => {
    return res;
  });
}
