import React from "react";

import { Button } from "@tiller-ds/core";
import { InputField, NumberInputField } from "@tiller-ds/formik-elements";

import { Formik } from "formik";
import * as yup from "yup";

import {
  MAX_GROUP_NAME_LENGTH,
  MAX_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
} from "../../../common/constants";
import {
  MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE,
  maxCharacterCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
} from "../../../common/YupConstants";
import { GroupSearchRequest } from "../api/GroupSearchRequest";

export type GroupRegistryFilterCardProps = {
  setSearchRequestJson: (searchRequest: GroupSearchRequest) => void;
  searchRequestJson: GroupSearchRequest;
};

type Form = {
  groupName: string;
  minAgeFromIncluding: number | undefined;
  maxAgeToIncluding: number | undefined;
  coachesFirstName: string;
  coachesLastName: string;
};

const initialValues = {
  groupName: "",
  minAgeFromIncluding: undefined,
  maxAgeToIncluding: undefined,
  coachesFirstName: "",
  coachesLastName: "",
};

const validationSchema = yup.object().shape({
  groupName: yup
    .string()
    .max(MAX_GROUP_NAME_LENGTH, maxCharacterCountMessage(MAX_GROUP_NAME_LENGTH))
    .nullable(),
  minAgeFromIncluding: yup.number().nullable(),
  maxAgeToIncluding: yup
    .number()
    .nullable()
    .when("minAgeFromIncluding", {
      is: (minAgeFromIncluding: number) => {
        return minAgeFromIncluding;
      },
      then: yup
        .number()
        .min(yup.ref("minAgeFromIncluding"), MAX_AGE_MORE_THAN_MIN_AGE_MESSAGE),
    }),
  coachesFirstName: yup
    .string()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  coachesLastName: yup
    .string()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
});

export default function GroupRegistryFilterCard({
  setSearchRequestJson,
  searchRequestJson,
}: GroupRegistryFilterCardProps) {
  const onSubmitForm = (form: Form) => {
    setSearchRequestJson({
      ...searchRequestJson,
      groupName: form.groupName === "" ? null : form.groupName,
      coachesFirstName:
        form.coachesFirstName === "" ? null : form.coachesFirstName,
      coachesLastName:
        form.coachesLastName === "" ? null : form.coachesLastName,
      minAgeFromIncluding:
        form.minAgeFromIncluding === undefined
          ? null
          : form.minAgeFromIncluding,
      maxAgeToIncluding:
        form.maxAgeToIncluding === undefined ? null : form.maxAgeToIncluding,
    });
  };

  function onClickResetFilter() {
    setSearchRequestJson({
      ...searchRequestJson,
      groupName: null,
      coachesFirstName: null,
      coachesLastName: null,
      minAgeFromIncluding: null,
      maxAgeToIncluding: null,
    });
  }

  return (
    <div className="w-full h-fit mb-5 bg-navy-50 border rounded-lg p-2">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
          >
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
              <InputField
                className="w-full"
                name="groupName"
                label="Ime grupe"
              ></InputField>
              <div className="w-full grid grid-cols-1 gap-2 sm:grid-cols-2">
                <NumberInputField
                  className="w-full"
                  name="minAgeFromIncluding"
                  label="Dob - Od"
                  maxLength={2}
                  allowNegative={false}
                ></NumberInputField>
                <NumberInputField
                  className="w-full"
                  name="maxAgeToIncluding"
                  label="Dob - Do"
                  maxLength={2}
                  allowNegative={false}
                ></NumberInputField>
              </div>
              <InputField
                className="w-full"
                name="coachesFirstName"
                label="Ime trenera"
              ></InputField>
              <InputField
                className="w-full"
                name="coachesLastName"
                label="Prezime trenera"
              ></InputField>
            </div>
            <div className="flex flex-row md:flex-none justify-end gap-2">
              <Button
                variant="outlined"
                type="reset"
                onClick={() => {
                  formik.resetForm();
                  onClickResetFilter();
                }}
              >
                Poništi
              </Button>
              <Button variant="filled" type="submit">
                Filtriraj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
