import React, { useEffect, useState } from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import {
  AutocompleteField,
  DateInputField,
  InputField,
  NumberInputField,
  SelectField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik, useField } from "formik";
import * as yup from "yup";

import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  GENDER_OPTIONS,
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
  OIB_LENGTH,
  PHONE_NUMBER_REGEX,
  ROLE_OPTIONS,
  SelectType,
} from "../../../common/constants";
import { isOibValid } from "../../../common/isOibValid";
import {
  CONNECT_PARENT_WITH_CHILDREN_MESSAGE,
  DATE_OF_MEMBERSHIP_START_IS_BEFORE_DATE_OF_BIRTH_MESSAGE,
  DECIMAL_OR_NUMBER_MESSAGE,
  DIGITS_ONLY_MESSAGE,
  INVALID_EMAIL_FORMAT_MESSAGE,
  INVALID_OIB_MESSAGE,
  maxCharacterCountMessage,
  maxDigitCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
  OIB_DIGIT_COUNT_MESSAGE,
  OIB_DIGITS_ONLY_MESSAGE,
  PERCENTAGE_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import ConfirmCreateAdminModal from "../../admin/components/ConfirmCreateAdminModal";
import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import { CreateAthleteRequest } from "../../athlete/api/CreateAthleteRequest";
import { postCreateAthleteRequest } from "../../athlete/api/postCreateAthleteRequest";
import { postSearchAthleteRequestNonPaged } from "../../athlete/api/postSearchAthleteRequestNonPaged";
import { CreateCoachRequest } from "../../coach/api/CreateCoachRequest";
import { postCreateCoachRequest } from "../../coach/api/postCreateCoachRequest";
import { GroupResponse } from "../../group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../group/api/postSearchGroupRequestNonPaged";
import { CreateParentRequest } from "../../parent/api/CreateParentRequest";
import { postCreateParentRequest } from "../../parent/api/postCreateParentRequest";
import handleCreateResponse from "../handleCreateResponse";
import { isBlank } from "../util/stringUtil";

type CreateUserModalProps = {
  entityCreated?: number;
  setEntityCreated?: (entityCreated: number) => void;
  modal: UseModal;
  group?: GroupResponse;
};

type Form = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string | null;
  roleSelect: string;
  oib: string;
  dateOfBirth: Date | string;
  dateOfMembershipStart: Date | string;
  discountPercentage?: number | string;
  athletes: AthleteResponse[];
  groups: GroupResponse[];
  genderSelect: string;
};

const validationSchema = {
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  phoneNumber: yup
    .string()
    .nullable()
    .max(MAX_PHONE_NUMBER_LENGTH, maxDigitCountMessage(MAX_PHONE_NUMBER_LENGTH))
    .matches(new RegExp(PHONE_NUMBER_REGEX), DIGITS_ONLY_MESSAGE),
  roleSelect: yup.string().required(REQUIRED_FIELD_MESSAGE),
};

const validationSchemaAthlete = yup.object().shape({
  ...validationSchema,
  email: yup
    .string()
    .nullable()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE),
  dateOfBirth: yup.date().required(REQUIRED_FIELD_MESSAGE),
  oib: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .matches(/^\d{0,11}$/, OIB_DIGITS_ONLY_MESSAGE)
    .length(OIB_LENGTH, OIB_DIGIT_COUNT_MESSAGE)
    .test(
      "oibValidationTest",
      INVALID_OIB_MESSAGE,
      (input: String | undefined | null) => {
        if (input) {
          return isOibValid(input);
        }
        return false;
      }
    ),
  genderSelect: yup.string().required(REQUIRED_FIELD_MESSAGE),
  dateOfMembershipStart: yup
    .date()
    .required(REQUIRED_FIELD_MESSAGE)
    .when("dateOfBirth", (dateOfBirth) => {
      if (dateOfBirth)
        return yup
          .date()
          .required(REQUIRED_FIELD_MESSAGE)
          .min(
            dateOfBirth,
            DATE_OF_MEMBERSHIP_START_IS_BEFORE_DATE_OF_BIRTH_MESSAGE
          );
      else return yup.date().required(REQUIRED_FIELD_MESSAGE);
    }),
  discountPercentage: yup
    .number()
    .min(0, PERCENTAGE_MESSAGE)
    .max(100, PERCENTAGE_MESSAGE)
    .typeError(DECIMAL_OR_NUMBER_MESSAGE),
});

const validationSchemaCoach = yup.object().shape({
  ...validationSchema,
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE),
  oib: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .matches(/^\d{0,11}$/, OIB_DIGITS_ONLY_MESSAGE)
    .length(OIB_LENGTH, OIB_DIGIT_COUNT_MESSAGE)
    .test(
      "oibValidationTest",
      INVALID_OIB_MESSAGE,
      (input: String | undefined | null) => {
        if (input) {
          return isOibValid(input);
        }
        return false;
      }
    ),
});

const validationSchemaParent = yup.object().shape({
  ...validationSchema,
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE),
  athletes: yup
    .array()
    .of(yup.object())
    .min(1, CONNECT_PARENT_WITH_CHILDREN_MESSAGE),
});

const validationSchemaAdmin = yup.object().shape({
  ...validationSchema,
});

type RoleSelectedDivProps = {
  setValidation: (validation: any) => void;
};

function RoleSelectedDiv({ setValidation }: RoleSelectedDivProps) {
  const [roleSelectedField] = useField("roleSelect");
  const [groupsAutocomplete, setGroupsAutocomplete] = useState(
    [] as GroupResponse[]
  );

  function athleteSearch(query: string) {
    if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH)
      return Promise.resolve([] as AthleteResponse[]);
    else {
      const [firstName, lastName] = query.split(" ");
      if (query.split(" ").length > 1) {
        return postSearchAthleteRequestNonPaged({
          firstName: firstName,
          lastName: lastName,
        });
      } else
        return postSearchAthleteRequestNonPaged({
          firstName: query,
          lastName: query,
        });
    }
  }

  useEffect(() => {
    postSearchGroupRequestNonPaged({
      groupName: "",
      briefResponse: true,
    }).then((response) => {
      if (response) {
        setGroupsAutocomplete(response);
      }
    });
  }, []);

  if (roleSelectedField.value === ROLE_OPTIONS[0].value) {
    setValidation(validationSchemaAthlete);
  }
  if (roleSelectedField.value === ROLE_OPTIONS[1].value) {
    setValidation(validationSchemaCoach);
  }
  if (roleSelectedField.value === ROLE_OPTIONS[2].value) {
    setValidation(validationSchemaParent);
  }
  if (roleSelectedField.value === ROLE_OPTIONS[3].value) {
    setValidation(validationSchemaAdmin);
  }

  return (
    <>
      <div className="border-y">
        {roleSelectedField.value === ROLE_OPTIONS[0].value ? (
          <div className="flex flex-col gap-4 mt-6 mb-6">
            <div className="flex flex-col sm:flex-row justify-between gap-4">
              <InputField
                name="oib"
                label="OIB"
                required={true}
                maxLength={OIB_LENGTH}
                className="sm:w-1/2"
              />
              <DateInputField
                name="dateOfBirth"
                label="Datum rođenja"
                required={true}
                className="sm:w-1/2"
                maxDate={new Date()}
                allowClear={false}
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-between gap-4">
              <SelectField
                name="genderSelect"
                label="Spol"
                options={GENDER_OPTIONS}
                getOptionLabel={(item: SelectType) => item.name}
                getOptionValue={(item: SelectType) => item.value}
                required={true}
                className="sm:w-1/2 sm:pr-2"
              />
              <DateInputField
                name="dateOfMembershipStart"
                label="Datum upisa u klub"
                required={true}
                className="sm:w-1/2"
                maxDate={new Date()}
                allowClear={false}
              />
            </div>
            <NumberInputField
              name="discountPercentage"
              label="Popust članarine"
              className="mt-3"
              allowClear={false}
              required={false}
              inlineTrailingIcon={<Icon type="percent" variant="light" />}
              decimalScale={2}
              placeholder="0,00"
              fixedDecimalScale
            />
            <AutocompleteField
              className="w-full"
              name="groups"
              label="Dodijeli grupu/e sportašu"
              options={groupsAutocomplete}
              allowMultiple
              getOptionLabel={(group) => `${group.groupName}`}
              getOptionValue={(item) => {
                return item.id;
              }}
              filter={(query: string, group) =>
                `${group.groupName.toLowerCase()}`.includes(query.toLowerCase())
              }
              getMultipleSelectedLabel={(items) => {
                return items.map((group) => `${group.groupName}`).join(", ");
              }}
              sendOptionValue={false}
            />
          </div>
        ) : null}
        {roleSelectedField.value === ROLE_OPTIONS[2].value ? (
          <div className="flex flex-col justify-start gap-4 mt-6 mb-6">
            <Typography
              element="h3"
              variant="h6"
              className="flex justify-start"
            >
              Poveži roditelja sa sportašem/ima
            </Typography>
            <AutocompleteField
              className="w-full mt-2"
              name="athletes"
              label="Sportaš/i"
              options={athleteSearch}
              placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
              allowMultiple
              getOptionLabel={(athlete) => (
                <div className="flex items-center justify-between flex-wrap">
                  <div>
                    {athlete.firstName} {athlete.lastName}
                  </div>
                  <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
                    {new Date(athlete.dateOfBirth).toLocaleDateString("hr-HR")}
                  </div>
                </div>
              )}
              getOptionValue={(item) => {
                return item.id;
              }}
              getMultipleSelectedLabel={(items) => {
                return items
                  .map((athlete) => `${athlete.firstName} ${athlete.lastName}`)
                  .join(", ");
              }}
              sendOptionValue={false}
              required={true}
            />
          </div>
        ) : null}
        {roleSelectedField.value === ROLE_OPTIONS[1].value ? (
          <div className="flex flex-col mt-6 mb-6 gap-4">
            <InputField
              name="oib"
              label="OIB"
              required={true}
              className="sm:w-1/2 sm:pr-2"
            />
            <AutocompleteField
              className="w-full"
              name="groups"
              label="Dodijeli grupu/e treneru"
              options={groupsAutocomplete}
              allowMultiple
              getOptionLabel={(group) => `${group.groupName}`}
              getOptionValue={(item) => {
                return item.id;
              }}
              filter={(query: string, group) =>
                `${group.groupName.toLowerCase()}`.includes(query.toLowerCase())
              }
              getMultipleSelectedLabel={(items) => {
                return items.map((group) => `${group.groupName}`).join(", ");
              }}
              sendOptionValue={false}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default function CreateUserModal({
  entityCreated,
  setEntityCreated,
  modal,
  group,
}: CreateUserModalProps) {
  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: null,
    roleSelect: "",
    oib: "",
    dateOfBirth: "2000-01-01",
    dateOfMembershipStart: new Date(),
    discountPercentage: undefined,
    athletes: [],
    groups: group ? [group] : [],
    genderSelect: GENDER_OPTIONS[0].value,
  } as Form;

  const { add: addNotification } = useNotifications();
  const confirmCreateAdminModal = useModal();

  function onSubmitCreateUser(values: Form) {
    let request = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber.length > 0 ? values.phoneNumber : null,
      email: !isBlank(values.email) ? values.email : null,
    };

    if (values.roleSelect === ROLE_OPTIONS[0].value) {
      postCreateAthleteRequest({
        ...request,
        oib: values.oib,
        dateOfBirth: values.dateOfBirth,
        gender: values.genderSelect,
        dateOfMembershipStart: values.dateOfMembershipStart,
        discountPercentage: values.discountPercentage ?? 0,
        groupIds: values.groups.map((group) => {
          return group.id;
        }),
      } as CreateAthleteRequest).then((response) => {
        if (handleCreateResponse(response, addNotification)) {
          if (entityCreated !== undefined && setEntityCreated !== undefined) {
            setEntityCreated(entityCreated + 1);
          }
          modal.onClose();
        }
      });
    } else if (values.roleSelect === ROLE_OPTIONS[1].value) {
      postCreateCoachRequest({
        ...request,
        oib: values.oib,
        groupIds: values.groups.map((group) => {
          return group.id;
        }),
      } as CreateCoachRequest).then((response) => {
        if (handleCreateResponse(response, addNotification)) {
          if (entityCreated !== undefined && setEntityCreated !== undefined) {
            setEntityCreated(entityCreated + 1);
          }
          modal.onClose();
        }
      });
    } else if (values.roleSelect.valueOf() === ROLE_OPTIONS[2].value) {
      postCreateParentRequest({
        ...request,
        childrenIds: values.athletes.map((athlete) => {
          return athlete.id;
        }),
      } as CreateParentRequest).then((response) => {
        if (handleCreateResponse(response, addNotification)) {
          if (entityCreated !== undefined && setEntityCreated !== undefined) {
            setEntityCreated(entityCreated + 1);
          }
          modal.onClose();
        }
      });
    } else if (values.roleSelect.valueOf() === ROLE_OPTIONS[3].value) {
      confirmCreateAdminModal.onOpen(null);
    }
  }

  const [validation, setValidation] = useState(validationSchemaAthlete);

  return (
    <>
      <Modal {...modal}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitCreateUser}
          validationSchema={validation}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="pr-3"
              autoComplete="off"
            >
              <Typography element="h3" variant="h5" className="mb-6">
                Kreiranje korisnika
              </Typography>
              <div className="flex flex-col gap-y-4 mb-6">
                <div className="flex flex-col sm:flex-row mt-3 justify-between gap-4">
                  <InputField
                    name="firstName"
                    label="Ime"
                    required={true}
                    className="sm:w-1/2"
                  />
                  <InputField
                    name="lastName"
                    label="Prezime"
                    required={true}
                    className="sm:w-1/2"
                  />
                </div>
                <div className="flex flex-col sm:flex-row justify-between gap-4">
                  <InputField
                    name="email"
                    label="E-mail"
                    className="sm:w-1/2"
                    required={formik.values.roleSelect !== "ATHLETE"}
                  />
                  <InputField
                    name="phoneNumber"
                    label="Broj telefona"
                    className="sm:w-1/2"
                  />
                </div>
                <SelectField
                  name="roleSelect"
                  label="Uloga člana u sustavu"
                  options={ROLE_OPTIONS}
                  getOptionLabel={(item: SelectType) => item.name}
                  getOptionValue={(item: SelectType) => item.value}
                  required={true}
                  className="sm:w-1/2 sm:pr-2"
                />
              </div>
              <RoleSelectedDiv setValidation={setValidation} />
              <Modal.Footer>
                <div className="flex justify-end space-x-2">
                  <Button
                    type="button"
                    className="mr-2"
                    variant="outlined"
                    onClick={() => {
                      modal.onClose();
                    }}
                  >
                    Odustani
                  </Button>
                  <Button variant="filled" type="submit">
                    Kreiraj korisnika
                  </Button>
                </div>
              </Modal.Footer>
              <ConfirmCreateAdminModal
                modal={confirmCreateAdminModal}
                entityCreated={entityCreated}
                setEntityCreated={setEntityCreated}
                createUserModal={modal}
              />
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
