import React, { useEffect, useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { AutocompleteField, InputField } from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_EMAIL_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
  OIB_LENGTH,
  PHONE_NUMBER_REGEX,
} from "../../../common/constants";
import { isOibValid } from "../../../common/isOibValid";
import {
  DIGITS_ONLY_MESSAGE,
  INVALID_EMAIL_FORMAT_MESSAGE,
  INVALID_OIB_MESSAGE,
  maxCharacterCountMessage,
  maxDigitCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
  OIB_DIGIT_COUNT_MESSAGE,
  OIB_DIGITS_ONLY_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import handleUpdateResponse from "../../common/handleUpdateResponse";
import { GroupResponse } from "../../group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../group/api/postSearchGroupRequestNonPaged";
import { CoachResponse } from "../api/CoachResponse";
import { putUpdateCoachRequest } from "../api/putUpdateCoachRequest";
import { UpdateCoachRequest } from "../api/UpdateCoachRequest";

type UpdateCoachModalProps = {
  modal: UseModal;
  coach: CoachResponse;
  setRender: (render: boolean) => void;
};

type Form = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  oib: string;
  groups: GroupResponse[];
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE),
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .min(MIN_EMAIL_LENGTH, minCharacterCountMessage(MIN_EMAIL_LENGTH))
    .max(MAX_EMAIL_LENGTH, maxCharacterCountMessage(MAX_EMAIL_LENGTH))
    .email(INVALID_EMAIL_FORMAT_MESSAGE),
  phoneNumber: yup
    .string()
    .nullable()
    .max(MAX_PHONE_NUMBER_LENGTH, maxDigitCountMessage(MAX_PHONE_NUMBER_LENGTH))
    .matches(new RegExp(PHONE_NUMBER_REGEX), DIGITS_ONLY_MESSAGE),
  oib: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .matches(/^\d{0,11}$/, OIB_DIGITS_ONLY_MESSAGE)
    .length(OIB_LENGTH, OIB_DIGIT_COUNT_MESSAGE)
    .test(
      "oibValidationTest",
      INVALID_OIB_MESSAGE,
      (input: String | undefined | null) => {
        if (input) {
          return isOibValid(input);
        }
        return false;
      }
    ),
  groups: yup.array().of(yup.object()),
});

const UpdateCoachModal = ({
  modal,
  coach,
  setRender,
}: UpdateCoachModalProps) => {
  const { add: addNotification } = useNotifications();
  const [groupsAutocomplete, setGroupsAutocomplete] = useState(
    [] as GroupResponse[]
  );

  useEffect(() => {
    postSearchGroupRequestNonPaged({
      groupName: "",
      briefResponse: true,
    }).then((response) => {
      setGroupsAutocomplete(response);
    });
  }, []);

  const initialFormValues = {
    firstName: coach.firstName,
    lastName: coach.lastName,
    phoneNumber: coach.phoneNumber,
    email: coach.email,
    oib: coach.oib,
    groups: coach.groups,
  } as Form;

  const onSubmitForm = (form: Form) => {
    putUpdateCoachRequest(
      {
        firstName: form.firstName,
        lastName: form.lastName,
        phoneNumber: form.phoneNumber ? form.phoneNumber : null,
        email: form.email,
        oib: form.oib,
        groupToRemoveIds: coach.groups.map((group) => {
          return group.id;
        }),
        groupToAddIds: form.groups.map((group) => {
          return group.id;
        }),
      } as UpdateCoachRequest,
      coach.id
    ).then((response) => {
      if (handleUpdateResponse(response, addNotification)) {
        setRender(true);
        modal.onClose();
      }
    });
  };

  return (
    <>
      <Modal {...modal}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="pr-3"
              autoComplete="off"
            >
              <Typography className="mb-4" variant="h4" element="h4">
                Uredi podatke trenera
              </Typography>
              <div className="flex">
                <InputField
                  name="firstName"
                  label="Ime"
                  className="mr-3 w-1/2"
                  allowClear
                  required
                  placeholder={coach.firstName}
                />
                <InputField
                  name="lastName"
                  label="Prezime"
                  className="ml-3 w-1/2"
                  allowClear
                  required
                  placeholder={coach.lastName}
                />
              </div>
              <InputField
                name="email"
                label="Email"
                className="mt-3"
                allowClear
                required
                placeholder={coach.email}
              />
              <InputField
                name="phoneNumber"
                label="Broj mobitela"
                className="mt-3"
                allowClear
              />
              <InputField
                name="oib"
                label="OIB"
                className="mt-3"
                maxLength={OIB_LENGTH}
                allowClear
                required
              />
              <AutocompleteField
                className="w-full mt-3"
                name="groups"
                label="Grupe trenera"
                options={groupsAutocomplete}
                allowMultiple
                getOptionLabel={(group) => `${group.groupName}`}
                getOptionValue={(item) => {
                  return item.id;
                }}
                filter={(query: string, group) =>
                  `${group.groupName.toLowerCase()}`.includes(
                    query.toLowerCase()
                  )
                }
                getMultipleSelectedLabel={(items) => {
                  return items.map((group) => `${group.groupName}`).join(", ");
                }}
                sendOptionValue={false}
              />
              <div className="mt-6 flex flex-row justify-end">
                <Button
                  type="button"
                  className="mr-2 w-100"
                  variant="outlined"
                  onClick={modal.onClose}
                >
                  Odbaci promjene
                </Button>
                <Button className="w-1/5" variant="filled" type="submit">
                  Spremi
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateCoachModal;
