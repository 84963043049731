import React from "react";

import { Card, Typography } from "@tiller-ds/core";
import { Date } from "@tiller-ds/date";
import { Icon } from "@tiller-ds/icons";

import formatDateInTimeZone from "../../../common/formatDateInTimeZone";
import { typographyCardTokens } from "../../pages/EventDetailsPage";

type EventTimeCardProps = {
  startDate: Date;
  endDate?: Date;
  startTime: string;
  endTime: string;
  repeatingEvent: string;
};

export default function EventTimeCard({
  startDate,
  endDate,
  startTime,
  endTime,
  repeatingEvent,
}: EventTimeCardProps) {
  const formattedStartTime = formatDateInTimeZone(startTime, "HH:mm");
  const formattedEndTime = formatDateInTimeZone(endTime, "HH:mm");
  const getDateString = (date: Date) =>
    `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;

  return (
    <Card className="h-full">
      <div className="p-4 flex lg:flex-col lg:justify-between h-full">
        <div className="mr-2 px-2 lg:px-0 my-auto lg:my-0">
          <Icon
            type="calendar-blank"
            size={6}
            variant="fill"
            className="w-8 h-8 lg:w-6 lg:h-6"
          />
        </div>
        <div className="grid gap-y-1">
          {endDate ? (
            <>
              <div className="flex">
                <Typography
                  tokens={typographyCardTokens}
                  element="h3"
                  variant="h6"
                >
                  {getDateString(startDate)}
                </Typography>
                <Typography
                  element="p"
                  variant="subtext"
                  className="pl-1 pt-0.5 font-normal"
                >
                  {`(${formattedStartTime}h)`}
                </Typography>
              </div>
              <div className="flex">
                <Typography
                  tokens={typographyCardTokens}
                  element="h3"
                  variant="h6"
                >
                  {getDateString(endDate)}
                </Typography>
                <Typography
                  element="p"
                  variant="subtext"
                  className="pl-1 pt-0.5 font-normal"
                >{`(${formattedEndTime}h)`}</Typography>
              </div>
            </>
          ) : (
            <>
              <Typography
                element="p"
                variant="subtext"
                className={repeatingEvent ? "text-gray-500" : "hidden"}
              >
                {`(${repeatingEvent})`}
              </Typography>
              <Typography
                tokens={typographyCardTokens}
                element="h3"
                variant="h6"
                className="lg:pt-8"
              >
                {getDateString(startDate)}
              </Typography>
              <Typography
                element="p"
                variant="subtext"
                className="font-normal"
              >{`${formattedStartTime}h - ${formattedEndTime}h`}</Typography>
            </>
          )}
        </div>
      </div>
    </Card>
  );
}
