import { GroupSearchRequest } from "./GroupSearchRequest";
import { GROUP_URL } from "../../../common/Routes";

export async function postSearchGroupRequest(request: GroupSearchRequest) {
  const response = await fetch(`${GROUP_URL}/search/pageable`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
