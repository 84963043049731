import React from "react";

import { Breadcrumbs, Link, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import MessageDetails from "../components/MessageDetails";

export default function MessageDetailsPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6 gap-y-4">
      <div className="flex flex-col w-full hidden md:block">
        <Typography
          element="h3"
          variant="h3"
          className="hidden md:inline font-biotiff"
        >
          Čitanje poruke
        </Typography>
        <div className="mt-4">
          <Breadcrumbs>
            <Breadcrumbs.Breadcrumb>
              <Link to="/messages">Poruke</Link>
            </Breadcrumbs.Breadcrumb>
            <Breadcrumbs.Breadcrumb>Čitanje poruke</Breadcrumbs.Breadcrumb>
          </Breadcrumbs>
        </div>
      </div>
      <div className="md:hidden">
        <div className="flex gap-x-4">
          <div
            className="flex cursor-pointer"
            onClick={() => navigate("/messages")}
          >
            <Icon type="caret-left" variant="bold" className="my-auto ml-3" />
          </div>
          <Typography element="h6" variant="h6">
            Čitanje poruke
          </Typography>
        </div>
      </div>
      <MessageDetails />
    </div>
  );
}
