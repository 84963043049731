import { AthleteResponse } from "./AthleteResponse";
import { AthleteSearchRequest } from "./AthleteSearchRequest";
import { ATHLETE_URL } from "../../../common/Routes";

export async function postSearchAthleteRequestNonPaged(
  request: AthleteSearchRequest
): Promise<AthleteResponse[]> {
  const response = await fetch(`${ATHLETE_URL}/search`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
