import React, { useEffect, useState } from "react";

import { Card, Pagination, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { DateInput } from "@tiller-ds/date";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import moment from "moment";

import { MAX_REPORT_ROWS } from "../../../common/constants";
import { AthleteAttendanceReportResponse } from "../../api/AthleteAttendanceReportResponse";
import { AthleteGroupReportResponse } from "../../api/AthleteGroupReportResponse";
import { exportAttendanceReportsExcel } from "../../api/exportAttendanceReportsExcel";
import { exportAttendanceReportsPDF } from "../../api/exportAttendanceReportsPDF";
import { getAthleteAttendanceReports } from "../../api/getAthleteAttendanceReports";
import { getAthleteGroupReports } from "../../api/getAthleteGroupReports";
import { firstDayOfMonthDate, lastDayOfMonthDate } from "../../util/dateUtil";

export function AttendanceReportData() {
  const [groups, setGroups] = useState<AthleteGroupReportResponse[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    firstDayOfMonthDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(lastDayOfMonthDate());
  const [selectedGroup, setSelectedGroup] = useState<
    AthleteGroupReportResponse
  >();
  const [attendanceReports, setAttendanceReports] = useState<
    AthleteAttendanceReportResponse[]
  >([]);
  const [totalAttendanceReports, setTotalAttendanceReports] = useState<number>(
    0
  );
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    getAthleteGroupReports({
      briefResponse: true,
    }).then((response) => {
      setGroups(response);
      if (response.length > 0) {
        setSelectedGroup(response[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (startDate === null || endDate === null) {
      return;
    }
    if (selectedGroup !== undefined) {
      getAthleteAttendanceReports({
        groupId: selectedGroup.id,
        startDate: moment(startDate).add(1, "day").toDate(),
        endDate: moment(endDate).add(1, "day").toDate(),
        pageNumber: page,
        pageSize: MAX_REPORT_ROWS,
      }).then((response) => {
        setAttendanceReports(response.content);
        setTotalAttendanceReports(response.totalElements);
      });
    }
  }, [startDate, endDate, selectedGroup, page]);

  const exportReport = (format: "excel" | "pdf") => {
    if (selectedGroup === undefined || startDate === null || endDate === null) {
      return;
    }

    const request = {
      groupId: selectedGroup.id,
      startDate: moment(startDate).add(1, "day").toDate(),
      endDate: moment(endDate).add(1, "day").toDate(),
    };
    format === "excel"
      ? exportAttendanceReportsExcel(request)
      : exportAttendanceReportsPDF(request);
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-1">
        <Typography variant="h4" element="h4">
          Podatci o izostancima
        </Typography>
        <Typography variant="text" element="p" className="text-gray-600">
          Podatci prikazani u tablici predstavljaju stanje na odabrani datum
        </Typography>
      </div>
      <Card className="flex flex-col gap-y-3">
        <Card.Body>
          <div className="flex flex-col gap-y-3 lg:gap-y-0 lg:flex-row justify-between pb-3 mb-3 border-b">
            <div className="flex flex-col gap-y-3 sm:gap-y-0 sm:flex-row gap-x-3 mt-auto">
              <DateInput
                label="Početak perioda"
                name="startDate"
                value={startDate}
                onReset={() => setStartDate(null)}
                onChange={(value) => setStartDate(value)}
                maxDate={endDate as Date | undefined}
                allowClear={false}
              />
              <DateInput
                label="Kraj perioda"
                name="endDate"
                value={endDate}
                onReset={() => setEndDate(null)}
                onChange={(value) => setEndDate(value)}
                minDate={startDate as Date | undefined}
                allowClear={false}
              />
            </div>
            <div className="flex flex-row justify-end gap-x-3 mt-auto ml-3">
              <DropdownMenu
                title={selectedGroup?.groupName}
                color="primary"
                visibleItemCount={5}
              >
                {groups.map((group) => (
                  <DropdownMenu.Item onSelect={() => setSelectedGroup(group)}>
                    {group.groupName}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu>
              <DropdownMenu
                title={<span className="hidden sm:inline-block">Preuzmi</span>}
                color="primary"
                openExpanderIcon={<Icon type="download-simple" />}
                closeExpanderIcon={<Icon type="download-simple" />}
                iconPlacement="leading"
                className="pr-2 sm:pr-4"
              >
                <DropdownMenu.Item onSelect={() => exportReport("excel")}>
                  .xlsx
                </DropdownMenu.Item>
                <DropdownMenu.Item onSelect={() => exportReport("pdf")}>
                  .pdf
                </DropdownMenu.Item>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex flex-col gap-y-10">
            {attendanceReports.length > 0 && (
              <DataTable data={attendanceReports} className="border">
                <DataTable.Column
                  header="IME SPORTAŠA"
                  id="athleteName"
                  canSort={false}
                >
                  {(item: AthleteAttendanceReportResponse) =>
                    `${item.athleteFirstName} ${item.athleteLastName}`
                  }
                </DataTable.Column>
                <DataTable.Column
                  header="DOLASCI"
                  id="attendanceCount"
                  canSort={false}
                >
                  {(item: AthleteAttendanceReportResponse) =>
                    item.attendanceCount
                  }
                </DataTable.Column>
                <DataTable.Column
                  header="IZOSTANCI"
                  id="absenceCount"
                  canSort={false}
                >
                  {(item: AthleteAttendanceReportResponse) => item.absenceCount}
                </DataTable.Column>
                <DataTable.Column
                  header="NENAJAVLJENI IZOSTANCI"
                  id="notPlannedAbsenceCount"
                  canSort={false}
                >
                  {(item: AthleteAttendanceReportResponse) =>
                    item.notPlannedAbsenceCount
                  }
                </DataTable.Column>
                <DataTable.Column
                  header="NAJAVLJENI IZOSTANCI"
                  id="plannedAbsenceCount"
                  canSort={false}
                >
                  {(item: AthleteAttendanceReportResponse) =>
                    item.plannedAbsenceCount
                  }
                </DataTable.Column>
                <DataTable.Column
                  header="DOLAZNOST"
                  id="attendancePercentage"
                  canSort={false}
                >
                  {(item: AthleteAttendanceReportResponse) =>
                    item.attendancePercentage + "%"
                  }
                </DataTable.Column>
              </DataTable>
            )}
          </div>
        </Card.Body>
        {totalAttendanceReports > MAX_REPORT_ROWS && (
          <div className="flex items-center justify-end mb-3 mr-1">
            <Pagination
              onPageChange={(i: number) => {
                setPage(i);
              }}
              pageNumber={page}
              pageSize={MAX_REPORT_ROWS}
              totalElements={totalAttendanceReports}
            >
              {() => null}
            </Pagination>
          </div>
        )}
      </Card>
    </div>
  );
}
