import React, { useContext, useEffect, useState } from "react";

import { Button, Card, IconButton, Typography } from "@tiller-ds/core";
import { CheckboxField, TextareaField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { Formik } from "formik";
import * as yup from "yup";

import DeleteAthleteNoteModal from "./DeleteAthleteNoteModal";
import { UserContext } from "../../../../auth/SecurityProvider";
import {
  MAX_CONTENT_LENGTH,
  MAX_NOTE_LENGTH,
  NoteVisibilityType,
} from "../../../../common/constants";
import {
  maxCharacterCountMessage,
  REQUIRED_FIELD_MESSAGE,
} from "../../../../common/YupConstants";
import { UserType } from "../../../common/api/UserResponse";
import NoAvailableData from "../../../common/components/NoAvailableData";
import { AthleteNoteRequest } from "../../api/AthleteNoteRequest";
import { AthleteNoteResponse } from "../../api/AthleteNoteResponse";
import { getNoteById } from "../api/getNoteById";
import { getNotesByAthleteId } from "../api/getNotesByAthleteId";
import { postCreateAthleteNote } from "../api/postCreateAthleteNote";
import { putUpdateAthleteNote } from "../api/putUpdateAthleteNote";

type AthleteNotesProps = {
  athleteId: number;
};

type Form = {
  content: string;
  limitVisibility: boolean;
};

type FormUpdate = {
  contentUpdate: string;
  limitVisibilityUpdate: boolean;
};

const validationSchema = yup.object().shape({
  content: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .max(MAX_CONTENT_LENGTH, maxCharacterCountMessage(MAX_CONTENT_LENGTH)),
});

const saveNoteInitialValues = {
  content: "",
  limitVisibility: false,
};

export default function AthleteNotes({ athleteId }: AthleteNotesProps) {
  const { userHasRole } = useContext(UserContext);
  const [notes, setNotes] = useState([] as AthleteNoteResponse[]);
  const [currentlyEditingNoteId, setCurrentlyEditingNoteId] = useState(0);
  const [updateNoteFormInitValues, setUpdateNoteFormInitValues] = useState(
    {} as FormUpdate
  );
  const [noteCharacterCount, setNoteCharacterCount] = useState(0);
  const [editNoteCharacterCount, setEditNoteCharacterCount] = useState(0);

  useEffect(() => {
    getNotesByAthleteId(athleteId).then((response) => {
      if (response) {
        setNotes(response);
      }
    });
  }, [athleteId]);

  function switchToUpdate(noteId: number) {
    getNoteById(noteId).then((response) => {
      if (response.visibility === NoteVisibilityType.ALL) {
        setUpdateNoteFormInitValues({
          contentUpdate: response.content,
          limitVisibilityUpdate: false,
        });
      } else {
        setUpdateNoteFormInitValues({
          contentUpdate: response.content,
          limitVisibilityUpdate: true,
        });
      }
      setCurrentlyEditingNoteId(noteId);
    });
  }

  const onSaveNote = (form: Form) => {
    postCreateAthleteNote({
      athleteId: athleteId,
      content: form.content,
      visibility: form.limitVisibility
        ? NoteVisibilityType.ADMIN_AND_COACH
        : NoteVisibilityType.ALL,
    } as AthleteNoteRequest).then((response: AthleteNoteResponse) => {
      setNotes([response, ...notes]);
    });
    form.content = "";
    form.limitVisibility = false;
    setNoteCharacterCount(0);
  };

  const onUpdateNote = (form: FormUpdate) => {
    putUpdateAthleteNote(
      {
        content: form.contentUpdate,
        athleteId: athleteId,
        visibility: form.limitVisibilityUpdate
          ? NoteVisibilityType.ADMIN_AND_COACH
          : NoteVisibilityType.ALL,
      },
      currentlyEditingNoteId
    ).then((response: AthleteNoteResponse) => {
      setNotes(
        [
          response,
          ...notes.filter(
            (note: AthleteNoteResponse) => note.id !== currentlyEditingNoteId
          ),
        ].sort((a, b) => b.date.toString().localeCompare(a.date.toString()))
      );
      setCurrentlyEditingNoteId(0);
      setEditNoteCharacterCount(0);
    });
  };

  return (
    <Card className="w-full h-full p-0 sm:p-4">
      <Card.Header className="flex justify-between items-center text-lg font-bold p-1">
        Bilješke
      </Card.Header>
      <Card.Body>
        {notes.length > 0 ? (
          <ul className="overflow-auto overscroll-auto h-52 border rounded-md border-gray p-2">
            {notes.map((note: AthleteNoteResponse) => {
              let liClassName;
              if (note.visibility === NoteVisibilityType.ALL) {
                liClassName =
                  "bg-indigo-50 rounded-md mb-2 p-4 border border-navy-300 shadow-2 text-black";
              } else {
                liClassName =
                  "bg-orange-100 rounded-md mb-2 p-4 border border-navy-300 shadow-2 text-black";
              }
              return (
                <li className={liClassName}>
                  {note.visibility === NoteVisibilityType.ADMIN_AND_COACH ? (
                    <Icon
                      type="eye-slash"
                      size={6}
                      className="text-orange-500"
                    />
                  ) : null}
                  {currentlyEditingNoteId === note.id ? (
                    <>
                      <Formik
                        initialValues={updateNoteFormInitValues}
                        onSubmit={onUpdateNote}
                      >
                        {(formik) => (
                          <form className="flex flex-col ">
                            <div className="w-full mb-2 flex items-start justify-between">
                              <div className="flex flex-col w-full">
                                <TextareaField
                                  className="w-full resize-none"
                                  name="contentUpdate"
                                  maxLength={MAX_NOTE_LENGTH}
                                  onKeyUp={() =>
                                    setEditNoteCharacterCount(
                                      formik.getFieldProps("contentUpdate")
                                        .value.length
                                    )
                                  }
                                ></TextareaField>
                                <Typography
                                  variant="subtext"
                                  className="flex justify-end mt-1 text-gray-400"
                                >{`${editNoteCharacterCount}/${MAX_NOTE_LENGTH}`}</Typography>
                              </div>
                              <div className="flex">
                                <IconButton
                                  icon={<Icon type="check" />}
                                  label="Spremi"
                                  onClick={formik.handleSubmit}
                                />
                                <IconButton
                                  icon={<Icon type="x" />}
                                  label="Odbaci"
                                  onClick={() => {
                                    setCurrentlyEditingNoteId(0);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-start gap-x-4 mt-4">
                              <Typography
                                variant="subtitle"
                                className="mt-1 text-gray-500"
                                icon={<Icon type="calendar-blank" />}
                                iconPlacement="leading"
                              >
                                {new Date(note.date).toLocaleDateString(
                                  "hr-HR"
                                )}
                              </Typography>
                              <CheckboxField
                                name="limitVisibilityUpdate"
                                label="Ograniči vidljivost roditelju i sportašu"
                              />
                            </div>
                          </form>
                        )}
                      </Formik>
                    </>
                  ) : (
                    <div>
                      <div className="flex w-full items-start justify-between">
                        <Typography className="break-word text-base">
                          {note.content}
                        </Typography>
                        {userHasRole(UserType.COACH) && (
                          <div className="flex flex-row p-1">
                            <IconButton
                              icon={
                                <Icon
                                  type="pencil-simple"
                                  variant="fill"
                                  className="text-gray-500"
                                />
                              }
                              label="Uredi"
                              onClick={() => {
                                switchToUpdate(note.id);
                                setEditNoteCharacterCount(note.content.length);
                              }}
                            />
                            <DeleteAthleteNoteModal
                              noteId={note.id}
                              notes={notes}
                              setNotes={setNotes}
                            />
                          </div>
                        )}
                      </div>
                      <Typography
                        variant="subtitle"
                        className="mt-1 text-gray-500"
                        icon={<Icon type="calendar-blank" />}
                        iconPlacement="leading"
                      >
                        {new Date(note.date).toLocaleDateString("hr-HR")}
                      </Typography>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <NoAvailableData message="Nema upisanih bilješki za sportaša" />
        )}

        {userHasRole(UserType.COACH) && (
          <Formik
            initialValues={saveNoteInitialValues}
            onSubmit={onSaveNote}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} className="flex flex-col ">
                <TextareaField
                  placeholder="Upišite svoju bilješku..."
                  name="content"
                  className="mt-3 resize-none"
                  maxLength={MAX_NOTE_LENGTH}
                  onKeyUp={() =>
                    setNoteCharacterCount(
                      formik.getFieldProps("content").value.length
                    )
                  }
                />
                <Typography
                  variant="subtext"
                  className="flex justify-end mt-1 text-gray-400"
                >{`${noteCharacterCount}/${MAX_NOTE_LENGTH}`}</Typography>
                <div className="flex justify-between gap-x-4">
                  <CheckboxField
                    name="limitVisibility"
                    label="Ograniči vidljivost roditelju i sportašu"
                  />
                  <Button variant="filled" className="mt-2" type="submit">
                    <span className="text-center">Dodaj</span>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
}
