import { REPEATING_EVENT_URL } from "../../common/Routes";

export async function getRepeatingEventWithParticipantsById(id: string) {
  const response = await fetch(`${REPEATING_EVENT_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
