import { SearchInvoiceRequest } from "./searchInvoiceRequest";
import { INVOICE_URL } from "../../../common/Routes";

export async function postSearchInvoiceRequest(
  request: SearchInvoiceRequest
): Promise<Response> {
  const response = await fetch(`${INVOICE_URL}/search/pageable`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
