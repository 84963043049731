import { NOTE_URL } from "../../../../common/Routes";
import { AthleteNoteRequest } from "../../api/AthleteNoteRequest";

export async function postCreateAthleteNote(request: AthleteNoteRequest) {
  const response = await fetch(NOTE_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
