import { UserType } from "../../archive/common/api/UserResponse";
import { CURRENT_USER_URL } from "../../common/Routes";

export type CurrentUserResponse = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userType: UserType;
  numOfUnreadMessages: number;
  hasAcceptedEula: boolean;
  allowedEmail: boolean;
};

export async function getCurrentUser(): Promise<CurrentUserResponse> {
  const response = await fetch(CURRENT_USER_URL, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(response.status);
}
