import React from "react";

import { Card, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import LinearAnswerResult from "./LinearAnswerResult";
import MultipleAnswerResult from "./MultipleAnswerResult";
import PersonalAnswerResult from "./PersonalAnswerResult";
import { SurveyAnswerType } from "../../../../../common/constants";

type SurveyQuestionResultsProps = {
  question: string;
  answerType: SurveyAnswerType;
};

export default function SurveyQuestionResults({
  answerType,
  question,
}: SurveyQuestionResultsProps) {
  return (
    <Card className="w-full h-full mb-8">
      <Card.Body>
        <>
          <Typography element="h6" variant="h6" className="font-biotif py-4">
            {question}
          </Typography>
          <div className="flex justify-between w-full">
            <div className="flex">
              <Typography
                element="h6"
                variant="subtitle"
                className="font-inter mr-4"
              >
                Odgovori
              </Typography>
              <Typography
                element="p"
                variant="subtitle"
                className="text-gray-400 flex items-center"
                icon={<Icon type="eye-slash" size={4} />}
                iconPlacement={"leading"}
              >
                Odgovori su anonimni
              </Typography>
            </div>
            <div className="flex">
              <Typography
                element="h6"
                variant="subtitle"
                className="font-inter"
              >
                8/10 Odgovora
              </Typography>
            </div>
          </div>
          {(() => {
            if (answerType === SurveyAnswerType.MultipleChoice) {
              return (
                <div className="space-y-4 py-4 w-full">
                  <MultipleAnswerResult
                    answer={"Ponedjeljkom i četvrtkom"}
                    totalRespondents={20}
                    totalAnswers={4}
                  />
                  <MultipleAnswerResult
                    answer={"Utorkom i četvrtkom"}
                    totalRespondents={20}
                    totalAnswers={6}
                  />
                  <MultipleAnswerResult
                    answer={"Srijedom i petkom"}
                    totalRespondents={20}
                    totalAnswers={2}
                  />
                  <MultipleAnswerResult
                    answer={"Utorkom i petkom"}
                    totalRespondents={20}
                    totalAnswers={8}
                  />
                </div>
              );
            } else if (answerType === SurveyAnswerType.LinearScale) {
              return (
                <div className="flex flex-col space-y-4 justify-between py-4 w-full sm:flex-row">
                  <div className="flex items-end">
                    <Typography
                      element="h6"
                      variant="text"
                      className="font-inter"
                    >
                      Loše
                    </Typography>
                  </div>
                  <LinearAnswerResult
                    answer={"1"}
                    totalRespondents={20}
                    totalAnswers={3}
                  />
                  <LinearAnswerResult
                    answer={"2"}
                    totalRespondents={20}
                    totalAnswers={10}
                  />
                  <LinearAnswerResult
                    answer={"3"}
                    totalRespondents={20}
                    totalAnswers={4}
                  />
                  <LinearAnswerResult
                    answer={"4"}
                    totalRespondents={20}
                    totalAnswers={3}
                  />
                  <div className="flex items-end">
                    <Typography
                      element="h6"
                      variant="text"
                      className="font-inter"
                    >
                      odlično
                    </Typography>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="py-4 mt-4 max-h-[23rem] w-full overflow-y-auto border-navy-200 border-t">
                  <PersonalAnswerResult
                    answer={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit ame consectetur adipiscing elit."
                    }
                    totalRespondents={20}
                    totalAnswers={4}
                  />
                  <PersonalAnswerResult
                    answer={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a eu faucibus auctor.  "
                    }
                    totalRespondents={20}
                    totalAnswers={4}
                  />
                  <PersonalAnswerResult
                    answer={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.  "
                    }
                    totalRespondents={20}
                    totalAnswers={4}
                  />
                </div>
              );
            }
          })()}
        </>
      </Card.Body>
    </Card>
  );
}
