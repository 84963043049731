import React from "react";

import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

type ResultCircularProgressProps = {
  totalRespondents: number;
  totalAnswers: number;
};

export default function ResultCircularProgress({
  totalRespondents,
  totalAnswers,
}: ResultCircularProgressProps) {
  const activeIndex = 0;

  const data = [
    {
      name: "Odgovora",
      value: totalAnswers,
    },
    {
      name: "Ukupno",
      value: totalRespondents,
    },
  ];

  const renderActiveShape = (props: {
    cx: any;
    cy: any;
    innerRadius: any;
    outerRadius: any;
    startAngle: any;
    endAngle: any;
    fill: any;
  }) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = props;

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000000"}>
          {`${totalAnswers}/${totalRespondents}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const COLORS = ["#8ceffd", "#eafafd"];

  return (
    <div className="w-full">
      <ResponsiveContainer height={100} width="100%">
        <PieChart>
          <Pie
            data={data}
            innerRadius={30}
            outerRadius={35}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
