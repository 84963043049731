import { UpdateCoachRequest } from "./UpdateCoachRequest";
import { COACH_URL } from "../../../common/Routes";

export async function putUpdateCoachRequest(
  request: UpdateCoachRequest,
  coachId: number
): Promise<Response> {
  return await fetch(`${COACH_URL}/${coachId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
