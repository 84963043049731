import { createContext, useState } from "react";
import * as React from "react";

type RegistryProviderProps = {
  children: React.ReactNode;
};

export const RegistryContext = createContext({
  registryTabIndex: 0,
  groupRegistryPage: 0,
  athleteRegistryPage: 0,
  parentRegistryPage: 0,
  coachRegistryPage: 0,
  adminRegistryPage: 0,
  setRegistryTabIndex: (page: number) => {},
  setGroupRegistryPage: (page: number) => {},
  setAthleteRegistryPage: (page: number) => {},
  setParentRegistryPage: (page: number) => {},
  setCoachRegistryPage: (page: number) => {},
  setAdminRegistryPage: (page: number) => {},
});

export default function RegistryProvider({ children }: RegistryProviderProps) {
  const [registryTabIndex, setRegistryTabIndex] = useState(0);
  const [groupRegistryPage, setGroupRegistryPage] = useState(0);
  const [athleteRegistryPage, setAthleteRegistryPage] = useState(0);
  const [parentRegistryPage, setParentRegistryPage] = useState(0);
  const [coachRegistryPage, setCoachRegistryPage] = useState(0);
  const [adminRegistryPage, setAdminRegistryPage] = useState(0);

  const value = {
    registryTabIndex,
    groupRegistryPage,
    athleteRegistryPage,
    parentRegistryPage,
    coachRegistryPage,
    adminRegistryPage,
    setRegistryTabIndex,
    setGroupRegistryPage,
    setAthleteRegistryPage,
    setParentRegistryPage,
    setCoachRegistryPage,
    setAdminRegistryPage,
  };

  return (
    <RegistryContext.Provider value={value}>
      {children}
    </RegistryContext.Provider>
  );
}
