import React, { useEffect, useState } from "react";

import { Card } from "@tiller-ds/core";
import { DescriptionList } from "@tiller-ds/data-display";
import { Toggle } from "@tiller-ds/form-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { AllowEmailNotificationsRequest } from "../../../archive/common/api/AllowEmailNotificationsRequest";
import { postAllowEmailFlag } from "../../../archive/common/api/postAllowEmailFlag";
import { CurrentUserResponse } from "../../../auth/api/getCurrentUser";
import { UserContext } from "../../../auth/SecurityProvider";
import LoadingCircle from "../../../common/components/LoadingCircle";

export default function AccountSettingsCard() {
  const [allowedEmail, setAllowedEmail] = useState(false);

  const { add: addNotification } = useNotifications();

  const [isLoading, setIsLoading] = useState(true);

  const { user, onChangeUser } = React.useContext(UserContext);

  const toggleReceivingEmail = () => {
    const request: AllowEmailNotificationsRequest = {
      allowed: !allowedEmail,
    };
    postAllowEmailFlag(request).then((response: any) => {
      if (response.ok) {
        response.json().then((body: CurrentUserResponse) => {
          let notificationContent: string = body.allowedEmail
            ? "Od sad ćete primati poruke na e-mail."
            : "Više nećete primati poruke na e-mail.";

          addNotification({
            title: "Promjene su spremljene.",
            content: notificationContent,
            messageList: [],
            severity: "INFO",
            timestamp: new Date(),
          });
        });
        user.allowedEmail = request.allowed;
        onChangeUser(user);
        setAllowedEmail((current) => !current);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setAllowedEmail(user.allowedEmail);
    setIsLoading(false);
  }, [user.allowedEmail]);

  return (
    <LoadingCircle isLoading={isLoading}>
      <Card>
        <Card.Body removeSpacing={true}>
          <DescriptionList>
            <div className="labelToggleOneLine">
              <DescriptionList.Item
                label="Primanje poruka na e-mail"
                type="same-column"
              >
                <Toggle checked={allowedEmail} onClick={toggleReceivingEmail} />
              </DescriptionList.Item>
            </div>
          </DescriptionList>
        </Card.Body>
      </Card>
    </LoadingCircle>
  );
}
