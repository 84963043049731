import { TrendReportsRequest } from "./TrendReportsRequest";
import { REPORTS_TREND_URL } from "../../common/Routes";

export async function getTrendReports(request: TrendReportsRequest) {
  const response = await fetch(`${REPORTS_TREND_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
