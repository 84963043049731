import React, { useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Badge, Button, Typography } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";
import { Toggle } from "@tiller-ds/form-elements";
import {
  CheckboxField,
  DateTimeInputField,
  FieldError,
  InputField,
  SelectField,
  TextareaField,
  TimeInputField,
} from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import {
  ErrorMessage,
  Formik,
  FormikHelpers,
  useField,
  useFormikContext,
} from "formik";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import CustomInputField from "./CustomInputField";
import EventRecipientsAutocompleteFieldSelector from "./EventRecipientsAutocompleteFieldSelector";
import UpdateEventModal from "./modals/UpdateEventModal";
import { UserResponse } from "../../archive/common/api/UserResponse";
import CancelModal from "../../common/components/modals/CancelModal";
import {
  DAYS_OF_WEEK,
  DELETE_UPDATE_REPEATING_EVENT_OPTIONS,
  EVENT_OPTIONS,
  EVENT_REPEATING_OPTIONS,
  EVENT_REPEATING_OPTIONS_WITHOUT_NO_REPEATING,
  MAX_EVENT_DESCRIPTION_LENGTH,
  MAX_LEADER_LENGTH,
  MAX_LOCATION_NAME_LENGTH,
  MAX_MESSAGE_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_DATE,
  SelectType,
  YEARS_LIMIT,
} from "../../common/constants";
import {
  END_TIME_AFTER_START_TIME_MESSAGE,
  maxCharacterCountMessage,
  MIN_EVENT_DURATION_MESSAGE,
  MIN_ONE_PARTICIPANT_MESSAGE,
  MIN_ONE_REPEATING_DAY_MESSAGE,
  MUST_BE_INTEGER_MESSAGE,
  MUST_BE_NUMBER_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
  SEPARATION_COUNT_MAX_MESSAGE,
  SEPARATION_COUNT_MIN_MESSAGE,
} from "../../common/YupConstants";
import { EventResponse } from "../api/EventResponse";
import handleErrorResponse from "../api/HandleErrorResponse";
import { postEvent } from "../api/postEvent";
import { updateEvent, updateRepeatingEvent } from "../api/updateEvent";
import formatDate from "../util/formatDate";

const endTimeValidationSchema = yup.string().when("repeating", {
  is: (repeating: string) => {
    return repeating !== EVENT_REPEATING_OPTIONS[0].value;
  },
  then: yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
});

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .max(MAX_TITLE_LENGTH, maxCharacterCountMessage(MAX_TITLE_LENGTH)),
  eventType: yup.string().required(REQUIRED_FIELD_MESSAGE),
  leader: yup
    .string()
    .max(MAX_LEADER_LENGTH, maxCharacterCountMessage(MAX_TITLE_LENGTH))
    .nullable(),
  locationName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .max(
      MAX_LOCATION_NAME_LENGTH,
      maxCharacterCountMessage(MAX_LOCATION_NAME_LENGTH)
    ),
  description: yup
    .string()
    .max(
      MAX_MESSAGE_DESCRIPTION_LENGTH,
      maxCharacterCountMessage(MAX_MESSAGE_DESCRIPTION_LENGTH)
    ),
  repeating: yup.string().required(REQUIRED_FIELD_MESSAGE),
  startTimeOfFirstInstance: yup.string().when("repeating", {
    is: (repeating: string) => repeating !== EVENT_REPEATING_OPTIONS[0].value,
    then: yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
  }),

  endTimeOfFirstInstance: endTimeValidationSchema
    .test("is-greater", END_TIME_AFTER_START_TIME_MESSAGE, async function (
      value
    ) {
      if (
        (await endTimeValidationSchema.isValid(value)) &&
        this.parent.repeating !== EVENT_REPEATING_OPTIONS[0].value
      ) {
        return moment(value, "HH:mm").isSameOrAfter(
          moment(this.parent.startTimeOfFirstInstance, "HH:mm")
        );
      }
      return true;
    })
    .test("min-duration", MIN_EVENT_DURATION_MESSAGE, async function (value) {
      if (
        (await endTimeValidationSchema.isValid(value)) &&
        this.parent.repeating !== EVENT_REPEATING_OPTIONS[0].value
      ) {
        return moment(value, "HH:mm").isSameOrAfter(
          moment(this.parent.startTimeOfFirstInstance, "HH:mm").add(5, "m")
        );
      }
      return true;
    }),
  daysOfWeek: yup.array().when("repeating", {
    is: (repeating: string) => repeating === EVENT_REPEATING_OPTIONS[2].value,
    then: yup.array().min(1, MIN_ONE_REPEATING_DAY_MESSAGE),
  }),
  separationCount: yup
    .number()
    .typeError(MUST_BE_NUMBER_MESSAGE)
    .integer(MUST_BE_INTEGER_MESSAGE)
    .when("repeating", {
      is: (repeating: string) => repeating === EVENT_REPEATING_OPTIONS[2].value,
      then: yup
        .number()
        .typeError(MUST_BE_NUMBER_MESSAGE)
        .integer(MUST_BE_INTEGER_MESSAGE)
        .required(REQUIRED_FIELD_MESSAGE)
        .min(1, SEPARATION_COUNT_MIN_MESSAGE)
        .max(51, SEPARATION_COUNT_MAX_MESSAGE),
    }),
  participants: yup
    .array()
    .min(1, MIN_ONE_PARTICIPANT_MESSAGE)
    .required(REQUIRED_FIELD_MESSAGE),
});

type Form = {
  title: string;
  eventType: string;
  locationName: string;
  locationAddress: string;
  description: string;
  repeating: string;
  eventLeader: string;
  startDateTime: Date;
  endDateTime: Date;
  startTimeOfFirstInstance: string;
  endTimeOfFirstInstance: string;
  dateOfFirstInstance: Date;
  dateOfLastInstance: Date;
  excludeWeekends: boolean;
  separationCount: number;
  participants: UserResponse[] | undefined;
  daysOfWeek: string[];
};

type CreateFormProps = {
  initialFormValues: Form;
  editMode: boolean;
  id?: string;
  repeatingEvent: boolean;
  setIsLoading: (isLoading: boolean) => void;
  participants: UserResponse[];
  recipientGroups: any[];
  setRecipientGroups: (recipientGroups: any) => void;
};

type DateTimePickerDivProps = {
  startDate: Date;
  onStartDateChange: (value: Date) => void;
  endDate: Date;
  onEndDateChange: (value: Date) => void;
};

const DateTimePickerDiv = ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}: DateTimePickerDivProps) => {
  const formikContext = useFormikContext();
  const [eventRepeatingSelectField] = useField("repeating");
  const days = useField("daysOfWeek")[0].value;

  const removeDay = (day: string) => {
    formikContext.setFieldValue(
      "daysOfWeek",
      days.filter((arrDay: string) => arrDay !== day)
    );
  };

  const addDay = (day: string) => {
    formikContext.setFieldValue("daysOfWeek", [...days, day]);
  };

  return (
    <>
      {eventRepeatingSelectField.value === EVENT_REPEATING_OPTIONS[0].value ? (
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 lg:space-x-12">
          <DateTimeInputField
            className="md:w-1/2"
            name="startDateTime"
            label="Početak događaja"
            allowClear={false}
            minDate={new Date(MIN_DATE)}
            maxDate={
              new Date(
                new Date().setFullYear(new Date().getFullYear() + YEARS_LIMIT)
              )
            }
            required
          />
          <EndDateTimeInputField />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 lg:space-x-12">
            <DateInput
              className="md:w-1/2"
              name="dateOfFirstInstance"
              label="Početni datum"
              allowClear={false}
              onBlur={() => {}}
              onChange={(value) => {
                if (value !== null) {
                  onStartDateChange(value);
                }
              }}
              value={startDate}
              minDate={new Date(MIN_DATE)}
              maxDate={
                new Date(
                  new Date().setFullYear(new Date().getFullYear() + YEARS_LIMIT)
                )
              }
              required
            />
            <DateInput
              className="pt-6 md:pt-0 md:w-1/2"
              name="dateOfLastInstance"
              label="Krajnji datum"
              onBlur={() => {}}
              allowClear={false}
              onChange={(value) => {
                if (value !== null) {
                  onEndDateChange(value);
                }
              }}
              value={
                endDate < startDate
                  ? startDate
                  : endDate >
                    new Date(
                      new Date().setFullYear(
                        startDate.getFullYear() + YEARS_LIMIT
                      )
                    )
                  ? new Date(
                      new Date().setFullYear(
                        startDate.getFullYear() + YEARS_LIMIT
                      )
                    )
                  : endDate
              }
              minDate={startDate}
              maxDate={
                startDate
                  ? new Date(
                      new Date().setFullYear(
                        startDate.getFullYear() + YEARS_LIMIT
                      )
                    )
                  : new Date(
                      new Date().setFullYear(
                        new Date().getFullYear() + YEARS_LIMIT
                      )
                    )
              }
              required
            />
          </div>
          <div className="flex flex-col md:flex-row md:w-1/2 md:pr-6 space-x-0 md:space-x-6 lg:space-x-12 md:pt-2">
            <TimeInputField
              className="md:w-1/2"
              name="startTimeOfFirstInstance"
              label="Početak po danu"
              placeholder="hh:mm"
              required
            />
            <TimeInputField
              className="pt-6 md:pt-0 md:w-1/2"
              name="endTimeOfFirstInstance"
              label="Završetak po danu"
              placeholder="hh:mm"
              required
            />
          </div>
        </>
      )}
      {eventRepeatingSelectField.value === EVENT_REPEATING_OPTIONS[2].value ? (
        <>
          <Typography className="justify-center align-top align-text-top mt-2">
            <span className="font-bold">Ponovi na dane</span>
            <span className="text-red-600"> *</span>
          </Typography>
          <div className="flex justify-between md:justify-start md:space-x-4">
            {DAYS_OF_WEEK.map((day) =>
              days.includes(day.value) ? (
                <Badge
                  variant="filled"
                  className="cursor-pointer"
                  onClick={() => removeDay(day.value)}
                >
                  {day.name}
                </Badge>
              ) : (
                <Badge
                  variant="outlined"
                  className="cursor-pointer"
                  onClick={() => addDay(day.value)}
                >
                  {day.name}
                </Badge>
              )
            )}
          </div>
          <div className="text-sm text-red-600">
            <ErrorMessage name="daysOfWeek" />
          </div>
        </>
      ) : null}
    </>
  );
};

const EndDateTimeInputField = () => {
  const [dateTimeField] = useField("startDateTime");
  const formikContext = useFormikContext();

  if (
    // @ts-ignore
    formikContext.values.endDateTime <
    moment(dateTimeField.value).add(5, "m").toDate()
  ) {
    formikContext.setFieldValue(
      "endDateTime",
      moment(dateTimeField.value).add(5, "m").toDate()
    );
  }

  return (
    <DateTimeInputField
      className="pt-6 md:pt-0 md:w-1/2"
      name="endDateTime"
      label="Kraj događaja"
      allowClear={false}
      minDate={dateTimeField.value ? dateTimeField.value : new Date(MIN_DATE)}
      maxDate={
        new Date(new Date().setFullYear(new Date().getFullYear() + YEARS_LIMIT))
      }
      required
    />
  );
};

const SelectedValueDiv = () => {
  const [eventRepeatingSelectField] = useField("repeating");
  return (
    <>
      {eventRepeatingSelectField.value === EVENT_REPEATING_OPTIONS[1].value ? (
        <div className="flex items-end my-auto pt-6">
          <div className="pb-0.5">
            <CheckboxField color="primary" name="excludeWeekends" />
          </div>
          <Typography className="pl-1 pb-2">Osim vikenda</Typography>
        </div>
      ) : null}
      {eventRepeatingSelectField.value === EVENT_REPEATING_OPTIONS[2].value ? (
        <div className="pt-6">
          <div className="flex items-end pt-0">
            <Typography className="pb-2">Ponovi svaki/a:</Typography>
            <CustomInputField
              className="px-2 w-1/5 md:w-1/5"
              name="separationCount"
            />
            <Typography className="pb-2">
              tjedan/a<span className="text-red-600"> *</span>
            </Typography>
          </div>
          <FieldError
            className="mt-1 text-sm text-red-600 md:pl-6"
            name="separationCount"
          />
        </div>
      ) : null}
    </>
  );
};

export default function CreateOrUpdateEventForm({
  initialFormValues,
  editMode,
  id,
  repeatingEvent,
  setIsLoading,
  participants,
  recipientGroups,
  setRecipientGroups,
}: CreateFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [dateOfFirstInstance, setDateOfFirstInstance] = useState(
    initialFormValues.dateOfFirstInstance
      ? initialFormValues.dateOfFirstInstance
      : new Date()
  );
  const [dateOfLastInstance, setDateOfLastInstance] = useState(
    initialFormValues.dateOfLastInstance
      ? initialFormValues.dateOfLastInstance
      : new Date()
  );

  const [editModeChangeRepeating, setEditModeChangeRepeating] = useState(false);
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(
    editMode && initialFormValues.description
      ? initialFormValues.description.length
      : 0
  );

  const { add: addNotification } = useNotifications();

  const updateEventModal = useModal();

  const cancelModal = useModal();

  const getEventRequest = (values: Form) => {
    const { startDateTime, endDateTime, ...requestValues } = values;
    return {
      ...requestValues,
      startTime: formatDate(startDateTime),
      endTime: formatDate(endDateTime),
      dateOfFirstInstance: formatDate(dateOfFirstInstance),
      dateOfLastInstance: formatDate(dateOfLastInstance),
      isRepeating: values.repeating !== EVENT_REPEATING_OPTIONS[0].value,
      repeatingType: values.repeating,
      participantGroups: recipientGroups,
      participantIds: values.participants?.map((participant) => {
        return participant.id;
      }),
      // unnecessary to send participants because participantIds are sent
      participants: undefined,
    };
  };

  const onSuccessfulEventUpdate = (title: string, content: string) => {
    addNotification({
      title: title,
      content: content,
      messageList: [],
      severity: "INFO",
      timestamp: new Date(),
    });
    navigate("/calendar");
  };

  const updateOneOrAllInstances = (values: Form, updatingMode: string) => {
    const eventRequest = getEventRequest(values);
    if (updatingMode === DELETE_UPDATE_REPEATING_EVENT_OPTIONS[0] && id) {
      setIsLoading(true);
      updateEvent(id, {
        ...eventRequest,
        changeRepeatingRules: false,
      }).then((res) => {
        if (res.ok) {
          onSuccessfulEventUpdate(
            "Događaj je uređen.",
            "Vaš događaj je uspješno uređen."
          );
          setIsLoading(false);
        }
      });
    } else if (
      updatingMode === DELETE_UPDATE_REPEATING_EVENT_OPTIONS[1] &&
      id
    ) {
      setIsLoading(true);
      updateRepeatingEvent(id, {
        ...eventRequest,
        changeRepeatingRules: false,
      }).then((res) => {
        if (res.ok) {
          onSuccessfulEventUpdate(
            "Događaji su uređeni.",
            "Vaši događaji su uspješno uređeni."
          );
          setIsLoading(false);
        }
      });
    }
  };

  const onSubmit = async (values: Form, formikHelpers: FormikHelpers<Form>) => {
    const eventRequest = getEventRequest(values);
    if (editMode && id) {
      if (repeatingEvent && !editModeChangeRepeating) {
        updateEventModal.onOpen(true);
      } else if (repeatingEvent) {
        setIsLoading(true);
        updateRepeatingEvent(id, {
          ...eventRequest,
          changeRepeatingRules: editModeChangeRepeating,
        }).then((res) => {
          if (res.ok) {
            onSuccessfulEventUpdate(
              "Događaji su uređeni.",
              "Vaši događaji su uspješno uređeni."
            );
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(true);
        updateEvent(id, {
          ...eventRequest,
          changeRepeatingRules: editModeChangeRepeating,
        }).then((res) => {
          if (res.ok) {
            onSuccessfulEventUpdate(
              "Događaj je uređen.",
              "Vaš događaj je uspješno uređen."
            );
            setIsLoading(false);
          }
        });
      }
    } else {
      setIsLoading(true);
      postEvent({
        ...eventRequest,
        changeRepeatingRules: false,
      }).then((res) => {
        if (res.ok) {
          res.json().then((body: EventResponse[]) => {
            addNotification({
              title: "Događaj je kreiran",
              content: "Vaš događaj je uspješno kreiran.",
              messageList: [],
              severity: "INFO",
              timestamp: new Date(),
              uxNotificationOptions: {
                actions: [
                  {
                    url: `/event-details/${body[0].id}`,
                    title: "Pogledaj događaj",
                  },
                ],
              },
            });
            setIsLoading(false);
            formikHelpers.resetForm();
            setRecipientGroups([]);
            navigate("/calendar/event/new");
          });
        } else {
          setIsLoading(true);
          handleErrorResponse(res, addNotification);
          setIsLoading(false);
        }
      });
    }
  };

  const getReturnPath = () => {
    if (location.pathname.includes("calendar")) {
      return "/calendar";
    } else {
      return "/home";
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col space-y-6"
            autoComplete="off"
          >
            <CancelModal
              modal={cancelModal}
              title={editMode ? "Uređivanje događaja" : "Kreiranje događaja"}
              returnPath={getReturnPath()}
            />
            <InputField name="title" label="Naziv događaja" required />
            <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 lg:space-x-12">
              <SelectField
                className="md:w-1/2"
                name="eventType"
                label="Tip događaja"
                options={EVENT_OPTIONS}
                getOptionLabel={(item: SelectType) => item.name}
                getOptionValue={(item: SelectType) => item.value}
                required
              />
              <InputField
                name="eventLeader"
                label="Voditelj događaja"
                className="pt-6 md:pt-0 md:w-1/2"
              />
            </div>
            <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 lg:space-x-12">
              <InputField
                className="md:w-1/2"
                name="locationName"
                label="Lokacija događaja"
                required
              />
              <InputField
                className="pt-6 md:pt-0 md:w-1/2"
                name="locationAddress"
                label="Adresa lokacije"
              />
            </div>
            <div>
              <TextareaField
                name="description"
                label="Opis događaja"
                maxLength={MAX_EVENT_DESCRIPTION_LENGTH}
                textareaClassName={`border border-gray-200 rounded-md p-1 h-20`}
                onKeyUp={() => {
                  setDescriptionCharacterCount(
                    formik.getFieldProps("description").value.length
                  );
                }}
              />
              <Typography
                variant="subtext"
                className="flex justify-end mt-1 text-gray-400"
              >{`${descriptionCharacterCount}/${MAX_EVENT_DESCRIPTION_LENGTH}`}</Typography>
            </div>
            <div className="flex flex-col flex-grow">
              <div className="flex flex-col">
                <Typography className="justify-center align-top align-text-top mt-2">
                  Sudionici<span className="text-red-600"> *</span>
                </Typography>
                <EventRecipientsAutocompleteFieldSelector
                  recipientsName={"participants"}
                  recipientGroupsName={"participantGroups"}
                  recipientGroups={recipientGroups}
                  setRecipientGroups={setRecipientGroups}
                  participants={participants}
                />
              </div>
            </div>
            {editMode ? (
              <>
                <div className="flex">
                  <Typography
                    element="p"
                    variant="subtext"
                    className="font-bold pr-4"
                  >
                    Vrsta događaja:
                  </Typography>
                  <Typography element="p" variant="subtext">
                    {repeatingEvent ? "Ponavljajući" : "Neponavljajući"}
                  </Typography>
                </div>
                {repeatingEvent ? (
                  <div className="flex">
                    <Typography
                      element="p"
                      variant="subtext"
                      className="font-bold pr-4 mt-1"
                    >
                      Želite li promijeniti pravila ponavljanja?
                    </Typography>
                    <div className="mx-1 mt-0">
                      <Toggle
                        checked={editModeChangeRepeating}
                        onClick={() =>
                          setEditModeChangeRepeating((prevState) => !prevState)
                        }
                        label={
                          <Typography element="p" variant="subtext">
                            {editModeChangeRepeating ? "Da" : "Ne"}
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            {!editMode || (editMode && editModeChangeRepeating) ? (
              <>
                <div className="flex flex-col">
                  <SelectField
                    className="md:w-1/2 md:pr-6"
                    name="repeating"
                    label="Ponovi događaj"
                    options={
                      repeatingEvent
                        ? EVENT_REPEATING_OPTIONS_WITHOUT_NO_REPEATING
                        : EVENT_REPEATING_OPTIONS
                    }
                    getOptionLabel={(item: SelectType) => item.name}
                    getOptionValue={(item: SelectType) => item.value}
                    required
                    hideClearButton={true}
                  />
                  <SelectedValueDiv />
                </div>
              </>
            ) : null}
            {(!repeatingEvent || (editMode && editModeChangeRepeating)) && (
              <>
                <Typography className="justify-center align-top align-text-top mt-2">
                  <span className="font-bold">Trajanje</span>
                  <span className="text-red-600"> *</span>
                </Typography>
                <DateTimePickerDiv
                  startDate={dateOfFirstInstance}
                  onStartDateChange={(value) => setDateOfFirstInstance(value)}
                  endDate={dateOfLastInstance}
                  onEndDateChange={(value) => setDateOfLastInstance(value)}
                />
              </>
            )}
            <hr className="border-gray-200" />
            <div className="flex justify-end">
              {!editMode ? (
                <>
                  <Button
                    type="reset"
                    variant="outlined"
                    size="sm"
                    className="mr-2"
                    onClick={cancelModal.onOpen}
                  >
                    Odustani
                  </Button>
                  <Button type="submit" size="sm">
                    Kreiraj događaj
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="reset"
                    variant="outlined"
                    size="sm"
                    className="mr-2"
                    onClick={cancelModal.onOpen}
                  >
                    Odustani
                  </Button>

                  <Button type="submit" size="sm">
                    Spremi promjene
                  </Button>
                </>
              )}
            </div>
            <UpdateEventModal
              modal={updateEventModal}
              onUpdateOneOrAllInstancesChange={(value) =>
                updateOneOrAllInstances(formik.values, value)
              }
            />
          </form>
        )}
      </Formik>
    </>
  );
}
