import { PROGRESS_URL } from "../../../common/Routes";

export type AthleteProgressDataRequest = {
  athleteId: number;
};

export async function postSearchAthleteProgressDataRequest(
  athleteProgressDataRequest: AthleteProgressDataRequest
) {
  const response = await fetch(`${PROGRESS_URL}/search`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(athleteProgressDataRequest),
  });
  return response.json();
}
