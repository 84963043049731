import { PROGRESS_URL } from "../../../common/Routes";

export async function getAverageMonthlyProgressDataOfAthlete(
  athleteId: number
) {
  const response = await fetch(`${PROGRESS_URL}/average/athlete/${athleteId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
