import FileDownload from "js-file-download";

import { RankTypeReportsRequest } from "./RankTypeReportsRequest";
import { GroupResponse } from "../../archive/group/api/GroupResponse";
import { GROUP_URL, REPORTS_RANKING_URL } from "../../common/Routes";
import { formatDateToHrWithDashes } from "../util/dateUtil";

export function exportRankReportsPDF(request: RankTypeReportsRequest) {
  let group: GroupResponse;
  if (request.groupId) {
    fetch(`${GROUP_URL}/${request.groupId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        group = json;
      });
  }
  fetch(`${REPORTS_RANKING_URL}/export/pdf`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const dateLabel = `${formatDateToHrWithDashes(
        request.startDate
      )}_${formatDateToHrWithDashes(request.endDate)}`;
      const groupLabel = group !== undefined ? group.groupName : "";
      const fileGroupDateLabel =
        groupLabel.length > 0 ? `${groupLabel}_${dateLabel}` : dateLabel;
      FileDownload(blob, `izvještaj_rang_liste_${fileGroupDateLabel}.pdf`);
    });
}
