import { AthleteGroupReportsRequest } from "./AthleteGroupReportsRequest";
import { GROUP_SEARCH_URL } from "../../common/Routes";

export async function getAthleteGroupReports(
  request: AthleteGroupReportsRequest
) {
  const response = await fetch(`${GROUP_SEARCH_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
