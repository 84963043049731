import React, { useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Tooltip, Typography } from "@tiller-ds/core";
import {
  CheckboxField,
  InputField,
  RadioGroupField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import {
  BestResultSorting,
  MAX_TYPE_NAME_LENGTH,
  MeasuringUnit,
  MIN_TYPE_NAME_LENGTH,
} from "../../../../common/constants";
import {
  maxCharacterCountMessage,
  minCharacterCountMessage,
  PARAMETER_NAME_ALREADY_USED_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../../../common/YupConstants";
import { postProgressTypeRequest } from "../../api/postProgressTypeRequest ";
import { ProgressTypeResponse } from "../../api/ProgressTypeResponse";

type Form = {
  typeName: string;
  isAscending: BestResultSorting;
  displayInPersonalRecords: boolean;
};

const initialFormValues: Form = {
  typeName: "",
  isAscending: BestResultSorting.Asc,
  displayInPersonalRecords: true,
};

type CreateProgressTypeModalProps = {
  modal: UseModal;
  setRender: (render: any) => void;
  progressTypeTableData: ProgressTypeResponse[];
};

const CreateProgressTypeModal = ({
  modal,
  setRender,
  progressTypeTableData,
}: CreateProgressTypeModalProps) => {
  const [measuringUnit, setMeasuringUnit] = useState({
    name: "Centimetri (cm)",
    value: MeasuringUnit.Centimeter,
  });

  const parameterNames = progressTypeTableData.map(
    (parameter: ProgressTypeResponse) => parameter.name
  );
  const validationSchema = yup.object().shape({
    typeName: yup
      .string()
      .min(MIN_TYPE_NAME_LENGTH, minCharacterCountMessage(MIN_TYPE_NAME_LENGTH))
      .max(MAX_TYPE_NAME_LENGTH, maxCharacterCountMessage(MAX_TYPE_NAME_LENGTH))
      .required(REQUIRED_FIELD_MESSAGE)
      .nullable()
      .notOneOf(parameterNames, PARAMETER_NAME_ALREADY_USED_MESSAGE),
  });

  const { add: addNotification } = useNotifications();

  const onSubmitMeasurement = (form: Form) => {
    postProgressTypeRequest({
      name: form.typeName,
      displayInPersonalRecords: showBestResultSorting,
      measuringUnit: measuringUnit.value,
      bestResultSorting: form.isAscending,
    }).then((_) => {
      setRender(true);
      addNotification({
        title: "Kreiranje parametra uspješno",
        content: "Kreiranje novog tipa parametra uspješno",
        messageList: [],
        severity: "INFO",
        timestamp: new Date(),
      });
      modal.onClose();
    });
  };

  const [showBestResultSorting, setShowBestResultSorting] = useState(
    initialFormValues.displayInPersonalRecords
  );

  const ToggleBestResultSorting = () => {
    setShowBestResultSorting(
      (showBestResultSorting: boolean) => !showBestResultSorting
    );
  };

  return (
    <>
      <Modal {...modal}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitMeasurement}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="p-2 flex flex-col">
              <Typography
                className="my-2 flex justify-start"
                variant="h4"
                element="h4"
              >
                Kreiranje novog mjernog parametra
              </Typography>
              <div className="flex flex-col space-y-4 my-2">
                <InputField
                  name="typeName"
                  label="Ime mjernog parametra"
                  allowClear
                  required
                />
                <div className="pb-2">
                  <Typography
                    className="my-2 flex justify-start"
                    variant="text"
                  >
                    Mjerna jedinica
                  </Typography>
                  <div className="flex justify-start">
                    <DropdownMenu title={measuringUnit.name}>
                      <DropdownMenu.Item
                        onSelect={() => {
                          setMeasuringUnit({
                            name: "Centimetri (cm)",
                            value: MeasuringUnit.Centimeter,
                          });
                        }}
                      >
                        Centimetri (cm)
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onSelect={() => {
                          setMeasuringUnit({
                            name: "Broj ponavljanja (br)",
                            value: MeasuringUnit.Repetition,
                          });
                        }}
                      >
                        Broj ponavljanja (br)
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onSelect={() => {
                          setMeasuringUnit({
                            name: "Kilogrami (kg)",
                            value: MeasuringUnit.Kilogram,
                          });
                        }}
                      >
                        Kilogrami (kg)
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onSelect={() => {
                          setMeasuringUnit({
                            name: "Vrijeme (mm:ss:cs)",
                            value: MeasuringUnit.Second,
                          });
                        }}
                      >
                        Vrijeme (mm:ss:cs)
                      </DropdownMenu.Item>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              <div className="flex flex-col my-2 pb-2">
                <Typography variant="text" className="mb-4">
                  Prikaži u sekciji osobnih rekorda?
                </Typography>
                <CheckboxField
                  name="displayInPersonalRecords"
                  label="Prikaži"
                  onChange={() => {
                    ToggleBestResultSorting();
                  }}
                  checked={showBestResultSorting}
                />
              </div>
              <div
                className={`my-2 pb-4 ${!showBestResultSorting && "hidden"}`}
              >
                <RadioGroupField
                  name="isAscending"
                  label={
                    <div className="flex">
                      <p className="text-left">
                        Najbolji rezultat je onaj koji je vrijednosno
                      </p>
                      <Tooltip
                        label={
                          <div>
                            Ako je kod mjerenja parametra cilj postići što niži
                            <br />
                            rezultat (npr. plivanje na 50m) tada označite
                            Najmanji, <br />
                            a ako je cilj postići što viši rezultat (npr. <br />
                            zadržavanje daha) tada označite Najveći. <br />
                          </div>
                        }
                      >
                        <Icon type="info" />
                      </Tooltip>
                    </div>
                  }
                >
                  <RadioGroupField.Item
                    label="Najveći"
                    value={BestResultSorting.Desc}
                    disabled={!showBestResultSorting}
                  />
                  <RadioGroupField.Item
                    label="Najmanji"
                    value={BestResultSorting.Asc}
                    disabled={!showBestResultSorting}
                  />
                </RadioGroupField>
              </div>
              <div className="w-full flex justify-end space-x-4 pt-4 border-t">
                <Button variant="outlined" onClick={modal.onClose}>
                  Odustani
                </Button>
                <Button variant="filled" type="submit">
                  Kreiraj
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CreateProgressTypeModal;
