import React from "react";

import { Card, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import UserIcon from "../../common/components/UserIcon";
import { ParentResponse } from "../api/ParentResponse";

type ParentDetailsHeaderProps = {
  parent: ParentResponse;
};

export default function ParentDetailsHeader({
  parent,
}: ParentDetailsHeaderProps) {
  return (
    <div className="w-full mb-8">
      <Card>
        <Card.Body>
          <div className="flex flex-col justify-between sm:flex-row justify-center">
            <div className="w-full flex flex-col items-center sm:flex-row justify-center md:justify-start lg:items-start">
              <div className="mb-4 mr-0 sm:mr-4 flex ">
                <UserIcon
                  initials={
                    parent.firstName.charAt(0).toUpperCase() +
                    parent.lastName.charAt(0).toUpperCase()
                  }
                  entityId={parent.id}
                  width="100px"
                  height="100px"
                  textClassName="text-5xl"
                />
              </div>
              <div className="flex items-center flex-col justify-center mb-2 sm:items-start justify-start ml-1 ">
                <div className="flex mb-2 sm:text-navy-500">
                  <Typography element="h2" variant="h4">
                    {`${parent.firstName} ${parent.lastName}`}
                  </Typography>
                </div>
                <div className="mb-4 text-center sm:text-left w-full  border-t-[1px] pt-2">
                  <div className="mt-2">
                    <div className="flex mb-2">
                      <Icon
                        type="phone"
                        className="mr-1 text-navy-400"
                        variant="fill"
                      />
                      {parent.phoneNumber ? (
                        <Typography className="text-base">
                          {parent.phoneNumber}
                        </Typography>
                      ) : (
                        <Typography variant="subtext" className="text-base">
                          Podatak nije unesen
                        </Typography>
                      )}
                    </div>
                    <div className="flex mb-2">
                      <Icon
                        type="envelope"
                        className="mr-1 text-navy-400"
                        variant="fill"
                      />
                      <Typography className="text-base">
                        {parent.email}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
