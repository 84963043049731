import { RecipientGroup } from "./RecipientGroup";
import { RECIPIENT_GROUPS_URL } from "../Routes";

export async function getRecipientGroups(): Promise<RecipientGroup[]> {
  const response = await fetch(`${RECIPIENT_GROUPS_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
