export function formatDateToHr(date: Date): string {
  return date.toLocaleDateString("hr-HR");
}

export function formatDateToHrWithDashes(date: Date): string {
  const dashedDate = formatDateToHr(date).replaceAll(". ", "-");
  return dashedDate.substring(0, dashedDate.length - 1);
}
export function firstDayOfMonthDate(): Date {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function lastDayOfMonthDate(): Date {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
