import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import {
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "../../../auth/SecurityProvider";
import AthletePersonalRecords from "../../../common/components/cards/AthletePersonalRecords";
import DeleteModal from "../../../common/components/modals/DeleteModal";
import { RegistryTabIndex } from "../../../common/constants";
import AthleteChartSelector from "../../charts/components/AthleteChartSelector";
import { UserType } from "../../common/api/UserResponse";
import NoAvailableData from "../../common/components/NoAvailableData";
import handleDeleteResponse from "../../common/handleDeleteResponse";
import { RegistryContext } from "../../common/RegistryProvider";
import { GroupResponse } from "../../group/api/GroupResponse";
import { ParentResponse } from "../../parent/api/ParentResponse";
import { getProgressTypes } from "../../statistics/api/getProgressTypes";
import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";
import { AthleteDetailedResponse } from "../api/AthleteDetailedResponse";
import { deleteAthlete } from "../api/deleteAthlete";
import { getAthleteById } from "../api/getAthleteById";
import AthleteGroupsEventsSelector from "../components/AthleteGroupsEventsSelector";
import AthleteHeader from "../components/AthleteHeader";
import EventAttendanceStatisticsCard from "../components/EventAttendanceStatisticsCard";
import UpdateAthleteModal from "../components/UpdateAthleteModal";
import AthleteNotes from "../note/components/AthleteNotes";

export default function AthleteDetails() {
  const { userHasRole } = useContext(UserContext);
  const { setRegistryTabIndex } = useContext(RegistryContext);
  const [athlete, setAthlete] = useState({} as AthleteDetailedResponse);
  const [loaded, setLoaded] = useState(false);
  const [groupList, setGroupList] = useState([] as GroupResponse[]);
  const [render, setRender] = useState(false);
  const [parents, setParents] = useState([] as ParentResponse[]);
  const [progressTypeList, setProgressTypeList] = useState(
    [] as ProgressTypeResponse[]
  );
  const { add: addNotification } = useNotifications();

  const navigate = useNavigate();

  const { id } = useParams();

  const deleteAthleteModal = useModal();

  const deleteAthleteModalFunction = () => {
    deleteAthlete(athlete.id).then((res) => {
      if (res.ok) {
        handleDeleteResponse(res, addNotification);
        deleteAthleteModal.onClose();
        navigate("/archive");
      }
    });
  };

  useEffect(() => {
    if (id) {
      getProgressTypes().then((response: ProgressTypeResponse[]) => {
        setProgressTypeList(response);
        getAthleteById(Number(id)).then((response: AthleteDetailedResponse) => {
          setAthlete(response);
          setLoaded(true);
          setRender(false);
          setGroupList(response.groups);
          setParents(response.parents);
        });
      });
    }
  }, [id, render]);

  const updateModal = useModal();

  setRegistryTabIndex(RegistryTabIndex.ATHLETES);

  if (!loaded) {
    return <></>;
  } else {
    return (
      <div>
        <div className="flex flex-col gap-y-8 px-4 md:px-0 md:my-6 ">
          <div className="flex flex-col gap-y-6">
            <div className="justify-between items-start hidden md:flex">
              <div className="flex md:flex-col">
                <Typography
                  element="h3"
                  variant="h3"
                  className="hidden md:inline font-biotiff"
                >
                  Detalji sportaša
                </Typography>
                <div className="hidden md:block">
                  {(userHasRole(UserType.ADMIN) ||
                    userHasRole(UserType.COACH)) && (
                    <Breadcrumbs>
                      <Breadcrumbs.Breadcrumb>
                        <Link to="/archive">Registar sportaša</Link>
                      </Breadcrumbs.Breadcrumb>
                      <Breadcrumbs.Breadcrumb>
                        Detalji sportaša
                      </Breadcrumbs.Breadcrumb>
                    </Breadcrumbs>
                  )}
                </div>
              </div>
              <div className="flex justify-end md:justify-between">
                {userHasRole(UserType.ADMIN) && (
                  <>
                    <div className="flex justify-between gap-2 items-center">
                      <Button
                        variant="outlined"
                        size="sm"
                        color="primary"
                        leadingIcon={<Icon type="trash" variant="bold" />}
                        onClick={deleteAthleteModal.onOpen}
                      >
                        Izbriši sportaša
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        color="primary"
                        leadingIcon={<Icon type="note-pencil" variant="bold" />}
                        onClick={updateModal.onOpen}
                      >
                        Uredi podatke
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col md:hidden">
              <div className="flex flex-col gap-y-2">
                <div className="flex md:flex-col justify-between items-center">
                  <IconButton
                    icon={<Icon type="caret-left" variant="bold" />}
                    className="md:hidden"
                    onClick={() => navigate("/archive")}
                  />
                  <div className="justify-end md:justify-between md:flex">
                    <DropdownMenu
                      title="Opcije"
                      variant="outlined"
                      color="primary"
                      openExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                      closeExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                    >
                      {userHasRole(UserType.ADMIN) && (
                        <>
                          <DropdownMenu.Item
                            onSelect={() => {
                              updateModal.onOpen(null);
                            }}
                          >
                            Uredi podatke
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onSelect={() => {
                              deleteAthleteModal.onOpen(null);
                            }}
                          >
                            Izbriši sportaša
                          </DropdownMenu.Item>
                        </>
                      )}
                      <DropdownMenu.Item onSelect={() => {}}>
                        Preuzmi zdravstveni karton
                      </DropdownMenu.Item>
                    </DropdownMenu>
                  </div>
                </div>
                <Typography
                  element="h6"
                  variant="h6"
                  className="md:hidden font-biotiff"
                >
                  Detalji sportaša
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col w-full xl:flex-row h-1/3 gap-6">
              <div className="w-full xl:w-1/2">
                <AthleteHeader
                  athlete={athlete}
                  parents={parents}
                  progressTypeList={progressTypeList}
                />
              </div>
              <div className="w-full xl:w-1/2">
                <AthletePersonalRecords
                  athlete={athlete}
                  progressTypeList={progressTypeList}
                />
              </div>
            </div>
            <EventAttendanceStatisticsCard
              id={athlete.id}
              firstName={athlete.firstName}
              lastName={athlete.lastName}
            />
            <div className="flex flex-col h-1/3 xl:flex-row justify-between gap-6">
              <div className="w-full">
                <Card className="w-full h-full p-4">
                  <Card.Header className="flex justify-between items-center text-lg font-bold">
                    Raspored treninga
                  </Card.Header>
                  <Card.Body>
                    <div className="w-full">
                      {athlete.groups.length > 0 ? (
                        <AthleteGroupsEventsSelector groupList={groupList} />
                      ) : (
                        <NoAvailableData message="Nema rasporeda treninga za ovog sportaša" />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="w-full">
                <AthleteNotes athleteId={athlete.id} />
              </div>
            </div>
            <div className="w-full">
              <Card className="p-4">
                <Card.Header className="font-bold p-0">
                  Statistika sportaša
                </Card.Header>
                <Card.Body>
                  {progressTypeList.length > 0 ? (
                    <AthleteChartSelector
                      athlete={athlete}
                      progressTypeList={progressTypeList}
                      groupList={groupList}
                    />
                  ) : (
                    <NoAvailableData message="Nema statistike za praćenje" />
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <DeleteModal
          modal={deleteAthleteModal}
          title="Izbriši sportaša"
          content="Jeste li sigurni da želite izbrisati sportaša?"
          buttonConfirmText="Izbriši sportaša"
          buttonConfirmFunction={deleteAthleteModalFunction}
        />
        {userHasRole(UserType.ADMIN) && (
          <UpdateAthleteModal
            modal={updateModal}
            setRender={setRender}
            athlete={athlete}
          />
        )}
      </div>
    );
  }
}
