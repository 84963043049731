import { Notification } from "@croz/nrich-notification-core";

import handleResponse from "../../common/handleResponse";

export default function handleErrorResponse(
  response: Response,
  addNotification: (notification: Notification) => void,
  notificationBody = {
    title: "Neuspješno kreiranje događaja",
    content: "Prilikom kreiranja događaja dogodila se pogreška",
    messageList: [],
    severity: "ERROR",
    timestamp: new Date(),
  } as Notification
): boolean {
  return handleResponse(response, addNotification, notificationBody);
}
