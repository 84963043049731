import { ClubManagementRequest } from "./ClubManagementRequest";
import { CLUB_MANAGEMENT_URL } from "../../common/Routes";

export async function postClubManagement(request: ClubManagementRequest) {
  const response = await fetch(CLUB_MANAGEMENT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
