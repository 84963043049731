import { NOTE_URL } from "../../../../common/Routes";
import { AthleteNoteResponse } from "../../api/AthleteNoteResponse";

export async function getNoteById(id: number): Promise<AthleteNoteResponse> {
  const response = await fetch(`${NOTE_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
