import React from "react";

import { Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import PersonalRecordWrapperCard from "./PersonalRecordWrapperCard";
import { AthleteDetailedResponse } from "../../../archive/athlete/api/AthleteDetailedResponse";

type HotStreakCardProps = {
  athlete: AthleteDetailedResponse;
};

export default function HotStreakCard({ athlete }: HotStreakCardProps) {
  return (
    <PersonalRecordWrapperCard>
      <div className="w-full flex items-center gap-4">
        <div className="flex justify-center items-center bg-orange-100 rounded-full w-12 h-12">
          <Typography element="p" variant="h5">
            <Icon
              type="fire"
              size={7}
              variant="bold"
              className="text-orange-500 flex"
            />
          </Typography>
        </div>
        <div>
          <Typography variant="subtext" className="text-gray-700">
            Treninzi za redom
          </Typography>
          <Typography
            element="p"
            variant="subtext"
            className="text-orange-500 font-bold"
          >
            {athlete.personalRecords.eventAttendanceHotStreakCount}
          </Typography>
        </div>
      </div>
    </PersonalRecordWrapperCard>
  );
}
