import { EventCommentResponse } from "./EventCommentResponse";
import { EVENT_URL } from "../../../common/Routes";

export async function getCommentsForEvent(
  eventId: string
): Promise<EventCommentResponse[]> {
  const response = await fetch(`${EVENT_URL}/${eventId}/comments`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
