import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Typography } from "@tiller-ds/core";

import { AthleteDetailedResponse } from "../../../archive/athlete/api/AthleteDetailedResponse";
import HotStreakCard from "../cards/HotStreakCard";

type CreateProgressTypeModalProps = {
  modal: UseModal;
  athlete: AthleteDetailedResponse;
  renderTopResults: () => (JSX.Element | null)[];
};

const PersonalRecordsModal = ({
  modal,
  athlete,
  renderTopResults,
}: CreateProgressTypeModalProps) => {
  return (
    <>
      <Modal {...modal}>
        <Modal.Content
          title={
            <Typography variant="h5" className="font-biotif">
              Svi osobni rekordi
            </Typography>
          }
        >
          <div className="flex flex-col gap-2 w-full">
            <HotStreakCard athlete={athlete} />
            {renderTopResults()}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PersonalRecordsModal;
