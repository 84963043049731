import React from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Card, Typography } from "@tiller-ds/core";

import Eula from "./Eula";
import LicenceLogoutModal from "./LicenceLogoutModal";
import { ReactComponent as HAVKLogoVertical } from "../../assets/img/HAVK_vertical.svg";
import { EulaRequest } from "../api/EulaRequest";
import { postEulaAcceptionFlag } from "../api/postEulaAcceptionFlag";

export default function LicenceAgreementPage() {
  const modal = useModal();

  function onAccept() {
    const eulaRequest = {
      hasAcceptedEula: true,
    } as EulaRequest;
    postEulaAcceptionFlag({ ...eulaRequest }).then(() => {
      window.location.reload();
    });
  }
  return (
    <div className="h-screen flex justify-center align-middle bg-navy-50">
      <div className="flex flex-col">
        <HAVKLogoVertical className="pt-10 pb-4" />
        <div className="mx-auto w-screen md:w-3/5">
          <Card>
            <Card.Header>
              <Typography element="h4" variant="h4">
                Uvjeti i pravila korištenja aplikacije
              </Typography>
            </Card.Header>
            <Card.Body>
              <div className="border-2 overflow-auto overscroll-auto p-2 h-64">
                <Eula />
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="flex justify-between">
                <Button type="reset" variant="outlined" onClick={modal.onOpen}>
                  Odjavi se
                </Button>
                <Button
                  className="ml-3"
                  type="submit"
                  onClick={() => {
                    onAccept();
                  }}
                >
                  Prihvaćam uvjete
                </Button>
                <LicenceLogoutModal modal={modal} />
              </div>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </div>
  );
}
