import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import {
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "../../../auth/SecurityProvider";
import DeleteModal from "../../../common/components/modals/DeleteModal";
import { RegistryTabIndex } from "../../../common/constants";
import GroupsList from "../../../home/components/GroupsList";
import { UserType } from "../../common/api/UserResponse";
import UserIcon from "../../common/components/UserIcon";
import handleDeleteResponse from "../../common/handleDeleteResponse";
import { RegistryContext } from "../../common/RegistryProvider";
import { GroupResponse } from "../../group/api/GroupResponse";
import GroupBadge from "../../group/components/GroupBadge";
import { CoachDetailedResponse } from "../api/CoachDetailedResponse";
import { deleteCoach } from "../api/deleteCoach";
import { getCoachById } from "../api/getCoachById";
import GroupEventsSelector from "../components/GroupEventsSelector";
import UpdateCoachModal from "../components/UpdateCoachModal";

export default function CoachDetails() {
  const { userHasRole } = useContext(UserContext);
  const { setRegistryTabIndex } = useContext(RegistryContext);
  const [coach, setCoach] = useState({} as CoachDetailedResponse);
  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([] as GroupResponse[]);
  const [render, setRender] = useState(false);

  const { add: addNotification } = useNotifications();

  const navigate = useNavigate();

  const { id } = useParams();

  const deleteCoachModal = useModal();

  const updateModal = useModal();

  const deleteCoachModalFunction = () => {
    deleteCoach(coach.id).then((res) => {
      if (res.ok) {
        handleDeleteResponse(res, addNotification);
        deleteCoachModal.onClose();
        navigate("/archive");
      }
    });
  };
  useEffect(() => {
    if (id) {
      getCoachById((id as unknown) as number).then(
        (response: CoachDetailedResponse) => {
          setCoach(response);
          setLoaded(true);
          setRender(false);
          setGroups(response.groups);
        }
      );
    }
  }, [id, render]);

  setRegistryTabIndex(RegistryTabIndex.COACHES);

  if (!loaded) {
    return <></>;
  } else {
    return (
      <div className="w-full">
        <div className="flex flex-col px-4 md:px-0 md:my-6 gap-y-6">
          <div className="flex flex-col">
            <div className="justify-between items-start hidden md:flex">
              <div className="flex md:flex-col">
                <Typography
                  element="h3"
                  variant="h3"
                  className="hidden md:inline font-biotiff"
                >
                  Detalji trenera
                </Typography>
                <div className="hidden md:block">
                  <Breadcrumbs>
                    <Breadcrumbs.Breadcrumb>
                      <Link to="/archive">Registar trenera</Link>
                    </Breadcrumbs.Breadcrumb>
                    <Breadcrumbs.Breadcrumb>
                      Detalji trenera
                    </Breadcrumbs.Breadcrumb>
                  </Breadcrumbs>
                </div>
              </div>
              <div className="flex justify-end md:justify-between">
                {userHasRole(UserType.ADMIN) && (
                  <>
                    <div className="flex justify-between items-center hidden md:block">
                      <Button
                        className="mr-1"
                        variant="outlined"
                        size="sm"
                        color="primary"
                        leadingIcon={
                          <Icon
                            type="trash"
                            variant="bold"
                            className="text-gray-500"
                          />
                        }
                        onClick={deleteCoachModal.onOpen}
                      >
                        Izbriši trenera
                      </Button>
                      <Button
                        className="w-fit"
                        leadingIcon={<Icon type="note-pencil" variant="bold" />}
                        size="sm"
                        variant="outlined"
                        color="primary"
                        onClick={updateModal.onOpen}
                      >
                        Uredi podatke
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col md:hidden">
              <div className="flex flex-col gap-y-2">
                <div className="flex md:flex-col justify-between items-center">
                  <IconButton
                    icon={<Icon type="caret-left" variant="bold" />}
                    className="md:hidden"
                    onClick={() => navigate("/archive")}
                  />
                  {userHasRole(UserType.ADMIN) && (
                    <>
                      <DropdownMenu
                        title="Opcije"
                        variant="outlined"
                        color="primary"
                        openExpanderIcon={
                          <Icon type="caret-down" variant="bold" />
                        }
                        closeExpanderIcon={
                          <Icon type="caret-down" variant="bold" />
                        }
                      >
                        <DropdownMenu.Item
                          onSelect={() => {
                            updateModal.onOpen(true);
                          }}
                        >
                          Uredi podatke
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onSelect={() => {
                            deleteCoachModal.onOpen(null);
                          }}
                        >
                          Izbriši trenera
                        </DropdownMenu.Item>
                      </DropdownMenu>
                    </>
                  )}
                </div>
                <Typography
                  element="h6"
                  variant="h6"
                  className="md:hidden font-biotiff"
                >
                  Detalji trenera
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center sm:flex-row w-full justify-between">
            <div className="w-full">
              <Card className="w-full">
                <Card.Body>
                  <div className="flex justify-center sm:justify-between">
                    <div className="flex flex-col items-center justify-center sm:items-start sm:flex-row md:justify-start lg:items-start">
                      <div className="mb-3 sm:mb-0">
                        <UserIcon
                          initials={
                            coach.firstName.charAt(0).toUpperCase() +
                            coach.lastName.charAt(0).toUpperCase()
                          }
                          entityId={coach.id}
                          width="100px"
                          height="100px"
                          textClassName="text-5xl"
                        />
                      </div>
                      <div className="flex flex-col justify-center items-center mb-3 sm:ml-4 sm:items-start">
                        <div className="flex mb-2 text-navy-500">
                          <Typography
                            element="h2"
                            variant="h4"
                            className="font-biotif"
                          >
                            {`${coach.firstName} ${coach.lastName}`}
                          </Typography>
                        </div>
                        <div className="flex text-navy-400 space-x-2 mb-4">
                          {coach.groups.map((group) => {
                            return (
                              <Link
                                to={`/archive/groups/${group.id}`}
                                className="hover:no-underline"
                              >
                                <GroupBadge
                                  groupName={group.groupName}
                                  groupId={group.id}
                                />
                              </Link>
                            );
                          })}
                        </div>
                        <div className="flex flex-col items-center sm:items-start w-72 border-t-[1px]">
                          <div className="flex mb-2 mt-4 text-navy-400">
                            <Icon
                              type="phone"
                              className="mr-1"
                              variant="fill"
                            />
                            {coach.phoneNumber ? (
                              <Typography className="text-base">
                                {coach.phoneNumber}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtext"
                                className="text-base"
                              >
                                Podatak nije unesen
                              </Typography>
                            )}
                          </div>
                          <div className="flex text-navy-400">
                            <Icon type="envelope" className="mr-1" />
                            <Typography className="text-base">
                              {coach.email}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          <GroupsList groupList={coach.groups} />
          <GroupEventsSelector groupList={groups}></GroupEventsSelector>
        </div>
        <DeleteModal
          modal={deleteCoachModal}
          title="Izbriši trenera"
          content="Jeste li sigurni da želite izbrisati trenera?"
          buttonConfirmText="Izbriši trenera"
          buttonConfirmFunction={deleteCoachModalFunction}
        />
        {userHasRole(UserType.ADMIN) && (
          <UpdateCoachModal
            modal={updateModal}
            coach={coach}
            setRender={setRender}
          />
        )}
      </div>
    );
  }
}
