import { CoachSearchRequest } from "./CoachSearchRequest";
import { COACH_URL } from "../../../common/Routes";

export async function postSearchCoachRequest(request: CoachSearchRequest) {
  const response = await fetch(`${COACH_URL}/search/pageable`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
