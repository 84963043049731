import React from "react";

import { iconConfig } from "@tiller-ds/icons";
import { ThemeProvider } from "@tiller-ds/theme";

import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import bearerTokenInterceptor from "./auth/bearerTokenInterceptor";
import responseErrorInterceptor from "./common/responseErrorInterceptor";
import reportWebVitals from "./reportWebVitals";
import { tillerConfig } from "./styles/tillerConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

responseErrorInterceptor();
bearerTokenInterceptor();

root.render(
  <ThemeProvider themeConfig={tillerConfig} iconConfig={iconConfig}>
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
