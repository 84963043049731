import React, { useEffect, useState } from "react";

import { Card, Typography } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";

import EventAttendancePieChart from "./EventAttendancePieChart";
import { EventAttendanceStatisticsRequest } from "../api/EventAttendanceStatisticsRequest";
import { EventAttendanceStatisticsResponse } from "../api/EventAttendanceStatisticsResponse";
import { postEventAttendanceStatisticsRequest } from "../api/postEventAttendanceStatisticsRequest";

const initialStartDate = new Date() as Date | null;
initialStartDate?.setFullYear(initialStartDate?.getFullYear() - 1);

type EventAttendanceStatisticsCardProps = {
  id: number;
  firstName: string;
  lastName: string;
};

const typographyHeadersTokens = {
  variant: {
    subtitle: {
      color: "text-blue-600",
    },
  },
};

const typographyDataTokens = {
  variant: {
    subtext: {
      color: "text-navy-500",
    },
  },
};

export default function EventAttendanceStatisticsCard({
  id,
}: EventAttendanceStatisticsCardProps) {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(new Date() as Date | null);
  const [eventAttendanceStatistics, setEventAttendanceStatistics] = useState(
    {} as EventAttendanceStatisticsResponse
  );
  const [loaded, setLoaded] = useState(false);

  const DATE_TIME = 12;

  useEffect(() => {
    if (id) {
      postEventAttendanceStatisticsRequest({
        participantId: id,
        startTime: startDate,
        endTime: endDate,
      } as EventAttendanceStatisticsRequest).then(
        (response: EventAttendanceStatisticsResponse) => {
          setEventAttendanceStatistics(response);
          setLoaded(true);
        }
      );
    }
  }, [id, startDate, endDate]);

  if (loaded) {
    return (
      <Card className="w-full p-4">
        <Card.Header>
          <div className="flex flex-col gap-2 justify-between items-center lg:flex-row">
            <div className="flex w-full lg:w-auto justify-start">
              <Card.Header.Title>
                <Typography variant="h5" element="h5">
                  Dolaznost na treninge
                </Typography>
              </Card.Header.Title>
            </div>
            <div className="flex flex-col w-full justify-between lg:w-auto gap-2 sm:flex-row md:justify-end">
              <DateInput
                className="w-full md:w-auto"
                label="Početak perioda"
                name="startDate"
                allowClear={false}
                onBlur={() => {}}
                onChange={(value) => {
                  value?.setHours(DATE_TIME);
                  setStartDate(value);
                }}
                value={startDate}
                maxDate={endDate as Date | undefined}
              ></DateInput>
              <DateInput
                className="w-full md:w-auto"
                label="Kraj perioda"
                name="endDate"
                allowClear={false}
                onBlur={() => {}}
                onChange={(value) => {
                  value?.setHours(DATE_TIME);
                  setEndDate(value);
                }}
                value={endDate}
                popoverPosition="right"
                minDate={startDate as Date | undefined}
              ></DateInput>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="flex flex-col justify-between lg:flex-row xl:justify-start w-full">
            <EventAttendancePieChart
              eventAttendanceStatistics={eventAttendanceStatistics}
            />
            <div className="flex flex-col gap-2 justify-between w-full lg:w-2/3 xl:w-3/4 2xl:w-1/2">
              <div className="flex">
                <Typography
                  tokens={typographyHeadersTokens}
                  variant="subtitle"
                  className="font-bold"
                >
                  DOLASCI
                </Typography>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col">
                  <Typography variant="subtext">Broj dolazaka</Typography>
                  <Typography
                    tokens={typographyDataTokens}
                    variant="subtext"
                    className="font-bold"
                  >
                    {eventAttendanceStatistics.totalTrainingsWasPresent}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="subtext">Ukupno</Typography>
                  <Typography
                    tokens={typographyDataTokens}
                    variant="subtext"
                    className="font-bold"
                  >
                    {`${eventAttendanceStatistics.totalTrainingsWasPresent}/${eventAttendanceStatistics.totalTrainings}`}{" "}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="subtext">Postotak dolaznosti</Typography>
                  <Typography
                    tokens={typographyDataTokens}
                    variant="subtext"
                    className="font-bold"
                  >
                    {eventAttendanceStatistics.totalTrainings !== 0
                      ? `${eventAttendanceStatistics.totalTrainingsWasPresentPercentage.toFixed(
                          2
                        )}%`
                      : "0.00%"}
                  </Typography>
                </div>
              </div>
              <div className="flex">
                <Typography
                  tokens={typographyHeadersTokens}
                  variant="subtitle"
                  className="font-bold"
                >
                  IZOSTANCI
                </Typography>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col">
                  <Typography variant="subtext">Broj izostanaka</Typography>
                  <Typography
                    tokens={typographyDataTokens}
                    variant="subtext"
                    className="font-bold"
                  >
                    {eventAttendanceStatistics.totalTrainingsWasNotPresent}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="subtext">Najavljeno</Typography>
                  <Typography
                    tokens={typographyDataTokens}
                    variant="subtext"
                    className="font-bold"
                  >
                    {
                      eventAttendanceStatistics.totalTrainingsWasNotPresentExcused
                    }
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="subtext">Nenajavljeno</Typography>
                  <Typography
                    tokens={typographyDataTokens}
                    variant="subtext"
                    className="font-bold"
                  >
                    {eventAttendanceStatistics.totalTrainingsWasNotPresent -
                      eventAttendanceStatistics.totalTrainingsWasNotPresentExcused}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  } else {
    return <></>;
  }
}
