import React, { useContext, useState } from "react";

import { Breadcrumbs, Button, Link, Typography } from "@tiller-ds/core";
import {
  AutocompleteField,
  CheckboxField,
  InputField,
  TextareaField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { postFilterUsers } from "../../archive/common/api/postFilterUsers";
import { UserResponse, UserType } from "../../archive/common/api/UserResponse";
import { UserContext } from "../../auth/SecurityProvider";
import { RecipientGroup } from "../../common/api/RecipientGroup";
import HorizontalLine from "../../common/components/HorizontalLine";
import RecipientsAutocompleteFieldSelector from "../../common/components/RecipientsAutocompleteFieldSelector";
import {
  MAX_MESSAGE_CONTENT_LENGTH,
  MAX_MESSAGE_DESCRIPTION_LENGTH,
  MAX_MESSAGE_SUBJECT_LENGTH,
  MAX_TITLE_LENGTH,
  userType,
} from "../../common/constants";
import {
  maxCharacterCountMessage,
  MIN_ONE_RECIPIENT_GROUP_MESSAGE,
  MIN_ONE_RECIPIENT_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from "../../common/YupConstants";
import { MessageResponse } from "../api/MessageResponse";
import { postMessage } from "../api/postMessage";

const initialMessageFormValues: MessageForm = {
  userRecipients: [],
  recipientGroups: [],
  title: "",
  description: "",
  isImportant: false,
};

type MessageForm = {
  userRecipients: UserResponse[];
  recipientGroups: RecipientGroup[];
  title: string;
  description: string;
  isImportant: boolean;
};

export default function MessageCreatePage() {
  const navigate = useNavigate();
  const { userHasAnyRole, user, onChangeNumOfUnreadMessages } = useContext(
    UserContext
  );
  const { add: addNotification } = useNotifications();
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);
  const [subjectCharacterCount, setSubjectCharacterCount] = useState(0);

  const validationSchema = yup.object().shape(
    {
      title: yup
        .string()
        .required(REQUIRED_FIELD_MESSAGE)
        .nullable()
        .max(MAX_TITLE_LENGTH, maxCharacterCountMessage(MAX_TITLE_LENGTH)),
      description: yup
        .string()
        .required(REQUIRED_FIELD_MESSAGE)
        .nullable()
        .max(
          MAX_MESSAGE_DESCRIPTION_LENGTH,
          maxCharacterCountMessage(MAX_MESSAGE_DESCRIPTION_LENGTH)
        ),
      userRecipients: yup.array().when("recipientGroups", (recipientGroups) => {
        if (recipientGroups === undefined || recipientGroups.length === 0)
          return yup.array().min(1, MIN_ONE_RECIPIENT_MESSAGE);
        else return yup.array();
      }),
      recipientGroups: yup.array().when("userRecipients", (userRecipients) => {
        if (userRecipients === undefined || userRecipients.length === 0)
          return yup.array().min(1, MIN_ONE_RECIPIENT_GROUP_MESSAGE);
        else return yup.array();
      }),
    },
    [
      ["userRecipients", "recipientGroups"],
      ["recipientGroups", "userRecipients"],
    ]
  );

  function userSearch(query: string) {
    return postFilterUsers({
      name: query,
      userType: ["COACH", "ATHLETE", "PARENT", "ADMIN"],
    });
  }

  const onSubmitMessage = (values: MessageForm) => {
    let messageRequest;
    if (values.userRecipients.length > 0) {
      messageRequest = {
        title: values.title,
        description: values.description,
        isImportant: values.isImportant,
        recipientIds: values.userRecipients.map((user) => user.id),
      };
    } else if (values.recipientGroups.length > 0) {
      messageRequest = {
        title: values.title,
        description: values.description,
        isImportant: values.isImportant,
        recipientGroups: values.recipientGroups,
      };
    }
    // @ts-ignore
    postMessage(messageRequest).then((response: MessageResponse) => {
      addNotification({
        title: "Poruka je poslana",
        content: "Vaša poruka je uspješno poslana.",
        messageList: [],
        severity: "INFO",
        timestamp: new Date(),
        uxNotificationOptions: {
          actions: [
            {
              url: "/message-details/" + response.id,
              title: "Pogledaj poruku",
            },
          ],
        },
      });
      if (values.userRecipients.find((recipient) => recipient.id === user.id)) {
        onChangeNumOfUnreadMessages(user.numOfUnreadMessages + 1);
      }
      navigate("/messages");
    });
  };

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6 gap-y-4">
      <div className="flex flex-col hidden md:block">
        <Typography
          element="h3"
          variant="h3"
          className="hidden md:inline font-biotiff"
        >
          Kreiranje poruke
        </Typography>
        <div className="mt-4">
          <Breadcrumbs>
            <Breadcrumbs.Breadcrumb>
              <Link to="/messages">Poruke</Link>
            </Breadcrumbs.Breadcrumb>
            <Breadcrumbs.Breadcrumb>Kreiranje poruke</Breadcrumbs.Breadcrumb>
          </Breadcrumbs>
        </div>
      </div>
      <div className="md:hidden">
        <div className="flex gap-x-4">
          <div
            className="flex cursor-pointer"
            onClick={() => navigate("/messages")}
          >
            <Icon
              type="caret-left"
              variant="bold"
              size={3}
              className="my-auto"
            />
          </div>
          <Typography element="h6" variant="h6">
            Kreiranje poruke
          </Typography>
        </div>
      </div>
      <div className="h-full">
        <Formik
          initialValues={initialMessageFormValues}
          onSubmit={onSubmitMessage}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex flex-col">
              <div>
                <Typography
                  element="h6"
                  variant="h6"
                  className="!text-white bg-[#00258E] pl-3 py-2"
                >
                  Nova poruka
                </Typography>
                <div className="flex flex-col flex-grow border-gray-500 border">
                  <div className="flex flex-row py-2 pr-2">
                    <Typography className="justify-center pl-2.5 pr-7 align-top align-text-top mt-4">
                      Prima:
                    </Typography>
                    <div className="w-full">
                      {userHasAnyRole([UserType.ADMIN, UserType.COACH]) ? (
                        <RecipientsAutocompleteFieldSelector
                          recipientsName={"userRecipients"}
                          recipientGroupsName={"recipientGroups"}
                        />
                      ) : (
                        <div className="mt-2">
                          <AutocompleteField
                            name="userRecipients"
                            options={userSearch}
                            allowMultiple
                            itemToString={(item) =>
                              `${item.firstName} ${item.lastName}`
                            }
                            getOptionLabel={(user) => (
                              <div className="flex items-center justify-between flex-wrap">
                                <div>
                                  {user.firstName} {user.lastName}
                                </div>
                                <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
                                  {userType.get(user.userType)}
                                </div>
                              </div>
                            )}
                            getOptionValue={(item) => item.id}
                            getMultipleSelectedLabel={(items) => {
                              return items
                                .map(
                                  (user: UserResponse) =>
                                    `${user.firstName} ${user.lastName}`
                                )
                                .join(", ");
                            }}
                            tags={true}
                            sendOptionValue={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <HorizontalLine />
                  <div className="flex flex-row py-2">
                    <Typography className="justify-center pl-2.5 pr-1 align-top align-text-top mt-2">
                      Predmet:
                    </Typography>
                    <div className="grow mr-2 ml-1">
                      <InputField
                        name="title"
                        className="input"
                        maxLength={MAX_MESSAGE_SUBJECT_LENGTH}
                        onKeyUp={() =>
                          setSubjectCharacterCount(
                            formik.getFieldProps("title").value
                              ? formik.getFieldProps("title").value.length
                              : 0
                          )
                        }
                      />
                      <Typography
                        variant="subtext"
                        className="flex justify-end mt-1 text-gray-400"
                      >{`${subjectCharacterCount}/${MAX_MESSAGE_SUBJECT_LENGTH}`}</Typography>
                    </div>
                  </div>
                  <HorizontalLine />
                  <div className="pl-2.5 pt-3 pb-3">
                    <CheckboxField
                      name="isImportant"
                      label={<Typography>Označi kao važno</Typography>}
                    />
                  </div>
                  <div className={"px-2.5 pb-2.5 "}>
                    <TextareaField
                      name="description"
                      textareaClassName="h-40"
                      maxLength={MAX_MESSAGE_CONTENT_LENGTH}
                      className="whitespace-pre-line"
                      onKeyUp={() => {
                        setDescriptionCharacterCount(
                          formik.getFieldProps("description").value.length
                        );
                      }}
                    />
                    <Typography
                      variant="subtext"
                      className="flex justify-end mt-1 text-gray-400"
                    >{`${descriptionCharacterCount}/${MAX_MESSAGE_CONTENT_LENGTH}`}</Typography>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-3 mb-5 space-x-2 ">
                <Button
                  type="button"
                  variant="outlined"
                  size="sm"
                  onClick={() => navigate("/messages")}
                >
                  Odustani
                </Button>
                <Button type="submit" size="sm">
                  Pošalji
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
