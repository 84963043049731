import React from "react";

import { Button, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import SurveySelector from "./admin/components/SurveySelector";

export default function SurveysOverview() {
  const navigate = useNavigate();
  return (
    <div className="w-full px-4 sm:px-0">
      <div className="flex flex-col w-full h-full mt-8">
        <div className="flex justify-between items-center mb-8">
          <Typography
            element="h3"
            variant="h3"
            className="hidden md:inline font-biotiff"
          >
            Ankete
          </Typography>
          <Typography
            element="h6"
            variant="h6"
            className="md:hidden font-biotiff"
          >
            Ankete
          </Typography>
          <Button
            variant="outlined"
            size="sm"
            color={"primary"}
            leadingIcon={<Icon type="plus" />}
            onClick={() => {
              navigate("/survey-create");
            }}
          >
            Nova anketa
          </Button>
        </div>
        <SurveySelector tabIndex={0} />
      </div>
    </div>
  );
}
