import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import {
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Link,
  Typography,
} from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "../../../auth/SecurityProvider";
import EventCardWithTimeAndLocation from "../../../common/components/cards/EventCardWithTimeAndLocation";
import CoachListModal from "../../../common/components/modals/CoachListModal";
import DeleteModal from "../../../common/components/modals/DeleteModal";
import { EventType, RegistryTabIndex } from "../../../common/constants";
import GroupDetailsStatisticSelector from "../../charts/components/GroupDetailsStatisticSelector";
import { UserType } from "../../common/api/UserResponse";
import CreateUserModal from "../../common/components/CreateUserModal";
import NoAvailableData from "../../common/components/NoAvailableData";
import UserIcon from "../../common/components/UserIcon";
import { RegistryContext } from "../../common/RegistryProvider";
import { deleteGroup } from "../api/deleteGroup";
import { getGroupDetails } from "../api/getGroupDetails";
import { GroupResponse } from "../api/GroupResponse";
import GroupAthletesTable from "../components/GroupAthletesTable";
import UpdateGroupModal from "../components/UpdateGroupModal";

export default function GroupDetails() {
  const { userHasRole } = useContext(UserContext);
  const { setRegistryTabIndex } = useContext(RegistryContext);
  const [groupDetails, setGroupDetails] = useState({} as GroupResponse);
  const [loaded, setLoaded] = useState(false);
  const [render, setRender] = useState(false);
  const [entityCreated, setEntityCreated] = useState(0);

  const { add: addNotification } = useNotifications();
  const navigate = useNavigate();
  const { id } = useParams();
  const currentDate = new Date();

  const deleteGroupModal = useModal();
  const createUser = useModal();

  const deleteGroupModalFunction = () => {
    deleteGroup(groupDetails.id).then((res) => {
      if (res.ok) {
        addNotification({
          title: "Grupa je izbrisana",
          content: `Grupa ${groupDetails.groupName} je uspješno izbrisana.`,
          messageList: [],
          severity: "INFO",
          timestamp: new Date(),
        });
        deleteGroupModal.onClose();
        navigate("/archive");
      }
    });
  };

  useEffect(() => {
    if (id !== undefined) {
      getGroupDetails(id).then((response: GroupResponse) => {
        setGroupDetails(response);
        setLoaded(true);
        setRender(false);
      });
    }
  }, [id, render]);

  const updateModal = useModal();

  const sortedEvents = () =>
    groupDetails.events.sort((firstEvent, secondEvent) =>
      firstEvent.startTime.localeCompare(secondEvent.startTime)
    );

  const filteredTrainingEvents = () =>
    sortedEvents().filter(
      (event) =>
        new Date(event.startTime) >= currentDate &&
        event.eventType === EventType.TRAINING
    );

  setRegistryTabIndex(RegistryTabIndex.GROUPS);

  if (!loaded) {
    return <></>;
  } else {
    return (
      <div className="flex flex-col px-4 md:px-0 md:my-6">
        <div className="flex flex-col gap-y-6">
          <div className="justify-between items-start hidden md:flex">
            <div className="flex md:flex-col">
              <Typography
                element="h3"
                variant="h3"
                className="hidden md:inline font-biotiff"
              >
                Detalji grupe
              </Typography>
              <div className="hidden md:block">
                <Breadcrumbs>
                  <Breadcrumbs.Breadcrumb>
                    <Link to="/archive">Registar grupa</Link>
                  </Breadcrumbs.Breadcrumb>
                  <Breadcrumbs.Breadcrumb>Detalji grupe</Breadcrumbs.Breadcrumb>
                </Breadcrumbs>
              </div>
            </div>
            <div className="flex justify-end md:justify-between">
              {userHasRole(UserType.ADMIN) && (
                <>
                  <div className="flex justify-between items-center">
                    <Button
                      className="w-fit mr-1"
                      variant="outlined"
                      size="sm"
                      color="primary"
                      leadingIcon={<Icon type="trash" variant="bold" />}
                      onClick={deleteGroupModal.onOpen}
                    >
                      <div className="pt-0 hidden md:flex">Izbriši</div>
                    </Button>
                    <Button
                      className="w-fit mr-1"
                      leadingIcon={<Icon type="note-pencil" variant="bold" />}
                      variant="outlined"
                      color="primary"
                      size="sm"
                      onClick={updateModal.onOpen}
                    >
                      Uredi
                    </Button>
                    <Button
                      leadingIcon={<Icon type="user" variant="fill" size={4} />}
                      variant="outlined"
                      color="primary"
                      size="sm"
                      onClick={createUser.onOpen}
                    >
                      Kreiraj korisnika
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col md:hidden">
            <div className="flex flex-col gap-y-2">
              <div className="flex md:flex-col justify-between items-center">
                <IconButton
                  icon={<Icon type="caret-left" variant="bold" />}
                  className="md:hidden"
                  onClick={() => navigate("/archive")}
                />
                <div className="justify-end md:justify-between md:flex">
                  <DropdownMenu
                    title="Opcije"
                    variant="outlined"
                    color="primary"
                    openExpanderIcon={<Icon type="caret-down" variant="bold" />}
                    closeExpanderIcon={
                      <Icon type="caret-down" variant="bold" />
                    }
                  >
                    {userHasRole(UserType.ADMIN) && (
                      <>
                        <DropdownMenu.Item
                          onSelect={() => {
                            updateModal.onOpen(null);
                          }}
                        >
                          Uredi podatke
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onSelect={() => {
                            deleteGroupModal.onOpen(null);
                          }}
                        >
                          Izbriši grupu
                        </DropdownMenu.Item>
                      </>
                    )}
                  </DropdownMenu>
                </div>
              </div>
              <Typography
                element="h6"
                variant="h6"
                className="md:hidden font-biotiff"
              >
                Detalji grupe
              </Typography>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row gap-6 justify-between">
            <div className="flex flex-col w-full xl:w-1/3 gap-6">
              <div>
                <Card>
                  <Card.Body>
                    <div className="flex w-full gap-4 xl:flex-col 2xl:flex-row">
                      <div className="flex gap-2 items-start xl:items-center 2xl:items-start">
                        <UserIcon
                          initials={
                            groupDetails.groupName.charAt(0).toUpperCase() +
                            groupDetails.groupName.charAt(1).toUpperCase()
                          }
                          entityId={groupDetails.id}
                          width="80px"
                          height="80px"
                          textClassName="text-2xl sm:text-4xl"
                        />
                        <Typography
                          element="h5"
                          variant="h5"
                          className="hidden xl:block 2xl:hidden"
                        >
                          {groupDetails.groupName}
                        </Typography>
                      </div>
                      <div className="flex flex-col justify-start gap-2">
                        <div className="flex flex-col gap-1">
                          <Typography
                            element="h5"
                            variant="h5"
                            className="xl:hidden 2xl:block"
                          >
                            {groupDetails.groupName}
                          </Typography>
                          <div className="flex flex-wrap gap-1">
                            <Typography className="text-sm">
                              Broj sportaša:
                            </Typography>
                            <Typography className="text-sm font-bold">
                              {loaded ? groupDetails.athletes.length : "..."}
                            </Typography>
                          </div>
                          <div className="flex flex-wrap gap-1">
                            <Typography className="text-sm">
                              Dobna granica:
                            </Typography>
                            <Typography className="text-sm font-bold">
                              {groupDetails.minAge}-{groupDetails.maxAge}
                            </Typography>
                          </div>
                          <div className="flex flex-wrap gap-1">
                            <Typography className="text-sm">
                              Trener/i:
                            </Typography>
                            {loaded && groupDetails.coaches[0] ? (
                              <>
                                <Typography className="text-sm font-bold">
                                  {`${groupDetails.coaches[0].firstName} ${groupDetails.coaches[0].lastName}`}
                                </Typography>
                                <div title="Pogledaj sve trenere">
                                  <CoachListModal
                                    coachList={groupDetails.coaches}
                                  />
                                </div>
                              </>
                            ) : (
                              <Typography className="text-sm font-bold italic">
                                Nema trenera
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <Card className="p-4">
                <Card.Header className="flex justify-between items-center text-lg font-bold">
                  Raspored treninga
                </Card.Header>
                <Card.Body>
                  <div className="w-full">
                    {filteredTrainingEvents().length > 0 ? (
                      <div className="flex flex-col gap-y-2 overflow-auto pr-2 h-64">
                        {filteredTrainingEvents().map((event) => {
                          return (
                            <EventCardWithTimeAndLocation
                              event={event}
                              className=""
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <NoAvailableData message="Nema zakazanog termina u ovoj grupi" />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="xl:w-2/3">
              <GroupAthletesTable
                groupId={groupDetails.id}
                loaded={loaded}
                groupName={groupDetails.groupName}
                render={render}
                setRender={setRender}
                entityCreated={entityCreated}
                setEntityCreated={setEntityCreated}
              />
            </div>
          </div>
          <GroupDetailsStatisticSelector
            groupId={groupDetails.id}
            groupName={groupDetails.groupName}
            render={render}
          />
          <DeleteModal
            modal={deleteGroupModal}
            title="Izbriši grupu"
            content="Jeste li sigurni da želite izbrisati grupu?"
            buttonConfirmText="Izbriši grupu"
            buttonConfirmFunction={deleteGroupModalFunction}
          />
          {userHasRole(UserType.ADMIN) && (
            <UpdateGroupModal
              modal={updateModal}
              setRender={setRender}
              group={groupDetails}
            />
          )}
        </div>
        <CreateUserModal
          entityCreated={entityCreated}
          setEntityCreated={setEntityCreated}
          modal={createUser}
          group={groupDetails}
        />
      </div>
    );
  }
}
