import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import AthleteDetails from "../../archive/athlete/pages/AthleteDetails";
import CoachDetails from "../../archive/coach/pages/CoachDetails";
import { UserType } from "../../archive/common/api/UserResponse";
import Archive from "../../archive/common/pages/Archive";
import GroupDetails from "../../archive/group/pages/GroupDetails";
import ParentDetails from "../../archive/parent/pages/ParentDetails";
import ProgressTypesPage from "../../archive/statistics/pages/ProgressTypesPage";
import RouteAuthWrapper from "../../auth/RouteAuthWrapper";
import { useEnvironmentVariable } from "../../env-provider/EnvironmentVariablesProvider";
import EventCreatePage from "../../event/pages/EventCreatePage";
import EventDetailsPage from "../../event/pages/EventDetailsPage";
import EventViewPage from "../../event/pages/EventViewPage";
import EditClubInfo from "../../home/pages/EditClubInfo";
import Home from "../../home/pages/Home";
import CreateInvoicePage from "../../invoice/admin-invoice-page/pages/CreateInvoicePage";
import PaymentsView from "../../invoice/common/PaymentsView";
import { ParentInvoicesViewPage } from "../../invoice/parent-invoice-page/pages/ParentInvoicesViewPage";
import MessageCreatePage from "../../messages/pages/MessageCreatePage";
import MessageDetailsPage from "../../messages/pages/MessageDetailsPage";
import MessagesViewPage from "../../messages/pages/MessagesViewPage";
import Reports from "../../reports/pages/Reports";
import SurveyCreate from "../../survey/admin/pages/SurveyCreate";
import SurveyResults from "../../survey/admin/pages/SurveyResults";
import SurveysOverview from "../../survey/SurveysOverview";
import UserProfile from "../../user-profile/pages/UserProfile";
import ErrorPage from "../pages/ErrorPage";

export default function RoutesComponent() {
  const { REACT_APP_ENABLE_SURVEYS } = useEnvironmentVariable();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route
        element={
          <RouteAuthWrapper rolesAllowed={[UserType.ADMIN, UserType.COACH]} />
        }
      >
        <Route path="/archive" element={<Archive />} />
        <Route path="/archive/coaches/:id" element={<CoachDetails />} />
        <Route path="/archive/groups/:id" element={<GroupDetails />} />
      </Route>
      <Route path="/archive/athletes/:id" element={<AthleteDetails />} />
      <Route
        element={
          <RouteAuthWrapper
            rolesAllowed={[UserType.ADMIN, UserType.COACH, UserType.PARENT]}
          />
        }
      >
        <Route path="/archive/parents/:id" element={<ParentDetails />} />
      </Route>
      <Route path="/calendar" element={<EventViewPage />} />
      <Route element={<RouteAuthWrapper rolesAllowed={[UserType.ADMIN]} />}>
        <Route
          path="/calendar/event/new"
          element={<EventCreatePage editMode={false} />}
        />
        <Route
          path="/home/event/new"
          element={<EventCreatePage editMode={false} />}
        />
        <Route
          path="/calendar/event/update/:id/:isRepeating"
          element={<EventCreatePage editMode={true} />}
        />

        {REACT_APP_ENABLE_SURVEYS === "true" && (
          <>
            <Route path="/survey-result/:id" element={<SurveyResults />} />
            <Route path="/survey-create" element={<SurveyCreate />} />
          </>
        )}
        <Route path="/home/edit-club-info" element={<EditClubInfo />} />
        <Route path="/progress-types" element={<ProgressTypesPage />} />
      </Route>

      <Route path="/error/:status" element={<ErrorPage />} />
      <Route path="/event-details/:id" element={<EventDetailsPage />} />
      <Route path="/home" element={<Home />} />
      <Route path="/messages" element={<MessagesViewPage />} />
      <Route path="/messages/new" element={<MessageCreatePage />} />
      <Route path="/message-details/:id" element={<MessageDetailsPage />} />
      {REACT_APP_ENABLE_SURVEYS === "true" && (
        <Route path="/survey" element={<SurveysOverview />} />
      )}
      <Route path="/user-profile" element={<UserProfile />} />
      <Route path="/logout" element={<></>} />
      <Route path="*" element={<Navigate to="/home" />} />

      <Route path="/reports" element={<Reports />} />

      <Route path="/reports" element={<Reports />} />

      <Route path="/reports" element={<Reports />} />

      <Route path="/payments" element={<PaymentsView />} />
      <Route path="/payments/parent" element={<ParentInvoicesViewPage />} />
      <Route path="/payments/new" element={<CreateInvoicePage />} />
    </Routes>
  );
}
