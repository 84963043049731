import { CLUB_INFO_URL } from "../../common/Routes";

export async function getClubInfo() {
  const response = await fetch(CLUB_INFO_URL, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
