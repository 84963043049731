import React from "react";

import { Tabs, Typography } from "@tiller-ds/core";

import { Ranks } from "../components/rank/Ranks";
import StateReport from "../components/state/StateReport";
import Trend from "../components/trend/Trend";

export default function Reports() {
  return (
    <div className="flex flex-col p-3">
      <Typography element="h3" variant="h3" className="font-biotiff">
        Izvještaji
      </Typography>
      <Tabs className="mt-10">
        <Tabs.Tab label="Stanje">
          <div className="gap-y-8 mt-10">
            <StateReport />
          </div>
        </Tabs.Tab>
        <Tabs.Tab label="Kretanja">
          <div className="gap-y-8 mt-10">
            <Trend />
          </div>
        </Tabs.Tab>
        <Tabs.Tab label="Rang liste">
          <div className="gap-y-8 mt-10">
            <Ranks />
          </div>
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}
