import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, IconButton } from "@tiller-ds/core";
import { Checkbox } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import MessageDeleteModal from "./MessageDeleteModal";
import { UserContext } from "../../auth/SecurityProvider";
import {
  BASE_MESSAGES_FILTER_REQUEST,
  HeaderCheckboxState,
  MessageFilter,
} from "../../common/constants";
import { Message } from "../api/Message";
import { postMarkAsPinned } from "../api/postMarkAsPinned";
import { postMarkAsRead } from "../api/postMarkAsRead";
import { postMarkAsUnpinned } from "../api/postMarkAsUnpinned";
import { postMarkAsUnread } from "../api/postMarkAsUnread";
import { MessageContext } from "../MessageProvider";

type MessageHeaderProps = {
  messages: Message[];
  onChangeMessages: (messages: Message[]) => void;
};

export default function MessagesHeader({
  messages,
  onChangeMessages,
}: MessageHeaderProps) {
  const navigate = useNavigate();
  const createMessage = () => {
    navigate("/messages/new");
  };

  const modal = useModal();

  const { user, onChangeNumOfUnreadMessages } = useContext(UserContext);

  const {
    showSent,
    messageFilter,
    setPage,
    setShowSent,
    setMessageFilter,
    setMessageFilterRequest,
  } = useContext(MessageContext);

  const [headerCheckboxState, setHeaderCheckboxState] = useState(
    HeaderCheckboxState.Unchecked
  );

  const [messagesFilterTitle, setMessagesFilterTitle] = useState(
    "Prikaži: " + messageFilter
  );
  const [
    markAsPinnedOrUnpinnedTitle,
    setMarkAsPinnedOrUnpinnedTitle,
  ] = useState("");
  const [markAsReadOrUnreadTitle, setMarkAsReadOrUnreadTitle] = useState("");

  function getCheckboxState() {
    return headerCheckboxState !== HeaderCheckboxState.Unchecked;
  }

  function getNewCheckboxState() {
    switch (headerCheckboxState) {
      case HeaderCheckboxState.Checked:
      case HeaderCheckboxState.HalfChecked:
        return HeaderCheckboxState.Unchecked;
      case HeaderCheckboxState.Unchecked:
        return HeaderCheckboxState.Checked;
    }
  }

  function onViewSelect(messageFilter: MessageFilter) {
    setMessagesFilterTitle("Prikaži: " + messageFilter);
    setPage(0);
    setMessageFilter(messageFilter);

    switch (messageFilter) {
      case MessageFilter.RECEIVED:
        setShowSent(false);
        setMessageFilterRequest({
          ...BASE_MESSAGES_FILTER_REQUEST,
        });
        break;
      case MessageFilter.READ:
        setShowSent(false);
        setMessageFilterRequest({
          ...BASE_MESSAGES_FILTER_REQUEST,
          read: true,
        });
        break;
      case MessageFilter.UNREAD:
        setShowSent(false);
        setMessageFilterRequest({
          ...BASE_MESSAGES_FILTER_REQUEST,
          read: false,
        });
        break;
      case MessageFilter.PINNED:
        setShowSent(false);
        setMessageFilterRequest({
          ...BASE_MESSAGES_FILTER_REQUEST,
          pinned: true,
        });
        break;
      case MessageFilter.SENT:
        setShowSent(true);
        setMessageFilterRequest({
          ...BASE_MESSAGES_FILTER_REQUEST,
        });
    }
  }

  function onTogglePinned() {
    const messageIds = [] as number[];
    messages.forEach((message) => {
      if (message.isChecked) {
        messageIds.push(message.id);
      }
    });

    switch (markAsPinnedOrUnpinnedTitle) {
      case "Označi kao bitno":
        if (messageIds.length > 0) {
          postMarkAsPinned({
            messageIds: messageIds,
          }).then(() =>
            onChangeMessages(
              messages.map((message) => {
                if (messageIds.includes(message.id)) {
                  return {
                    ...message,
                    isPinned: true,
                  };
                } else {
                  return message;
                }
              })
            )
          );
        }
        break;

      case "Označi kao nebitno":
        if (messageIds.length > 0) {
          postMarkAsUnpinned({
            messageIds: messageIds,
          }).then(() =>
            onChangeMessages(
              messages.map((message) => {
                if (messageIds.includes(message.id)) {
                  return {
                    ...message,
                    isPinned: false,
                  };
                } else {
                  return message;
                }
              })
            )
          );
        }
    }

    setHeaderCheckboxState(HeaderCheckboxState.Unchecked);
  }

  function onToggleRead() {
    const messageIds = [] as number[];
    messages.forEach((message) => {
      if (message.isChecked) {
        messageIds.push(message.id);
      }
    });

    switch (markAsReadOrUnreadTitle) {
      case "Označi kao pročitano":
        if (messageIds.length > 0) {
          let changedMessages = messages.filter((message) => {
            return messageIds.includes(message.id) && !message.isRead;
          }).length;

          postMarkAsRead({
            messageIds: messageIds,
          }).then(() => {
            onChangeNumOfUnreadMessages(
              user.numOfUnreadMessages - changedMessages
            );
            onChangeMessages(
              messages.map((message) => {
                if (messageIds.includes(message.id)) {
                  return {
                    ...message,
                    isRead: true,
                  };
                } else {
                  return message;
                }
              })
            );
          });
        }
        break;

      case "Označi kao nepročitano":
        if (messageIds.length > 0) {
          let changedMessages = messages.filter((message) => {
            return messageIds.includes(message.id) && message.isRead;
          }).length;
          postMarkAsUnread({
            messageIds: messageIds,
          }).then(() => {
            onChangeNumOfUnreadMessages(
              user.numOfUnreadMessages + changedMessages
            );
            onChangeMessages(
              messages.map((message) => {
                if (messageIds.includes(message.id)) {
                  return {
                    ...message,
                    isRead: false,
                  };
                } else {
                  return message;
                }
              })
            );
          });
        }
    }

    setHeaderCheckboxState(HeaderCheckboxState.Unchecked);
  }

  function onSelectReadOrUnreadMessages(isRead: boolean) {
    onChangeMessages(
      messages.map((message) => {
        message.isChecked = message.isRead === isRead;
        return message;
      })
    );
  }

  let markAsDeletedIds: number[] = [];
  messages.forEach((message) => {
    if (message.isChecked) {
      markAsDeletedIds.push(message.id);
    }
  });

  useEffect(() => {
    if (headerCheckboxState !== HeaderCheckboxState.HalfChecked) {
      onChangeMessages(
        messages.map((message) => {
          message.isChecked =
            headerCheckboxState === HeaderCheckboxState.Checked;
          return message;
        })
      );
    }
    /* eslint-disable */
  }, [headerCheckboxState]);
  /* eslint-enable */

  useEffect(() => {
    if (messages.length === 0) {
      setHeaderCheckboxState(HeaderCheckboxState.Unchecked);
      return;
    }

    const allMessagesChecked = messages.every((message) => message.isChecked);
    const checkedMessages = messages.filter((message) => message.isChecked);

    if (checkedMessages.length === 0) {
      setHeaderCheckboxState(HeaderCheckboxState.Unchecked);
    } else if (allMessagesChecked) {
      setHeaderCheckboxState(HeaderCheckboxState.Checked);
    } else {
      setHeaderCheckboxState(HeaderCheckboxState.HalfChecked);
    }

    const allCheckedMessagesPinned =
      checkedMessages.length !== 0 &&
      checkedMessages.every((message) => message.isPinned);

    if (allCheckedMessagesPinned) {
      setMarkAsPinnedOrUnpinnedTitle("Označi kao nebitno");
    } else {
      setMarkAsPinnedOrUnpinnedTitle("Označi kao bitno");
    }

    const allCheckedMessagesRead =
      checkedMessages.length !== 0 &&
      checkedMessages.every((message) => message.isRead);

    if (allCheckedMessagesRead) {
      setMarkAsReadOrUnreadTitle("Označi kao nepročitano");
    } else {
      setMarkAsReadOrUnreadTitle("Označi kao pročitano");
    }
  }, [messages]);

  function setHeaderClassNames(headerCheckboxState: any) {
    return classNames({
      "sm:visible whitespace-nowrap": true,
      "invisible ": headerCheckboxState !== HeaderCheckboxState.Unchecked,
    });
  }

  return (
    <div className="flex py-2 sticky top-0 z-10 bg-[#F3F4F6] lg:static grid">
      <div className="flex w-full items-center justify-between">
        <div className="flex">
          <div className="items-center">
            <div className="mt-2 ml-2 md:ml-5 z-10">
              <Checkbox
                disabled={messages.length === 0}
                onChange={() => setHeaderCheckboxState(getNewCheckboxState())}
                checked={headerCheckboxState !== HeaderCheckboxState.Unchecked}
              />
            </div>
          </div>
          <div className="relative z-0">
            <DropdownMenu
              disabled={messages.length === 0}
              menuType="icon"
              color="white"
              variant="text"
              openExpanderIcon={<Icon type="caret-down" variant="bold" />}
              closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
            >
              <DropdownMenu.Item
                disabled={messages.length === 0}
                onSelect={() =>
                  setHeaderCheckboxState(HeaderCheckboxState.Checked)
                }
              >
                Primljeno
              </DropdownMenu.Item>
              <DropdownMenu.Item
                disabled={messages.length === 0}
                onSelect={() =>
                  setHeaderCheckboxState(HeaderCheckboxState.Unchecked)
                }
              >
                Ništa
              </DropdownMenu.Item>
              <DropdownMenu.Item
                disabled={messages.length === 0}
                onSelect={() => onSelectReadOrUnreadMessages(true)}
              >
                Pročitano
              </DropdownMenu.Item>
              <DropdownMenu.Item
                disabled={messages.length === 0}
                onSelect={() => onSelectReadOrUnreadMessages(false)}
              >
                Nepročitano
              </DropdownMenu.Item>
            </DropdownMenu>
          </div>
          <div className="flex items-center">
            {getCheckboxState() && (
              <div>
                <div className="hidden xl:block">
                  <Button
                    variant="text"
                    className="border-x-2 rounded-none"
                    onClick={onTogglePinned}
                  >
                    {markAsPinnedOrUnpinnedTitle}
                  </Button>
                  {!showSent && (
                    <Button
                      variant="text"
                      className="border-r-2 rounded-none"
                      onClick={onToggleRead}
                    >
                      {markAsReadOrUnreadTitle}
                    </Button>
                  )}
                  <Button
                    variant="text"
                    className="hidden lg:inline-block rounded-none"
                    onClick={modal.onOpen}
                  >
                    Izbriši
                  </Button>
                </div>
                <div className="xl:hidden flex gap-x-6 mr-4">
                  <IconButton
                    showTooltip={false}
                    onClick={onTogglePinned}
                    icon={<Icon type="star" variant="fill" />}
                    color="black"
                    className="border-0"
                  />
                  <IconButton
                    showTooltip={false}
                    onClick={onToggleRead}
                    icon={<Icon type="calendar-check" variant="fill" />}
                    type="clipboard-check"
                    color="black"
                    className="border-0"
                  />
                  <IconButton
                    showTooltip={false}
                    onClick={modal.onOpen}
                    icon={<Icon type="trash" variant="bold" />}
                    color="black"
                    className="border-0"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={setHeaderClassNames(headerCheckboxState)}>
          <DropdownMenu
            color="primary"
            title={messagesFilterTitle}
            openExpanderIcon={<Icon type="caret-down" variant="bold" />}
            closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
          >
            <DropdownMenu.Item
              onSelect={() => onViewSelect(MessageFilter.RECEIVED)}
            >
              Prikaži: Primljeno
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => onViewSelect(MessageFilter.READ)}
            >
              Prikaži: Pročitano
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => onViewSelect(MessageFilter.UNREAD)}
            >
              Prikaži: Nepročitano
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => onViewSelect(MessageFilter.PINNED)}
            >
              Prikaži: Prikvačeno
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => onViewSelect(MessageFilter.SENT)}
            >
              Prikaži: Poslano
            </DropdownMenu.Item>
          </DropdownMenu>
        </div>
        <div className="hidden lg:block ml-auto mr-4 lg:mr-0">
          <Button
            leadingIcon={<Icon type="plus" size={3} variant="bold" />}
            variant="filled"
            size="md"
            onClick={createMessage}
          >
            <div className="my-auto whitespace-nowrap">Nova poruka</div>
          </Button>
        </div>
      </div>
      <MessageDeleteModal
        modal={modal}
        messageIds={markAsDeletedIds}
        messages={messages}
        onChangeMessages={onChangeMessages}
      />
    </div>
  );
}
