import React from "react";

import { Card, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { typographyCardTokens } from "../../pages/EventDetailsPage";

type EventLeaderCardProps = {
  content: string;
};

export default function EventLeaderCard({ content }: EventLeaderCardProps) {
  return (
    <Card className="h-full">
      <div className="p-4 flex lg:flex-col lg:justify-between h-full">
        <div className="mr-2 px-2 lg:px-0 my-auto lg:my-0">
          <Icon
            type="users"
            variant="fill"
            size={6}
            className="w-8 h-8 lg:w-6 lg:h-6"
          ></Icon>
        </div>
        <div className="grid gap-y-1">
          <Typography tokens={typographyCardTokens} element="h3" variant="h6">
            Voditelj događaja
          </Typography>
          <Typography
            className={content.length === 0 ? "italic" : "not-italic"}
            element="p"
            variant="subtext"
          >
            {content.length === 0 ? "Voditelj nije unesen" : content}
          </Typography>
        </div>
      </div>
    </Card>
  );
}
