import React, { useContext, useEffect, useState } from "react";

import { IconButton } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import FullCalendar from "@fullcalendar/react";

import { UserType } from "../../../archive/common/api/UserResponse";
import { GroupResponse } from "../../../archive/group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../../archive/group/api/postSearchGroupRequestNonPaged";
import { UserContext } from "../../../auth/SecurityProvider";
import { CalendarView, EventType } from "../../../common/constants";
import { CalendarStateContext } from "../../CalendarProvider";

const CALENDAR_VIEW_TITLE_MAP = new Map<CalendarView, string>([
  [CalendarView.DAY_VIEW, "Dnevni prikaz"],
  [CalendarView.WEEK_VIEW, "Tjedni prikaz"],
  [CalendarView.MONTH_VIEW, "Mjesečni prikaz"],
]);

const FILTER_TITLE_MAP = new Map<EventType, string>([
  [EventType.ALL, "Svi događaji"],
  [EventType.GENERAL, "Ostalo"],
  [EventType.TRAINING, "Treninzi"],
  [EventType.COMPETITION, "Natjecanja"],
  [EventType.MEETING, "Sastanci"],
]);

type CalendarHeaderProps = {
  calendarComponentRef: React.RefObject<FullCalendar>;
  calendarTitle: string;
  updateCalendarTitle: (newTitle: string) => void;
};

export default function CalendarHeader({
  calendarComponentRef,
  calendarTitle,
  updateCalendarTitle,
}: CalendarHeaderProps) {
  const {
    calendarView,
    setCalendarView,
    eventFilter,
    setEventFilter,
    groupFilter,
    setGroupFilter,
    setInitialDate,
    setStartDate,
    setEndDate,
  } = useContext(CalendarStateContext);
  useEffect(refreshTitle);

  const { userHasRole } = useContext(UserContext);
  const [groups, setGroups] = useState([] as GroupResponse[]);

  useEffect(() => {
    if (userHasRole(UserType.ADMIN)) {
      postSearchGroupRequestNonPaged({
        groupName: "",
        briefResponse: true,
      }).then((response) => {
        setGroups(response);
      });
    }
  }, [userHasRole]);

  function refreshTitle() {
    let newTitle = calendarComponentRef.current?.getApi()?.getCurrentData()
      .viewTitle;
    if (newTitle) {
      updateCalendarTitle(newTitle);
    }
  }

  function updateDateRange() {
    setInitialDate(calendarComponentRef.current!.getApi().view.currentStart);
    setStartDate(calendarComponentRef.current!.getApi().view.currentStart);
    setEndDate(calendarComponentRef.current!.getApi().view.currentEnd);
  }

  function onViewSelect(view: CalendarView) {
    setCalendarView(view);
    calendarComponentRef.current?.getApi()?.changeView(view);
    updateDateRange();
    refreshTitle();
  }

  function onPreviousClick() {
    calendarComponentRef.current?.getApi()?.prev();
    updateDateRange();
    refreshTitle();
  }

  function onNextClick() {
    calendarComponentRef.current?.getApi()?.next();
    updateDateRange();
    refreshTitle();
  }

  return (
    <div className="flex flex-row justify-between mb-5 grid grid-cols-12">
      <div className="grid col-span-6 lg:col-span-4 lg:order-1 px-2 lg:px-0">
        <DropdownMenu
          title={CALENDAR_VIEW_TITLE_MAP.get(calendarView)}
          color="primary"
          openExpanderIcon={<Icon type="caret-down" variant="bold" />}
          closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
        >
          <DropdownMenu.Item
            onSelect={() => onViewSelect(CalendarView.MONTH_VIEW)}
          >
            Mjesečni prikaz
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => onViewSelect(CalendarView.WEEK_VIEW)}
          >
            Tjedni prikaz
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => onViewSelect(CalendarView.DAY_VIEW)}
          >
            Dnevni prikaz
          </DropdownMenu.Item>
        </DropdownMenu>
      </div>

      <div className="grid col-span-12 lg:col-span-4 order-first justify-center lg:order-2 mb-2 lg:mb-0">
        <div className="flex items-center">
          <IconButton
            showTooltip={false}
            icon={
              <Icon
                type="caret-left"
                size={3}
                className="text-gray-500"
                variant="bold"
              />
            }
            label="Prethodni"
            onClick={onPreviousClick}
          />
          {calendarTitle}
          <IconButton
            icon={
              <Icon
                type="caret-right"
                size={3}
                className="text-gray-500"
                variant="bold"
              />
            }
            showTooltip={false}
            label="Sljedeći"
            onClick={onNextClick}
          />
        </div>
      </div>

      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row col-span-6 lg:col-span-4 justify-end place-items-end lg:order-3 px-2 md:space-x-4 lg:px-0">
        {userHasRole(UserType.ADMIN) && (
          <DropdownMenu
            // @ts-ignore
            title={groupFilter ? groupFilter.groupName : "Sve grupe"}
            color="primary"
            openExpanderIcon={<Icon type="caret-down" variant="bold" />}
            closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
          >
            <DropdownMenu.Item onSelect={() => setGroupFilter(undefined)}>
              Sve grupe
            </DropdownMenu.Item>
            {groups.map((group) => {
              return (
                <DropdownMenu.Item onSelect={() => setGroupFilter(group)}>
                  {group.groupName}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu>
        )}
        <DropdownMenu
          title={FILTER_TITLE_MAP.get(eventFilter)}
          color="primary"
          openExpanderIcon={<Icon type="caret-down" variant="bold" />}
          closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
        >
          <DropdownMenu.Item onSelect={() => setEventFilter(EventType.ALL)}>
            Svi događaji
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => setEventFilter(EventType.COMPETITION)}
          >
            Natjecanja
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={() => setEventFilter(EventType.TRAINING)}
          >
            Treninzi
          </DropdownMenu.Item>
          <DropdownMenu.Item onSelect={() => setEventFilter(EventType.MEETING)}>
            Sastanci
          </DropdownMenu.Item>
          <DropdownMenu.Item onSelect={() => setEventFilter(EventType.GENERAL)}>
            Ostalo
          </DropdownMenu.Item>
        </DropdownMenu>
      </div>
    </div>
  );
}
