export default function switchColor(status: string) {
  switch (status) {
    case "Aktivna":
      return "success";
    case "Neaktivna":
      return "secondary";
    case "Završena":
      return "warning";
    default:
      return "success";
  }
}
