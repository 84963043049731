import { EventAttendanceStatisticsRequest } from "./EventAttendanceStatisticsRequest";
import { EventAttendanceStatisticsResponse } from "./EventAttendanceStatisticsResponse";
import { EVENT_ATTENDANCES_URL } from "../../../common/Routes";

export async function postEventAttendanceStatisticsRequest(
  request: EventAttendanceStatisticsRequest
): Promise<EventAttendanceStatisticsResponse> {
  const response = await fetch(`${EVENT_ATTENDANCES_URL}/statistics`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
