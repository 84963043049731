import FileDownload from "js-file-download";

import { EventReportsRequest } from "./EventReportsRequest";
import { REPORTS_STATE_EVENTS_URL } from "../../common/Routes";
import { formatDateToHrWithDashes } from "../util/dateUtil";

export function exportEventReportsExcel(request: EventReportsRequest) {
  fetch(`${REPORTS_STATE_EVENTS_URL}/export/excel`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const startDateFormatted = formatDateToHrWithDashes(request.startDate);
      const endDateFormatted = formatDateToHrWithDashes(request.endDate);
      FileDownload(
        blob,
        `izvještaj_događaja_${startDateFormatted}_${endDateFormatted}.xlsx`
      );
    });
}
