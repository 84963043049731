import React, { useEffect, useState } from "react";

import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { MeasuringUnit } from "../../../common/constants";
import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";
import ProgressResultMapper from "../util/ProgressResultMapper";

type AthleteLineChartProps = {
  progressType: ProgressTypeResponse;
  athleteStatistic: StatisticByDate[];
};

export type StatisticByDate = {
  date: string;
  result: number;
};

export default function AthleteChart({
  progressType,
  athleteStatistic,
}: AthleteLineChartProps) {
  const [totalElements, setTotalElements] = useState(0);
  const [resultDescription, setResultDescription] = useState("");

  useEffect(() => {
    if (progressType.measuringUnit !== undefined) {
      setResultDescription(getResultDescription(progressType.measuringUnit));
    }
  }, [progressType]);

  useEffect(() => {
    if (athleteStatistic !== undefined) {
      setTotalElements(athleteStatistic.length);
    }
  }, [athleteStatistic]);

  const getResultDescription = (measuringUnit: MeasuringUnit) => {
    switch (measuringUnit) {
      case MeasuringUnit.Centimeter:
        return "Visina";
      case MeasuringUnit.Kilogram:
        return "Težina";
      case MeasuringUnit.Second:
        return "Vrijeme";
      case MeasuringUnit.Repetition:
        return "Ponavljanja";
    }
  };

  return (
    <ResponsiveContainer height={300} width="100%" className="mb-8">
      <AreaChart
        width={500}
        height={400}
        data={athleteStatistic}
        margin={{
          top: 10,
          right: 5,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickLine={false}
          tickFormatter={(dateString) => {
            if (dateString !== 0 && Date.parse(dateString)) {
              return new Date(dateString).toLocaleDateString("hr-HR", {
                month: "2-digit",
                year: "2-digit",
              });
            } else {
              return "";
            }
          }}
          dy={3}
          height={50}
        />
        <YAxis
          tickLine={false}
          tickFormatter={(result) => {
            return result.toString();
          }}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload) {
              return (
                <div className="bg-white rounded border border-gray-250 shadow-md tooltip p-3">
                  <p>{`${new Date(label).toLocaleDateString("hr-HR")}`}</p>
                  <p className="mt-1 text-aqua-800">{`${resultDescription}: ${ProgressResultMapper(
                    Number(payload[0].value),
                    progressType.measuringUnit
                  )}`}</p>
                </div>
              );
            } else {
              return null;
            }
          }}
        />
        <Area
          type="monotone"
          dataKey="result"
          stroke="#0094A8"
          fill="#B3F5FF"
        />
        <Brush
          startIndex={
            totalElements > 13
              ? totalElements - 13
              : totalElements - totalElements
          }
          endIndex={totalElements > 0 ? totalElements - 1 : 0}
          travellerWidth={30}
          height={30}
          stroke="#00258E"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
