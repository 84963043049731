import { UserFilterRequest } from "./UserFilterRequest";
import { UserResponse } from "./UserResponse";
import { USER_URL } from "../../../common/Routes";

export async function postFilterUsers(
  filter: UserFilterRequest
): Promise<UserResponse[]> {
  const response = await fetch(`${USER_URL}/filter`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(filter),
  });
  return response.json();
}
