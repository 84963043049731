export const EVENT_URL = "/api/events";
export const REPEATING_EVENT_URL = "/api/repeating-events";
export const FILTER_EVENTS_URL = "/api/events/filter";
export const EVENT_PARTICIPANTS_URL = "/api/events/event-with-participants";

export const COACH_URL = "/api/coaches";
export const ATHLETE_URL = "/api/athletes";
export const PARENT_URL = "/api/parents";
export const ADMIN_URL = "/api/admins";
export const GROUP_URL = "/api/groups";
export const GROUP_SEARCH_URL = "/api/groups/search";
export const USER_URL = "/api/users";
export const ALLOW_EMAIL = `${USER_URL}/allow-email`;
export const CURRENT_USER_URL = `${USER_URL}/current-user`;
export const PROGRESS_URL = "/api/progress";
export const PLANNED_ABSENCE_URL = "/api/planned-absences";
export const NOTE_URL = "/api/notes";
export const EVENT_ATTENDANCES_URL = "/api/event-attendances";
export const RECIPIENT_GROUPS_URL = "/api/recipient-groups";

export const MESSAGE_URL = "/api/messages";
export const MESSAGE_RECIPIENTS_URL = "/api/message-recipients";
export const MESSAGE_MARK_AS_READ_URL = `${MESSAGE_RECIPIENTS_URL}/mark-as-read`;
export const MESSAGE_MARK_AS_UNREAD_URL = `${MESSAGE_RECIPIENTS_URL}/mark-as-unread`;
export const MESSAGE_MARK_AS_PINNED_URL = `${MESSAGE_RECIPIENTS_URL}/mark-as-pinned`;
export const MESSAGE_MARK_AS_UNPINNED_URL = `${MESSAGE_RECIPIENTS_URL}/mark-as-unpinned`;
export const MESSAGE_MARK_AS_DELETED = `${MESSAGE_RECIPIENTS_URL}/mark-as-deleted`;

export const CLUB_MANAGEMENT_URL = "/api/club/management";
export const CLUB_INFO_URL = "/api/club/info";

export const REPORTS_STATE_STATIC_DATA_URL = "/api/reports/state/static";
export const REPORTS_STATE_EVENTS_URL = "/api/reports/state/events";
export const REPORTS_STATE_ATHLETES_URL = "/api/reports/state/athletes";
export const REPORTS_STATE_GROUPS_URL = "/api/reports/state/groups";
export const REPORTS_STATE_ATTENDANCES_URL = "/api/reports/state/attendances";
export const REPORTS_TREND_URL = "/api/reports/trend";
export const REPORTS_RANKING_URL = "/api/reports/ranking";
export const REPORTS_RANKING_TYPE_URL = "/api/reports/ranking/types";
export const REPORTS_RANKING_RANK_URL = "/api/reports/ranking/ranks";

export const INVOICE_URL = "/api/invoices";
export const INVOICE_TEMPLATE_URL = "/api/invoice-template";
export const INVOICE_RECIPIENT_GROUPS_URL = "/api/invoice-recipient-group";
export const INVOICE_SEND_REMINDER_URL = `${INVOICE_URL}/send-reminder`;
export const INVOICE_UPLOAD_BANK_STATEMENT = `${INVOICE_URL}/upload-bank-statement`;
