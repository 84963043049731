import React from "react";

import { Breadcrumbs, Card, Link, Typography } from "@tiller-ds/core";

import { SurveyAnswerType } from "../../../common/constants";
import SurveyQuestionResults from "../components/Cards/ResultCard/SurveyQuestionResults";

export default function SurveyResults() {
  return (
    <div className="w-full px-4 sm:px-0">
      <div className="flex flex-col items-center w-full h-full mt-8">
        <div className="justify-start w-full items-center mb-8">
          <Typography
            element="h3"
            variant="h3"
            className="hidden md:inline font-biotiff"
          >
            Ankete
          </Typography>
          <Typography
            element="h6"
            variant="h6"
            className="md:hidden font-biotiff"
          >
            Ankete
          </Typography>
          <div>
            <Breadcrumbs>
              <Breadcrumbs.Breadcrumb>
                <Link to="/survey">Nedavne ankete</Link>
              </Breadcrumbs.Breadcrumb>
              <Breadcrumbs.Breadcrumb>
                {"Rezultati ankete"}
              </Breadcrumbs.Breadcrumb>
            </Breadcrumbs>
          </div>
        </div>
        <div className="w-full lg:w-4/6">
          <Card className="w-full h-full mb-8">
            <Card.Body>
              <div className="w-full py-4">
                <Typography
                  element="h4"
                  variant="h4"
                  className="font-biotif mb-8"
                >
                  Rezultati: Zadovoljstvo proteklom sezonom - kadeti
                </Typography>
                <Typography element="h6" variant="text" className="font-inter">
                  Zadovoljstvo proteklom sezonom - kadeti
                </Typography>
              </div>
              <div className="flex justify-end w-full">
                <Typography
                  element="h6"
                  variant="subtitle"
                  className="font-inter"
                >
                  10 Ispitanika
                </Typography>
              </div>
            </Card.Body>
          </Card>
          <SurveyQuestionResults
            question={" Koji termin treninga vam najviše odgovara?"}
            answerType={SurveyAnswerType.MultipleChoice}
          />
          <SurveyQuestionResults
            question={
              "Ocijenite kvalitetu opreme koju je vaše dijete nosilo prošlu sezonu:"
            }
            answerType={SurveyAnswerType.LinearScale}
          />
          <SurveyQuestionResults
            question={"Vaše osobno mišljenje o protekloj sezoni:"}
            answerType={SurveyAnswerType.PersonalAnswer}
          />
          <SurveyQuestionResults
            question={" Koji termin treninga vam najviše odgovara?"}
            answerType={SurveyAnswerType.MultipleChoice}
          />
          <SurveyQuestionResults
            question={
              "Ocijenite kvalitetu opreme koju je vaše dijete nosilo prošlu sezonu:"
            }
            answerType={SurveyAnswerType.LinearScale}
          />
          <SurveyQuestionResults
            question={" Koji termin treninga vam najviše odgovara?"}
            answerType={SurveyAnswerType.MultipleChoice}
          />
          <SurveyQuestionResults
            question={
              "Ocijenite kvalitetu opreme koju je vaše dijete nosilo prošlu sezonu:"
            }
            answerType={SurveyAnswerType.LinearScale}
          />
        </div>
      </div>
    </div>
  );
}
