import { RankTypeReportsRequestPageable } from "./RankTypeReportsRequestPageable";
import { REPORTS_RANKING_TYPE_URL } from "../../common/Routes";

export async function getRankTypeReports(
  request: RankTypeReportsRequestPageable
) {
  const response = await fetch(`${REPORTS_RANKING_TYPE_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
