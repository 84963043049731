import { CreateAthleteRequest } from "./CreateAthleteRequest";
import { ATHLETE_URL } from "../../../common/Routes";

export async function postCreateAthleteRequest(
  request: CreateAthleteRequest
): Promise<Response> {
  return await fetch(`${ATHLETE_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
