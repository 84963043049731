import React, { useEffect, useState } from "react";

import { Badge, Link, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { SidebarNavigation } from "@tiller-ds/menu";

import { UserType } from "../../archive/common/api/UserResponse";
import logo from "../../assets/img/logo-with-text-white.png";
import logoWithoutText from "../../assets/img/logo-without-text-white.png";
import { logout } from "../../auth/api/logout";
import { UserContext } from "../../auth/SecurityProvider";
import { useEnvironmentVariable } from "../../env-provider/EnvironmentVariablesProvider";

const typographyTokens = {
  variant: {
    subtitle: {
      color: "text-white",
    },
  },
};

declare const APP_VERSION: string;

export default function NavigationItems() {
  const { user, userHasAnyRole, userHasRole } = React.useContext(UserContext);
  const { REACT_APP_ENABLE_SURVEYS } = useEnvironmentVariable();

  const [isWindowMedium, setIsWindowMedium] = useState(false);

  useEffect(() => {
    handleWindowResize();
  }, []);

  function handleWindowResize() {
    if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
      setIsWindowMedium(true);
    } else {
      setIsWindowMedium(false);
    }
  }

  window.addEventListener("resize", handleWindowResize);

  return (
    <SidebarNavigation
      bottomActions={
        <div className="p-6 md:p-2 lg:p-6">
          <Typography element="p" variant="subtext" className="text-center">
            {isWindowMedium
              ? APP_VERSION
              : `Verzija aplikacije: ${APP_VERSION}`}
          </Typography>
          <hr className="border-gray-500 pt-2 md:pt-1 mt-1" />
          {
            <SidebarNavigation.Item
              to="/user-profile"
              icon={
                <Icon
                  className="hidden md:inline ml-0.5 text-primary-light mt-1"
                  type="user"
                  variant="fill"
                />
              }
            >
              <Typography
                tokens={typographyTokens}
                element="p"
                variant="subtitle"
                className="md:hidden lg:inline pl-0 md:pl-1"
              >
                {user?.firstName} {user?.lastName}
              </Typography>
            </SidebarNavigation.Item>
          }
          <div onClick={logout}>
            <SidebarNavigation.Item
              icon={
                <Icon
                  className="hidden md:inline ml-0.5 text-primary-light mt-1"
                  type="sign-out"
                />
              }
            >
              <Typography
                tokens={typographyTokens}
                element="p"
                variant="subtitle"
                className="md:hidden lg:inline pl-0 md:pl-1"
              >
                Odjavi se
              </Typography>
            </SidebarNavigation.Item>
          </div>
        </div>
      }
      logo={
        <Link to="/home">
          <img src={logo} alt="logo" className="hidden lg:inline w-28 mt-3" />
          <img src={logoWithoutText} alt="logo" className="lg:hidden w-8" />
        </Link>
      }
    >
      <div className="flex flex-col px-6 md:px-2 lg:px-6 pt-4 h-full">
        <SidebarNavigation.Item
          to="/home"
          icon={
            <Icon
              className="hidden md:inline ml-0.5 text-primary-light mt-1"
              type="house"
              variant="fill"
            />
          }
        >
          <Typography
            tokens={typographyTokens}
            element="p"
            variant="subtitle"
            className="md:hidden lg:inline pl-0 md:pl-1"
          >
            Početna stranica
          </Typography>
        </SidebarNavigation.Item>
        <SidebarNavigation.Item
          to="/calendar"
          icon={
            <Icon
              className="hidden md:inline ml-0.5 text-primary-light mt-1"
              type="calendar-blank"
              variant="fill"
            />
          }
        >
          <Typography
            tokens={typographyTokens}
            element="p"
            variant="subtitle"
            className="md:hidden lg:inline pl-0 md:pl-1"
          >
            Kalendar
          </Typography>
        </SidebarNavigation.Item>
        {userHasAnyRole([UserType.ADMIN, UserType.COACH]) && (
          <SidebarNavigation.Item
            to="/archive"
            icon={
              <Icon
                className="hidden md:inline ml-0.5 text-primary-light mt-1"
                type="archive"
                variant="fill"
              />
            }
          >
            <Typography
              tokens={typographyTokens}
              element="p"
              variant="subtitle"
              className="md:hidden lg:inline pl-0 md:pl-1"
            >
              Registri
            </Typography>
          </SidebarNavigation.Item>
        )}
        {REACT_APP_ENABLE_SURVEYS === "true" && (
          <SidebarNavigation.Item
            to="/survey"
            icon={
              <Icon
                className="hidden md:inline ml-0.5 text-primary-light mt-1"
                type="clipboard"
              />
            }
          >
            <Typography
              tokens={typographyTokens}
              element="p"
              variant="subtitle"
              className="md:hidden lg:inline pl-0 md:pl-1"
            >
              Ankete
            </Typography>
          </SidebarNavigation.Item>
        )}
        {/*        {userHasRole(UserType.ADMIN) && (
          <SidebarNavigation.Item
            to="/reports"
            icon={
              <Icon
                className="hidden md:inline ml-0.5"
                type="file"
                variant="fill"
              />
            }
          >
            <Typography
              tokens={typographyTokens}
              element="p"
              variant="subtitle"
              className="md:hidden lg:inline pl-0 md:pl-1"
            >
              Izvještaji
            </Typography>
          </SidebarNavigation.Item>
        )}*/}
        <SidebarNavigation.Item
          to="/messages"
          icon={
            <Icon
              className="hidden md:inline ml-0.5 text-primary-light mt-1"
              type="chat-circle-dots"
              variant="fill"
            />
          }
        >
          <Typography
            tokens={typographyTokens}
            element="p"
            variant="subtitle"
            className="md:hidden lg:inline pl-0 md:pl-1"
          >
            Poruke
          </Typography>
          {user.numOfUnreadMessages > 0 && (
            <div className="px-0 py-0">
              <Badge className="md:hidden lg:inline ml-3">
                <Typography
                  tokens={typographyTokens}
                  variant="subtitle"
                  className="pt-0.5"
                >
                  {user.numOfUnreadMessages}
                </Typography>
              </Badge>
            </div>
          )}
        </SidebarNavigation.Item>

        {userHasRole(UserType.PARENT) && (
          <SidebarNavigation.Item
            to="/payments/parent"
            icon={
              <Icon
                className="hidden md:inline ml-0.5 pt-1"
                type="credit-card"
                variant="fill"
              />
            }
          >
            <Typography
              tokens={typographyTokens}
              element="p"
              variant="subtitle"
              className="md:hidden lg:inline pl-0 md:pl-1"
            >
              Računi
            </Typography>
            {user.numOfUnpaidInvoices && user.numOfUnpaidInvoices > 0 ? (
              <div className="px-0 py-0">
                <Badge className="md:hidden lg:inline ml-3">
                  <Typography
                    tokens={typographyTokens}
                    variant="subtitle"
                    className="pt-0.5"
                  >
                    {user.numOfUnpaidInvoices}
                  </Typography>
                </Badge>
              </div>
            ) : null}
          </SidebarNavigation.Item>
        )}
        {userHasRole(UserType.ADMIN) && (
          <SidebarNavigation.Item
            to="/payments"
            icon={
              <Icon
                className="hidden md:inline ml-0.5 pt-1"
                type="credit-card"
                variant="fill"
              />
            }
          >
            <Typography
              tokens={typographyTokens}
              element="p"
              variant="subtitle"
              className="md:hidden lg:inline pl-0 md:pl-1"
            >
              Plaćanja
            </Typography>
          </SidebarNavigation.Item>
        )}
      </div>
    </SidebarNavigation>
  );
}
