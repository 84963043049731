import React from "react";

import { Typography } from "@tiller-ds/core";

import UserIcon from "../../../../../archive/common/components/UserIcon";

type AnswerProps = {
  answer: string;
  totalRespondents: number;
  totalAnswers: number;
};

export default function PersonalAnswerResult({
  answer,
  totalRespondents,
  totalAnswers,
}: AnswerProps) {
  return (
    <div className="flex flex-col items-start justify-between w-full mt-8 px-2 h-max cursor-pointer sm:mt-2 sm:flex-row sm:h-20 2xl:px-6">
      <div className="flex items-center space-x-4 w-full mr-2 xl:mr-4 sm:w-1/3 mb-4 sm:mb-0">
        <div className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12">
          <UserIcon
            initials={"A".charAt(0).toUpperCase() + "O".charAt(0).toUpperCase()}
            entityId={13}
            width="100%"
            height="100%"
            textClassName="text-xs lg:text-sm 2xl:text-lg mb-1"
          />
        </div>
        <Typography variant="h6" className="font-biotif text-xs sm:text-sm">
          Anonimni odgovor
        </Typography>
      </div>
      <div className="w-full max-h-14 w-full overflow-y-auto sm:w-2/3 xl:max-h-20">
        <Typography
          element="p"
          variant="subtext"
          className="font-inter text-gray-700 break-word"
        >
          {answer}
          {totalAnswers}
          {totalRespondents}
        </Typography>
      </div>
    </div>
  );
}
