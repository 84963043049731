import React from "react";

import { Card, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { typographyCardTokens } from "../../pages/EventDetailsPage";

type EventLocationCardProps = {
  locationName: string;
  locationAddress: string;
};

export default function EventLocationCard({
  locationName,
  locationAddress,
}: EventLocationCardProps) {
  return (
    <Card className="h-full">
      <div className="p-4 flex lg:flex-col lg:justify-between h-full">
        <div className="mr-2 px-2 lg:px-0 my-auto lg:my-0">
          <Icon
            type="map-pin"
            variant="fill"
            size={6}
            className="w-8 h-8 lg:w-6 lg:h-6"
          ></Icon>
        </div>
        <div className="grid gap-y-1">
          <Typography
            tokens={typographyCardTokens}
            element="h3"
            variant="h6"
            className="lg:pt-8"
          >
            {locationName}
          </Typography>
          <Typography
            className={locationAddress.length === 0 ? "italic" : "not-italic"}
            element="p"
            variant="subtext"
          >
            {locationAddress.length === 0
              ? "Adresa nije unesena"
              : locationAddress}
          </Typography>
        </div>
      </div>
    </Card>
  );
}
