import { FilterEventsRequest } from "./FilterEventsRequest";
import { FILTER_EVENTS_URL } from "../../common/Routes";

export async function postFilterEvents(event: FilterEventsRequest) {
  const response = await fetch(FILTER_EVENTS_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(event),
  });
  return response.json();
}
