import React, { useEffect, useState } from "react";

import { Card, Pagination, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { DateInput } from "@tiller-ds/date";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { MAX_REPORT_ROWS } from "../../../common/constants";
import { AthleteGroupReportResponse } from "../../api/AllAthleteGroupsReportResponse";
import { exportGroupReportsExcel } from "../../api/exportGroupReportsExcel";
import { exportGroupReportsPDF } from "../../api/exportGroupReportsPDF";
import { getAllAthleteGroupReports } from "../../api/getAllAthleteGroupReports";
import { formatDateToHr } from "../../util/dateUtil";

export function GroupStateData() {
  const [groupReports, setGroupReports] = useState<
    AthleteGroupReportResponse[]
  >([]);
  const [totalGroupReports, setTotalGroupReports] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    getAllAthleteGroupReports({
      pageNumber: page,
      pageSize: MAX_REPORT_ROWS,
    }).then((response) => {
      setGroupReports(response.content);
      setTotalGroupReports(response.totalElements);
    });
  }, [page]);

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-1">
        <Typography variant="h4" element="h4">
          Trenutni podatci o grupama
        </Typography>
        <Typography variant="text" element="p" className="text-gray-600">
          Podatci prikazani u tablici predstavljaju stanje na odabrani datum
        </Typography>
      </div>
      <Card className="flex flex-col gap-y-3">
        <Card.Body>
          <div className="flex flex-row justify-between gap-x-3 border-b mb-3 pb-3">
            <div className="mt-auto">
              <DateInput
                label="Na dan"
                name="day"
                disabled
                placeholder={formatDateToHr(new Date())}
                onChange={() => {}}
                value={new Date()}
                allowClear={false}
              />
            </div>
            <div className="mt-auto">
              <DropdownMenu
                title={<span className="hidden sm:inline-block">Preuzmi</span>}
                color="primary"
                openExpanderIcon={<Icon type="download-simple" />}
                closeExpanderIcon={<Icon type="download-simple" />}
                iconPlacement="leading"
                className="pr-2 sm:pr-4"
              >
                <DropdownMenu.Item onSelect={() => exportGroupReportsExcel()}>
                  .xlsx
                </DropdownMenu.Item>
                <DropdownMenu.Item onSelect={() => exportGroupReportsPDF()}>
                  .pdf
                </DropdownMenu.Item>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex flex-col gap-y-10">
            {groupReports.length > 0 && (
              <DataTable data={groupReports} className="border">
                <DataTable.Column
                  header="NAZIV GRUPE"
                  id="groupName"
                  canSort={false}
                >
                  {(item: AthleteGroupReportResponse) => item.groupName}
                </DataTable.Column>
                <DataTable.Column
                  header="BROJ SPORTAŠA"
                  id="athleteCount"
                  canSort={false}
                >
                  {(item: AthleteGroupReportResponse) => item.numberOfAthletes}
                </DataTable.Column>
                <DataTable.Column
                  header="BROJ TRENERA"
                  id="coachCount"
                  canSort={false}
                >
                  {(item: AthleteGroupReportResponse) => item.numberOfCoaches}
                </DataTable.Column>
                <DataTable.Column
                  header="PROSJEČNA DOB"
                  id="averageAge"
                  canSort={false}
                >
                  {(item: AthleteGroupReportResponse) => item.averageAthleteAge}
                </DataTable.Column>
              </DataTable>
            )}
          </div>
        </Card.Body>
        {totalGroupReports > MAX_REPORT_ROWS && (
          <div className="flex items-center justify-end mb-3 mr-1">
            <Pagination
              onPageChange={(i: number) => {
                setPage(i);
              }}
              pageNumber={page}
              pageSize={MAX_REPORT_ROWS}
              totalElements={totalGroupReports}
            >
              {() => null}
            </Pagination>
          </div>
        )}
      </Card>
    </div>
  );
}
