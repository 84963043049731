import FileDownload from "js-file-download";

import { TrendReportsRequest } from "./TrendReportsRequest";
import { REPORTS_TREND_URL } from "../../common/Routes";

export async function exportTrendReportsPDF(request: TrendReportsRequest) {
  fetch(`${REPORTS_TREND_URL}/export/pdf`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      FileDownload(blob, `izvještaj_trenda_${request.year}.pdf`);
    });
}
