import React from "react";

import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";

import { EventAttendanceStatisticsResponse } from "../api/EventAttendanceStatisticsResponse";

type EventAttendancePieChartProps = {
  eventAttendanceStatistics: EventAttendanceStatisticsResponse;
};
export default function EventAttendancePieChart({
  eventAttendanceStatistics,
}: EventAttendancePieChartProps) {
  const activeIndex = 0;

  const data = [
    {
      name: "Dolasci",
      value: eventAttendanceStatistics.totalTrainingsWasPresent,
    },
    {
      name: "Izostanci",
      value: eventAttendanceStatistics.totalTrainingsWasNotPresent,
    },
  ];

  const renderActiveShape = (props: {
    cx: any;
    cy: any;
    innerRadius: any;
    outerRadius: any;
    startAngle: any;
    endAngle: any;
    fill: any;
  }) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = props;

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000000"}>
          {`${eventAttendanceStatistics.totalTrainingsWasPresent}/${eventAttendanceStatistics.totalTrainings}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const COLORS = ["#00258E", "#42E8FF"];

  return (
    <div className="w-full lg:w-1/3 xl:w-1/4">
      <ResponsiveContainer height={160} width="100%">
        <PieChart>
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
