import React from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { Button, IconButton, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import { putUpdateAthleteRequest } from "../../athlete/api/putUpdateAthleteRequest";
import { UpdateAthleteRequest } from "../../athlete/api/UpdateAthleteRequest";
import { ParentBriefResponse } from "../../parent/api/ParentBriefResponse";

type RemoveAthleteFromGroupProps = {
  groupId: number;
  athlete: AthleteResponse;
  groupName: string;
  setRender: (render: boolean) => void;
};

const RemoveAthleteFromGroupModal = ({
  groupId,
  athlete,
  groupName,
  setRender,
}: RemoveAthleteFromGroupProps) => {
  const modal = useModal();
  const { add: addNotification } = useNotifications();

  const onRemoveAthleteFromGroup = () => {
    const updateAthleteRequest = {
      groupToRemoveIds: [groupId],
      gender: athlete.gender,
      oib: athlete.oib,
      parentIds: athlete.parents.map(
        (parent: ParentBriefResponse) => parent.id
      ),
      email: athlete.email,
      dateOfBirth: athlete.dateOfBirth,
      dateOfMembershipStart: athlete.dateOfMembershipStart,
      firstName: athlete.firstName,
      lastName: athlete.lastName,
    } as UpdateAthleteRequest;

    putUpdateAthleteRequest(updateAthleteRequest, athlete.id).then(
      (response) => {
        if (response.ok) {
          setRender(true);
          addNotification({
            title: "Sportaš je izbrisan iz grupe.",
            content: `Vaš sportaš je uspješno izbrisan iz grupe "${groupName}".`,
            messageList: [],
            severity: "INFO",
            timestamp: new Date(),
          });

          modal.onClose();
        }
      }
    );
  };

  return (
    <>
      <IconButton
        onClick={() => {
          modal.onOpen(null);
        }}
        icon={<Icon type="user-minus" className="text-gray-500" />}
        label="Ukloni"
      />
      <Modal {...modal}>
        <Modal.Content
          title={
            <Typography element="h3" variant="h4">
              Izbriši sportaša iz grupe
            </Typography>
          }
        >
          <Typography element="p" variant="subtext" className="pt-2">
            Želite li izbrisati sportaša "{athlete.firstName} {athlete.lastName}
            " iz grupe "{groupName}"?
          </Typography>
        </Modal.Content>
        <Modal.Footer>
          <Button variant="filled" onClick={onRemoveAthleteFromGroup}>
            Izbriši
          </Button>
          <Button variant="outlined" onClick={modal.onClose}>
            Odustani
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveAthleteFromGroupModal;
