import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

import { useNotifications } from "@croz/nrich-notification-core";

import { deleteInvoiceTemplate } from "../api/deleteInvoiceTemplate";
import { InvoiceTemplateResponse } from "../api/InvoiceTemplateResponse";
import handleDeleteInvoiceTemplateResponse from "../handleDeleteInvoiceTemplateResponse";

type DeleteInvoiceTemplateModalProps = {
  modal: UseModal<unknown>;
  invoiceTemplate: InvoiceTemplateResponse;
  setRender: (render: boolean) => void;
};

export default function DeleteInvoiceTemplateModal({
  modal,
  invoiceTemplate,
  setRender,
}: DeleteInvoiceTemplateModalProps) {
  const { add: addNotification } = useNotifications();

  const deleteInvoiceTemplateModalFunction = () => {
    deleteInvoiceTemplate(invoiceTemplate.id).then((response) => {
      if (response.ok) {
        handleDeleteInvoiceTemplateResponse(response, addNotification);
        setRender(true);
        modal.onClose();
      }
    });
  };

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h3" variant="h4">
            {"Izbriši predložak: ".concat(invoiceTemplate.title)}
          </Typography>
        }
      >
        <Typography element="p" variant="subtext" className="pt-2">
          {"Jeste li sigurni da želite izbrisati predložak ".concat(
            invoiceTemplate.title,
            " ?"
          )}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={deleteInvoiceTemplateModalFunction}>
          {"Izbriši predložak"}
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
