import React, { useEffect, useState } from "react";

import { Tabs, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import ComparisonChart from "./ComparisonChart";
import { MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE } from "../../../common/constants";
import NoAvailableData from "../../common/components/NoAvailableData";
import { GroupResponse } from "../../group/api/GroupResponse";
import { getAverageMonthlyProgressDataOfGroup } from "../../statistics/api/getAverageMonthlyProgressDataOfGroup";
import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";

type ChartSelectorProps = {
  groupId: number;
  groupName: string;
  progressTypeList: ProgressTypeResponse[];
  groupList: GroupResponse[];
};

export default function GroupChartSelector({
  groupId,
  groupName,
  progressTypeList,
  groupList,
}: ChartSelectorProps) {
  const [selectedGroup, setSelectedGroup] = useState({} as GroupResponse);
  const [mainGroupProgressData, setMainGroupProgressData] = useState(
    {} as Record<number, Record<string, number>>
  );
  const [selectedGroupProgressData, setSelectedGroupProgressData] = useState(
    {} as Record<number, Record<string, number>>
  );
  const [groupDropdownUsed, setGroupDropdownUsed] = useState<boolean>();

  useEffect(() => {
    if (groupId !== undefined && selectedGroup.id !== undefined) {
      getAverageMonthlyProgressDataOfGroup(groupId).then(
        (response: Record<number, Record<string, number>>) => {
          setMainGroupProgressData(response);
        }
      );
      getAverageMonthlyProgressDataOfGroup(selectedGroup.id).then(
        (response: Record<number, Record<string, number>>) => {
          setSelectedGroupProgressData(response);
        }
      );
    }
  }, [groupId, selectedGroup]);

  useEffect(() => {
    const group = groupList.find((group) => group.groupName === groupName);
    if (group) {
      setSelectedGroup(group);
    }
  }, [groupList, groupName]);

  const groupSelectHandler = (group: GroupResponse) => {
    setSelectedGroup(group);
    if (!groupDropdownUsed) {
      setGroupDropdownUsed(true);
    }
  };

  if (progressTypeList.length === 0) {
    return <NoAvailableData message="Nema praćene statistike" />;
  }

  return (
    <div className="w-full mt-4">
      <Tabs defaultIndex={0}>
        {progressTypeList.map((progressType: ProgressTypeResponse) => {
          return (
            <Tabs.Tab label={progressType.name}>
              <div className="justify-center mt-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                  <Typography
                    element="h6"
                    variant="h6"
                    className="py-2 pr-2 flex w-fit sm:w-full justify-start"
                  >
                    Mjesečni prosjek grupe u odnosu na odabranu grupu
                  </Typography>
                  <div className="flex w-full justify-start sm:justify-end">
                    <DropdownMenu
                      title={
                        selectedGroup.groupName && groupDropdownUsed
                          ? selectedGroup.groupName
                          : "Usporedi s grupom"
                      }
                      color="primary"
                      visibleItemCount={
                        groupList.length <
                        MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE
                          ? groupList.length
                          : MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE
                      }
                      openExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                      closeExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                    >
                      {groupList.map((group: GroupResponse) => {
                        return (
                          <DropdownMenu.Item
                            onSelect={() => {
                              groupSelectHandler(group);
                            }}
                          >
                            {group.groupName}
                          </DropdownMenu.Item>
                        );
                      })}
                    </DropdownMenu>
                  </div>
                </div>
                {selectedGroupProgressData[progressType.id] &&
                mainGroupProgressData[progressType.id] ? (
                  <ComparisonChart
                    athleteOrGroupName={groupName}
                    athleteOrGroupProgressData={
                      mainGroupProgressData[progressType.id]
                    }
                    athleteOrGroupToCompareName={selectedGroup.groupName}
                    athleteOrGroupToCompareProgressData={
                      selectedGroupProgressData[progressType.id]
                    }
                    progressType={progressType}
                  />
                ) : (
                  <NoAvailableData message="Nema praćene statistike" />
                )}
              </div>
            </Tabs.Tab>
          );
        })}
      </Tabs>
    </div>
  );
}
