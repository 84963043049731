import React, { useEffect, useState } from "react";

import { UseModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { SelectField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";

import { AthleteDetailedResponse } from "../../archive/athlete/api/AthleteDetailedResponse";
import { ParentDetailedResponse } from "../../archive/parent/api/ParentDetailedResponse";
import { PlannedAbsenceResponse } from "../../common/api/PlannedAbsenceResponse";
import {
  DeletePlannedAbsenceBulkRequest,
  deletePlannedAbsences,
} from "../api/deletePlannedAbsence";
import { getPlannedAbsencesByUserId } from "../api/getPlannedAbsencesByUserId";

type CancelAbsenceFormProps = {
  parent: ParentDetailedResponse;
  modal: UseModal<any>;
};

const initialValues = {
  selectedAbsences: [],
};

type Form = {
  selectedAbsences: PlannedAbsenceResponse[];
};

export default function CancelAbsenceForm({
  parent,
  modal,
}: CancelAbsenceFormProps) {
  const [loaded, setLoaded] = useState(false);
  const [plannedAbsences, setPlannedAbsences] = useState(
    [] as PlannedAbsenceResponse[]
  );
  const [athlete, setAthlete] = useState({} as AthleteDetailedResponse);

  const { add: addNotification } = useNotifications();

  useEffect(() => {
    setAthlete(
      parent.children[0]
        ? parent.children[0]
        : ({ firstName: "-" } as AthleteDetailedResponse)
    );
  }, [setAthlete, parent]);

  useEffect(() => {
    if (athlete.id) {
      getPlannedAbsencesByUserId(athlete.id).then((response) => {
        let plannedAbsencesSortedByDate: PlannedAbsenceResponse[];
        plannedAbsencesSortedByDate = response;

        plannedAbsencesSortedByDate.sort((a, b) =>
          a.startTime.toString().localeCompare(b.startTime.toString())
        );

        setPlannedAbsences(plannedAbsencesSortedByDate);
        setLoaded(true);
      });
    }
  }, [athlete]);

  const onSubmitForm = (form: Form) => {
    if (form.selectedAbsences.length > 0) {
      const deletePlannedAbsencesBulkRequest = {} as DeletePlannedAbsenceBulkRequest;

      deletePlannedAbsencesBulkRequest.plannedAbsenceIds = form.selectedAbsences.map(
        (plannedAbsence) => plannedAbsence.id
      );

      deletePlannedAbsences(deletePlannedAbsencesBulkRequest).then(() => {
        addNotification({
          title:
            form.selectedAbsences.length > 1
              ? "Najavljene odsutnosti poništene"
              : "Najavljena odsutnost poništena",
          content:
            form.selectedAbsences.length > 1
              ? "Uspješno ste poništili označene najavljene odsutnosti."
              : "Uspješno ste poništili označenu najavljenu odsutnost.",
          messageList: [],
          severity: "INFO",
          timestamp: new Date(),
        });
      });
      modal.onClose();
    }
  };

  if (loaded) {
    return (
      <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="pt-6">
            {parent.children.length > 1 ? (
              <>
                <div>
                  <Typography className="title">Odsutan sportaš</Typography>
                  <div className="mt-1">
                    <DropdownMenu
                      title={
                        athlete
                          ? `${athlete.firstName} ${athlete.lastName}`
                          : "Izaberi dijete"
                      }
                      color={"primary"}
                      openExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                      closeExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                    >
                      {parent.children.map((child: AthleteDetailedResponse) => {
                        return (
                          <DropdownMenu.Item
                            onSelect={() => {
                              setAthlete(child);
                              formik.values.selectedAbsences = [];
                            }}
                          >
                            {`${child.firstName} ${child.lastName}`}
                          </DropdownMenu.Item>
                        );
                      })}
                    </DropdownMenu>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="mt-6">
              {plannedAbsences.length > 0 ? (
                <>
                  <div className="mt-1"></div>
                  <SelectField
                    label="Najavljene odsutnosti"
                    name="selectedAbsences"
                    options={plannedAbsences}
                    placeholder="Označite najavljene odsutnosti"
                    allowMultiple={true}
                    hideClearButton={true}
                    getMultipleSelectedLabel={(
                      items: PlannedAbsenceResponse[]
                    ) => <>{`Označeno: ${items.length}`}</>}
                    getOptionLabel={(item: PlannedAbsenceResponse) => (
                      <>
                        {new Date(item.startTime).toLocaleDateString("hr-HR")}
                        {" - "}
                        {new Date(item.endTime).toLocaleDateString("hr-HR")}
                      </>
                    )}
                  />
                </>
              ) : (
                <>
                  <Typography element="h6" variant="h6">
                    Nema najavljenih odsutnosti.
                  </Typography>
                </>
              )}
            </div>

            <div className="flex justify-end pt-6 mt-6 border-t">
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  modal.onClose();
                }}
              >
                Odustani
              </Button>
              <Button
                className="ml-3"
                type="submit"
                disabled={
                  formik.values.selectedAbsences &&
                  formik.values.selectedAbsences.length === 0
                }
                variant="filled"
                color="primary"
              >
                Poništi najavu
              </Button>
            </div>
          </form>
        )}
      </Formik>
    );
  } else {
    return <></>;
  }
}
