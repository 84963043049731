import React, { useEffect, useState } from "react";

import { Card, Typography } from "@tiller-ds/core";

import { getStaticDataReport } from "../../api/getStaticDataReport";

export function StaticStateData() {
  const [totalAthletes, setTotalAthletes] = useState<number>();
  const [totalCoaches, setTotalCoaches] = useState<number>();
  const [totalGroups, setTotalGroups] = useState<number>();

  useEffect(() => {
    getStaticDataReport().then((response) => {
      setTotalAthletes(response.totalNumberOfAthletes);
      setTotalCoaches(response.totalNumberOfCoaches);
      setTotalGroups(response.totalNumberOfGroups);
    });
  }, []);

  return (
    <div className="flex flex-col gap-y-5">
      <Typography variant="h4" element="h4">
        Statični podatci
      </Typography>
      <div className="flex flex-col gap-y-2 sm:gap-y-0 sm:flex-row sm:gap-x-5 sm:w-2/3">
        <Card className="h-full">
          <Card.Body>
            <div>
              <Typography variant="text" element="p">
                Broj upisanih sportaša
              </Typography>
              <Typography variant="text" element="p" className="font-bold">
                {totalAthletes}
              </Typography>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Typography variant="text" element="p">
              Broj Trenera
            </Typography>
            <Typography variant="text" element="p" className="font-bold">
              {totalCoaches}
            </Typography>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Typography variant="text" element="p">
              Broj grupa
            </Typography>
            <Typography variant="text" element="p" className="font-bold">
              {totalGroups}
            </Typography>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
