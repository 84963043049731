import { AllowEmailNotificationsRequest } from "./AllowEmailNotificationsRequest";
import { ALLOW_EMAIL } from "../../../common/Routes";

export async function postAllowEmailFlag(
  request: AllowEmailNotificationsRequest
) {
  return await fetch(ALLOW_EMAIL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
