import React, { useState } from "react";

import { Button } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";
import { InputField } from "@tiller-ds/formik-elements";

import { Formik } from "formik";
import * as yup from "yup";

import {
  MAX_GROUP_NAME_LENGTH,
  MAX_NAME_LENGTH,
  MAX_SURNAME_LENGTH,
  MIN_NAME_LENGTH,
  MIN_SURNAME_LENGTH,
  NAME_AND_SURNAME_REGEX,
} from "../../../common/constants";
import {
  maxCharacterCountMessage,
  minCharacterCountMessage,
  NO_SPECIAL_CHARACTERS_MESSAGE,
} from "../../../common/YupConstants";
import { AthleteSearchRequest } from "../api/AthleteSearchRequest";

export type AthleteRegistryFilterCardProps = {
  setSearchRequestJson: (searchRequest: AthleteSearchRequest) => void;
  searchRequestJson: AthleteSearchRequest;
};

type Form = {
  firstName: string;
  lastName: string;
  groupsGroupName: string;
  parentName: string;
};

const initialValues = {
  firstName: "",
  lastName: "",
  groupsGroupName: "",
  parentName: "",
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  lastName: yup
    .string()
    .min(MIN_SURNAME_LENGTH, minCharacterCountMessage(MIN_SURNAME_LENGTH))
    .max(MAX_SURNAME_LENGTH, maxCharacterCountMessage(MAX_SURNAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
  groupsGroupName: yup
    .string()
    .max(MAX_GROUP_NAME_LENGTH, maxCharacterCountMessage(MAX_GROUP_NAME_LENGTH))
    .nullable(),
  parentName: yup
    .string()
    .min(MIN_NAME_LENGTH, minCharacterCountMessage(MIN_NAME_LENGTH))
    .max(MAX_NAME_LENGTH, maxCharacterCountMessage(MAX_NAME_LENGTH))
    .matches(RegExp(NAME_AND_SURNAME_REGEX), NO_SPECIAL_CHARACTERS_MESSAGE)
    .nullable(),
});

export default function AthleteRegistryFilterCard({
  setSearchRequestJson,
  searchRequestJson,
}: AthleteRegistryFilterCardProps) {
  const [startDate, setStartDate] = useState(null as Date | null);
  const [endDate, setEndDate] = useState(null as Date | null);
  const [
    dateOfMembershipStartFromIncluding,
    setDateOfMembershipStartFromIncluding,
  ] = useState(null as Date | null);
  const [
    dateOfMembershipStartToIncluding,
    setDateOfMembershipStartToIncluding,
  ] = useState(null as Date | null);

  const onSubmitForm = (form: Form) => {
    setSearchRequestJson({
      ...searchRequestJson,
      firstName: form.firstName === "" ? null : form.firstName,
      lastName: form.lastName === "" ? null : form.lastName,
      dateOfBirthFromIncluding: startDate,
      dateOfBirthToIncluding: endDate,
      dateOfMembershipStartFromIncluding: dateOfMembershipStartFromIncluding,
      dateOfMembershipStartToIncluding: dateOfMembershipStartToIncluding,
      groupsGroupName:
        form.groupsGroupName === "" ? null : form.groupsGroupName,
      parentsFirstName: form.parentName === "" ? null : form.parentName,
    });
  };

  function onClickResetFilter() {
    setStartDate(null);
    setEndDate(null);
    setDateOfMembershipStartFromIncluding(null);
    setDateOfMembershipStartToIncluding(null);

    setSearchRequestJson({
      ...searchRequestJson,
      firstName: null,
      lastName: null,
      dateOfBirthFromIncluding: null,
      dateOfBirthToIncluding: null,
      dateOfMembershipStartFromIncluding: null,
      dateOfMembershipStartToIncluding: null,
      groupsGroupName: null,
      parentsFirstName: null,
    });
  }

  return (
    <div className="w-full h-fit mb-5 bg-navy-50 border rounded-lg p-2">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
          >
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 xl:grid-cols-4 md:flex-row justify-between">
              <div className="flex flex-col justify-between gap-2 w-full">
                <InputField name="firstName" label="Ime"></InputField>
                <InputField name="lastName" label="Prezime"></InputField>
              </div>
              <div className="flex flex-col justify-between gap-2 w-full">
                <DateInput
                  name="dateOfBirthFromIncluding"
                  label="Datum rođenja - od"
                  onBlur={() => {}}
                  onChange={(value) => {
                    value?.setHours(12);
                    setStartDate(value);
                  }}
                  value={startDate}
                  onReset={() => {
                    setStartDate(null);
                  }}
                  maxDate={endDate as Date | undefined}
                />
                <DateInput
                  name="dateOfBirthToIncluding"
                  label="Datum rođenja - do"
                  onBlur={() => {}}
                  onChange={(value) => {
                    value?.setHours(12);
                    setEndDate(value);
                  }}
                  value={endDate}
                  onReset={() => {
                    setEndDate(null);
                  }}
                  minDate={startDate as Date | undefined}
                />
              </div>
              <div className="flex flex-col justify-between gap-2 w-full">
                <InputField name="groupsGroupName" label="Grupa"></InputField>
                <InputField
                  name="parentName"
                  label="Ime roditelja"
                ></InputField>
              </div>
              <div className="flex flex-col justify-between gap-2 w-full">
                <DateInput
                  name="dateOfMembershipStartFromIncluding"
                  label="Datum upisa - od"
                  onBlur={() => {}}
                  onChange={(value) => {
                    value?.setHours(12);
                    setDateOfMembershipStartFromIncluding(value);
                  }}
                  onReset={() => {
                    setDateOfMembershipStartFromIncluding(null);
                  }}
                  maxDate={dateOfMembershipStartToIncluding as Date | undefined}
                  value={dateOfMembershipStartFromIncluding}
                />
                <DateInput
                  name="dateOfMembershipStartToIncluding"
                  label="Datum upisa - do"
                  onBlur={() => {}}
                  onChange={(value) => {
                    value?.setHours(12);
                    setDateOfMembershipStartToIncluding(value);
                  }}
                  minDate={
                    dateOfMembershipStartFromIncluding as Date | undefined
                  }
                  onReset={() => {
                    setDateOfMembershipStartToIncluding(null);
                  }}
                  value={dateOfMembershipStartToIncluding}
                />
              </div>
            </div>
            <div className="flex flex-row md:flex-none justify-end gap-2">
              <Button
                variant="outlined"
                type="reset"
                onClick={() => {
                  formik.resetForm();
                  onClickResetFilter();
                }}
              >
                Poništi
              </Button>
              <Button variant="filled" type="submit">
                Filtriraj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
