import React, { useContext } from "react";

import { Card, Link, Tooltip, Typography } from "@tiller-ds/core";

import { UserContext } from "../../../auth/SecurityProvider";
import { UserType } from "../../../common/constants";
import UserIcon from "../../common/components/UserIcon";
import GroupBadge from "../../group/components/GroupBadge";
import { ParentResponse } from "../../parent/api/ParentResponse";
import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";
import { AthleteDetailedResponse } from "../api/AthleteDetailedResponse";

type AthleteHeaderProps = {
  athlete: AthleteDetailedResponse;
  parents: ParentResponse[];
  progressTypeList: ProgressTypeResponse[];
};

export default function AthleteHeader({
  athlete,
  parents,
}: AthleteHeaderProps) {
  const { userHasAnyRole } = useContext(UserContext);

  return (
    <Card className="h-full">
      <Card.Body>
        <div className="flex flex-col w-full gap-4">
          <div className="flex gap-2 items-center">
            <div className="flex">
              <UserIcon
                initials={
                  athlete.firstName.charAt(0).toUpperCase() +
                  athlete.lastName.charAt(0).toUpperCase()
                }
                entityId={athlete.id}
                width="80px"
                height="80px"
                textClassName="text-2xl sm:text-4xl"
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <Typography element="h5" variant="h5">
                {`${athlete.firstName} ${athlete.lastName}`}
              </Typography>
              <div className="flex flex-wrap justify-start gap-2 w-full">
                {athlete.groups.map((group) => {
                  return (
                    <GroupBadge
                      groupName={group.groupName}
                      groupId={group.id}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex items-center flex-col justify-center sm:items-start sm:flex-wrap justify-start">
            <div className="flex flex-col w-full text-center justify-between sm:text-left items-start gap-4">
              <div className="flex flex-col w-full gap-1">
                <Typography
                  variant="subtext"
                  className="flex w-full text-gray-500 items-start border-b"
                >
                  OSOBNI PODACI SPORTAŠA
                </Typography>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 w-full items-start gap-1">
                  <div className="flex flex-col items-start">
                    <Typography className="text-sm">Datum rođenja</Typography>
                    <Typography className="text-sm font-bold">
                      {new Date(athlete.dateOfBirth).toLocaleDateString(
                        "hr-HR"
                      )}
                    </Typography>
                  </div>
                  <div className="flex flex-col items-start">
                    <Typography className="text-sm">Broj mobitela</Typography>
                    {athlete.phoneNumber ? (
                      <Typography className="text-sm font-bold">
                        {athlete.phoneNumber}
                      </Typography>
                    ) : (
                      <Typography variant="subtext" className="text-sm">
                        Podatak nije unesen
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col items-start">
                    <Typography className="text-sm">Email</Typography>
                    <Typography className="text-sm font-bold">
                      {athlete.email}
                    </Typography>
                  </div>
                  <div className="flex flex-col items-start">
                    <Typography className="text-sm">
                      Datum upisa u klub
                    </Typography>
                    <Typography className="text-sm font-bold">
                      {new Date(
                        athlete.dateOfMembershipStart
                      ).toLocaleDateString("hr-HR")}
                    </Typography>
                  </div>
                  <div className="flex flex-col items-start">
                    <Typography className="text-sm">
                      Popust članarine
                    </Typography>
                    {athlete.discountPercentage > 0 ? (
                      <Typography className="text-sm font-bold">
                        {athlete.discountPercentage}%
                      </Typography>
                    ) : (
                      <Typography className="text-sm text" variant="subtext">
                        Nema popusta
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              {parents.length > 0 && (
                <div className="flex flex-col w-full gap-1">
                  <Typography
                    variant="subtext"
                    className="flex w-full text-gray-500 items-start border-b"
                  >
                    PODACI O RODITELJIMA
                  </Typography>
                  <div className="flex flex-col gap-4 sm:gap-2 md:gap-1 xl:gap-2 2xl:gap-1">
                    {parents.map((parent) => {
                      return (
                        <div className="w-full items-start gap-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 ">
                          <div className="flex flex-col items-start">
                            <Typography className="text-sm">
                              Roditelj
                            </Typography>
                            {parent.deleted ? (
                              <Tooltip
                                label={
                                  <div>Korisnički profil je deaktiviran</div>
                                }
                              >
                                <Typography className="text-sm font-bold underline underline-offset-4">
                                  {`${parent.firstName} ${parent.lastName}`}
                                </Typography>
                              </Tooltip>
                            ) : userHasAnyRole([
                                UserType.ADMIN,
                                UserType.COACH,
                              ]) ? (
                              <Link
                                to={`/archive/parents/${parent.id}`}
                                className="text-base hover:no-underline"
                              >
                                <Typography className="text-sm font-bold underline underline-offset-4">
                                  {`${parent.firstName} ${parent.lastName}`}
                                </Typography>
                              </Link>
                            ) : (
                              <Typography className="text-sm font-bold underline underline-offset-4">
                                {`${parent.firstName} ${parent.lastName}`}
                              </Typography>
                            )}
                          </div>
                          <div className="flex flex-col items-start ">
                            <Typography className="text-sm">
                              Broj telefona
                            </Typography>
                            <Typography className="text-sm font-bold">
                              {parent.phoneNumber}
                            </Typography>
                          </div>
                          <div className="flex flex-col items-start">
                            <Typography className="text-sm">Email</Typography>
                            <Typography className="text-sm font-bold">
                              {parent.email}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
