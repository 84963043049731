import React, { useEffect, useState } from "react";

import { Typography } from "@tiller-ds/core";
import { Input } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import LinearAnswerOption from "./LinearAnswerOption";
import { Answer } from "./SurveyQuestionCreate";
import { SurveyAnswerType } from "../../../../../common/constants";
import { range } from "../../../../util/Range";

type LinearAnswerOptionCreateProps = {
  setAnswers: (answers: Answer[]) => void;
  answers: Answer[];
  addNewAnswer: (type: SurveyAnswerType) => any;
};

export default function LinearAnswerOptionCreate({
  setAnswers,
  answers,
}: LinearAnswerOptionCreateProps) {
  const [minRange, setMinRange] = useState(1);
  const [maxRange, setMaxRange] = useState(4);

  const [minLabel, setMinLabel] = useState("");
  const [maxLabel, setMaxLabel] = useState("");

  const inputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    if (e.currentTarget.name === "minLabel") {
      setMinLabel(value);
    } else if (e.currentTarget.name === "maxLabel") {
      setMaxLabel(value);
    }
  };

  useEffect(() => {
    const UpdateAnswers = () => {
      const updatedRange = [...answers].map((answer) => {
        if (answer.type === SurveyAnswerType.LinearScale) {
          answer.minRating = minRange;
          answer.maxRating = maxRange;
          answer.minRatingLabel = minLabel;
          answer.maxRatingLabel = maxLabel;
        }
        return answer;
      });
      setAnswers(updatedRange);
    };
    UpdateAnswers();
    // eslint-disable-next-line
  }, [minRange, maxRange, maxLabel, minLabel]);

  return (
    <>
      <div className="w-full flex flex-col justify-between items-center py-4 mb-4 sm:flex-row">
        <div className="flex w-full space-x-4 mb-4 sm:mb-0">
          <DropdownMenu
            title={minRange}
            name={"Select min rating"}
            openExpanderIcon={<Icon type="caret-down" variant="bold" />}
            closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
          >
            {range(0, 3).map((index: number) => {
              return (
                <DropdownMenu.Item
                  onSelect={() => {
                    setMinRange(index);
                  }}
                >
                  {index.toString()}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu>
          <DropdownMenu
            title={maxRange}
            name={"Select min rating"}
            openExpanderIcon={<Icon type="caret-down" variant="bold" />}
            closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
          >
            {range(4, 8).map((index: number) => {
              return (
                <DropdownMenu.Item
                  onSelect={() => {
                    setMaxRange(index);
                  }}
                >
                  {index.toString()}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu>
        </div>
        <div className="flex w-full justify-start space-x-4 sm:justify-end">
          <Input
            value={minLabel}
            placeholder={"Loše"}
            name={"minLabel"}
            allowClear={true}
            className="w-1/3 sm:w-full"
            onChange={inputHandler}
          />
          <Input
            value={maxLabel}
            placeholder={"Odlično"}
            name={"maxLabel"}
            allowClear={true}
            className="w-1/3 sm:w-full"
            onChange={inputHandler}
          />
        </div>
      </div>
      <div className="flex justify-between py-2 w-full mb-4 xl:py-4">
        <div className="flex items-end">
          <Typography element="h6" variant="text" className="font-inter">
            {minLabel ? minLabel : "Loše"}
          </Typography>
        </div>
        {range(minRange, maxRange + 1).map((el) => {
          return <LinearAnswerOption fieldName={el.toString()} />;
        })}
        <div className="flex items-end">
          <Typography element="h6" variant="text" className="font-inter">
            {maxLabel ? maxLabel : "Odlično"}
          </Typography>
        </div>
      </div>
    </>
  );
}
