import React, { useContext, useState } from "react";

import { Card, Tabs } from "@tiller-ds/core";

import AdminRegistry from "../../admin/components/AdminRegistry";
import AthleteRegistry from "../../athlete/components/AthleteRegistry";
import CoachRegistry from "../../coach/components/CoachRegistry";
import GroupRegistry from "../../group/components/GroupRegistry";
import ParentRegistry from "../../parent/components/ParentRegistry";
import { RegistryContext } from "../RegistryProvider";

type TabSelectorProps = {
  entityCreated: number;
};

export default function TabSelector({ entityCreated }: TabSelectorProps) {
  const { registryTabIndex, setRegistryTabIndex } = useContext(RegistryContext);
  const [totalGroups, setTotalGroups] = useState(0);
  const [totalAthletes, setTotalAthletes] = useState(0);
  const [totalParents, setTotalParents] = useState(0);
  const [totalCoaches, setTotalCoaches] = useState(0);
  const [totalAdmins, setTotalAdmins] = useState(0);

  return (
    <div className="w-full">
      <Card className="p-2">
        <Tabs defaultIndex={registryTabIndex}>
          <Tabs.Tab
            label={`Grupe (${totalGroups})`}
            onClick={(tabIndex) => setRegistryTabIndex(tabIndex)}
          >
            <GroupRegistry
              totalGroups={totalGroups}
              setTotalGroups={setTotalGroups}
              entityCreated={entityCreated}
            />
          </Tabs.Tab>
          <Tabs.Tab
            label={`Sportaši (${totalAthletes})`}
            onClick={(tabIndex) => setRegistryTabIndex(tabIndex)}
          >
            <AthleteRegistry
              totalAthletes={totalAthletes}
              setTotalAthletes={setTotalAthletes}
              entityCreated={entityCreated}
            />
          </Tabs.Tab>
          <Tabs.Tab
            label={`Roditelji (${totalParents})`}
            onClick={(tabIndex) => setRegistryTabIndex(tabIndex)}
          >
            <ParentRegistry
              totalParents={totalParents}
              setTotalParents={setTotalParents}
              entityCreated={entityCreated}
            />
          </Tabs.Tab>
          <Tabs.Tab
            label={`Treneri (${totalCoaches})`}
            onClick={(tabIndex) => setRegistryTabIndex(tabIndex)}
          >
            <CoachRegistry
              totalCoaches={totalCoaches}
              setTotalCoaches={setTotalCoaches}
              entityCreated={entityCreated}
            />
          </Tabs.Tab>
          <Tabs.Tab
            label={`Administatori (${totalAdmins})`}
            onClick={(tabIndex) => setRegistryTabIndex(tabIndex)}
          >
            <AdminRegistry
              totalAdmins={totalAdmins}
              setTotalAdmins={setTotalAdmins}
              entityCreated={entityCreated}
            />
          </Tabs.Tab>
        </Tabs>
      </Card>
    </div>
  );
}
