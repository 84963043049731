import React from "react";

import { Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import PersonalRecordWrapperCard from "./PersonalRecordWrapperCard";

export default function EmptyPersonalRecordsCard() {
  return (
    <PersonalRecordWrapperCard>
      <div className="w-full flex items-center gap-4">
        <div className="flex justify-center items-center bg-rose-100 rounded-full w-12 h-12">
          <Typography element="p" variant="h5">
            <Icon
              type="hash"
              variant="bold"
              size={7}
              className="text-rose-600 flex"
            />
          </Typography>
        </div>
        <div>
          <Typography variant="subtext" className="text-gray-700">
            <i>Nije dodijeljen rekord</i>
          </Typography>
        </div>
      </div>
    </PersonalRecordWrapperCard>
  );
}
