import React from "react";

type MonkiProps = {
  type?: number;
};

export default function Monki({ type }: MonkiProps) {
  let src;
  switch (type) {
    case 1:
      src = "https://c.tenor.com/Kfjz4Tk1MuMAAAAM/monkey-drinking.gif";
      break;
    default:
      src = "https://c.tenor.com/ox_GpEaMrzoAAAAd/wildathearts-monkey.gif";
      break;
  }
  return <img className="object-fill" src={src} alt="Monki" />;
}
