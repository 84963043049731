import React from "react";

import { Badge, Card, IconButton, Tooltip, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Checkbox } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";

import {
  INVOICE_VIEW_DATE_FORMAT,
  InvoiceStatus,
} from "../../../common/constants";
import formatDateInTimeZone from "../../../common/formatDateInTimeZone";
import { InvoiceResponse } from "../../common/InvoiceResponse";
import { formatAmount } from "../../common/InvoiceUtil";
import { exportInvoiceExcel } from "../api/getExportPayment";

type ParentInvoiceTableProps = {
  invoices: InvoiceResponse[];
  paidCheckboxHandler: (invoiceId: number) => void;
  onQRButtonClick: (invoiceId: number) => void;
};

export function ParentInvoiceTable({
  invoices,
  paidCheckboxHandler,
  onQRButtonClick,
}: ParentInvoiceTableProps) {
  return (
    <Card>
      <Card.Body removeSpacing={true}>
        <DataTable data={invoices} className="w-full">
          <DataTable.Column
            header="Poziv na broj"
            id="referenceNumber"
            key={"referenceNumber"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) => invoice.referenceNumber}
          </DataTable.Column>
          <DataTable.Column
            header="Sportaš"
            id="athlete"
            key={"athlete"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) => `${invoice.athlete.firstName}`}
          </DataTable.Column>
          <DataTable.Column
            header="Opis"
            id="description"
            key={"description"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) => invoice.description}
          </DataTable.Column>
          <DataTable.Column
            header={
              <div className="w-full text-right">
                <p>Iznos</p>
              </div>
            }
            id="amount"
            key={"amount"}
            canSort={false}
            align="right"
          >
            {(invoice: InvoiceResponse) => (
              <Typography variant="text">
                {formatAmount(invoice.amountWithDiscount)}
              </Typography>
            )}
          </DataTable.Column>
          <DataTable.Column
            header="Datum kreiranja"
            id="creationDate"
            key={"creationDate"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) =>
              formatDateInTimeZone(
                invoice.creationDate,
                INVOICE_VIEW_DATE_FORMAT
              )
            }
          </DataTable.Column>
          <DataTable.Column
            header="Datum dospijeća"
            id="dueDate"
            key={"dueDate"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) =>
              formatDateInTimeZone(invoice.dueDate, INVOICE_VIEW_DATE_FORMAT)
            }
          </DataTable.Column>
          <DataTable.Column
            header="Uplatnica"
            id="paymentSlip"
            key={"paymentSlip"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) => (
              <IconButton
                icon={<Icon type="file" />}
                className="mx-auto translate-x-7 text-gray-500"
                label="Preuzmi uplatnicu"
                onClick={() =>
                  exportInvoiceExcel({
                    invoiceId: invoice.id,
                    athleteFullName: `${invoice.athlete.firstName}_${invoice.athlete.lastName}`,
                    creationDate: formatDateInTimeZone(
                      invoice.creationDate,
                      "dd.MM.yyyy."
                    ),
                  })
                }
              />
            )}
          </DataTable.Column>
          <DataTable.Column
            header="QR"
            id="qrCode"
            key={"qrCode"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) => (
              <IconButton
                icon={<Icon type="qr-code" />}
                className="mx-auto text-gray-500"
                label="Generiraj QR kod"
                onClick={() => {
                  onQRButtonClick(invoice.id);
                }}
              />
            )}
          </DataTable.Column>
          <DataTable.Column header="Status" id="status" canSort={false}>
            {(item: InvoiceResponse) =>
              item.status === InvoiceStatus.NEPODMIRENO ? (
                <Badge color="warning">{InvoiceStatus.NEPODMIRENO}</Badge>
              ) : (
                <Badge color="success">{InvoiceStatus.PODMIRENO}</Badge>
              )
            }
          </DataTable.Column>
          <DataTable.Column
            header={
              <div className="flex flex-row">
                <p className="mt-1">Uplaćeno</p>
                <Tooltip
                  label={
                    "Ovdje možete pratiti koji račun ste platili." +
                    "\n" +
                    "Račun će promijeniti status tek nakon što klub zabilježi vašu uplatu."
                  }
                >
                  <Icon type={"info"} size={4} />
                </Tooltip>
              </div>
            }
            id="paid"
            key={"paid"}
            canSort={false}
          >
            {(invoice: InvoiceResponse) => (
              <Checkbox
                checked={invoice.isPaid}
                onChange={() => paidCheckboxHandler(invoice.id)}
                className="mx-auto"
              />
            )}
          </DataTable.Column>
        </DataTable>
      </Card.Body>
    </Card>
  );
}
