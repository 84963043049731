import { Notification } from "@croz/nrich-notification-core";

import handleResponse from "../../common/handleResponse";

export default function handleMarkingInvoiceAsPaidRequest(
  response: Response,
  addNotification: (notification: Notification) => void,
  notificationBody = {
    title: "Računi zatvoreni",
    content: `Odabrani računi su uspješno zatvoreni`,
    messageList: [],
    severity: "INFO",
    timestamp: new Date(),
  } as Notification
): boolean {
  return handleResponse(response, addNotification, notificationBody);
}
