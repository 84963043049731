import FileDownload from "js-file-download";

import { AthleteAttendanceReportsRequest } from "./AthleteAttendanceReportsRequest";
import { GROUP_URL, REPORTS_STATE_ATTENDANCES_URL } from "../../common/Routes";
import { formatDateToHrWithDashes } from "../util/dateUtil";

export function exportAttendanceReportsPDF(
  request: AthleteAttendanceReportsRequest
) {
  fetch(`${GROUP_URL}/${request.groupId}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((group) => {
      fetch(`${REPORTS_STATE_ATTENDANCES_URL}/export/pdf`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const startDateFormatted = formatDateToHrWithDashes(
            request.startDate
          );
          const endDateFormatted = formatDateToHrWithDashes(request.endDate);
          FileDownload(
            blob,
            `izvještaj_izostanci_${group.groupName}_${startDateFormatted}_${endDateFormatted}.pdf`
          );
        });
    });
}
