import React, { useContext } from "react";

import { Card, Typography } from "@tiller-ds/core";
import { DescriptionList } from "@tiller-ds/data-display";

import { UserContext } from "../../../auth/SecurityProvider";

export default function AdminProfile() {
  const { user } = useContext(UserContext);

  return (
    <Card>
      <Card.Body removeSpacing={true}>
        <DescriptionList>
          <DescriptionList.Item label="Ime" type="same-column">
            <Typography className="font-semibold">{user.firstName}</Typography>
          </DescriptionList.Item>
          <DescriptionList.Item label="Prezime" type="same-column">
            <Typography className="font-semibold">{user.lastName}</Typography>
          </DescriptionList.Item>
          <DescriptionList.Item label="E-mail" type="same-column">
            <Typography className="font-semibold">{user.email}</Typography>
          </DescriptionList.Item>
          <DescriptionList.Item label="Broj telefona" type="same-column">
            <Typography className="font-semibold">
              {user.phoneNumber}
            </Typography>
          </DescriptionList.Item>
        </DescriptionList>
      </Card.Body>
    </Card>
  );
}
