import * as React from "react";
import { createContext, useState } from "react";

import { endOfWeek, startOfWeek } from "date-fns";

import { GroupResponse } from "../archive/group/api/GroupResponse";
import { CalendarView, EventType } from "../common/constants";

type CalendarProviderProps = {
  children: React.ReactNode;
};

const today = new Date();

export const CalendarStateContext = createContext({
  calendarView: CalendarView.WEEK_VIEW,
  setCalendarView: (calendarView: CalendarView) => {},
  eventFilter: EventType.ALL,
  setEventFilter: (eventFilter: EventType) => {},
  groupFilter: undefined,
  setGroupFilter: (groupFilter: undefined | GroupResponse) => {},
  initialDate: today,
  setInitialDate: (initialDate: Date) => {},
  startDate: today,
  setStartDate: (startDate: Date) => {},
  endDate: today,
  setEndDate: (endDate: Date) => {},
});

export default function CalendarProvider({ children }: CalendarProviderProps) {
  const dateToday = new Date();

  const [calendarView, setCalendarView] = useState(CalendarView.WEEK_VIEW);
  const [eventFilter, setEventFilter] = useState(EventType.ALL);
  const [groupFilter, setGroupFilter] = useState<GroupResponse | undefined>(
    undefined
  );
  const [initialDate, setInitialDate] = useState(today);
  const [startDate, setStartDate] = useState(
    startOfWeek(dateToday, { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState(
    endOfWeek(dateToday, { weekStartsOn: 1 })
  );

  const value = {
    calendarView,
    setCalendarView,
    eventFilter,
    setEventFilter,
    groupFilter,
    setGroupFilter,
    initialDate,
    setInitialDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };

  return (
    // @ts-ignore
    <CalendarStateContext.Provider value={value}>
      {children}
    </CalendarStateContext.Provider>
  );
}
