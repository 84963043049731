import { UpdateParentRequest } from "./UpdateParentRequest";
import { PARENT_URL } from "../../../common/Routes";

export async function putUpdateParentRequest(
  request: UpdateParentRequest,
  parentId: number
): Promise<Response> {
  return await fetch(`${PARENT_URL}/${parentId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
