export enum UserType {
  PARENT = "PARENT",
  ATHLETE = "ATHLETE",
  COACH = "COACH",
  ADMIN = "ADMIN",
} //= "PARENT" | "ATHLETE" | "COACH" | "ADMIN";

export type UserResponse = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userType: UserType;
};
