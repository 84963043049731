import { PROGRESS_URL } from "../../../common/Routes";

export async function deleteProgressType(progressTypeId: number) {
  return fetch(`${PROGRESS_URL}/type/${progressTypeId}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
