import { InvoiceReminderRequest } from "./InvoiceReminderRequest";
import { INVOICE_SEND_REMINDER_URL } from "../../../common/Routes";

export async function postInvoiceReminderRequest(
  request: InvoiceReminderRequest
): Promise<Response> {
  return await fetch(INVOICE_SEND_REMINDER_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
