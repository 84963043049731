import React from "react";

import { IntlProvider } from "@tiller-ds/intl";
import { SidebarLayout } from "@tiller-ds/menu";

import { BrowserRouter } from "react-router-dom";

import RegistryProvider from "./archive/common/RegistryProvider";
import SecurityProvider from "./auth/SecurityProvider";
import NavigationItems from "./common/components/NavigationItems";
import { Notifications } from "./common/components/Notifications";
import RoutesComponent from "./common/components/RoutesComponent";
import EnvironmentVariablesProvider from "./env-provider/EnvironmentVariablesProvider";
import CalendarProvider from "./event/CalendarProvider";
import MessageProvider from "./messages/MessageProvider";

function App() {
  return (
    <EnvironmentVariablesProvider>
      <SecurityProvider>
        <IntlProvider lang="hr" loadDictionary={undefined}>
          <BrowserRouter>
            <Notifications />
            <div className="flex flex-row justify-start w-full h-screen">
              <div className="w-full">
                <SidebarLayout navigation={<NavigationItems />}>
                  <SidebarLayout.Content>
                    <RegistryProvider>
                      <CalendarProvider>
                        <MessageProvider>
                          <RoutesComponent />
                        </MessageProvider>
                      </CalendarProvider>
                    </RegistryProvider>
                  </SidebarLayout.Content>
                </SidebarLayout>
              </div>
            </div>
          </BrowserRouter>
        </IntlProvider>
      </SecurityProvider>
    </EnvironmentVariablesProvider>
  );
}

export default App;
