import { EventReportsRequest } from "./EventReportsRequest";
import { REPORTS_STATE_EVENTS_URL } from "../../common/Routes";

export async function getEventReports(request: EventReportsRequest) {
  const response = await fetch(`${REPORTS_STATE_EVENTS_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
