import React, { useMemo, useState } from "react";

import { Modal, UseModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { TextareaField } from "@tiller-ds/formik-elements";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";
import * as yup from "yup";

import { ErrorResponse } from "../../../common/api/ErrorResponse";
import { MAX_INVOICE_REMINDER_MESSAGE_LENGTH } from "../../../common/constants";
import {
  maxCharacterCountMessage,
  REQUIRED_FIELD_MESSAGE,
} from "../../../common/YupConstants";
import { InvoiceResponse } from "../../common/InvoiceResponse";
import { InvoiceReminderRequest } from "../api/InvoiceReminderRequest";
import { InvoiceReminderResponse } from "../api/InvoiceReminderResponse";
import { postInvoiceReminderRequest } from "../api/postInvoiceReminderRequest";

type SendInvoiceReminderComponentProps = {
  modal: UseModal<unknown>;
  selectedInvoices: Record<string, boolean>;
  invoiceData: InvoiceResponse[];
};

const initialValues: InvoiceReminderRequest = {
  invoiceIds: [],
  reminderMessage: `Poštovani,
podsjećamo Vas da imate nepodmirena dugovanja prema klubu.
Molimo Vas podmirite dugovanja.`,
};

const invoiceReminderValidationSchema = yup.object().shape({
  reminderMessage: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .nullable()
    .max(
      MAX_INVOICE_REMINDER_MESSAGE_LENGTH,
      maxCharacterCountMessage(MAX_INVOICE_REMINDER_MESSAGE_LENGTH)
    ),
});

export default function SendInvoiceReminderModal({
  modal,
  selectedInvoices,
  invoiceData,
}: SendInvoiceReminderComponentProps) {
  const { add: addNotification } = useNotifications();
  const [
    reminderMessageCharacterCount,
    setReminderMessageCharacterCount,
  ] = useState(initialValues.reminderMessage.length);

  const invoiceIds = useMemo(() => {
    return invoiceData
      .filter((invoice) =>
        Object.keys(selectedInvoices).includes(
          invoiceData.indexOf(invoice).toString()
        )
      )
      .map((invoice) => invoice.id);
  }, [invoiceData, selectedInvoices]);

  const invoiceRecipient = useMemo(() => {
    return invoiceData
      .filter((invoice) => invoiceIds.includes(invoice.id))
      .map((invoice) =>
        invoice.athlete.parents.map((parent) =>
          parent.firstName.concat(" ", parent.lastName)
        )
      );
  }, [invoiceIds, invoiceData]);

  const onSubmitForm = (request: InvoiceReminderRequest) => {
    request.invoiceIds = invoiceIds;

    postInvoiceReminderRequest(request).then((response) => {
      if (response.ok) {
        response.json().then((body: InvoiceReminderResponse) => {
          addNotification({
            title: "Podsjetnik uspješno poslan!",
            content: body.responseMessage,
            messageList: [],
            severity: "INFO",
            timestamp: new Date(),
          });
        });
      } else {
        response.json().then((body: ErrorResponse) => {
          addNotification({
            title: body.notification.title,
            content: body.notification.content,
            messageList: body.notification.messageList,
            severity: body.notification.severity,
            timestamp: new Date(body.notification.timestamp),
          });
        });
      }
      modal.onClose();
    });
  };

  return (
    <Modal {...modal}>
      <Formik
        initialValues={initialValues}
        validationSchema={invoiceReminderValidationSchema}
        onSubmit={onSubmitForm}
      >
        {(formik) => (
          <form
            className="pr-3"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Typography className="mb-4" element="h4" variant="h4">
              {"Pošalji podsjetnik"}
            </Typography>
            <Typography
              className="text-gray-500 mb-4"
              element="p"
              variant="subtext"
            >
              {invoiceRecipient.length > 1 ? "Primatelji: " : "Primatelj: "}
              {invoiceRecipient.join(", ")}
            </Typography>
            <TextareaField
              textareaClassName="h-40"
              name="reminderMessage"
              placeholder="Tekst podsjetnika"
              type={"email"}
              maxLength={MAX_INVOICE_REMINDER_MESSAGE_LENGTH}
              onKeyUp={() => {
                setReminderMessageCharacterCount(
                  formik.getFieldProps("reminderMessage").value.length
                );
              }}
            />
            <Typography
              variant="subtext"
              className="flex justify-end mt-1 text-gray-400"
            >{`${reminderMessageCharacterCount}/${MAX_INVOICE_REMINDER_MESSAGE_LENGTH}`}</Typography>
            <div className="flex flex-col-reverse gap-y-3 sm:gap-y-0 mt-6 sm:flex-row sm:justify-end">
              <Button
                type="button"
                className="w-full sm:mr-2 sm:w-fit"
                variant="outlined"
                onClick={() => {
                  modal.onClose();
                }}
              >
                Odbaci promjene
              </Button>
              <Button
                className="w-full sm:w-fit"
                variant="filled"
                type="submit"
              >
                Pošalji
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
