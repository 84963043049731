import React, { useEffect } from "react";

import { Notification } from "@tiller-ds/alert";
import { Link } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";

type Action = {
  url: string;
  title: string;
};

type UxOptions = {
  actions?: Action[];
  timeout?: number;
};

const NOTIFICATION_TIMEOUT_TIME = 5000;

export const Notifications = () => {
  const { notifications, remove } = useNotifications();

  const navigate = useNavigate();

  function determineNotificationTimeout(notification: any) {
    if (notification?.uxNotificationOptions?.timeout != null) {
      return notification?.uxNotificationOptions?.timeout as number;
    }
    return NOTIFICATION_TIMEOUT_TIME;
  }

  useEffect(() => {
    notifications.forEach((notification) => {
      setTimeout(() => {
        if (notification) {
          remove(notification);
        }
      }, determineNotificationTimeout(notification));
    });
  }, [notifications, remove]);

  function iconClassNames(notification: any) {
    return classNames({
      "text-green-400": notification.severity === "INFO",
      "text-orange-600": notification.severity === "WARNING",
      "text-red-600": true,
    });
  }

  return (
    <div className="fixed right-5 bottom-4 z-50 ">
      {notifications.map((notification) => {
        const uxOptions = (notification.uxNotificationOptions ||
          {}) as UxOptions;

        return (
          <div className="pt-2 pr-2">
            <Notification
              icon={
                <Icon
                  className={iconClassNames(notification)}
                  size={6}
                  type="check-circle"
                  variant="fill"
                />
              }
              onDismiss={() => remove(notification)}
              content={notification.content}
              title={notification.title}
              actions={uxOptions?.actions?.map((action) => {
                return (
                  <Link
                    onClick={() => {
                      remove(notification);
                      navigate(action.url);
                    }}
                  >
                    {action.title}
                  </Link>
                );
              })}
              timeout={determineNotificationTimeout(notification)}
            />
          </div>
        );
      })}
    </div>
  );
};
