import { PROGRESS_URL } from "../../../common/Routes";

export async function getProgressTypes() {
  const response = await fetch(`${PROGRESS_URL}/type`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
