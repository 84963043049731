import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Card, Typography } from "@tiller-ds/core";
import { DateInput } from "@tiller-ds/date";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { RankList } from "./RankList";
import { RankModal } from "./RankModal";
import { GroupResponse } from "../../../archive/group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../../archive/group/api/postSearchGroupRequestNonPaged";
import {
  MEASUREMENT_UNIT_ABBREVIATION,
  SCREEN_WIDTH_BREAKPOINTS,
} from "../../../common/constants";
import { exportRankReportsExcel } from "../../api/exportRankReportsExcel";
import { exportRankReportsPDF } from "../../api/exportRankReportsPDF";
import { getRankTypeReports } from "../../api/getRankTypeReports";
import { RankTypeReportResponse } from "../../api/RankTypeReportResponse";
import { firstDayOfMonthDate, lastDayOfMonthDate } from "../../util/dateUtil";

const groupFilterDefaultOption = "Svi sportaši";

export function Ranks() {
  const [startDate, setStartDate] = useState<Date | null>(firstDayOfMonthDate);
  const [endDate, setEndDate] = useState<Date | null>(lastDayOfMonthDate);
  const [groups, setGroups] = useState<GroupResponse[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupResponse>();
  const [filterByGroup, setFilterByGroup] = useState<boolean>(false);
  const [rankTypeReports, setRankTypeReports] = useState<
    RankTypeReportResponse[]
  >([]);
  const [expandedReport, setExpandedReport] = useState<
    RankTypeReportResponse
  >();

  const modal = useModal();

  useEffect(() => {
    postSearchGroupRequestNonPaged({
      briefResponse: true,
    }).then((response) => {
      setGroups(response);
      if (response.length > 0) {
        setFilterByGroup(false);
      }
    });
    setFilterByGroup(false);
    setSelectedGroup(undefined);
  }, []);

  useEffect(() => {
    if (startDate === null || endDate === null) {
      return;
    }
    getRankTypeReports({
      startDate: startDate,
      endDate: endDate,
      shownStatisticsCount: 5,
      groupId:
        selectedGroup !== undefined && filterByGroup
          ? selectedGroup.id
          : undefined,
    }).then((response) => setRankTypeReports(response));
  }, [filterByGroup, selectedGroup, endDate, startDate]);

  const shouldCollapseEmptyCards = () => {
    return window.innerWidth < SCREEN_WIDTH_BREAKPOINTS.tablet;
  };

  const exportReport = (format: "excel" | "pdf") => {
    if (startDate === null || endDate === null) {
      return;
    }

    const request =
      selectedGroup !== undefined
        ? {
            groupId: selectedGroup.id,
            startDate: startDate,
            endDate: endDate,
          }
        : {
            startDate: startDate,
            endDate: endDate,
          };

    format === "excel"
      ? exportRankReportsExcel(request)
      : exportRankReportsPDF(request);
  };

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-3">
        <Typography variant="h5" element="h5" className="text-sm">
          Rang liste sportaša po mjerljivoj statistici
        </Typography>
        <div className="flex flex-col gap-y-3 xl:flex-row md:gap-x-3 xl:justify-end xl:ml-auto w-full">
          <div className="flex flex-col justify-center sm:flex-row xl:justify-end sm:gap-x-3">
            <DateInput
              label="Početak perioda"
              name="startDate"
              value={startDate}
              allowClear={false}
              onChange={(value) => setStartDate(value)}
              maxDate={endDate as Date | undefined}
            />
            <DateInput
              label="Kraj perioda"
              name="endDate"
              value={endDate}
              allowClear={false}
              onChange={(value) => setEndDate(value)}
              minDate={startDate as Date | undefined}
            />
          </div>
          <div className="flex flex-row justify-between sm:justify-center sm:gap-x-3 mt-auto">
            <DropdownMenu
              title={
                filterByGroup
                  ? selectedGroup?.groupName
                  : groupFilterDefaultOption
              }
              color="primary"
              className="mt-auto align-bottom"
            >
              <DropdownMenu.Item onSelect={() => setFilterByGroup(false)}>
                {groupFilterDefaultOption}
              </DropdownMenu.Item>
              {groups.map((group) => (
                <DropdownMenu.Item
                  onSelect={() => {
                    setSelectedGroup(group);
                    setFilterByGroup(true);
                  }}
                >
                  {group.groupName}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu>
            <DropdownMenu
              title={<span className="hidden sm:inline-block">Preuzmi</span>}
              color="primary"
              openExpanderIcon={<Icon type="download-simple" />}
              closeExpanderIcon={<Icon type="download-simple" />}
              iconPlacement="leading"
              className="pr-2 sm:pr-4 mt-auto align-bottom"
            >
              <DropdownMenu.Item onSelect={() => exportReport("excel")}>
                .xlsx
              </DropdownMenu.Item>
              <DropdownMenu.Item onSelect={() => exportReport("pdf")}>
                .pdf
              </DropdownMenu.Item>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-3 xl:gap-y-8 mt-10">
        {rankTypeReports.map((report) => (
          <Card
            className="h-full w-full"
            isExpanded={
              !(
                shouldCollapseEmptyCards() &&
                report.results.content.length === 0
              )
            }
          >
            {shouldCollapseEmptyCards() && report.results.content.length === 0 && (
              <Card.Header>
                <Card.Header.Title>
                  <Typography variant="h6" element="h6">
                    {report.progressType}
                  </Typography>
                </Card.Header.Title>
                <Card.Header.Subtitle>
                  <Typography variant="subtext" element="p">
                    (
                    {
                      MEASUREMENT_UNIT_ABBREVIATION[
                        report.measuringUnit.toLowerCase()
                      ]
                    }
                    )
                  </Typography>
                </Card.Header.Subtitle>
              </Card.Header>
            )}
            <Card.Body>
              {!shouldCollapseEmptyCards() && (
                <div>
                  <Typography variant="h6" element="h6">
                    {report.progressType}
                  </Typography>
                  <Typography variant="subtext" element="p">
                    (
                    {
                      MEASUREMENT_UNIT_ABBREVIATION[
                        report.measuringUnit.toLowerCase()
                      ]
                    }
                    )
                  </Typography>
                </div>
              )}
              <div className="flex flex-col gap-y-3">
                <div>
                  <RankList results={report.results.content} />
                  {report.results.totalElements > 5 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      className="mt-3"
                      onClick={() => {
                        setExpandedReport(report);
                        modal.onOpen(null);
                      }}
                    >
                      Pogledaj sve
                    </Button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
      {rankTypeReports.length === 0 && (
        <div className="mt-16 text-center">
          <Typography variant="subtext" element="p">
            Nema zabilježenih statistika
          </Typography>
        </div>
      )}
      {expandedReport && (
        <RankModal
          progressTitle={expandedReport?.progressType}
          progressMeasurementUnit={expandedReport?.measuringUnit}
          modal={modal}
          progressTypeId={expandedReport.progressTypeId}
          startDate={startDate}
          endDate={endDate}
          groupId={
            filterByGroup && selectedGroup !== undefined
              ? selectedGroup.id
              : undefined
          }
        />
      )}
    </div>
  );
}
