import React, { useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { RadioGroup } from "@tiller-ds/form-elements";

import { DELETE_UPDATE_REPEATING_EVENT_OPTIONS } from "../../../common/constants";

type UpdateEventModalProps = {
  modal: UseModal;
  onUpdateOneOrAllInstancesChange: (value: string) => void;
};

export default function UpdateEventModal({
  modal,
  onUpdateOneOrAllInstancesChange,
}: UpdateEventModalProps) {
  const [updatingMode, setUpdatingMode] = useState(
    DELETE_UPDATE_REPEATING_EVENT_OPTIONS[0]
  );

  const onUpdateEvent = () => {
    onUpdateOneOrAllInstancesChange(updatingMode);
    modal.onClose();
  };

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h3" variant="h4">
            Uredi događaj
          </Typography>
        }
      >
        <RadioGroup
          name="updatingMode"
          onChange={(value) => {
            setUpdatingMode(value as string);
          }}
          value={updatingMode}
          vertical={true}
          label={""}
        >
          <RadioGroup.Item
            label="Uredi samo ovaj događaj"
            value={DELETE_UPDATE_REPEATING_EVENT_OPTIONS[0]}
          />
          <RadioGroup.Item
            label="Uredi sve instance ovog događaja"
            value={DELETE_UPDATE_REPEATING_EVENT_OPTIONS[1]}
          />
        </RadioGroup>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={onUpdateEvent}>
          Uredi
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
