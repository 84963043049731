import React, { useEffect, useState } from "react";

import { Card, Typography } from "@tiller-ds/core";
import { DescriptionList } from "@tiller-ds/data-display";

import { CoachResponse } from "../../../archive/coach/api/CoachResponse";
import { getCoachById } from "../../../archive/coach/api/getCoachById";
import LoadingCircle from "../../../common/components/LoadingCircle";

export type CoachProfileProps = {
  id: number;
};

export default function CoachProfile({ id }: CoachProfileProps) {
  const [coach, setCoach] = useState({} as CoachResponse);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCoachById(id).then((coach) => {
      setCoach(coach);
      setIsLoading(false);
    });
  }, [id]);

  return (
    <LoadingCircle isLoading={isLoading}>
      <Card>
        <Card.Body removeSpacing={true}>
          <DescriptionList>
            <DescriptionList.Item label="Ime" type="same-column">
              <Typography className="font-semibold">
                {coach.firstName}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Prezime" type="same-column">
              <Typography className="font-semibold">
                {coach.lastName}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="OIB" type="same-column">
              <Typography className="font-semibold">{coach.oib}</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item label="Broj telefona" type="same-column">
              <Typography className="font-semibold">
                {coach.phoneNumber}
              </Typography>
            </DescriptionList.Item>
            <DescriptionList.Item
              label="Uloga člana u sustavu"
              type="same-column"
            >
              <Typography className="font-semibold">Trener</Typography>
            </DescriptionList.Item>
            <DescriptionList.Item
              label="Dodijeljene grupe za treniranje"
              type="same-column"
            >
              <Typography className="font-semibold">
                {coach.groups ? (
                  coach.groups.map((group, index) => (
                    <span>{(index ? ", " : "") + group.groupName}</span>
                  ))
                ) : (
                  <i>Nema informacija</i>
                )}
              </Typography>
            </DescriptionList.Item>
          </DescriptionList>
        </Card.Body>
      </Card>
    </LoadingCircle>
  );
}
