import React, { useState } from "react";

import FullCalendar from "@fullcalendar/react";

import Calendar from "../components/calendar/Calendar";
import CalendarFooter from "../components/calendar/CalendarFooter";
import CalendarHeader from "../components/calendar/CalendarHeader";

export default function EventViewPage() {
  const calendarComponentRef = React.createRef<FullCalendar>();

  const [calendarTitle, setCalendarTitle] = useState("Kalendar");

  function updateCalendarTitle(newTitle: string) {
    setCalendarTitle(newTitle);
  }

  return (
    <div className="h-full">
      <CalendarHeader
        calendarComponentRef={calendarComponentRef}
        calendarTitle={calendarTitle}
        updateCalendarTitle={updateCalendarTitle}
      />
      <Calendar
        calendarComponentRef={calendarComponentRef}
        updateCalendarTitle={updateCalendarTitle}
      />
      <CalendarFooter />
    </div>
  );
}
