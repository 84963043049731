import { UpdateInvoiceTemplateRequest } from "./updateInvoiceTemplateRequest";
import { INVOICE_TEMPLATE_URL } from "../../../common/Routes";

export async function putUpdateInvoiceTemplateRequest(
  request: UpdateInvoiceTemplateRequest
): Promise<Response> {
  return await fetch(`${INVOICE_TEMPLATE_URL}/${request.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
