import { ThemeConfigFactory } from "@tiller-ds/theme/defaultTheme";

export const tillerConfig: ThemeConfigFactory = {
  component: {
    Badge: {
      base: {
        padding: "px-2 md:px-4",
      },
      variant: {
        outlined: {
          color: {
            primary: {
              base: "border-navy-500 text-navy-500",
            },
          },
        },
        filled: {
          base: "border",
          color: {
            primary: {
              base: "bg-navy-500 border-navy-500 text-white",
              removeIcon: "text-white",
            },
            secondary: {
              base: "text-gray-800 bg-navy-75",
            },
          },
        },
      },
    },
    Card: {
      master: "relative h-full w-full",
      container: {
        overflow: "overflow-visible",
      },
      body: {
        padding: "px-4 py-5 sm:p-6 w-full",
      },
    },
    SidebarLayout: {
      padding: "py-0 w-screen",
      backgroundColor: "bg-navy-75",
    },
    Breadcrumbs: {
      container: {
        backgroundColor: "",
      },
    },
    Modal: {
      Container: {
        base: {
          master:
            "fixed bottom-0 inset-x-0 sm:inset-0 sm:flex sm:items-center sm:justify-center",
        },
      },
      Icon: {
        backgroundColor: "bg-white",
      },
    },
    SidebarNavigation: {
      base: {
        master: "flex-col scrollbar overflow-y-auto space-y-1 h-full",
        boxShadow: "",
        borderRadius: "",
        default: "",
        dark: "bg-gray-700 text-white",
        light: "bg-gray-100 border-b border-gray-200 text-gray-700",
        padding: "",
      },
      topContainer: "w-full grid grid-cols-3 justify-center md:flex",
      logo: {
        master:
          "flex col-span-2 md:items-center justify-end md:justify-center px-4 md:px-1",
      },
      container: {
        master: "md:h-screen flex flex-col gap-5 md:w-16 lg:w-64",
        default: "bg-navy-600 text-white",
        padding: "py-4 px-3 md:px-0 lg:px-3",
      },
      item: {
        master: "flex leading-5",
        padding: "py-3 px-3 md:py-2",
        base: {
          default:
            "text-white w-full justify-center lg:justify-start text-center lg:text-start md:border-l-0 lg:border-l-2 hover:bg-navy-500 hover:rounded-md lg:hover:rounded-none lg:hover:text-gray-300 lg:border-transparent lg:hover:bg-transparent lg:hover:border-gray-300",
        },
        active: {
          default:
            "justify-center lg:justify-start bg-navy-500 lg:bg-transparent md:border-l-0 lg:border-l-2 border-gray-300 lg:hover:text-gray-300 rounded-md lg:rounded-none",
        },
      },
      icon: {
        master:
          "w-0 h-0 md:w-6 md:h-6 flex items-center transition ease-in-out duration-150 group-hover:text-primary-light group-focus:text-primary-light",
        color: "text-primary-light",
      },
    },
    Checkbox: {
      backgroundColor: "bg-white checked:bg-navy-500",
    },
    DateInput: {
      DatePicker: {
        Button: {
          dateHovered: "hover:bg-navy-500 hover:text-white",
        },
      },
    },
    DescriptionList: {
      Type: {
        default: {
          margin: "mt-0",
          border: "border-t",
          borderColor: "border-gray-200",
        },
      },
    },
    DropdownMenu: {
      MenuItem: {
        master: "whitespace-nowrap",
      },
    },
    Pagination: {
      default: {
        backgroundColor: "none hover:bg-navy-100",
        textColor: "text-gray-700",
        borderColor: "none",
      },
      current: {
        backgroundColor: "none hover:bg-navy-100",
        textColor: "text-navy-500 text-bold",
        borderColor: "border-bottom border-b-2 border-navy-500",
      },
      pageSummary: {
        fontSize: "text-sm",
        lineHeight: "leading-5",
      },
    },
    ButtonGroups: {
      master: "relative inline-flex items-center z-0",
      base: "shadow-none",
    },
    Typography: {
      variant: {
        h3: {
          color: `text-primary`,
        },
      },
    },
    RadioGroup: {
      base: {
        color: {
          primary: "checked:bg-navy-500",
        },
      },
    },
  },
};
