import React from "react";

import { AutocompleteField } from "@tiller-ds/formik-elements";

import {
  AUTOCOMPLETE_SEARCH_PLACEHOLDER,
  INVOICE_RECIPIENTS,
  MIN_AUTOCOMPLETE_QUERY_LENGTH,
} from "../../../common/constants";
import { AthleteResponse } from "../api/AthleteResponse";
import { postSearchAthleteRequestNonPaged } from "../api/postSearchAthleteRequestNonPaged";

function athleteSearch(query: string) {
  if (query.length < MIN_AUTOCOMPLETE_QUERY_LENGTH) {
    return Promise.resolve([] as AthleteResponse[]);
  }

  const [firstName, lastName] = query.split(" ");
  let request =
    query.split(" ").length > 1
      ? { firstName: firstName, lastName: lastName }
      : { firstName: query, lastName: query };

  return postSearchAthleteRequestNonPaged(request);
}

function AthleteOptionLabel({ user }: any) {
  return (
    <div className="flex items-center justify-between flex-wrap">
      <div>
        {user.firstName} {user.lastName}
      </div>
      <div className="flex-shrink-0 text-sm leading-5 text-gray-500">
        {"Sportaš"}
      </div>
    </div>
  );
}

export default function SearchAthleteAutocompleteComponent() {
  return (
    <AutocompleteField
      name={INVOICE_RECIPIENTS}
      options={athleteSearch}
      placeholder={AUTOCOMPLETE_SEARCH_PLACEHOLDER}
      allowMultiple
      itemToString={(item) => `${item.firstName} ${item.lastName}`}
      getOptionLabel={(user) => <AthleteOptionLabel user={user} />}
      getOptionValue={(item) => item}
      getMultipleSelectedLabel={(items) => {
        return items
          .map((user: AthleteResponse) => `${user.firstName} ${user.lastName}`)
          .join(", ");
      }}
      tags={true}
      sendOptionValue={true}
    />
  );
}
