import React, { useContext, useEffect, useState } from "react";

import { AthleteDetailedResponse } from "../../archive/athlete/api/AthleteDetailedResponse";
import { getAthleteById } from "../../archive/athlete/api/getAthleteById";
import { CoachDetailedResponse } from "../../archive/coach/api/CoachDetailedResponse";
import { getCoachById } from "../../archive/coach/api/getCoachById";
import GroupEventsSelector from "../../archive/coach/components/GroupEventsSelector";
import { UserType } from "../../archive/common/api/UserResponse";
import { GroupResponse } from "../../archive/group/api/GroupResponse";
import { postSearchGroupRequestNonPaged } from "../../archive/group/api/postSearchGroupRequestNonPaged";
import { getParentById } from "../../archive/parent/api/getParentById";
import { ParentDetailedResponse } from "../../archive/parent/api/ParentDetailedResponse";
import { UserContext } from "../../auth/SecurityProvider";
import LoadingCircle from "../../common/components/LoadingCircle";
import AdminHeader from "../components/AdminHeader";
import GroupsList from "../components/GroupsList";
import HomeClubInfo from "../components/HomeClubInfo";
import HomePersonalEvents from "../components/HomePersonalEvents";
import ParentHeader from "../components/ParentHeader";

export default function Home() {
  const [groups, setGroups] = useState([] as GroupResponse[]);
  const [loaded, setLoaded] = useState(false);
  const [athlete, setAthlete] = useState({} as AthleteDetailedResponse);
  const [children, setChildren] = useState([] as AthleteDetailedResponse[]);
  const [entityCreated, setEntityCreated] = useState(0);

  const { user, userHasRole } = useContext(UserContext);

  useEffect(() => {
    if (userHasRole(UserType.ADMIN)) {
      postSearchGroupRequestNonPaged({
        groupName: "",
        briefResponse: false,
      }).then((response: any) => {
        setGroups(response);
        setLoaded(true);
      });
    } else if (userHasRole(UserType.COACH)) {
      getCoachById(user.id).then((response: CoachDetailedResponse) => {
        setGroups(response.groups);
        setLoaded(true);
      });
    } else if (userHasRole(UserType.PARENT)) {
      getParentById(user.id).then((response: ParentDetailedResponse) => {
        let parentChildren = response.children
          .sort((child1, child2) =>
            child1.firstName.localeCompare(child2.firstName)
          )
          .map((child) => {
            return child;
          });
        setChildren(parentChildren);
        setAthlete(parentChildren[0]);
        setGroups(parentChildren[0].groups);
        setLoaded(true);
      });
    } else if (userHasRole(UserType.ATHLETE)) {
      getAthleteById(user.id).then((response: AthleteDetailedResponse) => {
        setAthlete(response);
        setGroups(response.groups);
        setLoaded(true);
      });
    }
    // eslint-disable-next-line
  }, [user.id, user.userType]);

  const renderCondition = () => {
    if (userHasRole(UserType.ADMIN) || userHasRole(UserType.COACH)) {
      return (
        <>
          <AdminHeader entityCreated={entityCreated} />
          <GroupsList groupList={groups} />
        </>
      );
    } else if (userHasRole(UserType.ATHLETE)) {
      return (
        <ParentHeader
          currentUser={user}
          selectedAthlete={athlete}
          setSelectedAthlete={setAthlete}
          groups={groups}
          setGroups={setGroups}
        />
      );
    } else if (userHasRole(UserType.PARENT)) {
      return (
        <ParentHeader
          currentUser={user}
          selectedAthlete={athlete}
          setSelectedAthlete={setAthlete}
          children={children}
          groups={groups}
          setGroups={setGroups}
        />
      );
    }
  };

  return (
    <LoadingCircle isLoading={!loaded}>
      <div className="flex flex-col xl:flex-row xl:gap-x-4 2xl:gap-x-8 px-4 sm:px-0 md:my-6">
        <div className="flex flex-col gap-y-4 w-full xl:w-4/5 ">
          {renderCondition()}
          <GroupEventsSelector groupList={groups} />
          <HomePersonalEvents userId={user.id} />
        </div>
        <HomeClubInfo
          currentUser={user}
          setEntityCreated={setEntityCreated}
          entityCreated={entityCreated}
        />
      </div>
    </LoadingCircle>
  );
}
