import { EulaRequest } from "./EulaRequest";
import { CURRENT_USER_URL } from "../../common/Routes";

export async function postEulaAcceptionFlag(eulaRequest: EulaRequest) {
  return await fetch(CURRENT_USER_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(eulaRequest),
  });
}
