import React from "react";

import { Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import Ruler from "../../../../assets/img/ruler.png";
import PersonalRecordWrapperCard from "../../../../common/components/cards/PersonalRecordWrapperCard";
import { MeasuringUnit } from "../../../../common/constants";
import { ProgressTypeResponse } from "../../api/ProgressTypeResponse";

type ProgressTypeCardProps = {
  progressType: ProgressTypeResponse;
  topResult?: string | undefined;
};

export default function ProgressTypeCard({
  progressType,
  topResult,
}: ProgressTypeCardProps) {
  const renderIconAndMeasuringUnit = () => {
    switch (progressType.measuringUnit) {
      case MeasuringUnit.Centimeter:
        return (
          <div className="w-full flex items-center gap-4">
            <div className="flex justify-center items-center bg-purple-100 rounded-full w-12 h-12">
              <Typography element="p" variant="h5" className="text-purple-600">
                <img src={Ruler} alt="Ravnalo ikona" />
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="subtext" className="text-gray-700">
                {progressType.name}
              </Typography>
              <Typography
                element="p"
                variant="subtext"
                className="text-purple-600 font-bold"
              >
                Iznos: {topResult ? topResult : `cm`}
              </Typography>
            </div>
          </div>
        );
      case MeasuringUnit.Kilogram:
        return (
          <div className="w-full flex items-center gap-4">
            <div className="flex justify-center items-center bg-sky-100 rounded-full w-12 h-12">
              <Typography element="p" variant="h5">
                <Icon
                  type="scales"
                  variant="bold"
                  size={7}
                  className="text-sky-600 flex"
                />
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="subtext" className="text-gray-700">
                {progressType.name}
              </Typography>
              <Typography
                element="p"
                variant="subtext"
                className="text-sky-600 font-bold"
              >
                Iznos: {topResult ? topResult : `kg`}
              </Typography>
            </div>
          </div>
        );
      case MeasuringUnit.Second:
        return (
          <div className="w-full flex items-center gap-4">
            <div className="flex justify-center items-center bg-teal-100 rounded-full w-12 h-12">
              <Typography element="p" variant="h5">
                <Icon type="clock" size={7} className="text-teal-600 flex" />
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="subtext" className="text-gray-700">
                {progressType.name}
              </Typography>
              <Typography
                element="p"
                variant="subtext"
                className="text-teal-600 font-bold"
              >
                Vrijeme: {topResult ? topResult : `mm:ss.cs`}
              </Typography>
            </div>
          </div>
        );
      case MeasuringUnit.Repetition:
        return (
          <div className="w-full flex items-center gap-4">
            <div className="flex justify-center items-center bg-rose-100 rounded-full w-12 h-12">
              <Typography element="p" variant="h5">
                <Icon
                  type="hash"
                  variant="bold"
                  size={7}
                  className="text-rose-600 flex"
                />
              </Typography>
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="subtext" className="text-gray-700">
                {progressType.name}
              </Typography>
              <Typography
                element="p"
                variant="subtext"
                className="text-rose-600 font-bold"
              >
                {topResult
                  ? `Broj ponavljanja: ${topResult}`
                  : `Broj ponavljanja`}
              </Typography>
            </div>
          </div>
        );
    }
  };
  return (
    <PersonalRecordWrapperCard>
      <div className="flex flex-col items-start justify-center h-full">
        {renderIconAndMeasuringUnit()}
      </div>
    </PersonalRecordWrapperCard>
  );
}
