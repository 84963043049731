import { CreateParentRequest } from "./CreateParentRequest";
import { PARENT_URL } from "../../../common/Routes";

export async function postCreateParentRequest(
  request: CreateParentRequest
): Promise<Response> {
  return await fetch(`${PARENT_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
