import { AthleteGroupReportsRequest } from "./AllAthleteGroupsReportRequest";
import { REPORTS_STATE_GROUPS_URL } from "../../common/Routes";

export async function getAllAthleteGroupReports(
  request: AthleteGroupReportsRequest
) {
  const response = await fetch(`${REPORTS_STATE_GROUPS_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(request),
  });

  return await response.json();
}
