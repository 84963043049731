import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { IconButton, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useParams } from "react-router-dom";

import DeleteMessageFromDetailsModal from "./DeleteMessageFromDetailsModal";
import { UserResponse } from "../../archive/common/api/UserResponse";
import { UserContext } from "../../auth/SecurityProvider";
import HorizontalLine from "../../common/components/HorizontalLine";
import LoadingCircle from "../../common/components/LoadingCircle";
import MessageImportantNotice from "../../common/components/MessageImportantNotice";
import Monki from "../../common/components/Monki";
import { getMessageById } from "../api/getMessageById";
import { getMessageRecipientsById } from "../api/getMessageRecipients";
import { MessageResponse } from "../api/MessageResponse";
import { postMarkAsPinned } from "../api/postMarkAsPinned";
import { postMarkAsUnpinned } from "../api/postMarkAsUnpinned";

export default function MessageDetails() {
  const { user } = React.useContext(UserContext);
  const [message, setMessage] = useState({} as MessageResponse);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const modal = useModal();

  const [recipients, setRecipients] = useState([] as UserResponse[]);

  function onMessagePin() {
    message.pinned
      ? postMarkAsUnpinned({
          messageIds: [message.id],
        }).then(() => setMessage({ ...message, pinned: !message.pinned }))
      : postMarkAsPinned({
          messageIds: [message.id],
        }).then(() => setMessage({ ...message, pinned: !message.pinned }));
  }

  useEffect(() => {
    if (id) {
      getMessageById(id)
        .then((messageResponse) => {
          setIsLoading(true);
          setMessage(messageResponse);
        })
        .then(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getMessageRecipientsById(id).then((response) => {
        setRecipients(response);
      });
    }
  }, [id]);

  return (
    <LoadingCircle isLoading={isLoading}>
      <div className="flex-col py-4 px-2 md:px-6 bg-white rounded-lg">
        <div className="flex pb-1.5 md:pb-2">
          <div className="flex items-start">
            <Typography className="mt-0.5" element="p" variant="text">
              Predmet:&nbsp;
            </Typography>
            <Typography element="h6" variant="h6">
              {message.isImportant && <MessageImportantNotice />}
              {message.title}
            </Typography>
          </div>
          <div className="ml-auto">
            <IconButton
              showTooltip={false}
              onClick={modal.onOpen}
              icon={
                <Icon type="trash" variant="fill" className="text-gray-500" />
              }
              className="border-0 h-full self-center mr-6"
            />
            <IconButton
              showTooltip={false}
              onClick={() => onMessagePin()}
              icon={
                <Icon
                  type="star"
                  variant={message.pinned ? "fill" : "regular"}
                  className={
                    message.pinned ? "text-yellow-500" : "text-gray-500"
                  }
                />
              }
              className="border-0 h-full self-center"
            />
          </div>
        </div>
        <HorizontalLine />
        {message.senderId === user.id ? (
          <div className="flex items-start pt-6 pb-5">
            <Typography className="mt-0.5" element="p" variant="text">
              Prima:&nbsp;
            </Typography>
            <Typography element="h6" variant="h6">
              <div className="flex flex-wrap">
                {recipients.map((recipient, index) => {
                  if (index === recipients.length - 1) {
                    return (
                      <Typography element="h6" variant="h6">
                        {`${recipient.firstName} ${recipient.lastName}`}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography element="h6" variant="h6">
                        {`${recipient.firstName} ${recipient.lastName},`}&nbsp;
                      </Typography>
                    );
                  }
                })}
              </div>
            </Typography>
            <div className="ml-auto">
              <Typography
                className="whitespace-nowrap ml-2"
                element="p"
                variant="subtitle"
              >
                {new Date(message.timeSent).toLocaleDateString("hr-HR")}
              </Typography>
            </div>
          </div>
        ) : (
          <div className="flex items-start pt-6 pb-5">
            <Typography className="mt-0.5" element="p" variant="text">
              Pošiljatelj:&nbsp;
            </Typography>
            <Typography element="h6" variant="h6">
              {`${message.senderFirstName} ${message.senderLastName}`}
            </Typography>
            <div className="ml-auto">
              <Typography
                className="whitespace-nowrap ml-2"
                element="p"
                variant="subtitle"
              >
                {new Date(message.timeSent).toLocaleDateString("hr-HR")}
              </Typography>
            </div>
          </div>
        )}
        <HorizontalLine />
        <div className="md:pl-4 pt-6 whitespace-pre-line">
          {message.isGeneric ? (
            <div dangerouslySetInnerHTML={{ __html: message.description }} />
          ) : (
            message.description
          )}
          {message.title?.toLowerCase().includes("monki") && (
            <div className="flex flex-col justify-center align-middle">
              <Monki />
            </div>
          )}
        </div>
        <DeleteMessageFromDetailsModal
          modal={modal}
          messageIds={[message.id]}
        />
      </div>
    </LoadingCircle>
  );
}
