import React from "react";

import { Button, Typography } from "@tiller-ds/core";

import { useParams, useNavigate } from "react-router-dom";

import { ReactComponent as HAVKLogoVertical } from "../../assets/img/HAVK_vertical.svg";

export default function ErrorPage() {
  const { status } = useParams();

  const navigate = useNavigate();

  let description;
  switch (status) {
    case "401":
    case "403":
      description = "Nedopušten pristup.";
      break;
    case "404":
      description = "Stranica kojoj ste pokušali pristupiti nije pronađena.";
      break;
    default:
      description = "Nešto je pošlo po krivu! Pokušajte ponovno kasnije.";
      break;
  }

  return (
    <div className="h-screen flex flex-col justify-center align-middle">
      <div className="flex flex-col">
        <div className="m-auto pb-6">
          <HAVKLogoVertical />
        </div>
        <div className="text-center flex flex-col justify-center space-y-2">
          <div className="flex flex-col space-y-3 pb-5 w-4/5 md:w-2/5 lg:w-1/3 ml-auto mr-auto">
            <Typography className="font-extrabold">{`Greška: ${status}`}</Typography>
            <Typography className="text-gray-500">{description}</Typography>
          </div>

          <div>
            <Button onClick={() => navigate("/home")} className="w-60">
              Početna stranica
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
