import FileDownload from "js-file-download";

import { ExportInvoiceRequest } from "./ExportInvoiceRequest";
import { INVOICE_URL } from "../../../common/Routes";

export function exportInvoiceExcel(request: ExportInvoiceRequest) {
  fetch(`${INVOICE_URL}/export/pdf/${request.invoiceId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.blob())
    .then((blob) => {
      FileDownload(
        blob,
        `${request.athleteFullName}_${request.creationDate}.pdf`
      );
    });
}
