import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button } from "@tiller-ds/core";

import { useNavigate } from "react-router-dom";

import { postMarkAsDeleted } from "../api/postMarkAsDeleted";

type DeleteMessageFromDetailsModalProps = {
  modal: UseModal;
  messageIds: number[];
};

export default function DeleteMessageFromDetailsModal({
  modal,
  messageIds,
}: DeleteMessageFromDetailsModalProps) {
  const navigate = useNavigate();

  const onMarkDelete = () => {
    postMarkAsDeleted({ messageIds }).then(() => {
      navigate("/messages");
    });
    modal.onClose();
  };

  return (
    <Modal {...modal}>
      <Modal.Content title="Brisanje">Želite li obrisati poruku?</Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" onClick={modal.onClose}>
          Odustani
        </Button>
        <Button variant="filled" onClick={onMarkDelete}>
          Izbriši
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
