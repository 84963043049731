import { PARENT_URL } from "../../../common/Routes";

export async function getParentById(parentId?: number) {
  const response = await fetch(`${PARENT_URL}/${parentId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
