import * as React from "react";

import { Input, InputProps } from "@tiller-ds/form-elements";

import { useField, useFormikContext } from "formik";

type CustomInputOnlyPropsUnion = "value" | "onChange" | "onBlur" | "error";

export type CustomInputFieldProps = {
  /**
   * The accessor value for the input field component (for validation, fetching, etc.).
   */
  name: string;

  /**
   * Determines whether a clear button (X) is shown in the field.
   */
  allowClear?: boolean;

  /**
   * Determines whether the components auto trim whitespace after typing
   */
  autoTrim?: boolean;
} & Omit<InputProps, CustomInputOnlyPropsUnion>;

export default function CustomInputField({
  name,
  allowClear,
  autoTrim = true,
  ...props
}: CustomInputFieldProps) {
  /* eslint-disable */
  const [field, meta, helpers] = useField(name);
  const formik = useFormikContext();
  /* eslint-enable */

  const onReset = () => {
    helpers.setValue("");
  };

  const onBlur = () => {
    helpers.setValue(field.value.trim());
  };

  return (
    <Input
      name={field.name}
      value={field.value ?? ""}
      error={false}
      onChange={field.onChange}
      onReset={allowClear ? onReset : undefined}
      onBlur={(e) => {
        field.onBlur(e);
        if (autoTrim) {
          onBlur();
        }
      }}
      {...props}
    />
  );
}
