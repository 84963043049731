import { USER_URL } from "../../common/Routes";

export function deleteSelf(id: number) {
  return fetch(`${USER_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }).then((res) => {
    return res;
  });
}
