import { AthleteAttendanceReportsRequest } from "./AthleteAttendanceReportsRequest";
import { REPORTS_STATE_ATTENDANCES_URL } from "../../common/Routes";

export async function getAthleteAttendanceReports(
  request: AthleteAttendanceReportsRequest
) {
  const response = await fetch(`${REPORTS_STATE_ATTENDANCES_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
