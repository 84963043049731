import React, { useEffect, useState } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Tabs, Typography } from "@tiller-ds/core";

import AnnounceAbsenceForm from "./AnnounceAbsenceForm";
import CancelAbsenceForm from "./CancelAbsenceForm";
import { getParentById } from "../../archive/parent/api/getParentById";
import { ParentDetailedResponse } from "../../archive/parent/api/ParentDetailedResponse";

type ReportAbsenceModalProps = {
  parentId: number;
  modal: UseModal;
};

const ReportAbsenceModal = ({ modal, parentId }: ReportAbsenceModalProps) => {
  const [parent, setParent] = useState({} as ParentDetailedResponse);

  useEffect(() => {
    getParentById(parentId).then((response: ParentDetailedResponse) => {
      setParent(response);
    });
  }, [parentId]);

  return (
    <>
      <Modal {...modal}>
        <div className="flex mb-6 mt-4 w-full text-lg items-center">
          <Typography element="h3" variant="h4">
            Evidencija odsutnosti
          </Typography>
        </div>
        <div className="mr-4 mb-4">
          <Tabs defaultIndex={0}>
            <Tabs.Tab label={"Najava odsutnosti"}>
              <AnnounceAbsenceForm modal={modal} parent={parent} />
            </Tabs.Tab>
            <Tabs.Tab label={"Najavljene odsutnosti"}>
              <CancelAbsenceForm modal={modal} parent={parent} />
            </Tabs.Tab>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

export default ReportAbsenceModal;
