import React, { useContext, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { UserContext } from "../../../auth/SecurityProvider";
import CreateGroupModal from "../../group/components/CreateGroupModal";
import { UserType } from "../api/UserResponse";
import CreateUserModal from "../components/CreateUserModal";
import TabSelector from "../components/TabSelector";

export default function Archive() {
  const [entityCreated, setEntityCreated] = useState(0);
  const { userHasRole } = useContext(UserContext);

  const createUser = useModal();

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6 gap-y-6">
      <div className="flex flex-col gap-y-2 sm:gap-y-0 sm:flex-row justify-between font-bold">
        <Typography
          element="h3"
          variant="h3"
          className="hidden md:inline font-biotiff"
        >
          Registri
        </Typography>
        <Typography
          element="h6"
          variant="h6"
          className="md:hidden font-biotiff"
        >
          Registri
        </Typography>
        {userHasRole(UserType.ADMIN) && (
          <div className="flex justify-end">
            <CreateGroupModal
              entityCreated={entityCreated}
              setEntityCreated={setEntityCreated}
            />
            <Button
              leadingIcon={<Icon type="user" variant="fill" size={4} />}
              variant="outlined"
              color="primary"
              size="sm"
              onClick={createUser.onOpen}
            >
              <p className="text-sm">Kreiraj korisnika</p>
            </Button>
          </div>
        )}
      </div>
      <TabSelector entityCreated={entityCreated} />
      <CreateUserModal
        entityCreated={entityCreated}
        setEntityCreated={setEntityCreated}
        modal={createUser}
      />
    </div>
  );
}
