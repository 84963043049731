import { ProgressTypeRequest } from "./ProgressTypeRequest";
import { PROGRESS_URL } from "../../../common/Routes";

export async function postProgressTypeRequest(request: ProgressTypeRequest) {
  const response = await fetch(`${PROGRESS_URL}/type`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
