import React, { useEffect, useState } from "react";

import { Card, Link, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { AthleteDetailedResponse } from "../../archive/athlete/api/AthleteDetailedResponse";
import { CoachResponse } from "../../archive/coach/api/CoachResponse";
import { UserResponse } from "../../archive/common/api/UserResponse";
import UserIcon from "../../archive/common/components/UserIcon";
import { GroupResponse } from "../../archive/group/api/GroupResponse";
import GroupBadge from "../../archive/group/components/GroupBadge";
import AthletePersonalRecords from "../../common/components/cards/AthletePersonalRecords";
import CoachListModal from "../../common/components/modals/CoachListModal";
import { UserType } from "../../common/constants";

type ParentHeaderProps = {
  currentUser: UserResponse;
  children?: AthleteDetailedResponse[];
  selectedAthlete: AthleteDetailedResponse;
  setSelectedAthlete: (selectedAthlete: AthleteDetailedResponse) => void;
  groups: GroupResponse[];
  setGroups: (groups: GroupResponse[]) => void;
};

export default function ParentHeader({
  currentUser,
  children,
  selectedAthlete,
  setSelectedAthlete,
  groups,
  setGroups,
}: ParentHeaderProps) {
  const [coachList, setCoachList] = useState([] as CoachResponse[]);

  useEffect(() => {
    setGroups(
      selectedAthlete.groups
        .sort((group1, group2) =>
          group1.groupName.localeCompare(group2.groupName)
        )
        .map((group) => {
          return group;
        })
    );

    let coachArray = [] as CoachResponse[];
    selectedAthlete.groups.forEach((group) => {
      group.coaches.forEach((coach) => {
        coachArray.push(coach);
      });
    });

    const uniqueIds = [] as number[];

    const uniqueCoaches = coachArray
      .filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);
        if (!isDuplicate) {
          uniqueIds.push(element.id);
          return true;
        }
        return false;
      })
      .sort((coach1, coach2) => coach1.lastName.localeCompare(coach2.lastName));

    setCoachList(uniqueCoaches);
    //eslint-disable-next-line
  }, [selectedAthlete]);

  return (
    <div className="w-full mb-8">
      <div className="flex justify-between items-center mb-4">
        <Typography
          element="h3"
          variant="h3"
          className="hidden md:inline font-biotiff"
        >
          Osnovne informacije
        </Typography>
        <Typography
          element="h6"
          variant="h6"
          className="md:hidden font-biotiff"
        >
          Osnovne informacije
        </Typography>
        <div>
          {currentUser.userType === UserType.PARENT && (
            <DropdownMenu
              title={
                children
                  ? `${selectedAthlete.firstName} ${selectedAthlete.lastName}`
                  : "Izaberite sportaša"
              }
              variant="filled"
              color="primary"
              openExpanderIcon={<Icon type="caret-down" variant="bold" />}
              closeExpanderIcon={<Icon type="caret-down" variant="bold" />}
            >
              {children &&
                children.map((athlete) => {
                  return (
                    <DropdownMenu.Item
                      onSelect={() => {
                        setSelectedAthlete(athlete);
                      }}
                    >
                      {`${athlete.firstName} ${athlete.lastName}`}
                    </DropdownMenu.Item>
                  );
                })}
            </DropdownMenu>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 xl:flex-row">
        <div className="w-full h-auto mb-8 lg:mb-0 xl:w-1/3">
          <Card className="h-full w-full">
            <Card.Body>
              <div className="flex flex-col w-full gap-4">
                <div className="flex gap-2 items-center">
                  <div className="flex">
                    <UserIcon
                      initials={
                        selectedAthlete.firstName.charAt(0).toUpperCase() +
                        selectedAthlete.lastName.charAt(0).toUpperCase()
                      }
                      entityId={selectedAthlete.id}
                      width="80px"
                      height="80px"
                      textClassName="text-2xl sm:text-4xl"
                    />
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    <Link to={`/archive/athletes/${selectedAthlete.id}`}>
                      <Typography element="h5" variant="h5">
                        {`${selectedAthlete.firstName} ${selectedAthlete.lastName}`}
                      </Typography>
                    </Link>
                    <div className="flex flex-wrap justify-start gap-2 w-full">
                      {groups.map((group) => {
                        return (
                          <GroupBadge
                            groupName={group.groupName}
                            groupId={group.id}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between items-start gap-4">
                  <div className="flex w-full justify-between items-center">
                    <Typography
                      variant="subtext"
                      className="flex w-full text-gray-500 items-start border-b"
                    >
                      PODACI O TRENERIMA
                    </Typography>
                    {coachList.length > 2 && (
                      <div className="ml-4" title="Pogledaj sve trenere">
                        <CoachListModal
                          coachList={coachList}
                          athlete={selectedAthlete}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full gap-1">
                    {coachList.slice(0, 2).map((coach) => {
                      return (
                        <div className="grid grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 gap-2 border-b">
                          <div className="flex flex-col items-start">
                            <Typography className="text-md">Trener</Typography>
                            <Typography className="text-md font-bold">
                              {coach.firstName + " " + coach.lastName}
                            </Typography>
                          </div>
                          <div className="flex flex-col items-start">
                            <Typography className="text-md">
                              Broj telefona
                            </Typography>
                            <Typography className="text-md font-bold">
                              {coach.phoneNumber}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="w-full xl:w-2/3">
          <AthletePersonalRecords
            athlete={selectedAthlete}
            progressTypeList={null}
          />
        </div>
      </div>
    </div>
  );
}
