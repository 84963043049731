import { EVENT_ATTENDANCES_URL } from "../Routes";

export async function getAttendanceRecord(eventId: number) {
  const response = await fetch(`${EVENT_ATTENDANCES_URL}/events/${eventId}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
