import { GroupResponse } from "./GroupResponse";
import { GroupSearchRequest } from "./GroupSearchRequest";
import { GROUP_URL } from "../../../common/Routes";

export async function postSearchGroupRequestNonPaged(
  request: GroupSearchRequest
): Promise<GroupResponse[]> {
  const response = await fetch(`${GROUP_URL}/search`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
