import React from "react";

import { Modal, useModal } from "@tiller-ds/alert";
import { Button, IconButton } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { AthleteNoteResponse } from "../../api/AthleteNoteResponse";
import { deleteAthleteNote } from "../api/deleteAthleteNote";

type DeleteAthleteNoteModalProps = {
  noteId: number;
  setNotes: (notes: AthleteNoteResponse[]) => void;
  notes: AthleteNoteResponse[];
};

const RemoveAthleteFromGroupModal = ({
  noteId,
  setNotes,
  notes,
}: DeleteAthleteNoteModalProps) => {
  const onDeleteAthleteNote = () => {
    deleteAthleteNote(noteId).then(() => {});
    setNotes(notes.filter((note: AthleteNoteResponse) => note.id !== noteId));
    modal.onClose();
  };

  const modal = useModal();

  return (
    <>
      <IconButton
        icon={<Icon type="trash" variant="bold" className="text-gray-500" />}
        label="Izbriši"
        onClick={() => {
          modal.onOpen(null);
        }}
      />
      <Modal {...modal}>
        <Modal.Content title="Izbriši bilješku">
          Želite li izbrisati bilješku?
        </Modal.Content>
        <Modal.Footer>
          <Button variant="outlined" onClick={modal.onClose}>
            Odustani
          </Button>
          <Button variant="filled" onClick={onDeleteAthleteNote}>
            Izbriši
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveAthleteFromGroupModal;
