import { ClubInfoRequest } from "./ClubInfoRequest";
import { CLUB_INFO_URL } from "../../common/Routes";

export async function postClubInfo(request: ClubInfoRequest) {
  const response = await fetch(CLUB_INFO_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
