import React from "react";

import { Navigate, Outlet } from "react-router-dom";

import { UserContext } from "./SecurityProvider";
import { UserType } from "../archive/common/api/UserResponse";

type ProtectedRouteProps = {
  rolesAllowed?: UserType[];
};

export default function RouteAuthWrapper({
  rolesAllowed,
}: ProtectedRouteProps) {
  const { user } = React.useContext(UserContext);
  let authorized = !rolesAllowed || rolesAllowed.includes(user.userType);

  return authorized ? <Outlet /> : <Navigate to={"/home"} replace />;
}
