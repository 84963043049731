import React, { useContext } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button } from "@tiller-ds/core";

import { UserContext } from "../../auth/SecurityProvider";
import { MESSAGES_PER_PAGE } from "../../common/constants";
import { Message } from "../api/Message";
import { postMarkAsDeleted } from "../api/postMarkAsDeleted";
import { MessageContext } from "../MessageProvider";
type DeleteMessageFromDetailsModalProps = {
  modal: UseModal;
  messageIds: number[];
  messages: Message[];
  onChangeMessages: (messages: Message[]) => void;
};

export default function DeleteMessageFromDetailsModal({
  modal,
  messageIds,
  messages,
  onChangeMessages,
}: DeleteMessageFromDetailsModalProps) {
  const { user, onChangeNumOfUnreadMessages } = useContext(UserContext);
  const {
    page,
    totalElements,
    messageFilterRequest,
    setPage,
    setMessageFilterRequest,
  } = useContext(MessageContext);

  function onMarkDelete() {
    const deletedUnreadMessagesNum = messages.filter((message) => {
      return messageIds.includes(message.id) && !message.isRead;
    }).length;

    postMarkAsDeleted({ messageIds }).then(() => {
      let newPage = page;
      if (
        page > 0 &&
        totalElements - messageIds.length <= page * MESSAGES_PER_PAGE
      ) {
        newPage = (totalElements - messageIds.length) / MESSAGES_PER_PAGE - 1;
      }
      onChangeNumOfUnreadMessages(
        user.numOfUnreadMessages - deletedUnreadMessagesNum
      );
      setMessageFilterRequest({
        ...messageFilterRequest,
        pageNumber: newPage,
      });
      if (newPage !== page) {
        setPage(newPage);
      }
      onChangeMessages(messages.filter((message) => !message.isChecked));
    });
    modal.onClose();
  }

  return (
    <Modal {...modal}>
      <Modal.Content title="Brisanje">
        Želite li obrisati označene poruke?
      </Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" onClick={modal.onClose}>
          Odustani
        </Button>
        <Button variant="filled" onClick={onMarkDelete}>
          Izbriši
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
