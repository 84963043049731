import FileDownload from "js-file-download";

import { TrendReportsRequest } from "./TrendReportsRequest";
import { REPORTS_TREND_URL } from "../../common/Routes";

export async function exportTrendReportsExcel(request: TrendReportsRequest) {
  fetch(`${REPORTS_TREND_URL}/export/excel`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  })
    .then((response) => response.blob())
    .then((blob) => {
      FileDownload(blob, `izvještaj_trenda_${request.year}.xlsx`);
    });
}
