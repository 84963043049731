import React from "react";

import { Tabs } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import DraftSurveyList from "./DraftSurveyList";
import RecentSurveyList from "./RecentSurveyList";

type SurveySelectorProps = {
  tabIndex: number;
};

export default function SurveySelector({ tabIndex }: SurveySelectorProps) {
  return (
    <div className="w-full">
      <Tabs defaultIndex={tabIndex}>
        <Tabs.Tab label={`Nedavne ankete (5)`} icon={<Icon type="file-text" />}>
          <RecentSurveyList />
        </Tabs.Tab>
        <Tabs.Tab label={`Skice (3)`} icon={<Icon type="bookmark-simple" />}>
          <DraftSurveyList />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}
