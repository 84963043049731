import React, { useContext } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { UserType } from "../../archive/common/api/UserResponse";
import handleDeleteResponse from "../../archive/common/handleDeleteResponse";
import { deleteSelf } from "../../auth/api/deleteSelf";
import { logout } from "../../auth/api/logout";
import { UserContext } from "../../auth/SecurityProvider";
import DeleteModal from "../../common/components/modals/DeleteModal";
import AccountSettingsCard from "../components/cards/AccountSettingsCard";
import AdminProfile from "../components/cards/AdminProfile";
import AthleteProfile from "../components/cards/AthleteProfile";
import CoachProfile from "../components/cards/CoachProfile";
import ParentProfile from "../components/cards/ParentProfile";

export default function UserProfile() {
  const { user } = React.useContext(UserContext);

  const { userHasRole } = useContext(UserContext);

  const { add: addNotification } = useNotifications();

  const userTypeProfileCard = () => {
    switch (user.userType) {
      case "ATHLETE":
        return <AthleteProfile id={user.id} />;
      case "ADMIN":
        return <AdminProfile />;
      case "COACH":
        return <CoachProfile id={user.id} />;
      case "PARENT":
        return <ParentProfile id={user.id} />;
      default:
        return null;
    }
  };

  const deleteUserModal = useModal();

  const deleteUserModalFunction = () => {
    deleteSelf(user.id).then((res) => {
      if (res.ok) {
        handleDeleteResponse(res, addNotification);
        deleteUserModal.onClose();
        logout();
      }
    });
  };

  return (
    <div className="flex flex-col px-4 md:px-0 md:my-6">
      <div className="flex flex-col xl:w-1/2 lg:w-3/4 md:w-full">
        <div className="flex flex-col gap-y-6">
          <Typography
            element="h3"
            variant="h3"
            className="hidden md:inline font-biotiff"
          >
            Korisnički račun
          </Typography>
          <Typography
            element="h6"
            variant="h6"
            className="md:hidden font-biotiff"
          >
            Korisnički račun
          </Typography>
          <div>{userTypeProfileCard()}</div>
          <Typography
            element="h3"
            variant="h3"
            className="hidden md:inline font-biotiff"
          >
            Postavke korisničkog računa
          </Typography>
          <Typography
            element="h6"
            variant="h6"
            className="md:hidden font-biotiff"
          >
            Postavke korisničkog računa
          </Typography>
          <div>
            <AccountSettingsCard />
          </div>
          <div>
            <div className="flex justify-end md:justify-between">
              {userHasRole(UserType.PARENT) && (
                <>
                  <div className="flex justify-between items-center">
                    <Button
                      className="w-fit mr-1"
                      variant="outlined"
                      size="sm"
                      color="primary"
                      leadingIcon={<Icon type="trash" variant="bold" />}
                      onClick={deleteUserModal.onOpen}
                    >
                      <div className="pt-0 hidden md:flex">
                        Izbriši korisnički račun
                      </div>
                      <div className="flex md:hidden">Izbriši</div>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <DeleteModal
            modal={deleteUserModal}
            title="Brisanje računa"
            content="Jeste li sigurni da želite izbrisati korisnički račun?"
            buttonConfirmText="Izbriši"
            buttonConfirmFunction={deleteUserModalFunction}
          />
        </div>
      </div>
    </div>
  );
}
