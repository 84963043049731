import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import { UserResponse, UserType } from "../../archive/common/api/UserResponse";
import CreateUserModal from "../../archive/common/components/CreateUserModal";
import { UserContext } from "../../auth/SecurityProvider";
import CardWithPicture from "../../common/components/cards/CardWithPicture";
import AttendanceRecordModal from "../../common/components/modals/AttendanceRecordModal";
import { EventType, NUM_OF_HOURS_IN_DAY } from "../../common/constants";
import { EventResponse } from "../../event/api/EventResponse";
import { FilterEventsRequest } from "../../event/api/FilterEventsRequest";
import { postFilterEvents } from "../../event/api/PostFilterEvents";
import ReportAbsenceModal from "../../event/components/ReportAbsenceModal";
import { ClubInfoResponse } from "../api/ClubInfoResponse";
import { ClubManagementResponse } from "../api/ClubManagementResponse";
import { getClubInfo } from "../api/getClubInfo";
import { getClubManagement } from "../api/getClubManagement";

type HomeClubInfoProps = {
  currentUser: UserResponse;
  entityCreated: number;
  setEntityCreated: (entityCreated: number) => void;
};

export default function HomeClubInfo({
  currentUser,
  entityCreated,
  setEntityCreated,
}: HomeClubInfoProps) {
  const [clubManagementData, setClubManagementData] = useState(
    {} as ClubManagementResponse
  );
  const [basicClubData, setBasicClubData] = useState({} as ClubInfoResponse);

  const navigate = useNavigate();

  const [nearestTrainingEvent, setNearestTrainingEvent] = useState(
    [] as EventResponse[]
  );
  const { userHasRole } = useContext(UserContext);

  useEffect(() => {
    getClubManagement().then((managementData) => {
      setClubManagementData(managementData);
    });
  }, []);

  useEffect(() => {
    getClubInfo().then((clubInfo) => {
      setBasicClubData(clubInfo);
    });
  }, []);

  function truncateDate(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }

  useEffect(() => {
    //get events only for current date
    const currentDate = new Date();
    truncateDate(currentDate);
    const endDate = new Date();
    truncateDate(endDate);
    endDate.setHours(endDate.getHours() + NUM_OF_HOURS_IN_DAY);
    postFilterEvents({
      startTimeTo: endDate.toISOString(),
      endTimeFromIncluding: currentDate.toISOString(),
      eventType: [EventType.TRAINING],
      participantsId: currentUser.id,
    } as FilterEventsRequest).then((response: EventResponse[]) => {
      setNearestTrainingEvent(response);
    });
  }, [currentUser.id]);

  const createEvent = () => {
    navigate("/home/event/new");
  };

  const editClubInfo = () => {
    navigate("/home/edit-club-info");
  };

  const editProgressTypes = () => {
    navigate("/progress-types");
  };

  const createUser = useModal();
  const attendanceRecord = useModal();
  const reportAbsence = useModal();

  return (
    <div
      className="
        flex
        flex-col
        lg:flex-row
        xl:flex-col
        justify-stretch

        gap-x-4
        xl:gap-x-0
        xl:gap-y-4

        pt-4
        xl:pt-0
        xl:pl-4
        xl:mt-4

        w-full
        xl:w-1/5

        border-t-2
        xl:border-t-0
        xl:border-l-2
        border-navy-100"
    >
      {userHasRole(UserType.ADMIN) && (
        <div className="w-full lg:w-1/3 xl:w-full pb-4 mb-4 xl:border-navy-200 xl:border-b-2">
          <Typography
            element="h3"
            variant="h6"
            className="mb-2 text-navy-600 font-biotif"
          >
            Kontrolna ploča
          </Typography>
          <div className="flex flex-col">
            <div className="flex flex-col items-stretch gap-y-4">
              <Button
                leadingIcon={<Icon type="note-pencil" variant="bold" />}
                variant="outlined"
                color="primary"
                className="text-600"
                size="md"
                onClick={createEvent}
              >
                Kreiraj događaj
              </Button>
              <Button
                leadingIcon={<Icon type="user" variant="fill" />}
                variant="outlined"
                color="primary"
                size="md"
                onClick={createUser.onOpen}
              >
                <p className="text-sm ">Kreiraj korisnika</p>
              </Button>
              <Button
                leadingIcon={<Icon type="chart-pie" variant="fill" />}
                variant="outlined"
                color="primary"
                size="md"
                onClick={editProgressTypes}
              >
                Uredi statistiku
              </Button>
              <Button
                leadingIcon={<Icon type="bookmark-simple" variant="fill" />}
                variant="outlined"
                color="primary"
                size="md"
                onClick={editClubInfo}
              >
                Uredi podatke
              </Button>
            </div>
          </div>
        </div>
      )}
      {userHasRole(UserType.COACH) && (
        <div className="w-full lg:w-1/3 xl:w-full pb-4 mb-4 xl:border-navy-200 xl:border-b-2">
          <Typography
            element="h3"
            variant="h6"
            className="mb-2 text-navy-600 font-biotif"
          >
            Kontrolna ploča
          </Typography>
          <div className="flex flex-col">
            <Button
              leadingIcon={<Icon type="users" variant="fill" />}
              id="open-button"
              size="sm"
              variant="outlined"
              onClick={attendanceRecord.onOpen}
              className="mr-0"
            >
              <div className="block">Evidencija dolazaka</div>
            </Button>
          </div>
          <AttendanceRecordModal
            events={nearestTrainingEvent}
            modal={attendanceRecord}
          />
        </div>
      )}
      {userHasRole(UserType.PARENT) && (
        <div className="w-full lg:w-1/3 xl:w-full pb-4 mb-4 xl:border-navy-200 xl:border-b-2">
          <Typography
            element="h3"
            variant="h6"
            className="mb-2 text-navy-600 font-biotif"
          >
            Kontrolna ploča
          </Typography>
          <div className="flex flex-col">
            <Button
              leadingIcon={<Icon type="user-minus" variant="fill" />}
              variant="outlined"
              color="primary"
              size="sm"
              onClick={reportAbsence.onOpen}
            >
              Odsutnost sportaša
            </Button>
            <ReportAbsenceModal
              modal={reportAbsence}
              parentId={currentUser.id}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col w-full lg:w-1/3 xl:w-full gap-y-2 pb-4 xl:border-navy-200 xl:border-b-2 mb-4">
        <Typography
          element="h3"
          variant="h6"
          className="text-navy-600 font-biotif"
        >
          Zastupnici kluba
        </Typography>
        <div className="flex flex-col gap-y-2">
          <CardWithPicture
            title="Predsjednik"
            name={clubManagementData.president}
          />
          <CardWithPicture
            title="Dopredsjednik"
            name={clubManagementData.vicePresident}
          />
          <CardWithPicture
            title="Direktor"
            name={clubManagementData.director}
          />
        </div>
      </div>
      <div className="flex flex-col w-full lg:w-1/3 xl:w-full gap-y-2">
        <Typography
          element="h3"
          variant="h6"
          className="text-navy-600 font-biotif"
        >
          Podaci o klubu
        </Typography>
        <div className="flex bg-white shadow rounded-lg px-1 py-2">
          <div className="flex-col">
            <div className="flex">
              <Icon
                className="px-1 rounded-full h-[1.45rem]"
                type="map-pin"
                variant="fill"
                size={6}
              />
              <Typography element="h3" variant="h6">
                Adresa kluba
              </Typography>
            </div>
            <Typography element="p" className="pl-2" variant="subtext">
              {(basicClubData.streetName === null ||
                basicClubData.streetName === "") &&
              (basicClubData.city === null || basicClubData.city === "") ? (
                <i>Nema informacija</i>
              ) : null}
              <span>{basicClubData.streetName}</span>
              {basicClubData.houseNumber !== null &&
              basicClubData.houseNumber !== "" ? (
                <span> {basicClubData.houseNumber}</span>
              ) : null}
              {basicClubData.streetName !== "" &&
              basicClubData.streetName !== null &&
              basicClubData.city !== null &&
              basicClubData.city !== "" ? (
                <span>, </span>
              ) : null}
              <span>{basicClubData.zipCode} </span>
              {basicClubData.zipCode !== "" &&
              basicClubData.zipCode !== null ? (
                <span>{basicClubData.city}</span>
              ) : (
                <span> {basicClubData.city}</span>
              )}
            </Typography>
          </div>
        </div>
        <div className="flex bg-white shadow rounded-lg px-1 py-2">
          <div className="flex-col">
            <div className="flex">
              <Icon
                className="px-1 rounded-full h-[1.45rem]"
                type="hash"
                variant="bold"
                size={6}
              />
              <Typography element="h3" variant="h6">
                OIB
              </Typography>
            </div>
            <Typography element="p" className="pl-2" variant="subtext">
              {basicClubData.oib ? basicClubData.oib : <i>Nema informacija</i>}
            </Typography>
          </div>
        </div>
        <div className="flex bg-white shadow rounded-lg px-1 py-2">
          <div className="flex-col ">
            <div className="flex">
              <Icon
                className="px-1 rounded-full h-[1.45rem]"
                type="hash"
                variant="bold"
                size={6}
              />
              <Typography element="h3" variant="h6">
                IBAN
              </Typography>
            </div>
            <Typography
              element="p"
              className="pl-2 text-[0.875rem] xl:text-[0.72rem] 2xl:text-[0.875rem]"
              variant="subtext"
            >
              {basicClubData.iban ? (
                basicClubData.iban
              ) : (
                <i>Nema informacija</i>
              )}
            </Typography>
          </div>
        </div>
      </div>
      <CreateUserModal
        modal={createUser}
        setEntityCreated={setEntityCreated}
        entityCreated={entityCreated}
      />
    </div>
  );
}
