import { CreateInvoiceRequest } from "./CreateInvoiceRequest";
import { INVOICE_URL } from "../../../common/Routes";
import { InvoiceResponse } from "../../common/InvoiceResponse";

export async function postPrepareInvoices(
  request: CreateInvoiceRequest
): Promise<InvoiceResponse[]> {
  const response = await fetch(`${INVOICE_URL}/prepare`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
