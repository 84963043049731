import { RankReportsRequestPageable } from "./RankReportsRequestPageable";
import { REPORTS_RANKING_RANK_URL } from "../../common/Routes";

export async function getRankReports(request: RankReportsRequestPageable) {
  const response = await fetch(`${REPORTS_RANKING_RANK_URL}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });

  return await response.json();
}
