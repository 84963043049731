import React, { useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Button, Card, Typography } from "@tiller-ds/core";

import EmptyPersonalRecordsCard from "./EmptyPersonalRecordsCard";
import HotStreakCard from "./HotStreakCard";
import { AthleteDetailedResponse } from "../../../archive/athlete/api/AthleteDetailedResponse";
import ProgressResultMapper from "../../../archive/charts/util/ProgressResultMapper";
import { getProgressTypes } from "../../../archive/statistics/api/getProgressTypes";
import { ProgressTypeResponse } from "../../../archive/statistics/api/ProgressTypeResponse";
import ProgressTypeCard from "../../../archive/statistics/components/cards/ProgressTypeCard";
import PersonalRecordsModal from "../modals/PersonalRecordsModal";

type AthletePersonalRecordsProps = {
  athlete: AthleteDetailedResponse;
  progressTypeList: ProgressTypeResponse[] | null;
};

type PersonalRecord = {
  progressTypeId: number;
  date: Date;
  result: number;
};

export default function AthletePersonalRecords({
  athlete,
  progressTypeList,
}: AthletePersonalRecordsProps) {
  const [progressTypes, setProgressTypes] = useState(
    [] as ProgressTypeResponse[]
  );
  const [loaded, setLoaded] = useState(false);

  const RENDER_RESULTS = 3;

  useEffect(() => {
    if (progressTypeList === null) {
      getProgressTypes().then((response: ProgressTypeResponse[]) => {
        setProgressTypes(response);
        setLoaded(true);
      });
    } else {
      setProgressTypes(progressTypeList);
      setLoaded(true);
    }
  }, [progressTypeList]);

  const renderTopResults = () => {
    let personalRecords: PersonalRecord[];
    personalRecords = progressTypes
      .filter(
        (progressType) =>
          progressType.displayInPersonalRecords &&
          athlete.personalRecords.topResults[progressType.id]
      )
      .map((progressType) => {
        const personalRecordObject =
          athlete.personalRecords.topResults[progressType.id];

        const personalRecord = {} as PersonalRecord;
        personalRecord.progressTypeId = progressType.id;
        const dateString = Object.keys(personalRecordObject)[0];
        personalRecord.date = new Date(dateString);
        personalRecord.result = personalRecordObject[dateString];

        return personalRecord;
      })
      .sort((a, b) => b.date.getTime() - a.date.getTime());

    return personalRecords.map((personalRecord) => {
      const progressType = progressTypes.find(
        (progressType) => progressType.id === personalRecord.progressTypeId
      ) as ProgressTypeResponse;

      const topResult = ProgressResultMapper(
        personalRecord.result,
        progressType.measuringUnit
      );
      return (
        <ProgressTypeCard progressType={progressType} topResult={topResult} />
      );
    });
  };

  const modalPersonalRecords = useModal();

  if (!loaded) {
    return <></>;
  } else {
    return (
      <>
        <Card className="flex h-full w-full">
          <Card.Body>
            <div className="flex justify-between items-end w-full mb-2">
              <Typography variant="h5" className="font-biotif">
                Osobni rekordi
              </Typography>
              <Button
                variant="outlined"
                size="md"
                color="primary"
                onClick={modalPersonalRecords.onOpen}
              >
                Pogledaj sve
              </Button>
            </div>
            <div className="flex flex-col w-full">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 gap-2 xl:gap-y-4 2xl:gap-x-4 show-first-four">
                <HotStreakCard athlete={athlete} />
                {renderTopResults().slice(0, RENDER_RESULTS)}
                <EmptyPersonalRecordsCard />
                <EmptyPersonalRecordsCard />
                <EmptyPersonalRecordsCard />
              </div>
            </div>
          </Card.Body>
        </Card>
        <PersonalRecordsModal
          modal={modalPersonalRecords}
          renderTopResults={renderTopResults}
          athlete={athlete}
        />
      </>
    );
  }
}
