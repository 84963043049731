import React from "react";

import { Typography } from "@tiller-ds/core";

type NoAvalibleDataProps = {
  message: string;
  className?: string;
};

export default function NoAvailableData({
  message,
  className,
}: NoAvalibleDataProps) {
  const predefinedClassName = `${className} flex flex-col gap-y-2 pr-2`;
  return (
    <div className={predefinedClassName}>
      <Typography className="text-sm italic">{message}</Typography>
    </div>
  );
}
