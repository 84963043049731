import { PlannedAbsenceRequest } from "./PlannedAbsenceRequest";
import { PLANNED_ABSENCE_URL } from "../../common/Routes";

export async function postPlannedAbsence(
  request: PlannedAbsenceRequest,
  participantId: string | number
) {
  const response = await fetch(
    `${PLANNED_ABSENCE_URL}/users/${participantId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    }
  );
  return response.json();
}
