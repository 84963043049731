import { CreateEventNoteRequest } from "./CreateEventNoteRequest";
import { EVENT_URL } from "../../../common/Routes";

export async function updateEventNote(
  request: CreateEventNoteRequest,
  noteId: number,
  eventId: string
) {
  const response = await fetch(`${EVENT_URL}/${eventId}/notes/${noteId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
