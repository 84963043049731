import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

import { useNavigate } from "react-router-dom";

type CancelModalProps = {
  modal: UseModal;
  title: string;
  returnPath: string;
};

export default function CancelModal({
  modal,
  title,
  returnPath,
}: CancelModalProps) {
  const navigate = useNavigate();

  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h4" variant="h4">
            Napusti stranicu
          </Typography>
        }
      >
        <Typography
          element="p"
          variant="subtext"
          className="pb-4 text-gray-500"
        >
          Jeste li sigurni da želite napustiti stranicu "{title}"? Sve što je do
          sada napisano biti će izgubljeno.
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={() => navigate(returnPath)}>
          Napusti stranicu
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
