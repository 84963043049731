import React, { Dispatch, SetStateAction } from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";
import { RadioGroup } from "@tiller-ds/form-elements";
import {
  CheckboxField,
  InputField,
  NumberInputField,
} from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";

import { useNotifications } from "@croz/nrich-notification-core";

import { Formik } from "formik";

import SearchInvoiceRecipientGroupAutocompleteComponent from "./SearchInvoiceRecipientGroupAutocompleteComponent";
import SearchAthleteAutocompleteComponent from "../../../archive/athlete/components/SearchAthleteAutocompleteComponent";
import handleUpdateResponse from "../../../archive/common/handleUpdateResponse";
import {
  INVOICE_RECIPIENT_GROUP,
  INVOICE_RECIPIENTS,
} from "../../../common/constants";
import { CreateInvoiceTemplateRequest } from "../api/createInvoiceTemplateRequest";
import { InvoiceRecipientGroupResponse } from "../api/InvoiceRecipientGroupResponse";
import { InvoiceRecipientResponse } from "../api/InvoiceRecipientResponse";
import { InvoiceTemplateResponse } from "../api/InvoiceTemplateResponse";
import { postCreateInvoiceTemplateRequest } from "../api/postCreateInvoiceTemplateRequest";
import { putUpdateInvoiceTemplateRequest } from "../api/putUpdateInvoiceTemplateRequest";
import { UpdateInvoiceTemplateRequest } from "../api/updateInvoiceTemplateRequest";
import handleCreateInvoiceTemplateResponse from "../handleCreateInvoiceTemplateResponse";
import { invoiceTemplateValidationSchema } from "../validation/invoiceTemplateValidationSchema";

type Form = {
  id?: number;
  title: string;
  amount: number;
  applyDiscount: boolean;
  dueDays: number;
  description: string;
  referenceNumber: string;
  addAthleteNameToDescription: boolean;
  invoiceRecipients?: InvoiceRecipientResponse[];
  invoiceRecipientGroups?: InvoiceRecipientGroupResponse[];
};

type CreateOrUpdateInvoiceTemplateModalProps = {
  modal: UseModal;
  invoiceTemplate: InvoiceTemplateResponse;
  setRender: (render: boolean) => void;
  selectedRecipientType: string;
  setSelectedRecipientType: Dispatch<SetStateAction<string>>;
  isUpdateModal: boolean;
};

const CreateOrUpdateInvoiceTemplateModal = ({
  modal,
  invoiceTemplate,
  setRender,
  selectedRecipientType,
  setSelectedRecipientType,
  isUpdateModal,
}: CreateOrUpdateInvoiceTemplateModalProps) => {
  const { add: addNotification } = useNotifications();

  const initialFormValues = {
    id: invoiceTemplate.id,
    title: invoiceTemplate.title,
    amount: invoiceTemplate.amount,
    dueDays: invoiceTemplate.dueDays,
    description: invoiceTemplate.description,
    applyDiscount: invoiceTemplate.applyDiscount,
    invoiceRecipients: invoiceTemplate.invoiceRecipients,
    invoiceRecipientGroups: invoiceTemplate.invoiceRecipientGroups,
    addAthleteNameToDescription: invoiceTemplate.addAthleteNameToDescription,
  } as Form;

  let onSubmitAction = (form: Form) => {
    if (!form.applyDiscount) {
      form.applyDiscount = false;
    }

    if (!form.addAthleteNameToDescription) {
      form.addAthleteNameToDescription = false;
    }

    if (isUpdateModal) {
      putUpdateInvoiceTemplateRequest({
        id: form.id,
        title: form.title,
        amount: form.amount,
        dueDays: form.dueDays,
        description: form.description,
        applyDiscount: form.applyDiscount,
        invoiceRecipients: form.invoiceRecipients?.map(
          (recipient) => recipient.id
        ),
        invoiceRecipientGroups: form.invoiceRecipientGroups?.map(
          (recipientGroup) => recipientGroup.id
        ),
        addAthleteNameToDescription: form.addAthleteNameToDescription,
      } as UpdateInvoiceTemplateRequest).then((response) => {
        if (handleUpdateResponse(response, addNotification)) {
          setRender(true);
          modal.onClose();
        }
      });
    } else {
      postCreateInvoiceTemplateRequest({
        title: form.title,
        amount: form.amount,
        dueDays: form.dueDays,
        description: form.description,
        applyDiscount: form.applyDiscount,
        invoiceRecipients: form.invoiceRecipients?.map(
          (recipient) => recipient.id
        ),
        invoiceRecipientGroups: form.invoiceRecipientGroups?.map(
          (recipientGroup) => recipientGroup.id
        ),
        addAthleteNameToDescription: form.addAthleteNameToDescription,
      } as CreateInvoiceTemplateRequest).then((response) => {
        if (handleCreateInvoiceTemplateResponse(response, addNotification)) {
          setRender(true);
          modal.onClose();
        }
      });
    }
  };

  return (
    <Modal {...modal}>
      <Formik
        validationSchema={invoiceTemplateValidationSchema}
        initialValues={initialFormValues}
        onSubmit={onSubmitAction}
      >
        {(formik) => (
          <form
            className="pr-3"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Typography className="mb-4" variant="h4" element="h4">
              {isUpdateModal ? "Uredi predložak" : "Kreiraj predložak"}
            </Typography>
            <InputField
              name="title"
              label={"Naziv predloška"}
              className="w-full mt-3"
              allowClear={true}
              required={true}
            />
            <div className="flex flex-col  mt-3 sm:flex-row sm:justify-between">
              <NumberInputField
                name="amount"
                label={"Iznos"}
                className="w-full sm:mr-2 sm:w-1/2 flex-grow"
                inlineTrailingIcon={
                  <Icon type="currency-eur" variant="light" />
                }
                allowClear={false}
                required={true}
                decimalScale={2}
                fixedDecimalScale
              />
              <div className="translate-y-1/2">
                <CheckboxField
                  name="applyDiscount"
                  label={"Primijeni popust"}
                />
              </div>
            </div>
            <InputField
              name="dueDays"
              label={"Broj dana do dospijeća"}
              className="mt-3 w-full"
              allowClear={true}
            />
            <div className="flex flex-col gap-y-1">
              <InputField
                name="description"
                label={"Opis plaćanja"}
                className="mt-3 w-full"
                allowClear={true}
              />
              <CheckboxField
                name="addAthleteNameToDescription"
                label="Dodaj ime sportaša u opis"
              />
            </div>
            <div className="w-full">
              <RadioGroup
                name="autocompleteType"
                label=""
                onChange={(value) => {
                  setSelectedRecipientType(value as string);
                  formik.setFieldValue(INVOICE_RECIPIENT_GROUP, []);
                  formik.setFieldValue(INVOICE_RECIPIENTS, []);
                }}
                value={selectedRecipientType}
              >
                <RadioGroup.Item
                  label="Grupe"
                  value={INVOICE_RECIPIENT_GROUP}
                ></RadioGroup.Item>
                <RadioGroup.Item
                  label="Pojedinačni članovi"
                  value={INVOICE_RECIPIENTS}
                ></RadioGroup.Item>
              </RadioGroup>
            </div>
            <div className="flex flex-col w-full mt-3">
              {selectedRecipientType === INVOICE_RECIPIENTS && (
                <SearchAthleteAutocompleteComponent />
              )}
              {selectedRecipientType === INVOICE_RECIPIENT_GROUP && (
                <SearchInvoiceRecipientGroupAutocompleteComponent />
              )}
            </div>
            <div className="flex flex-col-reverse gap-y-3 sm:gap-y-0 mt-6 sm:flex-row sm:justify-end">
              <Button
                type="button"
                className="w-full sm:mr-2 sm:w-fit"
                variant="outlined"
                onClick={() => {
                  modal.onClose();
                }}
              >
                Odbaci promjene
              </Button>
              <Button
                className="w-full sm:w-fit"
                variant="filled"
                type="submit"
              >
                Spremi
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateOrUpdateInvoiceTemplateModal;
