import * as React from "react";
import { createContext, useState } from "react";

import { MessageFilterRequest } from "./api/MessageFilterRequest";
import {
  BASE_MESSAGES_FILTER_REQUEST,
  MessageFilter,
  MESSAGES_PER_PAGE,
} from "../common/constants";

type MessageProviderProps = {
  children: React.ReactNode;
};

export const MessageContext = createContext({
  page: 0,
  totalElements: 0,
  pageSize: MESSAGES_PER_PAGE,
  showSent: false,
  messageFilter: MessageFilter.RECEIVED,
  messageFilterRequest: BASE_MESSAGES_FILTER_REQUEST,
  setPage: (page: number) => {},
  setTotalElements: (page: number) => {},
  setPageSize: (page: number) => {},
  setShowSent: (showSent: boolean) => {},
  setMessageFilter: (messageFilter: MessageFilter) => {},
  setMessageFilterRequest: (messageFilterRequest: MessageFilterRequest) => {},
});

export default function MessageProvider({ children }: MessageProviderProps) {
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(MESSAGES_PER_PAGE);
  const [showSent, setShowSent] = useState(false);
  const [messageFilter, setMessageFilter] = useState(MessageFilter.RECEIVED);
  const [messageFilterRequest, setMessageFilterRequest] = useState(
    BASE_MESSAGES_FILTER_REQUEST
  );

  const value = {
    page,
    totalElements,
    pageSize,
    showSent,
    messageFilter,
    messageFilterRequest,
    setPage,
    setTotalElements,
    setPageSize,
    setShowSent,
    setMessageFilter,
    setMessageFilterRequest,
  };

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
}
