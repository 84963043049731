import React, { useEffect, useState } from "react";

import { Tabs, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import _ from "lodash";

import AthleteChart, { StatisticByDate } from "./AthleteChart";
import ComparisonChart from "./ComparisonChart";
import { MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE } from "../../../common/constants";
import { AthleteResponse } from "../../athlete/api/AthleteResponse";
import NoAvailableData from "../../common/components/NoAvailableData";
import { GroupResponse } from "../../group/api/GroupResponse";
import { getAverageMonthlyProgressDataOfAthlete } from "../../statistics/api/getAverageMonthlyProgressDataOfAthlete";
import { getAverageMonthlyProgressDataOfGroup } from "../../statistics/api/getAverageMonthlyProgressDataOfGroup";
import {
  AthleteProgressDataRequest,
  postSearchAthleteProgressDataRequest,
} from "../../statistics/api/postSearchAthleteProgressDataRequest";
import { ProgressTrackingDataResponse } from "../../statistics/api/ProgressTrackingDataResponse";
import { ProgressTypeResponse } from "../../statistics/api/ProgressTypeResponse";

type AthleteChartSelectorProps = {
  athlete: AthleteResponse;
  progressTypeList: ProgressTypeResponse[];
  groupList: GroupResponse[];
  athleteList?: AthleteResponse[];
};

export default function AthleteChartSelector({
  athlete,
  progressTypeList,
  groupList,
  athleteList,
}: AthleteChartSelectorProps) {
  const [athleteProgressData, setAthleteProgressData] = useState(
    {} as Record<number, StatisticByDate[]>
  );
  const [selectedAthlete, setSelectedAthlete] = useState(athlete);
  const [
    selectedAthleteMonthlyAvgProgressData,
    setSelectedAthleteMonthlyAvgProgressData,
  ] = useState({} as Record<number, Record<string, number>>);
  const [selectedGroup, setSelectedGroup] = useState({} as GroupResponse);
  const [
    selectedGroupMonthlyAvgProgressData,
    setSelectedGroupMonthlyAvgProgressData,
  ] = useState({} as Record<number, Record<string, number>>);

  useEffect(() => {
    if (selectedAthlete !== undefined) {
      postSearchAthleteProgressDataRequest({
        athleteId: selectedAthlete.id,
      } as AthleteProgressDataRequest).then((response) => {
        const athleteProgressDataByProgressType = {} as Record<
          number,
          StatisticByDate[]
        >;

        response.forEach((data: ProgressTrackingDataResponse) => {
          if (!athleteProgressDataByProgressType[data.progressType.id]) {
            const statistic = [] as StatisticByDate[];
            statistic.push({
              date: data.date.toString(),
              result: data.result,
            } as StatisticByDate);
            athleteProgressDataByProgressType[data.progressType.id] = statistic;
          } else if (
            athleteProgressDataByProgressType[data.progressType.id].length < 31
          ) {
            athleteProgressDataByProgressType[data.progressType.id].push({
              date: data.date.toString(),
              result: data.result,
            } as StatisticByDate);
          }
        });
        setAthleteProgressData(athleteProgressDataByProgressType);
      });
    }
  }, [selectedAthlete]);

  useEffect(() => {
    if (athleteList && athleteList.length > 0) {
      setSelectedAthlete(athleteList[0]);
    }
  }, [athleteList]);

  useEffect(() => {
    if (!_.isEmpty(selectedGroup) && !_.isEmpty(selectedAthlete)) {
      getAverageMonthlyProgressDataOfGroup(selectedGroup.id).then(
        (response: Record<number, Record<string, number>>) => {
          setSelectedGroupMonthlyAvgProgressData(response);
        }
      );
      getAverageMonthlyProgressDataOfAthlete(selectedAthlete.id).then(
        (response: Record<number, Record<string, number>>) => {
          setSelectedAthleteMonthlyAvgProgressData(response);
        }
      );
    }
  }, [selectedAthlete, selectedGroup]);

  if (progressTypeList.length === 0) {
    return <NoAvailableData message="Nema statistike za praćenje" />;
  }

  return (
    <Tabs defaultIndex={0}>
      {progressTypeList.map((progressType: ProgressTypeResponse) => {
        return (
          <Tabs.Tab label={progressType.name}>
            <div className="justify-center mt-4">
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <Typography
                  element="h6"
                  variant="h6"
                  className="py-2 pr-2 flex w-full justify-start"
                >
                  Mjerenja sportaša
                </Typography>
                {athleteList && athleteList.length > 1 && (
                  <div className="flex w-full justify-start sm:justify-end">
                    <DropdownMenu
                      title={
                        selectedAthlete
                          ? `${selectedAthlete.firstName} ${selectedAthlete.lastName}`
                          : "Izaberi Sportaša"
                      }
                      color="primary"
                      visibleItemCount={
                        athleteList.length <
                        MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE
                          ? athleteList.length
                          : MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE
                      }
                      openExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                      closeExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                    >
                      {athleteList.map((athlete: AthleteResponse) => {
                        return (
                          <DropdownMenu.Item
                            onSelect={() => {
                              setSelectedAthlete(athlete);
                            }}
                          >
                            {`${athlete.firstName} ${athlete.lastName}`}
                          </DropdownMenu.Item>
                        );
                      })}
                    </DropdownMenu>
                  </div>
                )}
              </div>
              {athleteProgressData[progressType.id] ? (
                <AthleteChart
                  progressType={progressType}
                  athleteStatistic={athleteProgressData[progressType.id]}
                />
              ) : (
                <NoAvailableData message="Nema praćene statistike" />
              )}
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <Typography
                  element="h6"
                  variant="h6"
                  className="py-2 pr-2 flex w-fit sm:w-full justify-start"
                >
                  Mjesečni prosjek sportaša u odnosu na odabranu grupu
                </Typography>
                <div className="flex w-full justify-start sm:justify-end">
                  {groupList.length > 0 && (
                    <DropdownMenu
                      title={
                        selectedGroup.groupName
                          ? selectedGroup.groupName
                          : "Izaberi Grupu"
                      }
                      color="primary"
                      visibleItemCount={
                        groupList.length <
                        MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE
                          ? groupList.length
                          : MAX_VISIBLE_ITEM_COUNT_DROPDOWN_ARCHIVE
                      }
                      openExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                      closeExpanderIcon={
                        <Icon type="caret-down" variant="bold" />
                      }
                    >
                      {groupList.map((group: GroupResponse) => {
                        return (
                          <DropdownMenu.Item
                            onSelect={() => {
                              setSelectedGroup(group);
                            }}
                          >
                            {group.groupName}
                          </DropdownMenu.Item>
                        );
                      })}
                    </DropdownMenu>
                  )}
                </div>
              </div>
              {selectedGroupMonthlyAvgProgressData[progressType.id] &&
              selectedAthleteMonthlyAvgProgressData[progressType.id] ? (
                <ComparisonChart
                  athleteOrGroupName={`${selectedAthlete.firstName} ${selectedAthlete.lastName}`}
                  athleteOrGroupProgressData={
                    selectedAthleteMonthlyAvgProgressData[progressType.id]
                  }
                  athleteOrGroupToCompareName={selectedGroup.groupName}
                  athleteOrGroupToCompareProgressData={
                    selectedGroupMonthlyAvgProgressData[progressType.id]
                  }
                  progressType={progressType}
                />
              ) : (
                <NoAvailableData message="Nema praćene statistike" />
              )}
            </div>
          </Tabs.Tab>
        );
      })}
    </Tabs>
  );
}
