import { AthleteSearchRequest } from "./AthleteSearchRequest";
import { ATHLETE_URL } from "../../../common/Routes";

export async function postSearchAthleteRequest(request: AthleteSearchRequest) {
  const response = await fetch(`${ATHLETE_URL}/search/pageable`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
