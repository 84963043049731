import React, { useContext, useEffect, useState } from "react";

import { useModal } from "@tiller-ds/alert";
import { Card, IconButton, Pagination, Typography } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Icon } from "@tiller-ds/icons";
import { DropdownMenu } from "@tiller-ds/menu";

import { useNotifications } from "@croz/nrich-notification-core";

import { UserContext } from "../../../auth/SecurityProvider";
import DeleteModal from "../../../common/components/modals/DeleteModal";
import {
  MAX_ARCHIVE_ROWS,
  REGISTRY_SEARCH_ERROR_MESSAGE,
} from "../../../common/constants";
import { UserType } from "../../common/api/UserResponse";
import RegistrySearch from "../../common/components/RegistrySearch";
import handleDeleteResponse from "../../common/handleDeleteResponse";
import { RegistryContext } from "../../common/RegistryProvider";
import { AdminResponse } from "../api/AdminResponse";
import { AdminSearchRequest } from "../api/AdminSearchRequest";
import { deleteAdmin } from "../api/deleteAdmin";
import { exportAdminsExcel } from "../api/exportAdminsExcel";
import { exportAdminsPDF } from "../api/exportAdminsPDF";
import { postSearchAdminRequest } from "../api/postSearchAdminRequest";

type AdminRegistryProps = {
  totalAdmins: number;
  setTotalAdmins: (totalAdmins: number) => void;
  entityCreated: number;
};

export default function AdminRegistry({
  totalAdmins,
  setTotalAdmins,
  entityCreated,
}: AdminRegistryProps) {
  const { user, userHasRole } = React.useContext(UserContext);
  const isAdmin = userHasRole(UserType.ADMIN);
  const deleteAdminModal = useModal();
  const [adminId, setAdminId] = useState(0);
  const [AdminTableData, setAdminTableData] = useState([] as AdminResponse[]);
  const [error, setError] = useState("");
  const { adminRegistryPage, setAdminRegistryPage } = useContext(
    RegistryContext
  );
  const { add: addNotification } = useNotifications();
  const [searchRequestJson, setSearchRequestJson] = useState({
    pageNumber: adminRegistryPage,
    pageSize: 10,
    sortPropertyList: [
      {
        property: "lastName",
        direction: "ASC",
      },
      {
        property: "firstName",
        direction: "ASC",
      },
    ],
  } as AdminSearchRequest);

  const deleteAdminModalFunction = () => {
    deleteAdmin(adminId).then((response) => {
      if (response.ok) {
        handleDeleteResponse(response, addNotification);
        setSearchRequestJson((searchRequestJson) => ({
          ...searchRequestJson,
          pageNumber: adminRegistryPage,
        }));
      }
      deleteAdminModal.onClose();
    });
  };

  useEffect(() => {
    postSearchAdminRequest(searchRequestJson).then((response: any) => {
      setTotalAdmins(response.totalElements);
      setAdminTableData(response.content);

      response.content.length > 0
        ? setError("")
        : setError(REGISTRY_SEARCH_ERROR_MESSAGE);
    });
  }, [searchRequestJson, setTotalAdmins, entityCreated]);

  return (
    <>
      <Card.Header className="py-2" removeSpacing={true}>
        <div className="flex justify-between items-center sm:items-end flex-col md:flex-row">
          <Typography element="h4" variant="h4" className="hidden md:block">
            Administratori
          </Typography>
          <div className="flex w-full sm:w-auto justify-start md:justify-end">
            <div className="flex w-full gap-2 items-center">
              <RegistrySearch
                searchRequestJson={searchRequestJson}
                setSearchRequestJson={setSearchRequestJson}
              ></RegistrySearch>
            </div>
          </div>
          <DropdownMenu
            title="Preuzmi"
            color="primary"
            iconPlacement="leading"
            openExpanderIcon={<Icon type="download-simple" variant="bold" />}
            closeExpanderIcon={<Icon type="download-simple" variant="bold" />}
          >
            <DropdownMenu.Item
              onSelect={() => exportAdminsExcel(searchRequestJson)}
            >
              .xlsx
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={() => exportAdminsPDF(searchRequestJson)}
            >
              .pdf
            </DropdownMenu.Item>
          </DropdownMenu>
        </div>
      </Card.Header>
      <Card.Body removeSpacing={true}>
        <DataTable data={AdminTableData}>
          <DataTable.Column
            className={isAdmin ? "w-3/12" : "w-3/12"}
            header="Ime"
            id="firstName"
            canSort={false}
          >
            {(item: AdminResponse) => item.firstName}
          </DataTable.Column>
          <DataTable.Column
            className={isAdmin ? "w-3/12" : "w-3/12"}
            header="Prezime"
            id="lastName"
            canSort={false}
          >
            {(item: AdminResponse) => item.lastName}
          </DataTable.Column>
          <DataTable.Column
            className={isAdmin ? "w-5/12" : "w-6/12"}
            header="Kontakt"
            id="contact"
            canSort={false}
          >
            {(item: AdminResponse) => {
              return (
                <>
                  <span>{item.email}</span> <br></br>
                  <span>{item.phoneNumber}</span>
                </>
              );
            }}
          </DataTable.Column>
          {userHasRole(UserType.ADMIN) ? (
            <DataTable.Column
              className="w-1/12"
              header="Akcije"
              id="actions"
              canSort={false}
            >
              {(item: AdminResponse) =>
                user.id !== item.id && (
                  <IconButton
                    icon={
                      <Icon
                        type="trash"
                        variant="bold"
                        className="text-gray-500"
                      />
                    }
                    label="Izbriši"
                    onClick={() => {
                      setAdminId(item.id);
                      deleteAdminModal.onOpen(null);
                    }}
                  />
                )
              }
            </DataTable.Column>
          ) : (
            <></>
          )}
        </DataTable>
        <div className="flex justify-center my-3">
          <Typography className="text-red-600" variant="subtext">
            {error}
          </Typography>
        </div>
      </Card.Body>
      {totalAdmins > MAX_ARCHIVE_ROWS && (
        <Card.Footer className="flex items-center justify-end">
          <Pagination
            onPageChange={(i: number) => {
              setAdminRegistryPage(i);
              setSearchRequestJson((searchRequestJson) => ({
                ...searchRequestJson,
                pageNumber: i,
              }));
            }}
            pageNumber={adminRegistryPage}
            pageSize={MAX_ARCHIVE_ROWS}
            totalElements={totalAdmins}
          >
            {() => null}
          </Pagination>
        </Card.Footer>
      )}

      <DeleteModal
        modal={deleteAdminModal}
        title="Izbriši administratora"
        content="Jeste li sigurni da želite izbrisati administratora?"
        buttonConfirmText="Izbriši administratora"
        buttonConfirmFunction={deleteAdminModalFunction}
      />
    </>
  );
}
