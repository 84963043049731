import { MessageRequest } from "./MessageRequest";
import { MESSAGE_URL } from "../../common/Routes";

export async function postMessage(message: MessageRequest) {
  const response = await fetch(MESSAGE_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(message),
  });
  return response.json();
}
