import React, { useState } from "react";

import { Typography } from "@tiller-ds/core";
import { Checkbox, Input } from "@tiller-ds/form-elements";

import { Answer } from "./SurveyQuestionCreate";
import { MAX_SURVEY_OPTION_VALUE_LENGTH } from "../../../../../common/constants";

type MultipleAnswerCreateProps = {
  fieldName: string;
  setAnswers: (answers: Answer[]) => void;
  answers: Answer[];
  singleChoice: boolean;
};

export default function MultipleAnswerOptionCreate({
  fieldName,
  setAnswers,
  answers,
  singleChoice,
}: MultipleAnswerCreateProps) {
  const [answerInput, setAnswerInput] = useState("");

  const inputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    setAnswerInput(value);
  };

  const UpdateAnswer = () => {
    const updatedAnswers = [...answers].map((answer) => {
      if (answer.name === fieldName) {
        answer.value = answerInput;
      }
      return answer;
    });
    setAnswers(updatedAnswers);
  };

  return (
    <div className="flex flex-col justify-between w-full py-2 px-6 h-fit border border-navy-200 rounded">
      <div className="flex flex-row">
        {singleChoice ? (
          <input
            className="mr-4 p-2 h-6 w-6"
            type="radio"
            disabled
            readOnly={true}
          />
        ) : (
          <Checkbox
            className="mr-4 rounded-full p-2 h-6 w-6"
            disabled={true}
            readOnly={true}
          />
        )}
        <Input
          value={answerInput}
          name={fieldName}
          placeholder={`Upišite opciju ${parseInt(fieldName) + 1}.`}
          allowClear={true}
          className="w-full"
          maxLength={MAX_SURVEY_OPTION_VALUE_LENGTH}
          onChange={inputHandler}
          onBlur={UpdateAnswer}
        />
      </div>
      <Typography
        variant="subtext"
        className="flex justify-end text-gray-400"
      >{`${
        answerInput.toString().length
      }/${MAX_SURVEY_OPTION_VALUE_LENGTH}`}</Typography>
    </div>
  );
}
