import React from "react";

import { Tabs } from "@tiller-ds/core";

import EventCardWithTimeAndLocation from "../../../common/components/cards/EventCardWithTimeAndLocation";
import { EventType } from "../../../common/constants";
import { EventResponse } from "../../../event/api/EventResponse";
import NoAvailableData from "../../common/components/NoAvailableData";
import { GroupResponse } from "../../group/api/GroupResponse";

type AthleteGroupsEventsSelectorProps = {
  groupList: GroupResponse[];
};

type AthleteGroupTrainingEvents = {
  groupName: string;
  groupTrainingEventsResponse: EventResponse[];
};

export default function AthleteGroupsEventsSelector({
  groupList,
}: AthleteGroupsEventsSelectorProps) {
  const currentDate = new Date();

  const getTrainingEvents = (group: GroupResponse) => {
    return group.events
      .sort((firstEvent, secondEvent) =>
        firstEvent.startTime.localeCompare(secondEvent.startTime)
      )
      .filter(
        (event) =>
          new Date(event.startTime) >= currentDate &&
          event.eventType === EventType.TRAINING
      );
  };

  const athleteGroupTrainingEvents: AthleteGroupTrainingEvents[] = groupList.map(
    (group) => {
      return {
        groupName: group.groupName,
        groupTrainingEventsResponse: getTrainingEvents(group),
      };
    }
  );

  return (
    <Tabs>
      {athleteGroupTrainingEvents.map((group) => {
        return (
          <Tabs.Tab label={group.groupName}>
            <div className="flex flex-col h-64 overflow-auto">
              {group.groupTrainingEventsResponse.length > 0 ? (
                group.groupTrainingEventsResponse.map((event) => {
                  return (
                    <EventCardWithTimeAndLocation
                      event={event}
                      className="mt-4"
                    />
                  );
                })
              ) : (
                <NoAvailableData
                  className="mt-2"
                  message="Nema zakazanog termina u ovoj grupi"
                />
              )}
            </div>
          </Tabs.Tab>
        );
      })}
    </Tabs>
  );
}
