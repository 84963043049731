import { NOTE_URL } from "../../../../common/Routes";

export function deleteAthleteNote(id: number) {
  return fetch(`${NOTE_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response;
  });
}
