import { InvoiceRecipientGroupResponse } from "./InvoiceRecipientGroupResponse";
import { INVOICE_RECIPIENT_GROUPS_URL } from "../../../common/Routes";

export async function getAthleteRecipientGroups(): Promise<
  InvoiceRecipientGroupResponse[]
> {
  const response = await fetch(`${INVOICE_RECIPIENT_GROUPS_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}
