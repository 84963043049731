import { INVOICE_URL } from "../../../common/Routes";

export async function getGenerateQRCode(invoiceId: number) {
  return await fetch(`${INVOICE_URL}/qr/${invoiceId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
