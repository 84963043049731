import { NOTE_URL } from "../../../../common/Routes";
import { AthleteNoteRequest } from "../../api/AthleteNoteRequest";

export async function putUpdateAthleteNote(
  request: AthleteNoteRequest,
  noteId: number
) {
  const response = await fetch(`${NOTE_URL}/${noteId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
  return response.json();
}
